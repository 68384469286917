const county = [
    {
        "id":"110000000000",
        "label":"北京市",
        "children":[
            {
                "id":"110101000000",
                "label":"东城区",
                "full_label":"北京市东城区"
            },
            {
                "id":"110102000000",
                "label":"西城区",
                "full_label":"北京市西城区"
            },
            {
                "id":"110105000000",
                "label":"朝阳区",
                "full_label":"北京市朝阳区"
            },
            {
                "id":"110106000000",
                "label":"丰台区",
                "full_label":"北京市丰台区"
            },
            {
                "id":"110107000000",
                "label":"石景山区",
                "full_label":"北京市石景山区"
            },
            {
                "id":"110108000000",
                "label":"海淀区",
                "full_label":"北京市海淀区"
            },
            {
                "id":"110109000000",
                "label":"门头沟区",
                "full_label":"北京市门头沟区"
            },
            {
                "id":"110111000000",
                "label":"房山区",
                "full_label":"北京市房山区"
            },
            {
                "id":"110112000000",
                "label":"通州区",
                "full_label":"北京市通州区"
            },
            {
                "id":"110113000000",
                "label":"顺义区",
                "full_label":"北京市顺义区"
            },
            {
                "id":"110114000000",
                "label":"昌平区",
                "full_label":"北京市昌平区"
            },
            {
                "id":"110115000000",
                "label":"大兴区",
                "full_label":"北京市大兴区"
            },
            {
                "id":"110116000000",
                "label":"怀柔区",
                "full_label":"北京市怀柔区"
            },
            {
                "id":"110117000000",
                "label":"平谷区",
                "full_label":"北京市平谷区"
            },
            {
                "id":"110118000000",
                "label":"密云区",
                "full_label":"北京市密云区"
            },
            {
                "id":"110119000000",
                "label":"延庆区",
                "full_label":"北京市延庆区"
            }
        ],
        "full_label":"北京市"
    },
    {
        "id":"120000000000",
        "label":"天津市",
        "children":[
            {
                "id":"120101000000",
                "label":"和平区",
                "full_label":"天津市和平区"
            },
            {
                "id":"120102000000",
                "label":"河东区",
                "full_label":"天津市河东区"
            },
            {
                "id":"120103000000",
                "label":"河西区",
                "full_label":"天津市河西区"
            },
            {
                "id":"120104000000",
                "label":"南开区",
                "full_label":"天津市南开区"
            },
            {
                "id":"120105000000",
                "label":"河北区",
                "full_label":"天津市河北区"
            },
            {
                "id":"120106000000",
                "label":"红桥区",
                "full_label":"天津市红桥区"
            },
            {
                "id":"120110000000",
                "label":"东丽区",
                "full_label":"天津市东丽区"
            },
            {
                "id":"120111000000",
                "label":"西青区",
                "full_label":"天津市西青区"
            },
            {
                "id":"120112000000",
                "label":"津南区",
                "full_label":"天津市津南区"
            },
            {
                "id":"120113000000",
                "label":"北辰区",
                "full_label":"天津市北辰区"
            },
            {
                "id":"120114000000",
                "label":"武清区",
                "full_label":"天津市武清区"
            },
            {
                "id":"120115000000",
                "label":"宝坻区",
                "full_label":"天津市宝坻区"
            },
            {
                "id":"120116000000",
                "label":"滨海新区",
                "full_label":"天津市滨海新区"
            },
            {
                "id":"120117000000",
                "label":"宁河区",
                "full_label":"天津市宁河区"
            },
            {
                "id":"120118000000",
                "label":"静海区",
                "full_label":"天津市静海区"
            },
            {
                "id":"120119000000",
                "label":"蓟州区",
                "full_label":"天津市蓟州区"
            }
        ],
        "full_label":"天津市"
    },
    {
        "id":"130000000000",
        "label":"河北省",
        "children":[
            {
                "id":"130100000000",
                "label":"石家庄市",
                "children":[
                    {
                        "id":"130102000000",
                        "label":"长安区",
                        "full_label":"河北省石家庄市长安区"
                    },
                    {
                        "id":"130104000000",
                        "label":"桥西区",
                        "full_label":"河北省石家庄市桥西区"
                    },
                    {
                        "id":"130105000000",
                        "label":"新华区",
                        "full_label":"河北省石家庄市新华区"
                    },
                    {
                        "id":"130107000000",
                        "label":"井陉矿区",
                        "full_label":"河北省石家庄市井陉矿区"
                    },
                    {
                        "id":"130108000000",
                        "label":"裕华区",
                        "full_label":"河北省石家庄市裕华区"
                    },
                    {
                        "id":"130109000000",
                        "label":"藁城区",
                        "full_label":"河北省石家庄市藁城区"
                    },
                    {
                        "id":"130110000000",
                        "label":"鹿泉区",
                        "full_label":"河北省石家庄市鹿泉区"
                    },
                    {
                        "id":"130111000000",
                        "label":"栾城区",
                        "full_label":"河北省石家庄市栾城区"
                    },
                    {
                        "id":"130121000000",
                        "label":"井陉县",
                        "full_label":"河北省石家庄市井陉县"
                    },
                    {
                        "id":"130123000000",
                        "label":"正定县",
                        "full_label":"河北省石家庄市正定县"
                    },
                    {
                        "id":"130125000000",
                        "label":"行唐县",
                        "full_label":"河北省石家庄市行唐县"
                    },
                    {
                        "id":"130126000000",
                        "label":"灵寿县",
                        "full_label":"河北省石家庄市灵寿县"
                    },
                    {
                        "id":"130127000000",
                        "label":"高邑县",
                        "full_label":"河北省石家庄市高邑县"
                    },
                    {
                        "id":"130128000000",
                        "label":"深泽县",
                        "full_label":"河北省石家庄市深泽县"
                    },
                    {
                        "id":"130129000000",
                        "label":"赞皇县",
                        "full_label":"河北省石家庄市赞皇县"
                    },
                    {
                        "id":"130130000000",
                        "label":"无极县",
                        "full_label":"河北省石家庄市无极县"
                    },
                    {
                        "id":"130131000000",
                        "label":"平山县",
                        "full_label":"河北省石家庄市平山县"
                    },
                    {
                        "id":"130132000000",
                        "label":"元氏县",
                        "full_label":"河北省石家庄市元氏县"
                    },
                    {
                        "id":"130133000000",
                        "label":"赵县",
                        "full_label":"河北省石家庄市赵县"
                    },
                    {
                        "id":"130181000000",
                        "label":"辛集市",
                        "full_label":"河北省石家庄市辛集市"
                    },
                    {
                        "id":"130183000000",
                        "label":"晋州市",
                        "full_label":"河北省石家庄市晋州市"
                    },
                    {
                        "id":"130184000000",
                        "label":"新乐市",
                        "full_label":"河北省石家庄市新乐市"
                    }
                ],
                "full_label":"河北省石家庄市"
            },
            {
                "id":"130200000000",
                "label":"唐山市",
                "children":[
                    {
                        "id":"130202000000",
                        "label":"路南区",
                        "full_label":"河北省唐山市路南区"
                    },
                    {
                        "id":"130203000000",
                        "label":"路北区",
                        "full_label":"河北省唐山市路北区"
                    },
                    {
                        "id":"130204000000",
                        "label":"古冶区",
                        "full_label":"河北省唐山市古冶区"
                    },
                    {
                        "id":"130205000000",
                        "label":"开平区",
                        "full_label":"河北省唐山市开平区"
                    },
                    {
                        "id":"130207000000",
                        "label":"丰南区",
                        "full_label":"河北省唐山市丰南区"
                    },
                    {
                        "id":"130208000000",
                        "label":"丰润区",
                        "full_label":"河北省唐山市丰润区"
                    },
                    {
                        "id":"130209000000",
                        "label":"曹妃甸区",
                        "full_label":"河北省唐山市曹妃甸区"
                    },
                    {
                        "id":"130224000000",
                        "label":"滦南县",
                        "full_label":"河北省唐山市滦南县"
                    },
                    {
                        "id":"130225000000",
                        "label":"乐亭县",
                        "full_label":"河北省唐山市乐亭县"
                    },
                    {
                        "id":"130227000000",
                        "label":"迁西县",
                        "full_label":"河北省唐山市迁西县"
                    },
                    {
                        "id":"130229000000",
                        "label":"玉田县",
                        "full_label":"河北省唐山市玉田县"
                    },
                    {
                        "id":"130281000000",
                        "label":"遵化市",
                        "full_label":"河北省唐山市遵化市"
                    },
                    {
                        "id":"130283000000",
                        "label":"迁安市",
                        "full_label":"河北省唐山市迁安市"
                    },
                    {
                        "id":"130284000000",
                        "label":"滦州市",
                        "full_label":"河北省唐山市滦州市"
                    }
                ],
                "full_label":"河北省唐山市"
            },
            {
                "id":"130300000000",
                "label":"秦皇岛市",
                "children":[
                    {
                        "id":"130302000000",
                        "label":"海港区",
                        "full_label":"河北省秦皇岛市海港区"
                    },
                    {
                        "id":"130303000000",
                        "label":"山海关区",
                        "full_label":"河北省秦皇岛市山海关区"
                    },
                    {
                        "id":"130304000000",
                        "label":"北戴河区",
                        "full_label":"河北省秦皇岛市北戴河区"
                    },
                    {
                        "id":"130306000000",
                        "label":"抚宁区",
                        "full_label":"河北省秦皇岛市抚宁区"
                    },
                    {
                        "id":"130321000000",
                        "label":"青龙满族自治县",
                        "full_label":"河北省秦皇岛市青龙满族自治县"
                    },
                    {
                        "id":"130322000000",
                        "label":"昌黎县",
                        "full_label":"河北省秦皇岛市昌黎县"
                    },
                    {
                        "id":"130324000000",
                        "label":"卢龙县",
                        "full_label":"河北省秦皇岛市卢龙县"
                    }
                ],
                "full_label":"河北省秦皇岛市"
            },
            {
                "id":"130400000000",
                "label":"邯郸市",
                "children":[
                    {
                        "id":"130402000000",
                        "label":"邯山区",
                        "full_label":"河北省邯郸市邯山区"
                    },
                    {
                        "id":"130403000000",
                        "label":"丛台区",
                        "full_label":"河北省邯郸市丛台区"
                    },
                    {
                        "id":"130404000000",
                        "label":"复兴区",
                        "full_label":"河北省邯郸市复兴区"
                    },
                    {
                        "id":"130406000000",
                        "label":"峰峰矿区",
                        "full_label":"河北省邯郸市峰峰矿区"
                    },
                    {
                        "id":"130407000000",
                        "label":"肥乡区",
                        "full_label":"河北省邯郸市肥乡区"
                    },
                    {
                        "id":"130408000000",
                        "label":"永年区",
                        "full_label":"河北省邯郸市永年区"
                    },
                    {
                        "id":"130423000000",
                        "label":"临漳县",
                        "full_label":"河北省邯郸市临漳县"
                    },
                    {
                        "id":"130424000000",
                        "label":"成安县",
                        "full_label":"河北省邯郸市成安县"
                    },
                    {
                        "id":"130425000000",
                        "label":"大名县",
                        "full_label":"河北省邯郸市大名县"
                    },
                    {
                        "id":"130426000000",
                        "label":"涉县",
                        "full_label":"河北省邯郸市涉县"
                    },
                    {
                        "id":"130427000000",
                        "label":"磁县",
                        "full_label":"河北省邯郸市磁县"
                    },
                    {
                        "id":"130430000000",
                        "label":"邱县",
                        "full_label":"河北省邯郸市邱县"
                    },
                    {
                        "id":"130431000000",
                        "label":"鸡泽县",
                        "full_label":"河北省邯郸市鸡泽县"
                    },
                    {
                        "id":"130432000000",
                        "label":"广平县",
                        "full_label":"河北省邯郸市广平县"
                    },
                    {
                        "id":"130433000000",
                        "label":"馆陶县",
                        "full_label":"河北省邯郸市馆陶县"
                    },
                    {
                        "id":"130434000000",
                        "label":"魏县",
                        "full_label":"河北省邯郸市魏县"
                    },
                    {
                        "id":"130435000000",
                        "label":"曲周县",
                        "full_label":"河北省邯郸市曲周县"
                    },
                    {
                        "id":"130481000000",
                        "label":"武安市",
                        "full_label":"河北省邯郸市武安市"
                    }
                ],
                "full_label":"河北省邯郸市"
            },
            {
                "id":"130500000000",
                "label":"邢台市",
                "children":[
                    {
                        "id":"130502000000",
                        "label":"襄都区",
                        "full_label":"河北省邢台市襄都区"
                    },
                    {
                        "id":"130503000000",
                        "label":"信都区",
                        "full_label":"河北省邢台市信都区"
                    },
                    {
                        "id":"130505000000",
                        "label":"任泽区",
                        "full_label":"河北省邢台市任泽区"
                    },
                    {
                        "id":"130506000000",
                        "label":"南和区",
                        "full_label":"河北省邢台市南和区"
                    },
                    {
                        "id":"130522000000",
                        "label":"临城县",
                        "full_label":"河北省邢台市临城县"
                    },
                    {
                        "id":"130523000000",
                        "label":"内丘县",
                        "full_label":"河北省邢台市内丘县"
                    },
                    {
                        "id":"130524000000",
                        "label":"柏乡县",
                        "full_label":"河北省邢台市柏乡县"
                    },
                    {
                        "id":"130525000000",
                        "label":"隆尧县",
                        "full_label":"河北省邢台市隆尧县"
                    },
                    {
                        "id":"130528000000",
                        "label":"宁晋县",
                        "full_label":"河北省邢台市宁晋县"
                    },
                    {
                        "id":"130529000000",
                        "label":"巨鹿县",
                        "full_label":"河北省邢台市巨鹿县"
                    },
                    {
                        "id":"130530000000",
                        "label":"新河县",
                        "full_label":"河北省邢台市新河县"
                    },
                    {
                        "id":"130531000000",
                        "label":"广宗县",
                        "full_label":"河北省邢台市广宗县"
                    },
                    {
                        "id":"130532000000",
                        "label":"平乡县",
                        "full_label":"河北省邢台市平乡县"
                    },
                    {
                        "id":"130533000000",
                        "label":"威县",
                        "full_label":"河北省邢台市威县"
                    },
                    {
                        "id":"130534000000",
                        "label":"清河县",
                        "full_label":"河北省邢台市清河县"
                    },
                    {
                        "id":"130535000000",
                        "label":"临西县",
                        "full_label":"河北省邢台市临西县"
                    },
                    {
                        "id":"130581000000",
                        "label":"南宫市",
                        "full_label":"河北省邢台市南宫市"
                    },
                    {
                        "id":"130582000000",
                        "label":"沙河市",
                        "full_label":"河北省邢台市沙河市"
                    }
                ],
                "full_label":"河北省邢台市"
            },
            {
                "id":"130600000000",
                "label":"保定市",
                "children":[
                    {
                        "id":"130602000000",
                        "label":"竞秀区",
                        "full_label":"河北省保定市竞秀区"
                    },
                    {
                        "id":"130606000000",
                        "label":"莲池区",
                        "full_label":"河北省保定市莲池区"
                    },
                    {
                        "id":"130607000000",
                        "label":"满城区",
                        "full_label":"河北省保定市满城区"
                    },
                    {
                        "id":"130608000000",
                        "label":"清苑区",
                        "full_label":"河北省保定市清苑区"
                    },
                    {
                        "id":"130609000000",
                        "label":"徐水区",
                        "full_label":"河北省保定市徐水区"
                    },
                    {
                        "id":"130623000000",
                        "label":"涞水县",
                        "full_label":"河北省保定市涞水县"
                    },
                    {
                        "id":"130624000000",
                        "label":"阜平县",
                        "full_label":"河北省保定市阜平县"
                    },
                    {
                        "id":"130626000000",
                        "label":"定兴县",
                        "full_label":"河北省保定市定兴县"
                    },
                    {
                        "id":"130627000000",
                        "label":"唐县",
                        "full_label":"河北省保定市唐县"
                    },
                    {
                        "id":"130628000000",
                        "label":"高阳县",
                        "full_label":"河北省保定市高阳县"
                    },
                    {
                        "id":"130629000000",
                        "label":"容城县",
                        "full_label":"河北省保定市容城县"
                    },
                    {
                        "id":"130630000000",
                        "label":"涞源县",
                        "full_label":"河北省保定市涞源县"
                    },
                    {
                        "id":"130631000000",
                        "label":"望都县",
                        "full_label":"河北省保定市望都县"
                    },
                    {
                        "id":"130632000000",
                        "label":"安新县",
                        "full_label":"河北省保定市安新县"
                    },
                    {
                        "id":"130633000000",
                        "label":"易县",
                        "full_label":"河北省保定市易县"
                    },
                    {
                        "id":"130634000000",
                        "label":"曲阳县",
                        "full_label":"河北省保定市曲阳县"
                    },
                    {
                        "id":"130635000000",
                        "label":"蠡县",
                        "full_label":"河北省保定市蠡县"
                    },
                    {
                        "id":"130636000000",
                        "label":"顺平县",
                        "full_label":"河北省保定市顺平县"
                    },
                    {
                        "id":"130637000000",
                        "label":"博野县",
                        "full_label":"河北省保定市博野县"
                    },
                    {
                        "id":"130638000000",
                        "label":"雄县",
                        "full_label":"河北省保定市雄县"
                    },
                    {
                        "id":"130681000000",
                        "label":"涿州市",
                        "full_label":"河北省保定市涿州市"
                    },
                    {
                        "id":"130682000000",
                        "label":"定州市",
                        "full_label":"河北省保定市定州市"
                    },
                    {
                        "id":"130683000000",
                        "label":"安国市",
                        "full_label":"河北省保定市安国市"
                    },
                    {
                        "id":"130684000000",
                        "label":"高碑店市",
                        "full_label":"河北省保定市高碑店市"
                    }
                ],
                "full_label":"河北省保定市"
            },
            {
                "id":"130700000000",
                "label":"张家口市",
                "children":[
                    {
                        "id":"130702000000",
                        "label":"桥东区",
                        "full_label":"河北省张家口市桥东区"
                    },
                    {
                        "id":"130703000000",
                        "label":"桥西区",
                        "full_label":"河北省张家口市桥西区"
                    },
                    {
                        "id":"130705000000",
                        "label":"宣化区",
                        "full_label":"河北省张家口市宣化区"
                    },
                    {
                        "id":"130706000000",
                        "label":"下花园区",
                        "full_label":"河北省张家口市下花园区"
                    },
                    {
                        "id":"130708000000",
                        "label":"万全区",
                        "full_label":"河北省张家口市万全区"
                    },
                    {
                        "id":"130709000000",
                        "label":"崇礼区",
                        "full_label":"河北省张家口市崇礼区"
                    },
                    {
                        "id":"130722000000",
                        "label":"张北县",
                        "full_label":"河北省张家口市张北县"
                    },
                    {
                        "id":"130723000000",
                        "label":"康保县",
                        "full_label":"河北省张家口市康保县"
                    },
                    {
                        "id":"130724000000",
                        "label":"沽源县",
                        "full_label":"河北省张家口市沽源县"
                    },
                    {
                        "id":"130725000000",
                        "label":"尚义县",
                        "full_label":"河北省张家口市尚义县"
                    },
                    {
                        "id":"130726000000",
                        "label":"蔚县",
                        "full_label":"河北省张家口市蔚县"
                    },
                    {
                        "id":"130727000000",
                        "label":"阳原县",
                        "full_label":"河北省张家口市阳原县"
                    },
                    {
                        "id":"130728000000",
                        "label":"怀安县",
                        "full_label":"河北省张家口市怀安县"
                    },
                    {
                        "id":"130730000000",
                        "label":"怀来县",
                        "full_label":"河北省张家口市怀来县"
                    },
                    {
                        "id":"130731000000",
                        "label":"涿鹿县",
                        "full_label":"河北省张家口市涿鹿县"
                    },
                    {
                        "id":"130732000000",
                        "label":"赤城县",
                        "full_label":"河北省张家口市赤城县"
                    }
                ],
                "full_label":"河北省张家口市"
            },
            {
                "id":"130800000000",
                "label":"承德市",
                "children":[
                    {
                        "id":"130802000000",
                        "label":"双桥区",
                        "full_label":"河北省承德市双桥区"
                    },
                    {
                        "id":"130803000000",
                        "label":"双滦区",
                        "full_label":"河北省承德市双滦区"
                    },
                    {
                        "id":"130804000000",
                        "label":"鹰手营子矿区",
                        "full_label":"河北省承德市鹰手营子矿区"
                    },
                    {
                        "id":"130821000000",
                        "label":"承德县",
                        "full_label":"河北省承德市承德县"
                    },
                    {
                        "id":"130822000000",
                        "label":"兴隆县",
                        "full_label":"河北省承德市兴隆县"
                    },
                    {
                        "id":"130824000000",
                        "label":"滦平县",
                        "full_label":"河北省承德市滦平县"
                    },
                    {
                        "id":"130825000000",
                        "label":"隆化县",
                        "full_label":"河北省承德市隆化县"
                    },
                    {
                        "id":"130826000000",
                        "label":"丰宁满族自治县",
                        "full_label":"河北省承德市丰宁满族自治县"
                    },
                    {
                        "id":"130827000000",
                        "label":"宽城满族自治县",
                        "full_label":"河北省承德市宽城满族自治县"
                    },
                    {
                        "id":"130828000000",
                        "label":"围场满族蒙古族自治县",
                        "full_label":"河北省承德市围场满族蒙古族自治县"
                    },
                    {
                        "id":"130881000000",
                        "label":"平泉市",
                        "full_label":"河北省承德市平泉市"
                    }
                ],
                "full_label":"河北省承德市"
            },
            {
                "id":"130900000000",
                "label":"沧州市",
                "children":[
                    {
                        "id":"130902000000",
                        "label":"新华区",
                        "full_label":"河北省沧州市新华区"
                    },
                    {
                        "id":"130903000000",
                        "label":"运河区",
                        "full_label":"河北省沧州市运河区"
                    },
                    {
                        "id":"130921000000",
                        "label":"沧县",
                        "full_label":"河北省沧州市沧县"
                    },
                    {
                        "id":"130922000000",
                        "label":"青县",
                        "full_label":"河北省沧州市青县"
                    },
                    {
                        "id":"130923000000",
                        "label":"东光县",
                        "full_label":"河北省沧州市东光县"
                    },
                    {
                        "id":"130924000000",
                        "label":"海兴县",
                        "full_label":"河北省沧州市海兴县"
                    },
                    {
                        "id":"130925000000",
                        "label":"盐山县",
                        "full_label":"河北省沧州市盐山县"
                    },
                    {
                        "id":"130926000000",
                        "label":"肃宁县",
                        "full_label":"河北省沧州市肃宁县"
                    },
                    {
                        "id":"130927000000",
                        "label":"南皮县",
                        "full_label":"河北省沧州市南皮县"
                    },
                    {
                        "id":"130928000000",
                        "label":"吴桥县",
                        "full_label":"河北省沧州市吴桥县"
                    },
                    {
                        "id":"130929000000",
                        "label":"献县",
                        "full_label":"河北省沧州市献县"
                    },
                    {
                        "id":"130930000000",
                        "label":"孟村回族自治县",
                        "full_label":"河北省沧州市孟村回族自治县"
                    },
                    {
                        "id":"130981000000",
                        "label":"泊头市",
                        "full_label":"河北省沧州市泊头市"
                    },
                    {
                        "id":"130982000000",
                        "label":"任丘市",
                        "full_label":"河北省沧州市任丘市"
                    },
                    {
                        "id":"130983000000",
                        "label":"黄骅市",
                        "full_label":"河北省沧州市黄骅市"
                    },
                    {
                        "id":"130984000000",
                        "label":"河间市",
                        "full_label":"河北省沧州市河间市"
                    }
                ],
                "full_label":"河北省沧州市"
            },
            {
                "id":"131000000000",
                "label":"廊坊市",
                "children":[
                    {
                        "id":"131002000000",
                        "label":"安次区",
                        "full_label":"河北省廊坊市安次区"
                    },
                    {
                        "id":"131003000000",
                        "label":"广阳区",
                        "full_label":"河北省廊坊市广阳区"
                    },
                    {
                        "id":"131022000000",
                        "label":"固安县",
                        "full_label":"河北省廊坊市固安县"
                    },
                    {
                        "id":"131023000000",
                        "label":"永清县",
                        "full_label":"河北省廊坊市永清县"
                    },
                    {
                        "id":"131024000000",
                        "label":"香河县",
                        "full_label":"河北省廊坊市香河县"
                    },
                    {
                        "id":"131025000000",
                        "label":"大城县",
                        "full_label":"河北省廊坊市大城县"
                    },
                    {
                        "id":"131026000000",
                        "label":"文安县",
                        "full_label":"河北省廊坊市文安县"
                    },
                    {
                        "id":"131028000000",
                        "label":"大厂回族自治县",
                        "full_label":"河北省廊坊市大厂回族自治县"
                    },
                    {
                        "id":"131081000000",
                        "label":"霸州市",
                        "full_label":"河北省廊坊市霸州市"
                    },
                    {
                        "id":"131082000000",
                        "label":"三河市",
                        "full_label":"河北省廊坊市三河市"
                    }
                ],
                "full_label":"河北省廊坊市"
            },
            {
                "id":"131100000000",
                "label":"衡水市",
                "children":[
                    {
                        "id":"131102000000",
                        "label":"桃城区",
                        "full_label":"河北省衡水市桃城区"
                    },
                    {
                        "id":"131103000000",
                        "label":"冀州区",
                        "full_label":"河北省衡水市冀州区"
                    },
                    {
                        "id":"131121000000",
                        "label":"枣强县",
                        "full_label":"河北省衡水市枣强县"
                    },
                    {
                        "id":"131122000000",
                        "label":"武邑县",
                        "full_label":"河北省衡水市武邑县"
                    },
                    {
                        "id":"131123000000",
                        "label":"武强县",
                        "full_label":"河北省衡水市武强县"
                    },
                    {
                        "id":"131124000000",
                        "label":"饶阳县",
                        "full_label":"河北省衡水市饶阳县"
                    },
                    {
                        "id":"131125000000",
                        "label":"安平县",
                        "full_label":"河北省衡水市安平县"
                    },
                    {
                        "id":"131126000000",
                        "label":"故城县",
                        "full_label":"河北省衡水市故城县"
                    },
                    {
                        "id":"131127000000",
                        "label":"景县",
                        "full_label":"河北省衡水市景县"
                    },
                    {
                        "id":"131128000000",
                        "label":"阜城县",
                        "full_label":"河北省衡水市阜城县"
                    },
                    {
                        "id":"131182000000",
                        "label":"深州市",
                        "full_label":"河北省衡水市深州市"
                    }
                ],
                "full_label":"河北省衡水市"
            }
        ],
        "full_label":"河北省"
    },
    {
        "id":"140000000000",
        "label":"山西省",
        "children":[
            {
                "id":"140100000000",
                "label":"太原市",
                "children":[
                    {
                        "id":"140105000000",
                        "label":"小店区",
                        "full_label":"山西省太原市小店区"
                    },
                    {
                        "id":"140106000000",
                        "label":"迎泽区",
                        "full_label":"山西省太原市迎泽区"
                    },
                    {
                        "id":"140107000000",
                        "label":"杏花岭区",
                        "full_label":"山西省太原市杏花岭区"
                    },
                    {
                        "id":"140108000000",
                        "label":"尖草坪区",
                        "full_label":"山西省太原市尖草坪区"
                    },
                    {
                        "id":"140109000000",
                        "label":"万柏林区",
                        "full_label":"山西省太原市万柏林区"
                    },
                    {
                        "id":"140110000000",
                        "label":"晋源区",
                        "full_label":"山西省太原市晋源区"
                    },
                    {
                        "id":"140121000000",
                        "label":"清徐县",
                        "full_label":"山西省太原市清徐县"
                    },
                    {
                        "id":"140122000000",
                        "label":"阳曲县",
                        "full_label":"山西省太原市阳曲县"
                    },
                    {
                        "id":"140123000000",
                        "label":"娄烦县",
                        "full_label":"山西省太原市娄烦县"
                    },
                    {
                        "id":"140181000000",
                        "label":"古交市",
                        "full_label":"山西省太原市古交市"
                    }
                ],
                "full_label":"山西省太原市"
            },
            {
                "id":"140200000000",
                "label":"大同市",
                "children":[
                    {
                        "id":"140212000000",
                        "label":"新荣区",
                        "full_label":"山西省大同市新荣区"
                    },
                    {
                        "id":"140213000000",
                        "label":"平城区",
                        "full_label":"山西省大同市平城区"
                    },
                    {
                        "id":"140214000000",
                        "label":"云冈区",
                        "full_label":"山西省大同市云冈区"
                    },
                    {
                        "id":"140215000000",
                        "label":"云州区",
                        "full_label":"山西省大同市云州区"
                    },
                    {
                        "id":"140221000000",
                        "label":"阳高县",
                        "full_label":"山西省大同市阳高县"
                    },
                    {
                        "id":"140222000000",
                        "label":"天镇县",
                        "full_label":"山西省大同市天镇县"
                    },
                    {
                        "id":"140223000000",
                        "label":"广灵县",
                        "full_label":"山西省大同市广灵县"
                    },
                    {
                        "id":"140224000000",
                        "label":"灵丘县",
                        "full_label":"山西省大同市灵丘县"
                    },
                    {
                        "id":"140225000000",
                        "label":"浑源县",
                        "full_label":"山西省大同市浑源县"
                    },
                    {
                        "id":"140226000000",
                        "label":"左云县",
                        "full_label":"山西省大同市左云县"
                    }
                ],
                "full_label":"山西省大同市"
            },
            {
                "id":"140300000000",
                "label":"阳泉市",
                "children":[
                    {
                        "id":"140302000000",
                        "label":"城区",
                        "full_label":"山西省阳泉市城区"
                    },
                    {
                        "id":"140303000000",
                        "label":"矿区",
                        "full_label":"山西省阳泉市矿区"
                    },
                    {
                        "id":"140311000000",
                        "label":"郊区",
                        "full_label":"山西省阳泉市郊区"
                    },
                    {
                        "id":"140321000000",
                        "label":"平定县",
                        "full_label":"山西省阳泉市平定县"
                    },
                    {
                        "id":"140322000000",
                        "label":"盂县",
                        "full_label":"山西省阳泉市盂县"
                    }
                ],
                "full_label":"山西省阳泉市"
            },
            {
                "id":"140400000000",
                "label":"长治市",
                "children":[
                    {
                        "id":"140403000000",
                        "label":"潞州区",
                        "full_label":"山西省长治市潞州区"
                    },
                    {
                        "id":"140404000000",
                        "label":"上党区",
                        "full_label":"山西省长治市上党区"
                    },
                    {
                        "id":"140405000000",
                        "label":"屯留区",
                        "full_label":"山西省长治市屯留区"
                    },
                    {
                        "id":"140406000000",
                        "label":"潞城区",
                        "full_label":"山西省长治市潞城区"
                    },
                    {
                        "id":"140423000000",
                        "label":"襄垣县",
                        "full_label":"山西省长治市襄垣县"
                    },
                    {
                        "id":"140425000000",
                        "label":"平顺县",
                        "full_label":"山西省长治市平顺县"
                    },
                    {
                        "id":"140426000000",
                        "label":"黎城县",
                        "full_label":"山西省长治市黎城县"
                    },
                    {
                        "id":"140427000000",
                        "label":"壶关县",
                        "full_label":"山西省长治市壶关县"
                    },
                    {
                        "id":"140428000000",
                        "label":"长子县",
                        "full_label":"山西省长治市长子县"
                    },
                    {
                        "id":"140429000000",
                        "label":"武乡县",
                        "full_label":"山西省长治市武乡县"
                    },
                    {
                        "id":"140430000000",
                        "label":"沁县",
                        "full_label":"山西省长治市沁县"
                    },
                    {
                        "id":"140431000000",
                        "label":"沁源县",
                        "full_label":"山西省长治市沁源县"
                    }
                ],
                "full_label":"山西省长治市"
            },
            {
                "id":"140500000000",
                "label":"晋城市",
                "children":[
                    {
                        "id":"140502000000",
                        "label":"城区",
                        "full_label":"山西省晋城市城区"
                    },
                    {
                        "id":"140521000000",
                        "label":"沁水县",
                        "full_label":"山西省晋城市沁水县"
                    },
                    {
                        "id":"140522000000",
                        "label":"阳城县",
                        "full_label":"山西省晋城市阳城县"
                    },
                    {
                        "id":"140524000000",
                        "label":"陵川县",
                        "full_label":"山西省晋城市陵川县"
                    },
                    {
                        "id":"140525000000",
                        "label":"泽州县",
                        "full_label":"山西省晋城市泽州县"
                    },
                    {
                        "id":"140581000000",
                        "label":"高平市",
                        "full_label":"山西省晋城市高平市"
                    }
                ],
                "full_label":"山西省晋城市"
            },
            {
                "id":"140600000000",
                "label":"朔州市",
                "children":[
                    {
                        "id":"140602000000",
                        "label":"朔城区",
                        "full_label":"山西省朔州市朔城区"
                    },
                    {
                        "id":"140603000000",
                        "label":"平鲁区",
                        "full_label":"山西省朔州市平鲁区"
                    },
                    {
                        "id":"140621000000",
                        "label":"山阴县",
                        "full_label":"山西省朔州市山阴县"
                    },
                    {
                        "id":"140622000000",
                        "label":"应县",
                        "full_label":"山西省朔州市应县"
                    },
                    {
                        "id":"140623000000",
                        "label":"右玉县",
                        "full_label":"山西省朔州市右玉县"
                    },
                    {
                        "id":"140681000000",
                        "label":"怀仁市",
                        "full_label":"山西省朔州市怀仁市"
                    }
                ],
                "full_label":"山西省朔州市"
            },
            {
                "id":"140700000000",
                "label":"晋中市",
                "children":[
                    {
                        "id":"140702000000",
                        "label":"榆次区",
                        "full_label":"山西省晋中市榆次区"
                    },
                    {
                        "id":"140703000000",
                        "label":"太谷区",
                        "full_label":"山西省晋中市太谷区"
                    },
                    {
                        "id":"140721000000",
                        "label":"榆社县",
                        "full_label":"山西省晋中市榆社县"
                    },
                    {
                        "id":"140722000000",
                        "label":"左权县",
                        "full_label":"山西省晋中市左权县"
                    },
                    {
                        "id":"140723000000",
                        "label":"和顺县",
                        "full_label":"山西省晋中市和顺县"
                    },
                    {
                        "id":"140724000000",
                        "label":"昔阳县",
                        "full_label":"山西省晋中市昔阳县"
                    },
                    {
                        "id":"140725000000",
                        "label":"寿阳县",
                        "full_label":"山西省晋中市寿阳县"
                    },
                    {
                        "id":"140727000000",
                        "label":"祁县",
                        "full_label":"山西省晋中市祁县"
                    },
                    {
                        "id":"140728000000",
                        "label":"平遥县",
                        "full_label":"山西省晋中市平遥县"
                    },
                    {
                        "id":"140729000000",
                        "label":"灵石县",
                        "full_label":"山西省晋中市灵石县"
                    },
                    {
                        "id":"140781000000",
                        "label":"介休市",
                        "full_label":"山西省晋中市介休市"
                    }
                ],
                "full_label":"山西省晋中市"
            },
            {
                "id":"140800000000",
                "label":"运城市",
                "children":[
                    {
                        "id":"140802000000",
                        "label":"盐湖区",
                        "full_label":"山西省运城市盐湖区"
                    },
                    {
                        "id":"140821000000",
                        "label":"临猗县",
                        "full_label":"山西省运城市临猗县"
                    },
                    {
                        "id":"140822000000",
                        "label":"万荣县",
                        "full_label":"山西省运城市万荣县"
                    },
                    {
                        "id":"140823000000",
                        "label":"闻喜县",
                        "full_label":"山西省运城市闻喜县"
                    },
                    {
                        "id":"140824000000",
                        "label":"稷山县",
                        "full_label":"山西省运城市稷山县"
                    },
                    {
                        "id":"140825000000",
                        "label":"新绛县",
                        "full_label":"山西省运城市新绛县"
                    },
                    {
                        "id":"140826000000",
                        "label":"绛县",
                        "full_label":"山西省运城市绛县"
                    },
                    {
                        "id":"140827000000",
                        "label":"垣曲县",
                        "full_label":"山西省运城市垣曲县"
                    },
                    {
                        "id":"140828000000",
                        "label":"夏县",
                        "full_label":"山西省运城市夏县"
                    },
                    {
                        "id":"140829000000",
                        "label":"平陆县",
                        "full_label":"山西省运城市平陆县"
                    },
                    {
                        "id":"140830000000",
                        "label":"芮城县",
                        "full_label":"山西省运城市芮城县"
                    },
                    {
                        "id":"140881000000",
                        "label":"永济市",
                        "full_label":"山西省运城市永济市"
                    },
                    {
                        "id":"140882000000",
                        "label":"河津市",
                        "full_label":"山西省运城市河津市"
                    }
                ],
                "full_label":"山西省运城市"
            },
            {
                "id":"140900000000",
                "label":"忻州市",
                "children":[
                    {
                        "id":"140902000000",
                        "label":"忻府区",
                        "full_label":"山西省忻州市忻府区"
                    },
                    {
                        "id":"140921000000",
                        "label":"定襄县",
                        "full_label":"山西省忻州市定襄县"
                    },
                    {
                        "id":"140922000000",
                        "label":"五台县",
                        "full_label":"山西省忻州市五台县"
                    },
                    {
                        "id":"140923000000",
                        "label":"代县",
                        "full_label":"山西省忻州市代县"
                    },
                    {
                        "id":"140924000000",
                        "label":"繁峙县",
                        "full_label":"山西省忻州市繁峙县"
                    },
                    {
                        "id":"140925000000",
                        "label":"宁武县",
                        "full_label":"山西省忻州市宁武县"
                    },
                    {
                        "id":"140926000000",
                        "label":"静乐县",
                        "full_label":"山西省忻州市静乐县"
                    },
                    {
                        "id":"140927000000",
                        "label":"神池县",
                        "full_label":"山西省忻州市神池县"
                    },
                    {
                        "id":"140928000000",
                        "label":"五寨县",
                        "full_label":"山西省忻州市五寨县"
                    },
                    {
                        "id":"140929000000",
                        "label":"岢岚县",
                        "full_label":"山西省忻州市岢岚县"
                    },
                    {
                        "id":"140930000000",
                        "label":"河曲县",
                        "full_label":"山西省忻州市河曲县"
                    },
                    {
                        "id":"140931000000",
                        "label":"保德县",
                        "full_label":"山西省忻州市保德县"
                    },
                    {
                        "id":"140932000000",
                        "label":"偏关县",
                        "full_label":"山西省忻州市偏关县"
                    },
                    {
                        "id":"140981000000",
                        "label":"原平市",
                        "full_label":"山西省忻州市原平市"
                    }
                ],
                "full_label":"山西省忻州市"
            },
            {
                "id":"141000000000",
                "label":"临汾市",
                "children":[
                    {
                        "id":"141002000000",
                        "label":"尧都区",
                        "full_label":"山西省临汾市尧都区"
                    },
                    {
                        "id":"141021000000",
                        "label":"曲沃县",
                        "full_label":"山西省临汾市曲沃县"
                    },
                    {
                        "id":"141022000000",
                        "label":"翼城县",
                        "full_label":"山西省临汾市翼城县"
                    },
                    {
                        "id":"141023000000",
                        "label":"襄汾县",
                        "full_label":"山西省临汾市襄汾县"
                    },
                    {
                        "id":"141024000000",
                        "label":"洪洞县",
                        "full_label":"山西省临汾市洪洞县"
                    },
                    {
                        "id":"141025000000",
                        "label":"古县",
                        "full_label":"山西省临汾市古县"
                    },
                    {
                        "id":"141026000000",
                        "label":"安泽县",
                        "full_label":"山西省临汾市安泽县"
                    },
                    {
                        "id":"141027000000",
                        "label":"浮山县",
                        "full_label":"山西省临汾市浮山县"
                    },
                    {
                        "id":"141028000000",
                        "label":"吉县",
                        "full_label":"山西省临汾市吉县"
                    },
                    {
                        "id":"141029000000",
                        "label":"乡宁县",
                        "full_label":"山西省临汾市乡宁县"
                    },
                    {
                        "id":"141030000000",
                        "label":"大宁县",
                        "full_label":"山西省临汾市大宁县"
                    },
                    {
                        "id":"141031000000",
                        "label":"隰县",
                        "full_label":"山西省临汾市隰县"
                    },
                    {
                        "id":"141032000000",
                        "label":"永和县",
                        "full_label":"山西省临汾市永和县"
                    },
                    {
                        "id":"141033000000",
                        "label":"蒲县",
                        "full_label":"山西省临汾市蒲县"
                    },
                    {
                        "id":"141034000000",
                        "label":"汾西县",
                        "full_label":"山西省临汾市汾西县"
                    },
                    {
                        "id":"141081000000",
                        "label":"侯马市",
                        "full_label":"山西省临汾市侯马市"
                    },
                    {
                        "id":"141082000000",
                        "label":"霍州市",
                        "full_label":"山西省临汾市霍州市"
                    }
                ],
                "full_label":"山西省临汾市"
            },
            {
                "id":"141100000000",
                "label":"吕梁市",
                "children":[
                    {
                        "id":"141102000000",
                        "label":"离石区",
                        "full_label":"山西省吕梁市离石区"
                    },
                    {
                        "id":"141121000000",
                        "label":"文水县",
                        "full_label":"山西省吕梁市文水县"
                    },
                    {
                        "id":"141122000000",
                        "label":"交城县",
                        "full_label":"山西省吕梁市交城县"
                    },
                    {
                        "id":"141123000000",
                        "label":"兴县",
                        "full_label":"山西省吕梁市兴县"
                    },
                    {
                        "id":"141124000000",
                        "label":"临县",
                        "full_label":"山西省吕梁市临县"
                    },
                    {
                        "id":"141125000000",
                        "label":"柳林县",
                        "full_label":"山西省吕梁市柳林县"
                    },
                    {
                        "id":"141126000000",
                        "label":"石楼县",
                        "full_label":"山西省吕梁市石楼县"
                    },
                    {
                        "id":"141127000000",
                        "label":"岚县",
                        "full_label":"山西省吕梁市岚县"
                    },
                    {
                        "id":"141128000000",
                        "label":"方山县",
                        "full_label":"山西省吕梁市方山县"
                    },
                    {
                        "id":"141129000000",
                        "label":"中阳县",
                        "full_label":"山西省吕梁市中阳县"
                    },
                    {
                        "id":"141130000000",
                        "label":"交口县",
                        "full_label":"山西省吕梁市交口县"
                    },
                    {
                        "id":"141181000000",
                        "label":"孝义市",
                        "full_label":"山西省吕梁市孝义市"
                    },
                    {
                        "id":"141182000000",
                        "label":"汾阳市",
                        "full_label":"山西省吕梁市汾阳市"
                    }
                ],
                "full_label":"山西省吕梁市"
            }
        ],
        "full_label":"山西省"
    },
    {
        "id":"150000000000",
        "label":"内蒙古自治区",
        "children":[
            {
                "id":"150100000000",
                "label":"呼和浩特市",
                "children":[
                    {
                        "id":"150102000000",
                        "label":"新城区",
                        "full_label":"内蒙古自治区呼和浩特市新城区"
                    },
                    {
                        "id":"150103000000",
                        "label":"回民区",
                        "full_label":"内蒙古自治区呼和浩特市回民区"
                    },
                    {
                        "id":"150104000000",
                        "label":"玉泉区",
                        "full_label":"内蒙古自治区呼和浩特市玉泉区"
                    },
                    {
                        "id":"150105000000",
                        "label":"赛罕区",
                        "full_label":"内蒙古自治区呼和浩特市赛罕区"
                    },
                    {
                        "id":"150121000000",
                        "label":"土默特左旗",
                        "full_label":"内蒙古自治区呼和浩特市土默特左旗"
                    },
                    {
                        "id":"150122000000",
                        "label":"托克托县",
                        "full_label":"内蒙古自治区呼和浩特市托克托县"
                    },
                    {
                        "id":"150123000000",
                        "label":"和林格尔县",
                        "full_label":"内蒙古自治区呼和浩特市和林格尔县"
                    },
                    {
                        "id":"150124000000",
                        "label":"清水河县",
                        "full_label":"内蒙古自治区呼和浩特市清水河县"
                    },
                    {
                        "id":"150125000000",
                        "label":"武川县",
                        "full_label":"内蒙古自治区呼和浩特市武川县"
                    }
                ],
                "full_label":"内蒙古自治区呼和浩特市"
            },
            {
                "id":"150200000000",
                "label":"包头市",
                "children":[
                    {
                        "id":"150202000000",
                        "label":"东河区",
                        "full_label":"内蒙古自治区包头市东河区"
                    },
                    {
                        "id":"150203000000",
                        "label":"昆都仑区",
                        "full_label":"内蒙古自治区包头市昆都仑区"
                    },
                    {
                        "id":"150204000000",
                        "label":"青山区",
                        "full_label":"内蒙古自治区包头市青山区"
                    },
                    {
                        "id":"150205000000",
                        "label":"石拐区",
                        "full_label":"内蒙古自治区包头市石拐区"
                    },
                    {
                        "id":"150206000000",
                        "label":"白云鄂博矿区",
                        "full_label":"内蒙古自治区包头市白云鄂博矿区"
                    },
                    {
                        "id":"150207000000",
                        "label":"九原区",
                        "full_label":"内蒙古自治区包头市九原区"
                    },
                    {
                        "id":"150221000000",
                        "label":"土默特右旗",
                        "full_label":"内蒙古自治区包头市土默特右旗"
                    },
                    {
                        "id":"150222000000",
                        "label":"固阳县",
                        "full_label":"内蒙古自治区包头市固阳县"
                    },
                    {
                        "id":"150223000000",
                        "label":"达尔罕茂明安联合旗",
                        "full_label":"内蒙古自治区包头市达尔罕茂明安联合旗"
                    }
                ],
                "full_label":"内蒙古自治区包头市"
            },
            {
                "id":"150300000000",
                "label":"乌海市",
                "children":[
                    {
                        "id":"150302000000",
                        "label":"海勃湾区",
                        "full_label":"内蒙古自治区乌海市海勃湾区"
                    },
                    {
                        "id":"150303000000",
                        "label":"海南区",
                        "full_label":"内蒙古自治区乌海市海南区"
                    },
                    {
                        "id":"150304000000",
                        "label":"乌达区",
                        "full_label":"内蒙古自治区乌海市乌达区"
                    }
                ],
                "full_label":"内蒙古自治区乌海市"
            },
            {
                "id":"150400000000",
                "label":"赤峰市",
                "children":[
                    {
                        "id":"150402000000",
                        "label":"红山区",
                        "full_label":"内蒙古自治区赤峰市红山区"
                    },
                    {
                        "id":"150403000000",
                        "label":"元宝山区",
                        "full_label":"内蒙古自治区赤峰市元宝山区"
                    },
                    {
                        "id":"150404000000",
                        "label":"松山区",
                        "full_label":"内蒙古自治区赤峰市松山区"
                    },
                    {
                        "id":"150421000000",
                        "label":"阿鲁科尔沁旗",
                        "full_label":"内蒙古自治区赤峰市阿鲁科尔沁旗"
                    },
                    {
                        "id":"150422000000",
                        "label":"巴林左旗",
                        "full_label":"内蒙古自治区赤峰市巴林左旗"
                    },
                    {
                        "id":"150423000000",
                        "label":"巴林右旗",
                        "full_label":"内蒙古自治区赤峰市巴林右旗"
                    },
                    {
                        "id":"150424000000",
                        "label":"林西县",
                        "full_label":"内蒙古自治区赤峰市林西县"
                    },
                    {
                        "id":"150425000000",
                        "label":"克什克腾旗",
                        "full_label":"内蒙古自治区赤峰市克什克腾旗"
                    },
                    {
                        "id":"150426000000",
                        "label":"翁牛特旗",
                        "full_label":"内蒙古自治区赤峰市翁牛特旗"
                    },
                    {
                        "id":"150428000000",
                        "label":"喀喇沁旗",
                        "full_label":"内蒙古自治区赤峰市喀喇沁旗"
                    },
                    {
                        "id":"150429000000",
                        "label":"宁城县",
                        "full_label":"内蒙古自治区赤峰市宁城县"
                    },
                    {
                        "id":"150430000000",
                        "label":"敖汉旗",
                        "full_label":"内蒙古自治区赤峰市敖汉旗"
                    }
                ],
                "full_label":"内蒙古自治区赤峰市"
            },
            {
                "id":"150500000000",
                "label":"通辽市",
                "children":[
                    {
                        "id":"150502000000",
                        "label":"科尔沁区",
                        "full_label":"内蒙古自治区通辽市科尔沁区"
                    },
                    {
                        "id":"150521000000",
                        "label":"科尔沁左翼中旗",
                        "full_label":"内蒙古自治区通辽市科尔沁左翼中旗"
                    },
                    {
                        "id":"150522000000",
                        "label":"科尔沁左翼后旗",
                        "full_label":"内蒙古自治区通辽市科尔沁左翼后旗"
                    },
                    {
                        "id":"150523000000",
                        "label":"开鲁县",
                        "full_label":"内蒙古自治区通辽市开鲁县"
                    },
                    {
                        "id":"150524000000",
                        "label":"库伦旗",
                        "full_label":"内蒙古自治区通辽市库伦旗"
                    },
                    {
                        "id":"150525000000",
                        "label":"奈曼旗",
                        "full_label":"内蒙古自治区通辽市奈曼旗"
                    },
                    {
                        "id":"150526000000",
                        "label":"扎鲁特旗",
                        "full_label":"内蒙古自治区通辽市扎鲁特旗"
                    },
                    {
                        "id":"150581000000",
                        "label":"霍林郭勒市",
                        "full_label":"内蒙古自治区通辽市霍林郭勒市"
                    }
                ],
                "full_label":"内蒙古自治区通辽市"
            },
            {
                "id":"150600000000",
                "label":"鄂尔多斯市",
                "children":[
                    {
                        "id":"150602000000",
                        "label":"东胜区",
                        "full_label":"内蒙古自治区鄂尔多斯市东胜区"
                    },
                    {
                        "id":"150603000000",
                        "label":"康巴什区",
                        "full_label":"内蒙古自治区鄂尔多斯市康巴什区"
                    },
                    {
                        "id":"150621000000",
                        "label":"达拉特旗",
                        "full_label":"内蒙古自治区鄂尔多斯市达拉特旗"
                    },
                    {
                        "id":"150622000000",
                        "label":"准格尔旗",
                        "full_label":"内蒙古自治区鄂尔多斯市准格尔旗"
                    },
                    {
                        "id":"150623000000",
                        "label":"鄂托克前旗",
                        "full_label":"内蒙古自治区鄂尔多斯市鄂托克前旗"
                    },
                    {
                        "id":"150624000000",
                        "label":"鄂托克旗",
                        "full_label":"内蒙古自治区鄂尔多斯市鄂托克旗"
                    },
                    {
                        "id":"150625000000",
                        "label":"杭锦旗",
                        "full_label":"内蒙古自治区鄂尔多斯市杭锦旗"
                    },
                    {
                        "id":"150626000000",
                        "label":"乌审旗",
                        "full_label":"内蒙古自治区鄂尔多斯市乌审旗"
                    },
                    {
                        "id":"150627000000",
                        "label":"伊金霍洛旗",
                        "full_label":"内蒙古自治区鄂尔多斯市伊金霍洛旗"
                    }
                ],
                "full_label":"内蒙古自治区鄂尔多斯市"
            },
            {
                "id":"150700000000",
                "label":"呼伦贝尔市",
                "children":[
                    {
                        "id":"150702000000",
                        "label":"海拉尔区",
                        "full_label":"内蒙古自治区呼伦贝尔市海拉尔区"
                    },
                    {
                        "id":"150703000000",
                        "label":"扎赉诺尔区",
                        "full_label":"内蒙古自治区呼伦贝尔市扎赉诺尔区"
                    },
                    {
                        "id":"150721000000",
                        "label":"阿荣旗",
                        "full_label":"内蒙古自治区呼伦贝尔市阿荣旗"
                    },
                    {
                        "id":"150722000000",
                        "label":"莫力达瓦达斡尔族自治旗",
                        "full_label":"内蒙古自治区呼伦贝尔市莫力达瓦达斡尔族自治旗"
                    },
                    {
                        "id":"150723000000",
                        "label":"鄂伦春自治旗",
                        "full_label":"内蒙古自治区呼伦贝尔市鄂伦春自治旗"
                    },
                    {
                        "id":"150724000000",
                        "label":"鄂温克族自治旗",
                        "full_label":"内蒙古自治区呼伦贝尔市鄂温克族自治旗"
                    },
                    {
                        "id":"150725000000",
                        "label":"陈巴尔虎旗",
                        "full_label":"内蒙古自治区呼伦贝尔市陈巴尔虎旗"
                    },
                    {
                        "id":"150726000000",
                        "label":"新巴尔虎左旗",
                        "full_label":"内蒙古自治区呼伦贝尔市新巴尔虎左旗"
                    },
                    {
                        "id":"150727000000",
                        "label":"新巴尔虎右旗",
                        "full_label":"内蒙古自治区呼伦贝尔市新巴尔虎右旗"
                    },
                    {
                        "id":"150781000000",
                        "label":"满洲里市",
                        "full_label":"内蒙古自治区呼伦贝尔市满洲里市"
                    },
                    {
                        "id":"150782000000",
                        "label":"牙克石市",
                        "full_label":"内蒙古自治区呼伦贝尔市牙克石市"
                    },
                    {
                        "id":"150783000000",
                        "label":"扎兰屯市",
                        "full_label":"内蒙古自治区呼伦贝尔市扎兰屯市"
                    },
                    {
                        "id":"150784000000",
                        "label":"额尔古纳市",
                        "full_label":"内蒙古自治区呼伦贝尔市额尔古纳市"
                    },
                    {
                        "id":"150785000000",
                        "label":"根河市",
                        "full_label":"内蒙古自治区呼伦贝尔市根河市"
                    }
                ],
                "full_label":"内蒙古自治区呼伦贝尔市"
            },
            {
                "id":"150800000000",
                "label":"巴彦淖尔市",
                "children":[
                    {
                        "id":"150802000000",
                        "label":"临河区",
                        "full_label":"内蒙古自治区巴彦淖尔市临河区"
                    },
                    {
                        "id":"150821000000",
                        "label":"五原县",
                        "full_label":"内蒙古自治区巴彦淖尔市五原县"
                    },
                    {
                        "id":"150822000000",
                        "label":"磴口县",
                        "full_label":"内蒙古自治区巴彦淖尔市磴口县"
                    },
                    {
                        "id":"150823000000",
                        "label":"乌拉特前旗",
                        "full_label":"内蒙古自治区巴彦淖尔市乌拉特前旗"
                    },
                    {
                        "id":"150824000000",
                        "label":"乌拉特中旗",
                        "full_label":"内蒙古自治区巴彦淖尔市乌拉特中旗"
                    },
                    {
                        "id":"150825000000",
                        "label":"乌拉特后旗",
                        "full_label":"内蒙古自治区巴彦淖尔市乌拉特后旗"
                    },
                    {
                        "id":"150826000000",
                        "label":"杭锦后旗",
                        "full_label":"内蒙古自治区巴彦淖尔市杭锦后旗"
                    }
                ],
                "full_label":"内蒙古自治区巴彦淖尔市"
            },
            {
                "id":"150900000000",
                "label":"乌兰察布市",
                "children":[
                    {
                        "id":"150902000000",
                        "label":"集宁区",
                        "full_label":"内蒙古自治区乌兰察布市集宁区"
                    },
                    {
                        "id":"150921000000",
                        "label":"卓资县",
                        "full_label":"内蒙古自治区乌兰察布市卓资县"
                    },
                    {
                        "id":"150922000000",
                        "label":"化德县",
                        "full_label":"内蒙古自治区乌兰察布市化德县"
                    },
                    {
                        "id":"150923000000",
                        "label":"商都县",
                        "full_label":"内蒙古自治区乌兰察布市商都县"
                    },
                    {
                        "id":"150924000000",
                        "label":"兴和县",
                        "full_label":"内蒙古自治区乌兰察布市兴和县"
                    },
                    {
                        "id":"150925000000",
                        "label":"凉城县",
                        "full_label":"内蒙古自治区乌兰察布市凉城县"
                    },
                    {
                        "id":"150926000000",
                        "label":"察哈尔右翼前旗",
                        "full_label":"内蒙古自治区乌兰察布市察哈尔右翼前旗"
                    },
                    {
                        "id":"150927000000",
                        "label":"察哈尔右翼中旗",
                        "full_label":"内蒙古自治区乌兰察布市察哈尔右翼中旗"
                    },
                    {
                        "id":"150928000000",
                        "label":"察哈尔右翼后旗",
                        "full_label":"内蒙古自治区乌兰察布市察哈尔右翼后旗"
                    },
                    {
                        "id":"150929000000",
                        "label":"四子王旗",
                        "full_label":"内蒙古自治区乌兰察布市四子王旗"
                    },
                    {
                        "id":"150981000000",
                        "label":"丰镇市",
                        "full_label":"内蒙古自治区乌兰察布市丰镇市"
                    }
                ],
                "full_label":"内蒙古自治区乌兰察布市"
            },
            {
                "id":"152200000000",
                "label":"兴安盟",
                "children":[
                    {
                        "id":"152201000000",
                        "label":"乌兰浩特市",
                        "full_label":"内蒙古自治区兴安盟乌兰浩特市"
                    },
                    {
                        "id":"152202000000",
                        "label":"阿尔山市",
                        "full_label":"内蒙古自治区兴安盟阿尔山市"
                    },
                    {
                        "id":"152221000000",
                        "label":"科尔沁右翼前旗",
                        "full_label":"内蒙古自治区兴安盟科尔沁右翼前旗"
                    },
                    {
                        "id":"152222000000",
                        "label":"科尔沁右翼中旗",
                        "full_label":"内蒙古自治区兴安盟科尔沁右翼中旗"
                    },
                    {
                        "id":"152223000000",
                        "label":"扎赉特旗",
                        "full_label":"内蒙古自治区兴安盟扎赉特旗"
                    },
                    {
                        "id":"152224000000",
                        "label":"突泉县",
                        "full_label":"内蒙古自治区兴安盟突泉县"
                    }
                ],
                "full_label":"内蒙古自治区兴安盟"
            },
            {
                "id":"152500000000",
                "label":"锡林郭勒盟",
                "children":[
                    {
                        "id":"152501000000",
                        "label":"二连浩特市",
                        "full_label":"内蒙古自治区锡林郭勒盟二连浩特市"
                    },
                    {
                        "id":"152502000000",
                        "label":"锡林浩特市",
                        "full_label":"内蒙古自治区锡林郭勒盟锡林浩特市"
                    },
                    {
                        "id":"152522000000",
                        "label":"阿巴嘎旗",
                        "full_label":"内蒙古自治区锡林郭勒盟阿巴嘎旗"
                    },
                    {
                        "id":"152523000000",
                        "label":"苏尼特左旗",
                        "full_label":"内蒙古自治区锡林郭勒盟苏尼特左旗"
                    },
                    {
                        "id":"152524000000",
                        "label":"苏尼特右旗",
                        "full_label":"内蒙古自治区锡林郭勒盟苏尼特右旗"
                    },
                    {
                        "id":"152525000000",
                        "label":"东乌珠穆沁旗",
                        "full_label":"内蒙古自治区锡林郭勒盟东乌珠穆沁旗"
                    },
                    {
                        "id":"152526000000",
                        "label":"西乌珠穆沁旗",
                        "full_label":"内蒙古自治区锡林郭勒盟西乌珠穆沁旗"
                    },
                    {
                        "id":"152527000000",
                        "label":"太仆寺旗",
                        "full_label":"内蒙古自治区锡林郭勒盟太仆寺旗"
                    },
                    {
                        "id":"152528000000",
                        "label":"镶黄旗",
                        "full_label":"内蒙古自治区锡林郭勒盟镶黄旗"
                    },
                    {
                        "id":"152529000000",
                        "label":"正镶白旗",
                        "full_label":"内蒙古自治区锡林郭勒盟正镶白旗"
                    },
                    {
                        "id":"152530000000",
                        "label":"正蓝旗",
                        "full_label":"内蒙古自治区锡林郭勒盟正蓝旗"
                    },
                    {
                        "id":"152531000000",
                        "label":"多伦县",
                        "full_label":"内蒙古自治区锡林郭勒盟多伦县"
                    }
                ],
                "full_label":"内蒙古自治区锡林郭勒盟"
            },
            {
                "id":"152900000000",
                "label":"阿拉善盟",
                "children":[
                    {
                        "id":"152921000000",
                        "label":"阿拉善左旗",
                        "full_label":"内蒙古自治区阿拉善盟阿拉善左旗"
                    },
                    {
                        "id":"152922000000",
                        "label":"阿拉善右旗",
                        "full_label":"内蒙古自治区阿拉善盟阿拉善右旗"
                    },
                    {
                        "id":"152923000000",
                        "label":"额济纳旗",
                        "full_label":"内蒙古自治区阿拉善盟额济纳旗"
                    }
                ],
                "full_label":"内蒙古自治区阿拉善盟"
            }
        ],
        "full_label":"内蒙古自治区"
    },
    {
        "id":"210000000000",
        "label":"辽宁省",
        "children":[
            {
                "id":"210100000000",
                "label":"沈阳市",
                "children":[
                    {
                        "id":"210102000000",
                        "label":"和平区",
                        "full_label":"辽宁省沈阳市和平区"
                    },
                    {
                        "id":"210103000000",
                        "label":"沈河区",
                        "full_label":"辽宁省沈阳市沈河区"
                    },
                    {
                        "id":"210104000000",
                        "label":"大东区",
                        "full_label":"辽宁省沈阳市大东区"
                    },
                    {
                        "id":"210105000000",
                        "label":"皇姑区",
                        "full_label":"辽宁省沈阳市皇姑区"
                    },
                    {
                        "id":"210106000000",
                        "label":"铁西区",
                        "full_label":"辽宁省沈阳市铁西区"
                    },
                    {
                        "id":"210111000000",
                        "label":"苏家屯区",
                        "full_label":"辽宁省沈阳市苏家屯区"
                    },
                    {
                        "id":"210112000000",
                        "label":"浑南区",
                        "full_label":"辽宁省沈阳市浑南区"
                    },
                    {
                        "id":"210113000000",
                        "label":"沈北新区",
                        "full_label":"辽宁省沈阳市沈北新区"
                    },
                    {
                        "id":"210114000000",
                        "label":"于洪区",
                        "full_label":"辽宁省沈阳市于洪区"
                    },
                    {
                        "id":"210115000000",
                        "label":"辽中区",
                        "full_label":"辽宁省沈阳市辽中区"
                    },
                    {
                        "id":"210123000000",
                        "label":"康平县",
                        "full_label":"辽宁省沈阳市康平县"
                    },
                    {
                        "id":"210124000000",
                        "label":"法库县",
                        "full_label":"辽宁省沈阳市法库县"
                    },
                    {
                        "id":"210181000000",
                        "label":"新民市",
                        "full_label":"辽宁省沈阳市新民市"
                    }
                ],
                "full_label":"辽宁省沈阳市"
            },
            {
                "id":"210200000000",
                "label":"大连市",
                "children":[
                    {
                        "id":"210202000000",
                        "label":"中山区",
                        "full_label":"辽宁省大连市中山区"
                    },
                    {
                        "id":"210203000000",
                        "label":"西岗区",
                        "full_label":"辽宁省大连市西岗区"
                    },
                    {
                        "id":"210204000000",
                        "label":"沙河口区",
                        "full_label":"辽宁省大连市沙河口区"
                    },
                    {
                        "id":"210211000000",
                        "label":"甘井子区",
                        "full_label":"辽宁省大连市甘井子区"
                    },
                    {
                        "id":"210212000000",
                        "label":"旅顺口区",
                        "full_label":"辽宁省大连市旅顺口区"
                    },
                    {
                        "id":"210213000000",
                        "label":"金州区",
                        "full_label":"辽宁省大连市金州区"
                    },
                    {
                        "id":"210214000000",
                        "label":"普兰店区",
                        "full_label":"辽宁省大连市普兰店区"
                    },
                    {
                        "id":"210224000000",
                        "label":"长海县",
                        "full_label":"辽宁省大连市长海县"
                    },
                    {
                        "id":"210281000000",
                        "label":"瓦房店市",
                        "full_label":"辽宁省大连市瓦房店市"
                    },
                    {
                        "id":"210283000000",
                        "label":"庄河市",
                        "full_label":"辽宁省大连市庄河市"
                    }
                ],
                "full_label":"辽宁省大连市"
            },
            {
                "id":"210300000000",
                "label":"鞍山市",
                "children":[
                    {
                        "id":"210302000000",
                        "label":"铁东区",
                        "full_label":"辽宁省鞍山市铁东区"
                    },
                    {
                        "id":"210303000000",
                        "label":"铁西区",
                        "full_label":"辽宁省鞍山市铁西区"
                    },
                    {
                        "id":"210304000000",
                        "label":"立山区",
                        "full_label":"辽宁省鞍山市立山区"
                    },
                    {
                        "id":"210311000000",
                        "label":"千山区",
                        "full_label":"辽宁省鞍山市千山区"
                    },
                    {
                        "id":"210321000000",
                        "label":"台安县",
                        "full_label":"辽宁省鞍山市台安县"
                    },
                    {
                        "id":"210323000000",
                        "label":"岫岩满族自治县",
                        "full_label":"辽宁省鞍山市岫岩满族自治县"
                    },
                    {
                        "id":"210381000000",
                        "label":"海城市",
                        "full_label":"辽宁省鞍山市海城市"
                    }
                ],
                "full_label":"辽宁省鞍山市"
            },
            {
                "id":"210400000000",
                "label":"抚顺市",
                "children":[
                    {
                        "id":"210402000000",
                        "label":"新抚区",
                        "full_label":"辽宁省抚顺市新抚区"
                    },
                    {
                        "id":"210403000000",
                        "label":"东洲区",
                        "full_label":"辽宁省抚顺市东洲区"
                    },
                    {
                        "id":"210404000000",
                        "label":"望花区",
                        "full_label":"辽宁省抚顺市望花区"
                    },
                    {
                        "id":"210411000000",
                        "label":"顺城区",
                        "full_label":"辽宁省抚顺市顺城区"
                    },
                    {
                        "id":"210421000000",
                        "label":"抚顺县",
                        "full_label":"辽宁省抚顺市抚顺县"
                    },
                    {
                        "id":"210422000000",
                        "label":"新宾满族自治县",
                        "full_label":"辽宁省抚顺市新宾满族自治县"
                    },
                    {
                        "id":"210423000000",
                        "label":"清原满族自治县",
                        "full_label":"辽宁省抚顺市清原满族自治县"
                    }
                ],
                "full_label":"辽宁省抚顺市"
            },
            {
                "id":"210500000000",
                "label":"本溪市",
                "children":[
                    {
                        "id":"210502000000",
                        "label":"平山区",
                        "full_label":"辽宁省本溪市平山区"
                    },
                    {
                        "id":"210503000000",
                        "label":"溪湖区",
                        "full_label":"辽宁省本溪市溪湖区"
                    },
                    {
                        "id":"210504000000",
                        "label":"明山区",
                        "full_label":"辽宁省本溪市明山区"
                    },
                    {
                        "id":"210505000000",
                        "label":"南芬区",
                        "full_label":"辽宁省本溪市南芬区"
                    },
                    {
                        "id":"210521000000",
                        "label":"本溪满族自治县",
                        "full_label":"辽宁省本溪市本溪满族自治县"
                    },
                    {
                        "id":"210522000000",
                        "label":"桓仁满族自治县",
                        "full_label":"辽宁省本溪市桓仁满族自治县"
                    }
                ],
                "full_label":"辽宁省本溪市"
            },
            {
                "id":"210600000000",
                "label":"丹东市",
                "children":[
                    {
                        "id":"210602000000",
                        "label":"元宝区",
                        "full_label":"辽宁省丹东市元宝区"
                    },
                    {
                        "id":"210603000000",
                        "label":"振兴区",
                        "full_label":"辽宁省丹东市振兴区"
                    },
                    {
                        "id":"210604000000",
                        "label":"振安区",
                        "full_label":"辽宁省丹东市振安区"
                    },
                    {
                        "id":"210624000000",
                        "label":"宽甸满族自治县",
                        "full_label":"辽宁省丹东市宽甸满族自治县"
                    },
                    {
                        "id":"210681000000",
                        "label":"东港市",
                        "full_label":"辽宁省丹东市东港市"
                    },
                    {
                        "id":"210682000000",
                        "label":"凤城市",
                        "full_label":"辽宁省丹东市凤城市"
                    }
                ],
                "full_label":"辽宁省丹东市"
            },
            {
                "id":"210700000000",
                "label":"锦州市",
                "children":[
                    {
                        "id":"210702000000",
                        "label":"古塔区",
                        "full_label":"辽宁省锦州市古塔区"
                    },
                    {
                        "id":"210703000000",
                        "label":"凌河区",
                        "full_label":"辽宁省锦州市凌河区"
                    },
                    {
                        "id":"210711000000",
                        "label":"太和区",
                        "full_label":"辽宁省锦州市太和区"
                    },
                    {
                        "id":"210726000000",
                        "label":"黑山县",
                        "full_label":"辽宁省锦州市黑山县"
                    },
                    {
                        "id":"210727000000",
                        "label":"义县",
                        "full_label":"辽宁省锦州市义县"
                    },
                    {
                        "id":"210781000000",
                        "label":"凌海市",
                        "full_label":"辽宁省锦州市凌海市"
                    },
                    {
                        "id":"210782000000",
                        "label":"北镇市",
                        "full_label":"辽宁省锦州市北镇市"
                    }
                ],
                "full_label":"辽宁省锦州市"
            },
            {
                "id":"210800000000",
                "label":"营口市",
                "children":[
                    {
                        "id":"210802000000",
                        "label":"站前区",
                        "full_label":"辽宁省营口市站前区"
                    },
                    {
                        "id":"210803000000",
                        "label":"西市区",
                        "full_label":"辽宁省营口市西市区"
                    },
                    {
                        "id":"210804000000",
                        "label":"鲅鱼圈区",
                        "full_label":"辽宁省营口市鲅鱼圈区"
                    },
                    {
                        "id":"210811000000",
                        "label":"老边区",
                        "full_label":"辽宁省营口市老边区"
                    },
                    {
                        "id":"210881000000",
                        "label":"盖州市",
                        "full_label":"辽宁省营口市盖州市"
                    },
                    {
                        "id":"210882000000",
                        "label":"大石桥市",
                        "full_label":"辽宁省营口市大石桥市"
                    }
                ],
                "full_label":"辽宁省营口市"
            },
            {
                "id":"210900000000",
                "label":"阜新市",
                "children":[
                    {
                        "id":"210902000000",
                        "label":"海州区",
                        "full_label":"辽宁省阜新市海州区"
                    },
                    {
                        "id":"210903000000",
                        "label":"新邱区",
                        "full_label":"辽宁省阜新市新邱区"
                    },
                    {
                        "id":"210904000000",
                        "label":"太平区",
                        "full_label":"辽宁省阜新市太平区"
                    },
                    {
                        "id":"210905000000",
                        "label":"清河门区",
                        "full_label":"辽宁省阜新市清河门区"
                    },
                    {
                        "id":"210911000000",
                        "label":"细河区",
                        "full_label":"辽宁省阜新市细河区"
                    },
                    {
                        "id":"210921000000",
                        "label":"阜新蒙古族自治县",
                        "full_label":"辽宁省阜新市阜新蒙古族自治县"
                    },
                    {
                        "id":"210922000000",
                        "label":"彰武县",
                        "full_label":"辽宁省阜新市彰武县"
                    }
                ],
                "full_label":"辽宁省阜新市"
            },
            {
                "id":"211000000000",
                "label":"辽阳市",
                "children":[
                    {
                        "id":"211002000000",
                        "label":"白塔区",
                        "full_label":"辽宁省辽阳市白塔区"
                    },
                    {
                        "id":"211003000000",
                        "label":"文圣区",
                        "full_label":"辽宁省辽阳市文圣区"
                    },
                    {
                        "id":"211004000000",
                        "label":"宏伟区",
                        "full_label":"辽宁省辽阳市宏伟区"
                    },
                    {
                        "id":"211005000000",
                        "label":"弓长岭区",
                        "full_label":"辽宁省辽阳市弓长岭区"
                    },
                    {
                        "id":"211011000000",
                        "label":"太子河区",
                        "full_label":"辽宁省辽阳市太子河区"
                    },
                    {
                        "id":"211021000000",
                        "label":"辽阳县",
                        "full_label":"辽宁省辽阳市辽阳县"
                    },
                    {
                        "id":"211081000000",
                        "label":"灯塔市",
                        "full_label":"辽宁省辽阳市灯塔市"
                    }
                ],
                "full_label":"辽宁省辽阳市"
            },
            {
                "id":"211100000000",
                "label":"盘锦市",
                "children":[
                    {
                        "id":"211102000000",
                        "label":"双台子区",
                        "full_label":"辽宁省盘锦市双台子区"
                    },
                    {
                        "id":"211103000000",
                        "label":"兴隆台区",
                        "full_label":"辽宁省盘锦市兴隆台区"
                    },
                    {
                        "id":"211104000000",
                        "label":"大洼区",
                        "full_label":"辽宁省盘锦市大洼区"
                    },
                    {
                        "id":"211122000000",
                        "label":"盘山县",
                        "full_label":"辽宁省盘锦市盘山县"
                    }
                ],
                "full_label":"辽宁省盘锦市"
            },
            {
                "id":"211200000000",
                "label":"铁岭市",
                "children":[
                    {
                        "id":"211202000000",
                        "label":"银州区",
                        "full_label":"辽宁省铁岭市银州区"
                    },
                    {
                        "id":"211204000000",
                        "label":"清河区",
                        "full_label":"辽宁省铁岭市清河区"
                    },
                    {
                        "id":"211221000000",
                        "label":"铁岭县",
                        "full_label":"辽宁省铁岭市铁岭县"
                    },
                    {
                        "id":"211223000000",
                        "label":"西丰县",
                        "full_label":"辽宁省铁岭市西丰县"
                    },
                    {
                        "id":"211224000000",
                        "label":"昌图县",
                        "full_label":"辽宁省铁岭市昌图县"
                    },
                    {
                        "id":"211281000000",
                        "label":"调兵山市",
                        "full_label":"辽宁省铁岭市调兵山市"
                    },
                    {
                        "id":"211282000000",
                        "label":"开原市",
                        "full_label":"辽宁省铁岭市开原市"
                    }
                ],
                "full_label":"辽宁省铁岭市"
            },
            {
                "id":"211300000000",
                "label":"朝阳市",
                "children":[
                    {
                        "id":"211302000000",
                        "label":"双塔区",
                        "full_label":"辽宁省朝阳市双塔区"
                    },
                    {
                        "id":"211303000000",
                        "label":"龙城区",
                        "full_label":"辽宁省朝阳市龙城区"
                    },
                    {
                        "id":"211321000000",
                        "label":"朝阳县",
                        "full_label":"辽宁省朝阳市朝阳县"
                    },
                    {
                        "id":"211322000000",
                        "label":"建平县",
                        "full_label":"辽宁省朝阳市建平县"
                    },
                    {
                        "id":"211324000000",
                        "label":"喀喇沁左翼蒙古族自治县",
                        "full_label":"辽宁省朝阳市喀喇沁左翼蒙古族自治县"
                    },
                    {
                        "id":"211381000000",
                        "label":"北票市",
                        "full_label":"辽宁省朝阳市北票市"
                    },
                    {
                        "id":"211382000000",
                        "label":"凌源市",
                        "full_label":"辽宁省朝阳市凌源市"
                    }
                ],
                "full_label":"辽宁省朝阳市"
            },
            {
                "id":"211400000000",
                "label":"葫芦岛市",
                "children":[
                    {
                        "id":"211402000000",
                        "label":"连山区",
                        "full_label":"辽宁省葫芦岛市连山区"
                    },
                    {
                        "id":"211403000000",
                        "label":"龙港区",
                        "full_label":"辽宁省葫芦岛市龙港区"
                    },
                    {
                        "id":"211404000000",
                        "label":"南票区",
                        "full_label":"辽宁省葫芦岛市南票区"
                    },
                    {
                        "id":"211421000000",
                        "label":"绥中县",
                        "full_label":"辽宁省葫芦岛市绥中县"
                    },
                    {
                        "id":"211422000000",
                        "label":"建昌县",
                        "full_label":"辽宁省葫芦岛市建昌县"
                    },
                    {
                        "id":"211481000000",
                        "label":"兴城市",
                        "full_label":"辽宁省葫芦岛市兴城市"
                    }
                ],
                "full_label":"辽宁省葫芦岛市"
            }
        ],
        "full_label":"辽宁省"
    },
    {
        "id":"220000000000",
        "label":"吉林省",
        "children":[
            {
                "id":"220100000000",
                "label":"长春市",
                "children":[
                    {
                        "id":"220102000000",
                        "label":"南关区",
                        "full_label":"吉林省长春市南关区"
                    },
                    {
                        "id":"220103000000",
                        "label":"宽城区",
                        "full_label":"吉林省长春市宽城区"
                    },
                    {
                        "id":"220104000000",
                        "label":"朝阳区",
                        "full_label":"吉林省长春市朝阳区"
                    },
                    {
                        "id":"220105000000",
                        "label":"二道区",
                        "full_label":"吉林省长春市二道区"
                    },
                    {
                        "id":"220106000000",
                        "label":"绿园区",
                        "full_label":"吉林省长春市绿园区"
                    },
                    {
                        "id":"220112000000",
                        "label":"双阳区",
                        "full_label":"吉林省长春市双阳区"
                    },
                    {
                        "id":"220113000000",
                        "label":"九台区",
                        "full_label":"吉林省长春市九台区"
                    },
                    {
                        "id":"220122000000",
                        "label":"农安县",
                        "full_label":"吉林省长春市农安县"
                    },
                    {
                        "id":"220182000000",
                        "label":"榆树市",
                        "full_label":"吉林省长春市榆树市"
                    },
                    {
                        "id":"220183000000",
                        "label":"德惠市",
                        "full_label":"吉林省长春市德惠市"
                    },
                    {
                        "id":"220184000000",
                        "label":"公主岭市",
                        "full_label":"吉林省长春市公主岭市"
                    }
                ],
                "full_label":"吉林省长春市"
            },
            {
                "id":"220200000000",
                "label":"吉林市",
                "children":[
                    {
                        "id":"220202000000",
                        "label":"昌邑区",
                        "full_label":"吉林省吉林市昌邑区"
                    },
                    {
                        "id":"220203000000",
                        "label":"龙潭区",
                        "full_label":"吉林省吉林市龙潭区"
                    },
                    {
                        "id":"220204000000",
                        "label":"船营区",
                        "full_label":"吉林省吉林市船营区"
                    },
                    {
                        "id":"220211000000",
                        "label":"丰满区",
                        "full_label":"吉林省吉林市丰满区"
                    },
                    {
                        "id":"220221000000",
                        "label":"永吉县",
                        "full_label":"吉林省吉林市永吉县"
                    },
                    {
                        "id":"220281000000",
                        "label":"蛟河市",
                        "full_label":"吉林省吉林市蛟河市"
                    },
                    {
                        "id":"220282000000",
                        "label":"桦甸市",
                        "full_label":"吉林省吉林市桦甸市"
                    },
                    {
                        "id":"220283000000",
                        "label":"舒兰市",
                        "full_label":"吉林省吉林市舒兰市"
                    },
                    {
                        "id":"220284000000",
                        "label":"磐石市",
                        "full_label":"吉林省吉林市磐石市"
                    }
                ],
                "full_label":"吉林省吉林市"
            },
            {
                "id":"220300000000",
                "label":"四平市",
                "children":[
                    {
                        "id":"220302000000",
                        "label":"铁西区",
                        "full_label":"吉林省四平市铁西区"
                    },
                    {
                        "id":"220303000000",
                        "label":"铁东区",
                        "full_label":"吉林省四平市铁东区"
                    },
                    {
                        "id":"220322000000",
                        "label":"梨树县",
                        "full_label":"吉林省四平市梨树县"
                    },
                    {
                        "id":"220323000000",
                        "label":"伊通满族自治县",
                        "full_label":"吉林省四平市伊通满族自治县"
                    },
                    {
                        "id":"220382000000",
                        "label":"双辽市",
                        "full_label":"吉林省四平市双辽市"
                    }
                ],
                "full_label":"吉林省四平市"
            },
            {
                "id":"220400000000",
                "label":"辽源市",
                "children":[
                    {
                        "id":"220402000000",
                        "label":"龙山区",
                        "full_label":"吉林省辽源市龙山区"
                    },
                    {
                        "id":"220403000000",
                        "label":"西安区",
                        "full_label":"吉林省辽源市西安区"
                    },
                    {
                        "id":"220421000000",
                        "label":"东丰县",
                        "full_label":"吉林省辽源市东丰县"
                    },
                    {
                        "id":"220422000000",
                        "label":"东辽县",
                        "full_label":"吉林省辽源市东辽县"
                    }
                ],
                "full_label":"吉林省辽源市"
            },
            {
                "id":"220500000000",
                "label":"通化市",
                "children":[
                    {
                        "id":"220502000000",
                        "label":"东昌区",
                        "full_label":"吉林省通化市东昌区"
                    },
                    {
                        "id":"220503000000",
                        "label":"二道江区",
                        "full_label":"吉林省通化市二道江区"
                    },
                    {
                        "id":"220521000000",
                        "label":"通化县",
                        "full_label":"吉林省通化市通化县"
                    },
                    {
                        "id":"220523000000",
                        "label":"辉南县",
                        "full_label":"吉林省通化市辉南县"
                    },
                    {
                        "id":"220524000000",
                        "label":"柳河县",
                        "full_label":"吉林省通化市柳河县"
                    },
                    {
                        "id":"220581000000",
                        "label":"梅河口市",
                        "full_label":"吉林省通化市梅河口市"
                    },
                    {
                        "id":"220582000000",
                        "label":"集安市",
                        "full_label":"吉林省通化市集安市"
                    }
                ],
                "full_label":"吉林省通化市"
            },
            {
                "id":"220600000000",
                "label":"白山市",
                "children":[
                    {
                        "id":"220602000000",
                        "label":"浑江区",
                        "full_label":"吉林省白山市浑江区"
                    },
                    {
                        "id":"220605000000",
                        "label":"江源区",
                        "full_label":"吉林省白山市江源区"
                    },
                    {
                        "id":"220621000000",
                        "label":"抚松县",
                        "full_label":"吉林省白山市抚松县"
                    },
                    {
                        "id":"220622000000",
                        "label":"靖宇县",
                        "full_label":"吉林省白山市靖宇县"
                    },
                    {
                        "id":"220623000000",
                        "label":"长白朝鲜族自治县",
                        "full_label":"吉林省白山市长白朝鲜族自治县"
                    },
                    {
                        "id":"220681000000",
                        "label":"临江市",
                        "full_label":"吉林省白山市临江市"
                    }
                ],
                "full_label":"吉林省白山市"
            },
            {
                "id":"220700000000",
                "label":"松原市",
                "children":[
                    {
                        "id":"220702000000",
                        "label":"宁江区",
                        "full_label":"吉林省松原市宁江区"
                    },
                    {
                        "id":"220721000000",
                        "label":"前郭尔罗斯蒙古族自治县",
                        "full_label":"吉林省松原市前郭尔罗斯蒙古族自治县"
                    },
                    {
                        "id":"220722000000",
                        "label":"长岭县",
                        "full_label":"吉林省松原市长岭县"
                    },
                    {
                        "id":"220723000000",
                        "label":"乾安县",
                        "full_label":"吉林省松原市乾安县"
                    },
                    {
                        "id":"220781000000",
                        "label":"扶余市",
                        "full_label":"吉林省松原市扶余市"
                    }
                ],
                "full_label":"吉林省松原市"
            },
            {
                "id":"220800000000",
                "label":"白城市",
                "children":[
                    {
                        "id":"220802000000",
                        "label":"洮北区",
                        "full_label":"吉林省白城市洮北区"
                    },
                    {
                        "id":"220821000000",
                        "label":"镇赉县",
                        "full_label":"吉林省白城市镇赉县"
                    },
                    {
                        "id":"220822000000",
                        "label":"通榆县",
                        "full_label":"吉林省白城市通榆县"
                    },
                    {
                        "id":"220881000000",
                        "label":"洮南市",
                        "full_label":"吉林省白城市洮南市"
                    },
                    {
                        "id":"220882000000",
                        "label":"大安市",
                        "full_label":"吉林省白城市大安市"
                    }
                ],
                "full_label":"吉林省白城市"
            },
            {
                "id":"222400000000",
                "label":"延边朝鲜族自治州",
                "children":[
                    {
                        "id":"222401000000",
                        "label":"延吉市",
                        "full_label":"吉林省延边朝鲜族自治州延吉市"
                    },
                    {
                        "id":"222402000000",
                        "label":"图们市",
                        "full_label":"吉林省延边朝鲜族自治州图们市"
                    },
                    {
                        "id":"222403000000",
                        "label":"敦化市",
                        "full_label":"吉林省延边朝鲜族自治州敦化市"
                    },
                    {
                        "id":"222404000000",
                        "label":"珲春市",
                        "full_label":"吉林省延边朝鲜族自治州珲春市"
                    },
                    {
                        "id":"222405000000",
                        "label":"龙井市",
                        "full_label":"吉林省延边朝鲜族自治州龙井市"
                    },
                    {
                        "id":"222406000000",
                        "label":"和龙市",
                        "full_label":"吉林省延边朝鲜族自治州和龙市"
                    },
                    {
                        "id":"222424000000",
                        "label":"汪清县",
                        "full_label":"吉林省延边朝鲜族自治州汪清县"
                    },
                    {
                        "id":"222426000000",
                        "label":"安图县",
                        "full_label":"吉林省延边朝鲜族自治州安图县"
                    }
                ],
                "full_label":"吉林省延边朝鲜族自治州"
            }
        ],
        "full_label":"吉林省"
    },
    {
        "id":"230000000000",
        "label":"黑龙江省",
        "children":[
            {
                "id":"230100000000",
                "label":"哈尔滨市",
                "children":[
                    {
                        "id":"230102000000",
                        "label":"道里区",
                        "full_label":"黑龙江省哈尔滨市道里区"
                    },
                    {
                        "id":"230103000000",
                        "label":"南岗区",
                        "full_label":"黑龙江省哈尔滨市南岗区"
                    },
                    {
                        "id":"230104000000",
                        "label":"道外区",
                        "full_label":"黑龙江省哈尔滨市道外区"
                    },
                    {
                        "id":"230108000000",
                        "label":"平房区",
                        "full_label":"黑龙江省哈尔滨市平房区"
                    },
                    {
                        "id":"230109000000",
                        "label":"松北区",
                        "full_label":"黑龙江省哈尔滨市松北区"
                    },
                    {
                        "id":"230110000000",
                        "label":"香坊区",
                        "full_label":"黑龙江省哈尔滨市香坊区"
                    },
                    {
                        "id":"230111000000",
                        "label":"呼兰区",
                        "full_label":"黑龙江省哈尔滨市呼兰区"
                    },
                    {
                        "id":"230112000000",
                        "label":"阿城区",
                        "full_label":"黑龙江省哈尔滨市阿城区"
                    },
                    {
                        "id":"230113000000",
                        "label":"双城区",
                        "full_label":"黑龙江省哈尔滨市双城区"
                    },
                    {
                        "id":"230123000000",
                        "label":"依兰县",
                        "full_label":"黑龙江省哈尔滨市依兰县"
                    },
                    {
                        "id":"230124000000",
                        "label":"方正县",
                        "full_label":"黑龙江省哈尔滨市方正县"
                    },
                    {
                        "id":"230125000000",
                        "label":"宾县",
                        "full_label":"黑龙江省哈尔滨市宾县"
                    },
                    {
                        "id":"230126000000",
                        "label":"巴彦县",
                        "full_label":"黑龙江省哈尔滨市巴彦县"
                    },
                    {
                        "id":"230127000000",
                        "label":"木兰县",
                        "full_label":"黑龙江省哈尔滨市木兰县"
                    },
                    {
                        "id":"230128000000",
                        "label":"通河县",
                        "full_label":"黑龙江省哈尔滨市通河县"
                    },
                    {
                        "id":"230129000000",
                        "label":"延寿县",
                        "full_label":"黑龙江省哈尔滨市延寿县"
                    },
                    {
                        "id":"230183000000",
                        "label":"尚志市",
                        "full_label":"黑龙江省哈尔滨市尚志市"
                    },
                    {
                        "id":"230184000000",
                        "label":"五常市",
                        "full_label":"黑龙江省哈尔滨市五常市"
                    }
                ],
                "full_label":"黑龙江省哈尔滨市"
            },
            {
                "id":"230200000000",
                "label":"齐齐哈尔市",
                "children":[
                    {
                        "id":"230202000000",
                        "label":"龙沙区",
                        "full_label":"黑龙江省齐齐哈尔市龙沙区"
                    },
                    {
                        "id":"230203000000",
                        "label":"建华区",
                        "full_label":"黑龙江省齐齐哈尔市建华区"
                    },
                    {
                        "id":"230204000000",
                        "label":"铁锋区",
                        "full_label":"黑龙江省齐齐哈尔市铁锋区"
                    },
                    {
                        "id":"230205000000",
                        "label":"昂昂溪区",
                        "full_label":"黑龙江省齐齐哈尔市昂昂溪区"
                    },
                    {
                        "id":"230206000000",
                        "label":"富拉尔基区",
                        "full_label":"黑龙江省齐齐哈尔市富拉尔基区"
                    },
                    {
                        "id":"230207000000",
                        "label":"碾子山区",
                        "full_label":"黑龙江省齐齐哈尔市碾子山区"
                    },
                    {
                        "id":"230208000000",
                        "label":"梅里斯达斡尔族区",
                        "full_label":"黑龙江省齐齐哈尔市梅里斯达斡尔族区"
                    },
                    {
                        "id":"230221000000",
                        "label":"龙江县",
                        "full_label":"黑龙江省齐齐哈尔市龙江县"
                    },
                    {
                        "id":"230223000000",
                        "label":"依安县",
                        "full_label":"黑龙江省齐齐哈尔市依安县"
                    },
                    {
                        "id":"230224000000",
                        "label":"泰来县",
                        "full_label":"黑龙江省齐齐哈尔市泰来县"
                    },
                    {
                        "id":"230225000000",
                        "label":"甘南县",
                        "full_label":"黑龙江省齐齐哈尔市甘南县"
                    },
                    {
                        "id":"230227000000",
                        "label":"富裕县",
                        "full_label":"黑龙江省齐齐哈尔市富裕县"
                    },
                    {
                        "id":"230229000000",
                        "label":"克山县",
                        "full_label":"黑龙江省齐齐哈尔市克山县"
                    },
                    {
                        "id":"230230000000",
                        "label":"克东县",
                        "full_label":"黑龙江省齐齐哈尔市克东县"
                    },
                    {
                        "id":"230231000000",
                        "label":"拜泉县",
                        "full_label":"黑龙江省齐齐哈尔市拜泉县"
                    },
                    {
                        "id":"230281000000",
                        "label":"讷河市",
                        "full_label":"黑龙江省齐齐哈尔市讷河市"
                    }
                ],
                "full_label":"黑龙江省齐齐哈尔市"
            },
            {
                "id":"230300000000",
                "label":"鸡西市",
                "children":[
                    {
                        "id":"230302000000",
                        "label":"鸡冠区",
                        "full_label":"黑龙江省鸡西市鸡冠区"
                    },
                    {
                        "id":"230303000000",
                        "label":"恒山区",
                        "full_label":"黑龙江省鸡西市恒山区"
                    },
                    {
                        "id":"230304000000",
                        "label":"滴道区",
                        "full_label":"黑龙江省鸡西市滴道区"
                    },
                    {
                        "id":"230305000000",
                        "label":"梨树区",
                        "full_label":"黑龙江省鸡西市梨树区"
                    },
                    {
                        "id":"230306000000",
                        "label":"城子河区",
                        "full_label":"黑龙江省鸡西市城子河区"
                    },
                    {
                        "id":"230307000000",
                        "label":"麻山区",
                        "full_label":"黑龙江省鸡西市麻山区"
                    },
                    {
                        "id":"230321000000",
                        "label":"鸡东县",
                        "full_label":"黑龙江省鸡西市鸡东县"
                    },
                    {
                        "id":"230381000000",
                        "label":"虎林市",
                        "full_label":"黑龙江省鸡西市虎林市"
                    },
                    {
                        "id":"230382000000",
                        "label":"密山市",
                        "full_label":"黑龙江省鸡西市密山市"
                    }
                ],
                "full_label":"黑龙江省鸡西市"
            },
            {
                "id":"230400000000",
                "label":"鹤岗市",
                "children":[
                    {
                        "id":"230402000000",
                        "label":"向阳区",
                        "full_label":"黑龙江省鹤岗市向阳区"
                    },
                    {
                        "id":"230403000000",
                        "label":"工农区",
                        "full_label":"黑龙江省鹤岗市工农区"
                    },
                    {
                        "id":"230404000000",
                        "label":"南山区",
                        "full_label":"黑龙江省鹤岗市南山区"
                    },
                    {
                        "id":"230405000000",
                        "label":"兴安区",
                        "full_label":"黑龙江省鹤岗市兴安区"
                    },
                    {
                        "id":"230406000000",
                        "label":"东山区",
                        "full_label":"黑龙江省鹤岗市东山区"
                    },
                    {
                        "id":"230407000000",
                        "label":"兴山区",
                        "full_label":"黑龙江省鹤岗市兴山区"
                    },
                    {
                        "id":"230421000000",
                        "label":"萝北县",
                        "full_label":"黑龙江省鹤岗市萝北县"
                    },
                    {
                        "id":"230422000000",
                        "label":"绥滨县",
                        "full_label":"黑龙江省鹤岗市绥滨县"
                    }
                ],
                "full_label":"黑龙江省鹤岗市"
            },
            {
                "id":"230500000000",
                "label":"双鸭山市",
                "children":[
                    {
                        "id":"230502000000",
                        "label":"尖山区",
                        "full_label":"黑龙江省双鸭山市尖山区"
                    },
                    {
                        "id":"230503000000",
                        "label":"岭东区",
                        "full_label":"黑龙江省双鸭山市岭东区"
                    },
                    {
                        "id":"230505000000",
                        "label":"四方台区",
                        "full_label":"黑龙江省双鸭山市四方台区"
                    },
                    {
                        "id":"230506000000",
                        "label":"宝山区",
                        "full_label":"黑龙江省双鸭山市宝山区"
                    },
                    {
                        "id":"230521000000",
                        "label":"集贤县",
                        "full_label":"黑龙江省双鸭山市集贤县"
                    },
                    {
                        "id":"230522000000",
                        "label":"友谊县",
                        "full_label":"黑龙江省双鸭山市友谊县"
                    },
                    {
                        "id":"230523000000",
                        "label":"宝清县",
                        "full_label":"黑龙江省双鸭山市宝清县"
                    },
                    {
                        "id":"230524000000",
                        "label":"饶河县",
                        "full_label":"黑龙江省双鸭山市饶河县"
                    }
                ],
                "full_label":"黑龙江省双鸭山市"
            },
            {
                "id":"230600000000",
                "label":"大庆市",
                "children":[
                    {
                        "id":"230602000000",
                        "label":"萨尔图区",
                        "full_label":"黑龙江省大庆市萨尔图区"
                    },
                    {
                        "id":"230603000000",
                        "label":"龙凤区",
                        "full_label":"黑龙江省大庆市龙凤区"
                    },
                    {
                        "id":"230604000000",
                        "label":"让胡路区",
                        "full_label":"黑龙江省大庆市让胡路区"
                    },
                    {
                        "id":"230605000000",
                        "label":"红岗区",
                        "full_label":"黑龙江省大庆市红岗区"
                    },
                    {
                        "id":"230606000000",
                        "label":"大同区",
                        "full_label":"黑龙江省大庆市大同区"
                    },
                    {
                        "id":"230621000000",
                        "label":"肇州县",
                        "full_label":"黑龙江省大庆市肇州县"
                    },
                    {
                        "id":"230622000000",
                        "label":"肇源县",
                        "full_label":"黑龙江省大庆市肇源县"
                    },
                    {
                        "id":"230623000000",
                        "label":"林甸县",
                        "full_label":"黑龙江省大庆市林甸县"
                    },
                    {
                        "id":"230624000000",
                        "label":"杜尔伯特蒙古族自治县",
                        "full_label":"黑龙江省大庆市杜尔伯特蒙古族自治县"
                    }
                ],
                "full_label":"黑龙江省大庆市"
            },
            {
                "id":"230700000000",
                "label":"伊春市",
                "children":[
                    {
                        "id":"230717000000",
                        "label":"伊美区",
                        "full_label":"黑龙江省伊春市伊美区"
                    },
                    {
                        "id":"230718000000",
                        "label":"乌翠区",
                        "full_label":"黑龙江省伊春市乌翠区"
                    },
                    {
                        "id":"230719000000",
                        "label":"友好区",
                        "full_label":"黑龙江省伊春市友好区"
                    },
                    {
                        "id":"230722000000",
                        "label":"嘉荫县",
                        "full_label":"黑龙江省伊春市嘉荫县"
                    },
                    {
                        "id":"230723000000",
                        "label":"汤旺县",
                        "full_label":"黑龙江省伊春市汤旺县"
                    },
                    {
                        "id":"230724000000",
                        "label":"丰林县",
                        "full_label":"黑龙江省伊春市丰林县"
                    },
                    {
                        "id":"230725000000",
                        "label":"大箐山县",
                        "full_label":"黑龙江省伊春市大箐山县"
                    },
                    {
                        "id":"230726000000",
                        "label":"南岔县",
                        "full_label":"黑龙江省伊春市南岔县"
                    },
                    {
                        "id":"230751000000",
                        "label":"金林区",
                        "full_label":"黑龙江省伊春市金林区"
                    },
                    {
                        "id":"230781000000",
                        "label":"铁力市",
                        "full_label":"黑龙江省伊春市铁力市"
                    }
                ],
                "full_label":"黑龙江省伊春市"
            },
            {
                "id":"230800000000",
                "label":"佳木斯市",
                "children":[
                    {
                        "id":"230803000000",
                        "label":"向阳区",
                        "full_label":"黑龙江省佳木斯市向阳区"
                    },
                    {
                        "id":"230804000000",
                        "label":"前进区",
                        "full_label":"黑龙江省佳木斯市前进区"
                    },
                    {
                        "id":"230805000000",
                        "label":"东风区",
                        "full_label":"黑龙江省佳木斯市东风区"
                    },
                    {
                        "id":"230811000000",
                        "label":"郊区",
                        "full_label":"黑龙江省佳木斯市郊区"
                    },
                    {
                        "id":"230822000000",
                        "label":"桦南县",
                        "full_label":"黑龙江省佳木斯市桦南县"
                    },
                    {
                        "id":"230826000000",
                        "label":"桦川县",
                        "full_label":"黑龙江省佳木斯市桦川县"
                    },
                    {
                        "id":"230828000000",
                        "label":"汤原县",
                        "full_label":"黑龙江省佳木斯市汤原县"
                    },
                    {
                        "id":"230881000000",
                        "label":"同江市",
                        "full_label":"黑龙江省佳木斯市同江市"
                    },
                    {
                        "id":"230882000000",
                        "label":"富锦市",
                        "full_label":"黑龙江省佳木斯市富锦市"
                    },
                    {
                        "id":"230883000000",
                        "label":"抚远市",
                        "full_label":"黑龙江省佳木斯市抚远市"
                    }
                ],
                "full_label":"黑龙江省佳木斯市"
            },
            {
                "id":"230900000000",
                "label":"七台河市",
                "children":[
                    {
                        "id":"230902000000",
                        "label":"新兴区",
                        "full_label":"黑龙江省七台河市新兴区"
                    },
                    {
                        "id":"230903000000",
                        "label":"桃山区",
                        "full_label":"黑龙江省七台河市桃山区"
                    },
                    {
                        "id":"230904000000",
                        "label":"茄子河区",
                        "full_label":"黑龙江省七台河市茄子河区"
                    },
                    {
                        "id":"230921000000",
                        "label":"勃利县",
                        "full_label":"黑龙江省七台河市勃利县"
                    }
                ],
                "full_label":"黑龙江省七台河市"
            },
            {
                "id":"231000000000",
                "label":"牡丹江市",
                "children":[
                    {
                        "id":"231002000000",
                        "label":"东安区",
                        "full_label":"黑龙江省牡丹江市东安区"
                    },
                    {
                        "id":"231003000000",
                        "label":"阳明区",
                        "full_label":"黑龙江省牡丹江市阳明区"
                    },
                    {
                        "id":"231004000000",
                        "label":"爱民区",
                        "full_label":"黑龙江省牡丹江市爱民区"
                    },
                    {
                        "id":"231005000000",
                        "label":"西安区",
                        "full_label":"黑龙江省牡丹江市西安区"
                    },
                    {
                        "id":"231025000000",
                        "label":"林口县",
                        "full_label":"黑龙江省牡丹江市林口县"
                    },
                    {
                        "id":"231081000000",
                        "label":"绥芬河市",
                        "full_label":"黑龙江省牡丹江市绥芬河市"
                    },
                    {
                        "id":"231083000000",
                        "label":"海林市",
                        "full_label":"黑龙江省牡丹江市海林市"
                    },
                    {
                        "id":"231084000000",
                        "label":"宁安市",
                        "full_label":"黑龙江省牡丹江市宁安市"
                    },
                    {
                        "id":"231085000000",
                        "label":"穆棱市",
                        "full_label":"黑龙江省牡丹江市穆棱市"
                    },
                    {
                        "id":"231086000000",
                        "label":"东宁市",
                        "full_label":"黑龙江省牡丹江市东宁市"
                    }
                ],
                "full_label":"黑龙江省牡丹江市"
            },
            {
                "id":"231100000000",
                "label":"黑河市",
                "children":[
                    {
                        "id":"231102000000",
                        "label":"爱辉区",
                        "full_label":"黑龙江省黑河市爱辉区"
                    },
                    {
                        "id":"231123000000",
                        "label":"逊克县",
                        "full_label":"黑龙江省黑河市逊克县"
                    },
                    {
                        "id":"231124000000",
                        "label":"孙吴县",
                        "full_label":"黑龙江省黑河市孙吴县"
                    },
                    {
                        "id":"231181000000",
                        "label":"北安市",
                        "full_label":"黑龙江省黑河市北安市"
                    },
                    {
                        "id":"231182000000",
                        "label":"五大连池市",
                        "full_label":"黑龙江省黑河市五大连池市"
                    },
                    {
                        "id":"231183000000",
                        "label":"嫩江市",
                        "full_label":"黑龙江省黑河市嫩江市"
                    }
                ],
                "full_label":"黑龙江省黑河市"
            },
            {
                "id":"231200000000",
                "label":"绥化市",
                "children":[
                    {
                        "id":"231202000000",
                        "label":"北林区",
                        "full_label":"黑龙江省绥化市北林区"
                    },
                    {
                        "id":"231221000000",
                        "label":"望奎县",
                        "full_label":"黑龙江省绥化市望奎县"
                    },
                    {
                        "id":"231222000000",
                        "label":"兰西县",
                        "full_label":"黑龙江省绥化市兰西县"
                    },
                    {
                        "id":"231223000000",
                        "label":"青冈县",
                        "full_label":"黑龙江省绥化市青冈县"
                    },
                    {
                        "id":"231224000000",
                        "label":"庆安县",
                        "full_label":"黑龙江省绥化市庆安县"
                    },
                    {
                        "id":"231225000000",
                        "label":"明水县",
                        "full_label":"黑龙江省绥化市明水县"
                    },
                    {
                        "id":"231226000000",
                        "label":"绥棱县",
                        "full_label":"黑龙江省绥化市绥棱县"
                    },
                    {
                        "id":"231281000000",
                        "label":"安达市",
                        "full_label":"黑龙江省绥化市安达市"
                    },
                    {
                        "id":"231282000000",
                        "label":"肇东市",
                        "full_label":"黑龙江省绥化市肇东市"
                    },
                    {
                        "id":"231283000000",
                        "label":"海伦市",
                        "full_label":"黑龙江省绥化市海伦市"
                    }
                ],
                "full_label":"黑龙江省绥化市"
            },
            {
                "id":"232700000000",
                "label":"大兴安岭地区",
                "children":[
                    {
                        "id":"232701000000",
                        "label":"漠河市",
                        "full_label":"黑龙江省大兴安岭地区漠河市"
                    },
                    {
                        "id":"232718000000",
                        "label":"加格达奇区",
                        "full_label":"黑龙江省大兴安岭地区加格达奇区"
                    },
                    {
                        "id":"232721000000",
                        "label":"呼玛县",
                        "full_label":"黑龙江省大兴安岭地区呼玛县"
                    },
                    {
                        "id":"232722000000",
                        "label":"塔河县",
                        "full_label":"黑龙江省大兴安岭地区塔河县"
                    }
                ],
                "full_label":"黑龙江省大兴安岭地区"
            }
        ],
        "full_label":"黑龙江省"
    },
    {
        "id":"310000000000",
        "label":"上海市",
        "children":[
            {
                "id":"310101000000",
                "label":"黄浦区",
                "full_label":"上海市黄浦区"
            },
            {
                "id":"310104000000",
                "label":"徐汇区",
                "full_label":"上海市徐汇区"
            },
            {
                "id":"310105000000",
                "label":"长宁区",
                "full_label":"上海市长宁区"
            },
            {
                "id":"310106000000",
                "label":"静安区",
                "full_label":"上海市静安区"
            },
            {
                "id":"310107000000",
                "label":"普陀区",
                "full_label":"上海市普陀区"
            },
            {
                "id":"310109000000",
                "label":"虹口区",
                "full_label":"上海市虹口区"
            },
            {
                "id":"310110000000",
                "label":"杨浦区",
                "full_label":"上海市杨浦区"
            },
            {
                "id":"310112000000",
                "label":"闵行区",
                "full_label":"上海市闵行区"
            },
            {
                "id":"310113000000",
                "label":"宝山区",
                "full_label":"上海市宝山区"
            },
            {
                "id":"310114000000",
                "label":"嘉定区",
                "full_label":"上海市嘉定区"
            },
            {
                "id":"310115000000",
                "label":"浦东新区",
                "full_label":"上海市浦东新区"
            },
            {
                "id":"310116000000",
                "label":"金山区",
                "full_label":"上海市金山区"
            },
            {
                "id":"310117000000",
                "label":"松江区",
                "full_label":"上海市松江区"
            },
            {
                "id":"310118000000",
                "label":"青浦区",
                "full_label":"上海市青浦区"
            },
            {
                "id":"310120000000",
                "label":"奉贤区",
                "full_label":"上海市奉贤区"
            },
            {
                "id":"310151000000",
                "label":"崇明区",
                "full_label":"上海市崇明区"
            }
        ],
        "full_label":"上海市"
    },
    {
        "id":"320000000000",
        "label":"江苏省",
        "children":[
            {
                "id":"320100000000",
                "label":"南京市",
                "children":[
                    {
                        "id":"320102000000",
                        "label":"玄武区",
                        "full_label":"江苏省南京市玄武区"
                    },
                    {
                        "id":"320104000000",
                        "label":"秦淮区",
                        "full_label":"江苏省南京市秦淮区"
                    },
                    {
                        "id":"320105000000",
                        "label":"建邺区",
                        "full_label":"江苏省南京市建邺区"
                    },
                    {
                        "id":"320106000000",
                        "label":"鼓楼区",
                        "full_label":"江苏省南京市鼓楼区"
                    },
                    {
                        "id":"320111000000",
                        "label":"浦口区",
                        "full_label":"江苏省南京市浦口区"
                    },
                    {
                        "id":"320113000000",
                        "label":"栖霞区",
                        "full_label":"江苏省南京市栖霞区"
                    },
                    {
                        "id":"320114000000",
                        "label":"雨花台区",
                        "full_label":"江苏省南京市雨花台区"
                    },
                    {
                        "id":"320115000000",
                        "label":"江宁区",
                        "full_label":"江苏省南京市江宁区"
                    },
                    {
                        "id":"320116000000",
                        "label":"六合区",
                        "full_label":"江苏省南京市六合区"
                    },
                    {
                        "id":"320117000000",
                        "label":"溧水区",
                        "full_label":"江苏省南京市溧水区"
                    },
                    {
                        "id":"320118000000",
                        "label":"高淳区",
                        "full_label":"江苏省南京市高淳区"
                    }
                ],
                "full_label":"江苏省南京市"
            },
            {
                "id":"320200000000",
                "label":"无锡市",
                "children":[
                    {
                        "id":"320205000000",
                        "label":"锡山区",
                        "full_label":"江苏省无锡市锡山区"
                    },
                    {
                        "id":"320206000000",
                        "label":"惠山区",
                        "full_label":"江苏省无锡市惠山区"
                    },
                    {
                        "id":"320211000000",
                        "label":"滨湖区",
                        "full_label":"江苏省无锡市滨湖区"
                    },
                    {
                        "id":"320213000000",
                        "label":"梁溪区",
                        "full_label":"江苏省无锡市梁溪区"
                    },
                    {
                        "id":"320214000000",
                        "label":"新吴区",
                        "full_label":"江苏省无锡市新吴区"
                    },
                    {
                        "id":"320281000000",
                        "label":"江阴市",
                        "full_label":"江苏省无锡市江阴市"
                    },
                    {
                        "id":"320282000000",
                        "label":"宜兴市",
                        "full_label":"江苏省无锡市宜兴市"
                    }
                ],
                "full_label":"江苏省无锡市"
            },
            {
                "id":"320300000000",
                "label":"徐州市",
                "children":[
                    {
                        "id":"320302000000",
                        "label":"鼓楼区",
                        "full_label":"江苏省徐州市鼓楼区"
                    },
                    {
                        "id":"320303000000",
                        "label":"云龙区",
                        "full_label":"江苏省徐州市云龙区"
                    },
                    {
                        "id":"320305000000",
                        "label":"贾汪区",
                        "full_label":"江苏省徐州市贾汪区"
                    },
                    {
                        "id":"320311000000",
                        "label":"泉山区",
                        "full_label":"江苏省徐州市泉山区"
                    },
                    {
                        "id":"320312000000",
                        "label":"铜山区",
                        "full_label":"江苏省徐州市铜山区"
                    },
                    {
                        "id":"320321000000",
                        "label":"丰县",
                        "full_label":"江苏省徐州市丰县"
                    },
                    {
                        "id":"320322000000",
                        "label":"沛县",
                        "full_label":"江苏省徐州市沛县"
                    },
                    {
                        "id":"320324000000",
                        "label":"睢宁县",
                        "full_label":"江苏省徐州市睢宁县"
                    },
                    {
                        "id":"320381000000",
                        "label":"新沂市",
                        "full_label":"江苏省徐州市新沂市"
                    },
                    {
                        "id":"320382000000",
                        "label":"邳州市",
                        "full_label":"江苏省徐州市邳州市"
                    }
                ],
                "full_label":"江苏省徐州市"
            },
            {
                "id":"320400000000",
                "label":"常州市",
                "children":[
                    {
                        "id":"320402000000",
                        "label":"天宁区",
                        "full_label":"江苏省常州市天宁区"
                    },
                    {
                        "id":"320404000000",
                        "label":"钟楼区",
                        "full_label":"江苏省常州市钟楼区"
                    },
                    {
                        "id":"320411000000",
                        "label":"新北区",
                        "full_label":"江苏省常州市新北区"
                    },
                    {
                        "id":"320412000000",
                        "label":"武进区",
                        "full_label":"江苏省常州市武进区"
                    },
                    {
                        "id":"320413000000",
                        "label":"金坛区",
                        "full_label":"江苏省常州市金坛区"
                    },
                    {
                        "id":"320481000000",
                        "label":"溧阳市",
                        "full_label":"江苏省常州市溧阳市"
                    }
                ],
                "full_label":"江苏省常州市"
            },
            {
                "id":"320500000000",
                "label":"苏州市",
                "children":[
                    {
                        "id":"320505000000",
                        "label":"虎丘区",
                        "full_label":"江苏省苏州市虎丘区"
                    },
                    {
                        "id":"320506000000",
                        "label":"吴中区",
                        "full_label":"江苏省苏州市吴中区"
                    },
                    {
                        "id":"320507000000",
                        "label":"相城区",
                        "full_label":"江苏省苏州市相城区"
                    },
                    {
                        "id":"320508000000",
                        "label":"姑苏区",
                        "full_label":"江苏省苏州市姑苏区"
                    },
                    {
                        "id":"320509000000",
                        "label":"吴江区",
                        "full_label":"江苏省苏州市吴江区"
                    },
                    {
                        "id":"320581000000",
                        "label":"常熟市",
                        "full_label":"江苏省苏州市常熟市"
                    },
                    {
                        "id":"320582000000",
                        "label":"张家港市",
                        "full_label":"江苏省苏州市张家港市"
                    },
                    {
                        "id":"320583000000",
                        "label":"昆山市",
                        "full_label":"江苏省苏州市昆山市"
                    },
                    {
                        "id":"320585000000",
                        "label":"太仓市",
                        "full_label":"江苏省苏州市太仓市"
                    }
                ],
                "full_label":"江苏省苏州市"
            },
            {
                "id":"320600000000",
                "label":"南通市",
                "children":[
                    {
                        "id":"320612000000",
                        "label":"通州区",
                        "full_label":"江苏省南通市通州区"
                    },
                    {
                        "id":"320613000000",
                        "label":"崇川区",
                        "full_label":"江苏省南通市崇川区"
                    },
                    {
                        "id":"320614000000",
                        "label":"海门区",
                        "full_label":"江苏省南通市海门区"
                    },
                    {
                        "id":"320623000000",
                        "label":"如东县",
                        "full_label":"江苏省南通市如东县"
                    },
                    {
                        "id":"320681000000",
                        "label":"启东市",
                        "full_label":"江苏省南通市启东市"
                    },
                    {
                        "id":"320682000000",
                        "label":"如皋市",
                        "full_label":"江苏省南通市如皋市"
                    },
                    {
                        "id":"320685000000",
                        "label":"海安市",
                        "full_label":"江苏省南通市海安市"
                    }
                ],
                "full_label":"江苏省南通市"
            },
            {
                "id":"320700000000",
                "label":"连云港市",
                "children":[
                    {
                        "id":"320703000000",
                        "label":"连云区",
                        "full_label":"江苏省连云港市连云区"
                    },
                    {
                        "id":"320706000000",
                        "label":"海州区",
                        "full_label":"江苏省连云港市海州区"
                    },
                    {
                        "id":"320707000000",
                        "label":"赣榆区",
                        "full_label":"江苏省连云港市赣榆区"
                    },
                    {
                        "id":"320722000000",
                        "label":"东海县",
                        "full_label":"江苏省连云港市东海县"
                    },
                    {
                        "id":"320723000000",
                        "label":"灌云县",
                        "full_label":"江苏省连云港市灌云县"
                    },
                    {
                        "id":"320724000000",
                        "label":"灌南县",
                        "full_label":"江苏省连云港市灌南县"
                    }
                ],
                "full_label":"江苏省连云港市"
            },
            {
                "id":"320800000000",
                "label":"淮安市",
                "children":[
                    {
                        "id":"320803000000",
                        "label":"淮安区",
                        "full_label":"江苏省淮安市淮安区"
                    },
                    {
                        "id":"320804000000",
                        "label":"淮阴区",
                        "full_label":"江苏省淮安市淮阴区"
                    },
                    {
                        "id":"320812000000",
                        "label":"清江浦区",
                        "full_label":"江苏省淮安市清江浦区"
                    },
                    {
                        "id":"320813000000",
                        "label":"洪泽区",
                        "full_label":"江苏省淮安市洪泽区"
                    },
                    {
                        "id":"320826000000",
                        "label":"涟水县",
                        "full_label":"江苏省淮安市涟水县"
                    },
                    {
                        "id":"320830000000",
                        "label":"盱眙县",
                        "full_label":"江苏省淮安市盱眙县"
                    },
                    {
                        "id":"320831000000",
                        "label":"金湖县",
                        "full_label":"江苏省淮安市金湖县"
                    }
                ],
                "full_label":"江苏省淮安市"
            },
            {
                "id":"320900000000",
                "label":"盐城市",
                "children":[
                    {
                        "id":"320902000000",
                        "label":"亭湖区",
                        "full_label":"江苏省盐城市亭湖区"
                    },
                    {
                        "id":"320903000000",
                        "label":"盐都区",
                        "full_label":"江苏省盐城市盐都区"
                    },
                    {
                        "id":"320904000000",
                        "label":"大丰区",
                        "full_label":"江苏省盐城市大丰区"
                    },
                    {
                        "id":"320921000000",
                        "label":"响水县",
                        "full_label":"江苏省盐城市响水县"
                    },
                    {
                        "id":"320922000000",
                        "label":"滨海县",
                        "full_label":"江苏省盐城市滨海县"
                    },
                    {
                        "id":"320923000000",
                        "label":"阜宁县",
                        "full_label":"江苏省盐城市阜宁县"
                    },
                    {
                        "id":"320924000000",
                        "label":"射阳县",
                        "full_label":"江苏省盐城市射阳县"
                    },
                    {
                        "id":"320925000000",
                        "label":"建湖县",
                        "full_label":"江苏省盐城市建湖县"
                    },
                    {
                        "id":"320981000000",
                        "label":"东台市",
                        "full_label":"江苏省盐城市东台市"
                    }
                ],
                "full_label":"江苏省盐城市"
            },
            {
                "id":"321000000000",
                "label":"扬州市",
                "children":[
                    {
                        "id":"321002000000",
                        "label":"广陵区",
                        "full_label":"江苏省扬州市广陵区"
                    },
                    {
                        "id":"321003000000",
                        "label":"邗江区",
                        "full_label":"江苏省扬州市邗江区"
                    },
                    {
                        "id":"321012000000",
                        "label":"江都区",
                        "full_label":"江苏省扬州市江都区"
                    },
                    {
                        "id":"321023000000",
                        "label":"宝应县",
                        "full_label":"江苏省扬州市宝应县"
                    },
                    {
                        "id":"321081000000",
                        "label":"仪征市",
                        "full_label":"江苏省扬州市仪征市"
                    },
                    {
                        "id":"321084000000",
                        "label":"高邮市",
                        "full_label":"江苏省扬州市高邮市"
                    }
                ],
                "full_label":"江苏省扬州市"
            },
            {
                "id":"321100000000",
                "label":"镇江市",
                "children":[
                    {
                        "id":"321102000000",
                        "label":"京口区",
                        "full_label":"江苏省镇江市京口区"
                    },
                    {
                        "id":"321111000000",
                        "label":"润州区",
                        "full_label":"江苏省镇江市润州区"
                    },
                    {
                        "id":"321112000000",
                        "label":"丹徒区",
                        "full_label":"江苏省镇江市丹徒区"
                    },
                    {
                        "id":"321181000000",
                        "label":"丹阳市",
                        "full_label":"江苏省镇江市丹阳市"
                    },
                    {
                        "id":"321182000000",
                        "label":"扬中市",
                        "full_label":"江苏省镇江市扬中市"
                    },
                    {
                        "id":"321183000000",
                        "label":"句容市",
                        "full_label":"江苏省镇江市句容市"
                    }
                ],
                "full_label":"江苏省镇江市"
            },
            {
                "id":"321200000000",
                "label":"泰州市",
                "children":[
                    {
                        "id":"321202000000",
                        "label":"海陵区",
                        "full_label":"江苏省泰州市海陵区"
                    },
                    {
                        "id":"321203000000",
                        "label":"高港区",
                        "full_label":"江苏省泰州市高港区"
                    },
                    {
                        "id":"321204000000",
                        "label":"姜堰区",
                        "full_label":"江苏省泰州市姜堰区"
                    },
                    {
                        "id":"321281000000",
                        "label":"兴化市",
                        "full_label":"江苏省泰州市兴化市"
                    },
                    {
                        "id":"321282000000",
                        "label":"靖江市",
                        "full_label":"江苏省泰州市靖江市"
                    },
                    {
                        "id":"321283000000",
                        "label":"泰兴市",
                        "full_label":"江苏省泰州市泰兴市"
                    }
                ],
                "full_label":"江苏省泰州市"
            },
            {
                "id":"321300000000",
                "label":"宿迁市",
                "children":[
                    {
                        "id":"321302000000",
                        "label":"宿城区",
                        "full_label":"江苏省宿迁市宿城区"
                    },
                    {
                        "id":"321311000000",
                        "label":"宿豫区",
                        "full_label":"江苏省宿迁市宿豫区"
                    },
                    {
                        "id":"321322000000",
                        "label":"沭阳县",
                        "full_label":"江苏省宿迁市沭阳县"
                    },
                    {
                        "id":"321323000000",
                        "label":"泗阳县",
                        "full_label":"江苏省宿迁市泗阳县"
                    },
                    {
                        "id":"321324000000",
                        "label":"泗洪县",
                        "full_label":"江苏省宿迁市泗洪县"
                    }
                ],
                "full_label":"江苏省宿迁市"
            }
        ],
        "full_label":"江苏省"
    },
    {
        "id":"330000000000",
        "label":"浙江省",
        "children":[
            {
                "id":"330100000000",
                "label":"杭州市",
                "children":[
                    {
                        "id":"330102000000",
                        "label":"上城区",
                        "full_label":"浙江省杭州市上城区"
                    },
                    {
                        "id":"330105000000",
                        "label":"拱墅区",
                        "full_label":"浙江省杭州市拱墅区"
                    },
                    {
                        "id":"330106000000",
                        "label":"西湖区",
                        "full_label":"浙江省杭州市西湖区"
                    },
                    {
                        "id":"330108000000",
                        "label":"滨江区",
                        "full_label":"浙江省杭州市滨江区"
                    },
                    {
                        "id":"330109000000",
                        "label":"萧山区",
                        "full_label":"浙江省杭州市萧山区"
                    },
                    {
                        "id":"330110000000",
                        "label":"余杭区",
                        "full_label":"浙江省杭州市余杭区"
                    },
                    {
                        "id":"330111000000",
                        "label":"富阳区",
                        "full_label":"浙江省杭州市富阳区"
                    },
                    {
                        "id":"330112000000",
                        "label":"临安区",
                        "full_label":"浙江省杭州市临安区"
                    },
                    {
                        "id":"330113000000",
                        "label":"临平区",
                        "full_label":"浙江省杭州市临平区"
                    },
                    {
                        "id":"330114000000",
                        "label":"钱塘区",
                        "full_label":"浙江省杭州市钱塘区"
                    },
                    {
                        "id":"330122000000",
                        "label":"桐庐县",
                        "full_label":"浙江省杭州市桐庐县"
                    },
                    {
                        "id":"330127000000",
                        "label":"淳安县",
                        "full_label":"浙江省杭州市淳安县"
                    },
                    {
                        "id":"330182000000",
                        "label":"建德市",
                        "full_label":"浙江省杭州市建德市"
                    }
                ],
                "full_label":"浙江省杭州市"
            },
            {
                "id":"330200000000",
                "label":"宁波市",
                "children":[
                    {
                        "id":"330203000000",
                        "label":"海曙区",
                        "full_label":"浙江省宁波市海曙区"
                    },
                    {
                        "id":"330205000000",
                        "label":"江北区",
                        "full_label":"浙江省宁波市江北区"
                    },
                    {
                        "id":"330206000000",
                        "label":"北仑区",
                        "full_label":"浙江省宁波市北仑区"
                    },
                    {
                        "id":"330211000000",
                        "label":"镇海区",
                        "full_label":"浙江省宁波市镇海区"
                    },
                    {
                        "id":"330212000000",
                        "label":"鄞州区",
                        "full_label":"浙江省宁波市鄞州区"
                    },
                    {
                        "id":"330213000000",
                        "label":"奉化区",
                        "full_label":"浙江省宁波市奉化区"
                    },
                    {
                        "id":"330225000000",
                        "label":"象山县",
                        "full_label":"浙江省宁波市象山县"
                    },
                    {
                        "id":"330226000000",
                        "label":"宁海县",
                        "full_label":"浙江省宁波市宁海县"
                    },
                    {
                        "id":"330281000000",
                        "label":"余姚市",
                        "full_label":"浙江省宁波市余姚市"
                    },
                    {
                        "id":"330282000000",
                        "label":"慈溪市",
                        "full_label":"浙江省宁波市慈溪市"
                    }
                ],
                "full_label":"浙江省宁波市"
            },
            {
                "id":"330300000000",
                "label":"温州市",
                "children":[
                    {
                        "id":"330302000000",
                        "label":"鹿城区",
                        "full_label":"浙江省温州市鹿城区"
                    },
                    {
                        "id":"330303000000",
                        "label":"龙湾区",
                        "full_label":"浙江省温州市龙湾区"
                    },
                    {
                        "id":"330304000000",
                        "label":"瓯海区",
                        "full_label":"浙江省温州市瓯海区"
                    },
                    {
                        "id":"330305000000",
                        "label":"洞头区",
                        "full_label":"浙江省温州市洞头区"
                    },
                    {
                        "id":"330324000000",
                        "label":"永嘉县",
                        "full_label":"浙江省温州市永嘉县"
                    },
                    {
                        "id":"330326000000",
                        "label":"平阳县",
                        "full_label":"浙江省温州市平阳县"
                    },
                    {
                        "id":"330327000000",
                        "label":"苍南县",
                        "full_label":"浙江省温州市苍南县"
                    },
                    {
                        "id":"330328000000",
                        "label":"文成县",
                        "full_label":"浙江省温州市文成县"
                    },
                    {
                        "id":"330329000000",
                        "label":"泰顺县",
                        "full_label":"浙江省温州市泰顺县"
                    },
                    {
                        "id":"330381000000",
                        "label":"瑞安市",
                        "full_label":"浙江省温州市瑞安市"
                    },
                    {
                        "id":"330382000000",
                        "label":"乐清市",
                        "full_label":"浙江省温州市乐清市"
                    },
                    {
                        "id":"330383000000",
                        "label":"龙港市",
                        "full_label":"浙江省温州市龙港市"
                    }
                ],
                "full_label":"浙江省温州市"
            },
            {
                "id":"330400000000",
                "label":"嘉兴市",
                "children":[
                    {
                        "id":"330402000000",
                        "label":"南湖区",
                        "full_label":"浙江省嘉兴市南湖区"
                    },
                    {
                        "id":"330411000000",
                        "label":"秀洲区",
                        "full_label":"浙江省嘉兴市秀洲区"
                    },
                    {
                        "id":"330421000000",
                        "label":"嘉善县",
                        "full_label":"浙江省嘉兴市嘉善县"
                    },
                    {
                        "id":"330424000000",
                        "label":"海盐县",
                        "full_label":"浙江省嘉兴市海盐县"
                    },
                    {
                        "id":"330481000000",
                        "label":"海宁市",
                        "full_label":"浙江省嘉兴市海宁市"
                    },
                    {
                        "id":"330482000000",
                        "label":"平湖市",
                        "full_label":"浙江省嘉兴市平湖市"
                    },
                    {
                        "id":"330483000000",
                        "label":"桐乡市",
                        "full_label":"浙江省嘉兴市桐乡市"
                    }
                ],
                "full_label":"浙江省嘉兴市"
            },
            {
                "id":"330500000000",
                "label":"湖州市",
                "children":[
                    {
                        "id":"330502000000",
                        "label":"吴兴区",
                        "full_label":"浙江省湖州市吴兴区"
                    },
                    {
                        "id":"330503000000",
                        "label":"南浔区",
                        "full_label":"浙江省湖州市南浔区"
                    },
                    {
                        "id":"330521000000",
                        "label":"德清县",
                        "full_label":"浙江省湖州市德清县"
                    },
                    {
                        "id":"330522000000",
                        "label":"长兴县",
                        "full_label":"浙江省湖州市长兴县"
                    },
                    {
                        "id":"330523000000",
                        "label":"安吉县",
                        "full_label":"浙江省湖州市安吉县"
                    }
                ],
                "full_label":"浙江省湖州市"
            },
            {
                "id":"330600000000",
                "label":"绍兴市",
                "children":[
                    {
                        "id":"330602000000",
                        "label":"越城区",
                        "full_label":"浙江省绍兴市越城区"
                    },
                    {
                        "id":"330603000000",
                        "label":"柯桥区",
                        "full_label":"浙江省绍兴市柯桥区"
                    },
                    {
                        "id":"330604000000",
                        "label":"上虞区",
                        "full_label":"浙江省绍兴市上虞区"
                    },
                    {
                        "id":"330624000000",
                        "label":"新昌县",
                        "full_label":"浙江省绍兴市新昌县"
                    },
                    {
                        "id":"330681000000",
                        "label":"诸暨市",
                        "full_label":"浙江省绍兴市诸暨市"
                    },
                    {
                        "id":"330683000000",
                        "label":"嵊州市",
                        "full_label":"浙江省绍兴市嵊州市"
                    }
                ],
                "full_label":"浙江省绍兴市"
            },
            {
                "id":"330700000000",
                "label":"金华市",
                "children":[
                    {
                        "id":"330702000000",
                        "label":"婺城区",
                        "full_label":"浙江省金华市婺城区"
                    },
                    {
                        "id":"330703000000",
                        "label":"金东区",
                        "full_label":"浙江省金华市金东区"
                    },
                    {
                        "id":"330723000000",
                        "label":"武义县",
                        "full_label":"浙江省金华市武义县"
                    },
                    {
                        "id":"330726000000",
                        "label":"浦江县",
                        "full_label":"浙江省金华市浦江县"
                    },
                    {
                        "id":"330727000000",
                        "label":"磐安县",
                        "full_label":"浙江省金华市磐安县"
                    },
                    {
                        "id":"330781000000",
                        "label":"兰溪市",
                        "full_label":"浙江省金华市兰溪市"
                    },
                    {
                        "id":"330782000000",
                        "label":"义乌市",
                        "full_label":"浙江省金华市义乌市"
                    },
                    {
                        "id":"330783000000",
                        "label":"东阳市",
                        "full_label":"浙江省金华市东阳市"
                    },
                    {
                        "id":"330784000000",
                        "label":"永康市",
                        "full_label":"浙江省金华市永康市"
                    }
                ],
                "full_label":"浙江省金华市"
            },
            {
                "id":"330800000000",
                "label":"衢州市",
                "children":[
                    {
                        "id":"330802000000",
                        "label":"柯城区",
                        "full_label":"浙江省衢州市柯城区"
                    },
                    {
                        "id":"330803000000",
                        "label":"衢江区",
                        "full_label":"浙江省衢州市衢江区"
                    },
                    {
                        "id":"330822000000",
                        "label":"常山县",
                        "full_label":"浙江省衢州市常山县"
                    },
                    {
                        "id":"330824000000",
                        "label":"开化县",
                        "full_label":"浙江省衢州市开化县"
                    },
                    {
                        "id":"330825000000",
                        "label":"龙游县",
                        "full_label":"浙江省衢州市龙游县"
                    },
                    {
                        "id":"330881000000",
                        "label":"江山市",
                        "full_label":"浙江省衢州市江山市"
                    }
                ],
                "full_label":"浙江省衢州市"
            },
            {
                "id":"330900000000",
                "label":"舟山市",
                "children":[
                    {
                        "id":"330902000000",
                        "label":"定海区",
                        "full_label":"浙江省舟山市定海区"
                    },
                    {
                        "id":"330903000000",
                        "label":"普陀区",
                        "full_label":"浙江省舟山市普陀区"
                    },
                    {
                        "id":"330921000000",
                        "label":"岱山县",
                        "full_label":"浙江省舟山市岱山县"
                    },
                    {
                        "id":"330922000000",
                        "label":"嵊泗县",
                        "full_label":"浙江省舟山市嵊泗县"
                    }
                ],
                "full_label":"浙江省舟山市"
            },
            {
                "id":"331000000000",
                "label":"台州市",
                "children":[
                    {
                        "id":"331002000000",
                        "label":"椒江区",
                        "full_label":"浙江省台州市椒江区"
                    },
                    {
                        "id":"331003000000",
                        "label":"黄岩区",
                        "full_label":"浙江省台州市黄岩区"
                    },
                    {
                        "id":"331004000000",
                        "label":"路桥区",
                        "full_label":"浙江省台州市路桥区"
                    },
                    {
                        "id":"331022000000",
                        "label":"三门县",
                        "full_label":"浙江省台州市三门县"
                    },
                    {
                        "id":"331023000000",
                        "label":"天台县",
                        "full_label":"浙江省台州市天台县"
                    },
                    {
                        "id":"331024000000",
                        "label":"仙居县",
                        "full_label":"浙江省台州市仙居县"
                    },
                    {
                        "id":"331081000000",
                        "label":"温岭市",
                        "full_label":"浙江省台州市温岭市"
                    },
                    {
                        "id":"331082000000",
                        "label":"临海市",
                        "full_label":"浙江省台州市临海市"
                    },
                    {
                        "id":"331083000000",
                        "label":"玉环市",
                        "full_label":"浙江省台州市玉环市"
                    }
                ],
                "full_label":"浙江省台州市"
            },
            {
                "id":"331100000000",
                "label":"丽水市",
                "children":[
                    {
                        "id":"331102000000",
                        "label":"莲都区",
                        "full_label":"浙江省丽水市莲都区"
                    },
                    {
                        "id":"331121000000",
                        "label":"青田县",
                        "full_label":"浙江省丽水市青田县"
                    },
                    {
                        "id":"331122000000",
                        "label":"缙云县",
                        "full_label":"浙江省丽水市缙云县"
                    },
                    {
                        "id":"331123000000",
                        "label":"遂昌县",
                        "full_label":"浙江省丽水市遂昌县"
                    },
                    {
                        "id":"331124000000",
                        "label":"松阳县",
                        "full_label":"浙江省丽水市松阳县"
                    },
                    {
                        "id":"331125000000",
                        "label":"云和县",
                        "full_label":"浙江省丽水市云和县"
                    },
                    {
                        "id":"331126000000",
                        "label":"庆元县",
                        "full_label":"浙江省丽水市庆元县"
                    },
                    {
                        "id":"331127000000",
                        "label":"景宁畲族自治县",
                        "full_label":"浙江省丽水市景宁畲族自治县"
                    },
                    {
                        "id":"331181000000",
                        "label":"龙泉市",
                        "full_label":"浙江省丽水市龙泉市"
                    }
                ],
                "full_label":"浙江省丽水市"
            }
        ],
        "full_label":"浙江省"
    },
    {
        "id":"340000000000",
        "label":"安徽省",
        "children":[
            {
                "id":"340100000000",
                "label":"合肥市",
                "children":[
                    {
                        "id":"340102000000",
                        "label":"瑶海区",
                        "full_label":"安徽省合肥市瑶海区"
                    },
                    {
                        "id":"340103000000",
                        "label":"庐阳区",
                        "full_label":"安徽省合肥市庐阳区"
                    },
                    {
                        "id":"340104000000",
                        "label":"蜀山区",
                        "full_label":"安徽省合肥市蜀山区"
                    },
                    {
                        "id":"340111000000",
                        "label":"包河区",
                        "full_label":"安徽省合肥市包河区"
                    },
                    {
                        "id":"340121000000",
                        "label":"长丰县",
                        "full_label":"安徽省合肥市长丰县"
                    },
                    {
                        "id":"340122000000",
                        "label":"肥东县",
                        "full_label":"安徽省合肥市肥东县"
                    },
                    {
                        "id":"340123000000",
                        "label":"肥西县",
                        "full_label":"安徽省合肥市肥西县"
                    },
                    {
                        "id":"340124000000",
                        "label":"庐江县",
                        "full_label":"安徽省合肥市庐江县"
                    },
                    {
                        "id":"340181000000",
                        "label":"巢湖市",
                        "full_label":"安徽省合肥市巢湖市"
                    }
                ],
                "full_label":"安徽省合肥市"
            },
            {
                "id":"340200000000",
                "label":"芜湖市",
                "children":[
                    {
                        "id":"340202000000",
                        "label":"镜湖区",
                        "full_label":"安徽省芜湖市镜湖区"
                    },
                    {
                        "id":"340207000000",
                        "label":"鸠江区",
                        "full_label":"安徽省芜湖市鸠江区"
                    },
                    {
                        "id":"340209000000",
                        "label":"弋江区",
                        "full_label":"安徽省芜湖市弋江区"
                    },
                    {
                        "id":"340210000000",
                        "label":"湾沚区",
                        "full_label":"安徽省芜湖市湾沚区"
                    },
                    {
                        "id":"340212000000",
                        "label":"繁昌区",
                        "full_label":"安徽省芜湖市繁昌区"
                    },
                    {
                        "id":"340223000000",
                        "label":"南陵县",
                        "full_label":"安徽省芜湖市南陵县"
                    },
                    {
                        "id":"340281000000",
                        "label":"无为市",
                        "full_label":"安徽省芜湖市无为市"
                    }
                ],
                "full_label":"安徽省芜湖市"
            },
            {
                "id":"340300000000",
                "label":"蚌埠市",
                "children":[
                    {
                        "id":"340302000000",
                        "label":"龙子湖区",
                        "full_label":"安徽省蚌埠市龙子湖区"
                    },
                    {
                        "id":"340303000000",
                        "label":"蚌山区",
                        "full_label":"安徽省蚌埠市蚌山区"
                    },
                    {
                        "id":"340304000000",
                        "label":"禹会区",
                        "full_label":"安徽省蚌埠市禹会区"
                    },
                    {
                        "id":"340311000000",
                        "label":"淮上区",
                        "full_label":"安徽省蚌埠市淮上区"
                    },
                    {
                        "id":"340321000000",
                        "label":"怀远县",
                        "full_label":"安徽省蚌埠市怀远县"
                    },
                    {
                        "id":"340322000000",
                        "label":"五河县",
                        "full_label":"安徽省蚌埠市五河县"
                    },
                    {
                        "id":"340323000000",
                        "label":"固镇县",
                        "full_label":"安徽省蚌埠市固镇县"
                    }
                ],
                "full_label":"安徽省蚌埠市"
            },
            {
                "id":"340400000000",
                "label":"淮南市",
                "children":[
                    {
                        "id":"340402000000",
                        "label":"大通区",
                        "full_label":"安徽省淮南市大通区"
                    },
                    {
                        "id":"340403000000",
                        "label":"田家庵区",
                        "full_label":"安徽省淮南市田家庵区"
                    },
                    {
                        "id":"340404000000",
                        "label":"谢家集区",
                        "full_label":"安徽省淮南市谢家集区"
                    },
                    {
                        "id":"340405000000",
                        "label":"八公山区",
                        "full_label":"安徽省淮南市八公山区"
                    },
                    {
                        "id":"340406000000",
                        "label":"潘集区",
                        "full_label":"安徽省淮南市潘集区"
                    },
                    {
                        "id":"340421000000",
                        "label":"凤台县",
                        "full_label":"安徽省淮南市凤台县"
                    },
                    {
                        "id":"340422000000",
                        "label":"寿县",
                        "full_label":"安徽省淮南市寿县"
                    }
                ],
                "full_label":"安徽省淮南市"
            },
            {
                "id":"340500000000",
                "label":"马鞍山市",
                "children":[
                    {
                        "id":"340503000000",
                        "label":"花山区",
                        "full_label":"安徽省马鞍山市花山区"
                    },
                    {
                        "id":"340504000000",
                        "label":"雨山区",
                        "full_label":"安徽省马鞍山市雨山区"
                    },
                    {
                        "id":"340506000000",
                        "label":"博望区",
                        "full_label":"安徽省马鞍山市博望区"
                    },
                    {
                        "id":"340521000000",
                        "label":"当涂县",
                        "full_label":"安徽省马鞍山市当涂县"
                    },
                    {
                        "id":"340522000000",
                        "label":"含山县",
                        "full_label":"安徽省马鞍山市含山县"
                    },
                    {
                        "id":"340523000000",
                        "label":"和县",
                        "full_label":"安徽省马鞍山市和县"
                    }
                ],
                "full_label":"安徽省马鞍山市"
            },
            {
                "id":"340600000000",
                "label":"淮北市",
                "children":[
                    {
                        "id":"340602000000",
                        "label":"杜集区",
                        "full_label":"安徽省淮北市杜集区"
                    },
                    {
                        "id":"340603000000",
                        "label":"相山区",
                        "full_label":"安徽省淮北市相山区"
                    },
                    {
                        "id":"340604000000",
                        "label":"烈山区",
                        "full_label":"安徽省淮北市烈山区"
                    },
                    {
                        "id":"340621000000",
                        "label":"濉溪县",
                        "full_label":"安徽省淮北市濉溪县"
                    }
                ],
                "full_label":"安徽省淮北市"
            },
            {
                "id":"340700000000",
                "label":"铜陵市",
                "children":[
                    {
                        "id":"340705000000",
                        "label":"铜官区",
                        "full_label":"安徽省铜陵市铜官区"
                    },
                    {
                        "id":"340706000000",
                        "label":"义安区",
                        "full_label":"安徽省铜陵市义安区"
                    },
                    {
                        "id":"340711000000",
                        "label":"郊区",
                        "full_label":"安徽省铜陵市郊区"
                    },
                    {
                        "id":"340722000000",
                        "label":"枞阳县",
                        "full_label":"安徽省铜陵市枞阳县"
                    }
                ],
                "full_label":"安徽省铜陵市"
            },
            {
                "id":"340800000000",
                "label":"安庆市",
                "children":[
                    {
                        "id":"340802000000",
                        "label":"迎江区",
                        "full_label":"安徽省安庆市迎江区"
                    },
                    {
                        "id":"340803000000",
                        "label":"大观区",
                        "full_label":"安徽省安庆市大观区"
                    },
                    {
                        "id":"340811000000",
                        "label":"宜秀区",
                        "full_label":"安徽省安庆市宜秀区"
                    },
                    {
                        "id":"340822000000",
                        "label":"怀宁县",
                        "full_label":"安徽省安庆市怀宁县"
                    },
                    {
                        "id":"340825000000",
                        "label":"太湖县",
                        "full_label":"安徽省安庆市太湖县"
                    },
                    {
                        "id":"340826000000",
                        "label":"宿松县",
                        "full_label":"安徽省安庆市宿松县"
                    },
                    {
                        "id":"340827000000",
                        "label":"望江县",
                        "full_label":"安徽省安庆市望江县"
                    },
                    {
                        "id":"340828000000",
                        "label":"岳西县",
                        "full_label":"安徽省安庆市岳西县"
                    },
                    {
                        "id":"340881000000",
                        "label":"桐城市",
                        "full_label":"安徽省安庆市桐城市"
                    },
                    {
                        "id":"340882000000",
                        "label":"潜山市",
                        "full_label":"安徽省安庆市潜山市"
                    }
                ],
                "full_label":"安徽省安庆市"
            },
            {
                "id":"341000000000",
                "label":"黄山市",
                "children":[
                    {
                        "id":"341002000000",
                        "label":"屯溪区",
                        "full_label":"安徽省黄山市屯溪区"
                    },
                    {
                        "id":"341003000000",
                        "label":"黄山区",
                        "full_label":"安徽省黄山市黄山区"
                    },
                    {
                        "id":"341004000000",
                        "label":"徽州区",
                        "full_label":"安徽省黄山市徽州区"
                    },
                    {
                        "id":"341021000000",
                        "label":"歙县",
                        "full_label":"安徽省黄山市歙县"
                    },
                    {
                        "id":"341022000000",
                        "label":"休宁县",
                        "full_label":"安徽省黄山市休宁县"
                    },
                    {
                        "id":"341023000000",
                        "label":"黟县",
                        "full_label":"安徽省黄山市黟县"
                    },
                    {
                        "id":"341024000000",
                        "label":"祁门县",
                        "full_label":"安徽省黄山市祁门县"
                    }
                ],
                "full_label":"安徽省黄山市"
            },
            {
                "id":"341100000000",
                "label":"滁州市",
                "children":[
                    {
                        "id":"341102000000",
                        "label":"琅琊区",
                        "full_label":"安徽省滁州市琅琊区"
                    },
                    {
                        "id":"341103000000",
                        "label":"南谯区",
                        "full_label":"安徽省滁州市南谯区"
                    },
                    {
                        "id":"341122000000",
                        "label":"来安县",
                        "full_label":"安徽省滁州市来安县"
                    },
                    {
                        "id":"341124000000",
                        "label":"全椒县",
                        "full_label":"安徽省滁州市全椒县"
                    },
                    {
                        "id":"341125000000",
                        "label":"定远县",
                        "full_label":"安徽省滁州市定远县"
                    },
                    {
                        "id":"341126000000",
                        "label":"凤阳县",
                        "full_label":"安徽省滁州市凤阳县"
                    },
                    {
                        "id":"341181000000",
                        "label":"天长市",
                        "full_label":"安徽省滁州市天长市"
                    },
                    {
                        "id":"341182000000",
                        "label":"明光市",
                        "full_label":"安徽省滁州市明光市"
                    }
                ],
                "full_label":"安徽省滁州市"
            },
            {
                "id":"341200000000",
                "label":"阜阳市",
                "children":[
                    {
                        "id":"341202000000",
                        "label":"颍州区",
                        "full_label":"安徽省阜阳市颍州区"
                    },
                    {
                        "id":"341203000000",
                        "label":"颍东区",
                        "full_label":"安徽省阜阳市颍东区"
                    },
                    {
                        "id":"341204000000",
                        "label":"颍泉区",
                        "full_label":"安徽省阜阳市颍泉区"
                    },
                    {
                        "id":"341221000000",
                        "label":"临泉县",
                        "full_label":"安徽省阜阳市临泉县"
                    },
                    {
                        "id":"341222000000",
                        "label":"太和县",
                        "full_label":"安徽省阜阳市太和县"
                    },
                    {
                        "id":"341225000000",
                        "label":"阜南县",
                        "full_label":"安徽省阜阳市阜南县"
                    },
                    {
                        "id":"341226000000",
                        "label":"颍上县",
                        "full_label":"安徽省阜阳市颍上县"
                    },
                    {
                        "id":"341282000000",
                        "label":"界首市",
                        "full_label":"安徽省阜阳市界首市"
                    }
                ],
                "full_label":"安徽省阜阳市"
            },
            {
                "id":"341300000000",
                "label":"宿州市",
                "children":[
                    {
                        "id":"341302000000",
                        "label":"埇桥区",
                        "full_label":"安徽省宿州市埇桥区"
                    },
                    {
                        "id":"341321000000",
                        "label":"砀山县",
                        "full_label":"安徽省宿州市砀山县"
                    },
                    {
                        "id":"341322000000",
                        "label":"萧县",
                        "full_label":"安徽省宿州市萧县"
                    },
                    {
                        "id":"341323000000",
                        "label":"灵璧县",
                        "full_label":"安徽省宿州市灵璧县"
                    },
                    {
                        "id":"341324000000",
                        "label":"泗县",
                        "full_label":"安徽省宿州市泗县"
                    }
                ],
                "full_label":"安徽省宿州市"
            },
            {
                "id":"341500000000",
                "label":"六安市",
                "children":[
                    {
                        "id":"341502000000",
                        "label":"金安区",
                        "full_label":"安徽省六安市金安区"
                    },
                    {
                        "id":"341503000000",
                        "label":"裕安区",
                        "full_label":"安徽省六安市裕安区"
                    },
                    {
                        "id":"341504000000",
                        "label":"叶集区",
                        "full_label":"安徽省六安市叶集区"
                    },
                    {
                        "id":"341522000000",
                        "label":"霍邱县",
                        "full_label":"安徽省六安市霍邱县"
                    },
                    {
                        "id":"341523000000",
                        "label":"舒城县",
                        "full_label":"安徽省六安市舒城县"
                    },
                    {
                        "id":"341524000000",
                        "label":"金寨县",
                        "full_label":"安徽省六安市金寨县"
                    },
                    {
                        "id":"341525000000",
                        "label":"霍山县",
                        "full_label":"安徽省六安市霍山县"
                    }
                ],
                "full_label":"安徽省六安市"
            },
            {
                "id":"341600000000",
                "label":"亳州市",
                "children":[
                    {
                        "id":"341602000000",
                        "label":"谯城区",
                        "full_label":"安徽省亳州市谯城区"
                    },
                    {
                        "id":"341621000000",
                        "label":"涡阳县",
                        "full_label":"安徽省亳州市涡阳县"
                    },
                    {
                        "id":"341622000000",
                        "label":"蒙城县",
                        "full_label":"安徽省亳州市蒙城县"
                    },
                    {
                        "id":"341623000000",
                        "label":"利辛县",
                        "full_label":"安徽省亳州市利辛县"
                    }
                ],
                "full_label":"安徽省亳州市"
            },
            {
                "id":"341700000000",
                "label":"池州市",
                "children":[
                    {
                        "id":"341702000000",
                        "label":"贵池区",
                        "full_label":"安徽省池州市贵池区"
                    },
                    {
                        "id":"341721000000",
                        "label":"东至县",
                        "full_label":"安徽省池州市东至县"
                    },
                    {
                        "id":"341722000000",
                        "label":"石台县",
                        "full_label":"安徽省池州市石台县"
                    },
                    {
                        "id":"341723000000",
                        "label":"青阳县",
                        "full_label":"安徽省池州市青阳县"
                    }
                ],
                "full_label":"安徽省池州市"
            },
            {
                "id":"341800000000",
                "label":"宣城市",
                "children":[
                    {
                        "id":"341802000000",
                        "label":"宣州区",
                        "full_label":"安徽省宣城市宣州区"
                    },
                    {
                        "id":"341821000000",
                        "label":"郎溪县",
                        "full_label":"安徽省宣城市郎溪县"
                    },
                    {
                        "id":"341823000000",
                        "label":"泾县",
                        "full_label":"安徽省宣城市泾县"
                    },
                    {
                        "id":"341824000000",
                        "label":"绩溪县",
                        "full_label":"安徽省宣城市绩溪县"
                    },
                    {
                        "id":"341825000000",
                        "label":"旌德县",
                        "full_label":"安徽省宣城市旌德县"
                    },
                    {
                        "id":"341881000000",
                        "label":"宁国市",
                        "full_label":"安徽省宣城市宁国市"
                    },
                    {
                        "id":"341882000000",
                        "label":"广德市",
                        "full_label":"安徽省宣城市广德市"
                    }
                ],
                "full_label":"安徽省宣城市"
            }
        ],
        "full_label":"安徽省"
    },
    {
        "id":"350000000000",
        "label":"福建省",
        "children":[
            {
                "id":"350100000000",
                "label":"福州市",
                "children":[
                    {
                        "id":"350102000000",
                        "label":"鼓楼区",
                        "full_label":"福建省福州市鼓楼区"
                    },
                    {
                        "id":"350103000000",
                        "label":"台江区",
                        "full_label":"福建省福州市台江区"
                    },
                    {
                        "id":"350104000000",
                        "label":"仓山区",
                        "full_label":"福建省福州市仓山区"
                    },
                    {
                        "id":"350105000000",
                        "label":"马尾区",
                        "full_label":"福建省福州市马尾区"
                    },
                    {
                        "id":"350111000000",
                        "label":"晋安区",
                        "full_label":"福建省福州市晋安区"
                    },
                    {
                        "id":"350112000000",
                        "label":"长乐区",
                        "full_label":"福建省福州市长乐区"
                    },
                    {
                        "id":"350121000000",
                        "label":"闽侯县",
                        "full_label":"福建省福州市闽侯县"
                    },
                    {
                        "id":"350122000000",
                        "label":"连江县",
                        "full_label":"福建省福州市连江县"
                    },
                    {
                        "id":"350123000000",
                        "label":"罗源县",
                        "full_label":"福建省福州市罗源县"
                    },
                    {
                        "id":"350124000000",
                        "label":"闽清县",
                        "full_label":"福建省福州市闽清县"
                    },
                    {
                        "id":"350125000000",
                        "label":"永泰县",
                        "full_label":"福建省福州市永泰县"
                    },
                    {
                        "id":"350128000000",
                        "label":"平潭县",
                        "full_label":"福建省福州市平潭县"
                    },
                    {
                        "id":"350181000000",
                        "label":"福清市",
                        "full_label":"福建省福州市福清市"
                    }
                ],
                "full_label":"福建省福州市"
            },
            {
                "id":"350200000000",
                "label":"厦门市",
                "children":[
                    {
                        "id":"350203000000",
                        "label":"思明区",
                        "full_label":"福建省厦门市思明区"
                    },
                    {
                        "id":"350205000000",
                        "label":"海沧区",
                        "full_label":"福建省厦门市海沧区"
                    },
                    {
                        "id":"350206000000",
                        "label":"湖里区",
                        "full_label":"福建省厦门市湖里区"
                    },
                    {
                        "id":"350211000000",
                        "label":"集美区",
                        "full_label":"福建省厦门市集美区"
                    },
                    {
                        "id":"350212000000",
                        "label":"同安区",
                        "full_label":"福建省厦门市同安区"
                    },
                    {
                        "id":"350213000000",
                        "label":"翔安区",
                        "full_label":"福建省厦门市翔安区"
                    }
                ],
                "full_label":"福建省厦门市"
            },
            {
                "id":"350300000000",
                "label":"莆田市",
                "children":[
                    {
                        "id":"350302000000",
                        "label":"城厢区",
                        "full_label":"福建省莆田市城厢区"
                    },
                    {
                        "id":"350303000000",
                        "label":"涵江区",
                        "full_label":"福建省莆田市涵江区"
                    },
                    {
                        "id":"350304000000",
                        "label":"荔城区",
                        "full_label":"福建省莆田市荔城区"
                    },
                    {
                        "id":"350305000000",
                        "label":"秀屿区",
                        "full_label":"福建省莆田市秀屿区"
                    },
                    {
                        "id":"350322000000",
                        "label":"仙游县",
                        "full_label":"福建省莆田市仙游县"
                    }
                ],
                "full_label":"福建省莆田市"
            },
            {
                "id":"350400000000",
                "label":"三明市",
                "children":[
                    {
                        "id":"350404000000",
                        "label":"三元区",
                        "full_label":"福建省三明市三元区"
                    },
                    {
                        "id":"350405000000",
                        "label":"沙县区",
                        "full_label":"福建省三明市沙县区"
                    },
                    {
                        "id":"350421000000",
                        "label":"明溪县",
                        "full_label":"福建省三明市明溪县"
                    },
                    {
                        "id":"350423000000",
                        "label":"清流县",
                        "full_label":"福建省三明市清流县"
                    },
                    {
                        "id":"350424000000",
                        "label":"宁化县",
                        "full_label":"福建省三明市宁化县"
                    },
                    {
                        "id":"350425000000",
                        "label":"大田县",
                        "full_label":"福建省三明市大田县"
                    },
                    {
                        "id":"350426000000",
                        "label":"尤溪县",
                        "full_label":"福建省三明市尤溪县"
                    },
                    {
                        "id":"350428000000",
                        "label":"将乐县",
                        "full_label":"福建省三明市将乐县"
                    },
                    {
                        "id":"350429000000",
                        "label":"泰宁县",
                        "full_label":"福建省三明市泰宁县"
                    },
                    {
                        "id":"350430000000",
                        "label":"建宁县",
                        "full_label":"福建省三明市建宁县"
                    },
                    {
                        "id":"350481000000",
                        "label":"永安市",
                        "full_label":"福建省三明市永安市"
                    }
                ],
                "full_label":"福建省三明市"
            },
            {
                "id":"350500000000",
                "label":"泉州市",
                "children":[
                    {
                        "id":"350502000000",
                        "label":"鲤城区",
                        "full_label":"福建省泉州市鲤城区"
                    },
                    {
                        "id":"350503000000",
                        "label":"丰泽区",
                        "full_label":"福建省泉州市丰泽区"
                    },
                    {
                        "id":"350504000000",
                        "label":"洛江区",
                        "full_label":"福建省泉州市洛江区"
                    },
                    {
                        "id":"350505000000",
                        "label":"泉港区",
                        "full_label":"福建省泉州市泉港区"
                    },
                    {
                        "id":"350521000000",
                        "label":"惠安县",
                        "full_label":"福建省泉州市惠安县"
                    },
                    {
                        "id":"350524000000",
                        "label":"安溪县",
                        "full_label":"福建省泉州市安溪县"
                    },
                    {
                        "id":"350525000000",
                        "label":"永春县",
                        "full_label":"福建省泉州市永春县"
                    },
                    {
                        "id":"350526000000",
                        "label":"德化县",
                        "full_label":"福建省泉州市德化县"
                    },
                    {
                        "id":"350527000000",
                        "label":"金门县",
                        "full_label":"福建省泉州市金门县"
                    },
                    {
                        "id":"350581000000",
                        "label":"石狮市",
                        "full_label":"福建省泉州市石狮市"
                    },
                    {
                        "id":"350582000000",
                        "label":"晋江市",
                        "full_label":"福建省泉州市晋江市"
                    },
                    {
                        "id":"350583000000",
                        "label":"南安市",
                        "full_label":"福建省泉州市南安市"
                    }
                ],
                "full_label":"福建省泉州市"
            },
            {
                "id":"350600000000",
                "label":"漳州市",
                "children":[
                    {
                        "id":"350602000000",
                        "label":"芗城区",
                        "full_label":"福建省漳州市芗城区"
                    },
                    {
                        "id":"350603000000",
                        "label":"龙文区",
                        "full_label":"福建省漳州市龙文区"
                    },
                    {
                        "id":"350604000000",
                        "label":"龙海区",
                        "full_label":"福建省漳州市龙海区"
                    },
                    {
                        "id":"350605000000",
                        "label":"长泰区",
                        "full_label":"福建省漳州市长泰区"
                    },
                    {
                        "id":"350622000000",
                        "label":"云霄县",
                        "full_label":"福建省漳州市云霄县"
                    },
                    {
                        "id":"350623000000",
                        "label":"漳浦县",
                        "full_label":"福建省漳州市漳浦县"
                    },
                    {
                        "id":"350624000000",
                        "label":"诏安县",
                        "full_label":"福建省漳州市诏安县"
                    },
                    {
                        "id":"350626000000",
                        "label":"东山县",
                        "full_label":"福建省漳州市东山县"
                    },
                    {
                        "id":"350627000000",
                        "label":"南靖县",
                        "full_label":"福建省漳州市南靖县"
                    },
                    {
                        "id":"350628000000",
                        "label":"平和县",
                        "full_label":"福建省漳州市平和县"
                    },
                    {
                        "id":"350629000000",
                        "label":"华安县",
                        "full_label":"福建省漳州市华安县"
                    }
                ],
                "full_label":"福建省漳州市"
            },
            {
                "id":"350700000000",
                "label":"南平市",
                "children":[
                    {
                        "id":"350702000000",
                        "label":"延平区",
                        "full_label":"福建省南平市延平区"
                    },
                    {
                        "id":"350703000000",
                        "label":"建阳区",
                        "full_label":"福建省南平市建阳区"
                    },
                    {
                        "id":"350721000000",
                        "label":"顺昌县",
                        "full_label":"福建省南平市顺昌县"
                    },
                    {
                        "id":"350722000000",
                        "label":"浦城县",
                        "full_label":"福建省南平市浦城县"
                    },
                    {
                        "id":"350723000000",
                        "label":"光泽县",
                        "full_label":"福建省南平市光泽县"
                    },
                    {
                        "id":"350724000000",
                        "label":"松溪县",
                        "full_label":"福建省南平市松溪县"
                    },
                    {
                        "id":"350725000000",
                        "label":"政和县",
                        "full_label":"福建省南平市政和县"
                    },
                    {
                        "id":"350781000000",
                        "label":"邵武市",
                        "full_label":"福建省南平市邵武市"
                    },
                    {
                        "id":"350782000000",
                        "label":"武夷山市",
                        "full_label":"福建省南平市武夷山市"
                    },
                    {
                        "id":"350783000000",
                        "label":"建瓯市",
                        "full_label":"福建省南平市建瓯市"
                    }
                ],
                "full_label":"福建省南平市"
            },
            {
                "id":"350800000000",
                "label":"龙岩市",
                "children":[
                    {
                        "id":"350802000000",
                        "label":"新罗区",
                        "full_label":"福建省龙岩市新罗区"
                    },
                    {
                        "id":"350803000000",
                        "label":"永定区",
                        "full_label":"福建省龙岩市永定区"
                    },
                    {
                        "id":"350821000000",
                        "label":"长汀县",
                        "full_label":"福建省龙岩市长汀县"
                    },
                    {
                        "id":"350823000000",
                        "label":"上杭县",
                        "full_label":"福建省龙岩市上杭县"
                    },
                    {
                        "id":"350824000000",
                        "label":"武平县",
                        "full_label":"福建省龙岩市武平县"
                    },
                    {
                        "id":"350825000000",
                        "label":"连城县",
                        "full_label":"福建省龙岩市连城县"
                    },
                    {
                        "id":"350881000000",
                        "label":"漳平市",
                        "full_label":"福建省龙岩市漳平市"
                    }
                ],
                "full_label":"福建省龙岩市"
            },
            {
                "id":"350900000000",
                "label":"宁德市",
                "children":[
                    {
                        "id":"350902000000",
                        "label":"蕉城区",
                        "full_label":"福建省宁德市蕉城区"
                    },
                    {
                        "id":"350921000000",
                        "label":"霞浦县",
                        "full_label":"福建省宁德市霞浦县"
                    },
                    {
                        "id":"350922000000",
                        "label":"古田县",
                        "full_label":"福建省宁德市古田县"
                    },
                    {
                        "id":"350923000000",
                        "label":"屏南县",
                        "full_label":"福建省宁德市屏南县"
                    },
                    {
                        "id":"350924000000",
                        "label":"寿宁县",
                        "full_label":"福建省宁德市寿宁县"
                    },
                    {
                        "id":"350925000000",
                        "label":"周宁县",
                        "full_label":"福建省宁德市周宁县"
                    },
                    {
                        "id":"350926000000",
                        "label":"柘荣县",
                        "full_label":"福建省宁德市柘荣县"
                    },
                    {
                        "id":"350981000000",
                        "label":"福安市",
                        "full_label":"福建省宁德市福安市"
                    },
                    {
                        "id":"350982000000",
                        "label":"福鼎市",
                        "full_label":"福建省宁德市福鼎市"
                    }
                ],
                "full_label":"福建省宁德市"
            }
        ],
        "full_label":"福建省"
    },
    {
        "id":"360000000000",
        "label":"江西省",
        "children":[
            {
                "id":"360100000000",
                "label":"南昌市",
                "children":[
                    {
                        "id":"360102000000",
                        "label":"东湖区",
                        "full_label":"江西省南昌市东湖区"
                    },
                    {
                        "id":"360103000000",
                        "label":"西湖区",
                        "full_label":"江西省南昌市西湖区"
                    },
                    {
                        "id":"360104000000",
                        "label":"青云谱区",
                        "full_label":"江西省南昌市青云谱区"
                    },
                    {
                        "id":"360111000000",
                        "label":"青山湖区",
                        "full_label":"江西省南昌市青山湖区"
                    },
                    {
                        "id":"360112000000",
                        "label":"新建区",
                        "full_label":"江西省南昌市新建区"
                    },
                    {
                        "id":"360113000000",
                        "label":"红谷滩区",
                        "full_label":"江西省南昌市红谷滩区"
                    },
                    {
                        "id":"360121000000",
                        "label":"南昌县",
                        "full_label":"江西省南昌市南昌县"
                    },
                    {
                        "id":"360123000000",
                        "label":"安义县",
                        "full_label":"江西省南昌市安义县"
                    },
                    {
                        "id":"360124000000",
                        "label":"进贤县",
                        "full_label":"江西省南昌市进贤县"
                    }
                ],
                "full_label":"江西省南昌市"
            },
            {
                "id":"360200000000",
                "label":"景德镇市",
                "children":[
                    {
                        "id":"360202000000",
                        "label":"昌江区",
                        "full_label":"江西省景德镇市昌江区"
                    },
                    {
                        "id":"360203000000",
                        "label":"珠山区",
                        "full_label":"江西省景德镇市珠山区"
                    },
                    {
                        "id":"360222000000",
                        "label":"浮梁县",
                        "full_label":"江西省景德镇市浮梁县"
                    },
                    {
                        "id":"360281000000",
                        "label":"乐平市",
                        "full_label":"江西省景德镇市乐平市"
                    }
                ],
                "full_label":"江西省景德镇市"
            },
            {
                "id":"360300000000",
                "label":"萍乡市",
                "children":[
                    {
                        "id":"360302000000",
                        "label":"安源区",
                        "full_label":"江西省萍乡市安源区"
                    },
                    {
                        "id":"360313000000",
                        "label":"湘东区",
                        "full_label":"江西省萍乡市湘东区"
                    },
                    {
                        "id":"360321000000",
                        "label":"莲花县",
                        "full_label":"江西省萍乡市莲花县"
                    },
                    {
                        "id":"360322000000",
                        "label":"上栗县",
                        "full_label":"江西省萍乡市上栗县"
                    },
                    {
                        "id":"360323000000",
                        "label":"芦溪县",
                        "full_label":"江西省萍乡市芦溪县"
                    }
                ],
                "full_label":"江西省萍乡市"
            },
            {
                "id":"360400000000",
                "label":"九江市",
                "children":[
                    {
                        "id":"360402000000",
                        "label":"濂溪区",
                        "full_label":"江西省九江市濂溪区"
                    },
                    {
                        "id":"360403000000",
                        "label":"浔阳区",
                        "full_label":"江西省九江市浔阳区"
                    },
                    {
                        "id":"360404000000",
                        "label":"柴桑区",
                        "full_label":"江西省九江市柴桑区"
                    },
                    {
                        "id":"360423000000",
                        "label":"武宁县",
                        "full_label":"江西省九江市武宁县"
                    },
                    {
                        "id":"360424000000",
                        "label":"修水县",
                        "full_label":"江西省九江市修水县"
                    },
                    {
                        "id":"360425000000",
                        "label":"永修县",
                        "full_label":"江西省九江市永修县"
                    },
                    {
                        "id":"360426000000",
                        "label":"德安县",
                        "full_label":"江西省九江市德安县"
                    },
                    {
                        "id":"360428000000",
                        "label":"都昌县",
                        "full_label":"江西省九江市都昌县"
                    },
                    {
                        "id":"360429000000",
                        "label":"湖口县",
                        "full_label":"江西省九江市湖口县"
                    },
                    {
                        "id":"360430000000",
                        "label":"彭泽县",
                        "full_label":"江西省九江市彭泽县"
                    },
                    {
                        "id":"360481000000",
                        "label":"瑞昌市",
                        "full_label":"江西省九江市瑞昌市"
                    },
                    {
                        "id":"360482000000",
                        "label":"共青城市",
                        "full_label":"江西省九江市共青城市"
                    },
                    {
                        "id":"360483000000",
                        "label":"庐山市",
                        "full_label":"江西省九江市庐山市"
                    }
                ],
                "full_label":"江西省九江市"
            },
            {
                "id":"360500000000",
                "label":"新余市",
                "children":[
                    {
                        "id":"360502000000",
                        "label":"渝水区",
                        "full_label":"江西省新余市渝水区"
                    },
                    {
                        "id":"360521000000",
                        "label":"分宜县",
                        "full_label":"江西省新余市分宜县"
                    }
                ],
                "full_label":"江西省新余市"
            },
            {
                "id":"360600000000",
                "label":"鹰潭市",
                "children":[
                    {
                        "id":"360602000000",
                        "label":"月湖区",
                        "full_label":"江西省鹰潭市月湖区"
                    },
                    {
                        "id":"360603000000",
                        "label":"余江区",
                        "full_label":"江西省鹰潭市余江区"
                    },
                    {
                        "id":"360681000000",
                        "label":"贵溪市",
                        "full_label":"江西省鹰潭市贵溪市"
                    }
                ],
                "full_label":"江西省鹰潭市"
            },
            {
                "id":"360700000000",
                "label":"赣州市",
                "children":[
                    {
                        "id":"360702000000",
                        "label":"章贡区",
                        "full_label":"江西省赣州市章贡区"
                    },
                    {
                        "id":"360703000000",
                        "label":"南康区",
                        "full_label":"江西省赣州市南康区"
                    },
                    {
                        "id":"360704000000",
                        "label":"赣县区",
                        "full_label":"江西省赣州市赣县区"
                    },
                    {
                        "id":"360722000000",
                        "label":"信丰县",
                        "full_label":"江西省赣州市信丰县"
                    },
                    {
                        "id":"360723000000",
                        "label":"大余县",
                        "full_label":"江西省赣州市大余县"
                    },
                    {
                        "id":"360724000000",
                        "label":"上犹县",
                        "full_label":"江西省赣州市上犹县"
                    },
                    {
                        "id":"360725000000",
                        "label":"崇义县",
                        "full_label":"江西省赣州市崇义县"
                    },
                    {
                        "id":"360726000000",
                        "label":"安远县",
                        "full_label":"江西省赣州市安远县"
                    },
                    {
                        "id":"360728000000",
                        "label":"定南县",
                        "full_label":"江西省赣州市定南县"
                    },
                    {
                        "id":"360729000000",
                        "label":"全南县",
                        "full_label":"江西省赣州市全南县"
                    },
                    {
                        "id":"360730000000",
                        "label":"宁都县",
                        "full_label":"江西省赣州市宁都县"
                    },
                    {
                        "id":"360731000000",
                        "label":"于都县",
                        "full_label":"江西省赣州市于都县"
                    },
                    {
                        "id":"360732000000",
                        "label":"兴国县",
                        "full_label":"江西省赣州市兴国县"
                    },
                    {
                        "id":"360733000000",
                        "label":"会昌县",
                        "full_label":"江西省赣州市会昌县"
                    },
                    {
                        "id":"360734000000",
                        "label":"寻乌县",
                        "full_label":"江西省赣州市寻乌县"
                    },
                    {
                        "id":"360735000000",
                        "label":"石城县",
                        "full_label":"江西省赣州市石城县"
                    },
                    {
                        "id":"360781000000",
                        "label":"瑞金市",
                        "full_label":"江西省赣州市瑞金市"
                    },
                    {
                        "id":"360783000000",
                        "label":"龙南市",
                        "full_label":"江西省赣州市龙南市"
                    }
                ],
                "full_label":"江西省赣州市"
            },
            {
                "id":"360800000000",
                "label":"吉安市",
                "children":[
                    {
                        "id":"360802000000",
                        "label":"吉州区",
                        "full_label":"江西省吉安市吉州区"
                    },
                    {
                        "id":"360803000000",
                        "label":"青原区",
                        "full_label":"江西省吉安市青原区"
                    },
                    {
                        "id":"360821000000",
                        "label":"吉安县",
                        "full_label":"江西省吉安市吉安县"
                    },
                    {
                        "id":"360822000000",
                        "label":"吉水县",
                        "full_label":"江西省吉安市吉水县"
                    },
                    {
                        "id":"360823000000",
                        "label":"峡江县",
                        "full_label":"江西省吉安市峡江县"
                    },
                    {
                        "id":"360824000000",
                        "label":"新干县",
                        "full_label":"江西省吉安市新干县"
                    },
                    {
                        "id":"360825000000",
                        "label":"永丰县",
                        "full_label":"江西省吉安市永丰县"
                    },
                    {
                        "id":"360826000000",
                        "label":"泰和县",
                        "full_label":"江西省吉安市泰和县"
                    },
                    {
                        "id":"360827000000",
                        "label":"遂川县",
                        "full_label":"江西省吉安市遂川县"
                    },
                    {
                        "id":"360828000000",
                        "label":"万安县",
                        "full_label":"江西省吉安市万安县"
                    },
                    {
                        "id":"360829000000",
                        "label":"安福县",
                        "full_label":"江西省吉安市安福县"
                    },
                    {
                        "id":"360830000000",
                        "label":"永新县",
                        "full_label":"江西省吉安市永新县"
                    },
                    {
                        "id":"360881000000",
                        "label":"井冈山市",
                        "full_label":"江西省吉安市井冈山市"
                    }
                ],
                "full_label":"江西省吉安市"
            },
            {
                "id":"360900000000",
                "label":"宜春市",
                "children":[
                    {
                        "id":"360902000000",
                        "label":"袁州区",
                        "full_label":"江西省宜春市袁州区"
                    },
                    {
                        "id":"360921000000",
                        "label":"奉新县",
                        "full_label":"江西省宜春市奉新县"
                    },
                    {
                        "id":"360922000000",
                        "label":"万载县",
                        "full_label":"江西省宜春市万载县"
                    },
                    {
                        "id":"360923000000",
                        "label":"上高县",
                        "full_label":"江西省宜春市上高县"
                    },
                    {
                        "id":"360924000000",
                        "label":"宜丰县",
                        "full_label":"江西省宜春市宜丰县"
                    },
                    {
                        "id":"360925000000",
                        "label":"靖安县",
                        "full_label":"江西省宜春市靖安县"
                    },
                    {
                        "id":"360926000000",
                        "label":"铜鼓县",
                        "full_label":"江西省宜春市铜鼓县"
                    },
                    {
                        "id":"360981000000",
                        "label":"丰城市",
                        "full_label":"江西省宜春市丰城市"
                    },
                    {
                        "id":"360982000000",
                        "label":"樟树市",
                        "full_label":"江西省宜春市樟树市"
                    },
                    {
                        "id":"360983000000",
                        "label":"高安市",
                        "full_label":"江西省宜春市高安市"
                    }
                ],
                "full_label":"江西省宜春市"
            },
            {
                "id":"361000000000",
                "label":"抚州市",
                "children":[
                    {
                        "id":"361002000000",
                        "label":"临川区",
                        "full_label":"江西省抚州市临川区"
                    },
                    {
                        "id":"361003000000",
                        "label":"东乡区",
                        "full_label":"江西省抚州市东乡区"
                    },
                    {
                        "id":"361021000000",
                        "label":"南城县",
                        "full_label":"江西省抚州市南城县"
                    },
                    {
                        "id":"361022000000",
                        "label":"黎川县",
                        "full_label":"江西省抚州市黎川县"
                    },
                    {
                        "id":"361023000000",
                        "label":"南丰县",
                        "full_label":"江西省抚州市南丰县"
                    },
                    {
                        "id":"361024000000",
                        "label":"崇仁县",
                        "full_label":"江西省抚州市崇仁县"
                    },
                    {
                        "id":"361025000000",
                        "label":"乐安县",
                        "full_label":"江西省抚州市乐安县"
                    },
                    {
                        "id":"361026000000",
                        "label":"宜黄县",
                        "full_label":"江西省抚州市宜黄县"
                    },
                    {
                        "id":"361027000000",
                        "label":"金溪县",
                        "full_label":"江西省抚州市金溪县"
                    },
                    {
                        "id":"361028000000",
                        "label":"资溪县",
                        "full_label":"江西省抚州市资溪县"
                    },
                    {
                        "id":"361030000000",
                        "label":"广昌县",
                        "full_label":"江西省抚州市广昌县"
                    }
                ],
                "full_label":"江西省抚州市"
            },
            {
                "id":"361100000000",
                "label":"上饶市",
                "children":[
                    {
                        "id":"361102000000",
                        "label":"信州区",
                        "full_label":"江西省上饶市信州区"
                    },
                    {
                        "id":"361103000000",
                        "label":"广丰区",
                        "full_label":"江西省上饶市广丰区"
                    },
                    {
                        "id":"361104000000",
                        "label":"广信区",
                        "full_label":"江西省上饶市广信区"
                    },
                    {
                        "id":"361123000000",
                        "label":"玉山县",
                        "full_label":"江西省上饶市玉山县"
                    },
                    {
                        "id":"361124000000",
                        "label":"铅山县",
                        "full_label":"江西省上饶市铅山县"
                    },
                    {
                        "id":"361125000000",
                        "label":"横峰县",
                        "full_label":"江西省上饶市横峰县"
                    },
                    {
                        "id":"361126000000",
                        "label":"弋阳县",
                        "full_label":"江西省上饶市弋阳县"
                    },
                    {
                        "id":"361127000000",
                        "label":"余干县",
                        "full_label":"江西省上饶市余干县"
                    },
                    {
                        "id":"361128000000",
                        "label":"鄱阳县",
                        "full_label":"江西省上饶市鄱阳县"
                    },
                    {
                        "id":"361129000000",
                        "label":"万年县",
                        "full_label":"江西省上饶市万年县"
                    },
                    {
                        "id":"361130000000",
                        "label":"婺源县",
                        "full_label":"江西省上饶市婺源县"
                    },
                    {
                        "id":"361181000000",
                        "label":"德兴市",
                        "full_label":"江西省上饶市德兴市"
                    }
                ],
                "full_label":"江西省上饶市"
            }
        ],
        "full_label":"江西省"
    },
    {
        "id":"370000000000",
        "label":"山东省",
        "children":[
            {
                "id":"370100000000",
                "label":"济南市",
                "children":[
                    {
                        "id":"370102000000",
                        "label":"历下区",
                        "full_label":"山东省济南市历下区"
                    },
                    {
                        "id":"370103000000",
                        "label":"市中区",
                        "full_label":"山东省济南市市中区"
                    },
                    {
                        "id":"370104000000",
                        "label":"槐荫区",
                        "full_label":"山东省济南市槐荫区"
                    },
                    {
                        "id":"370105000000",
                        "label":"天桥区",
                        "full_label":"山东省济南市天桥区"
                    },
                    {
                        "id":"370112000000",
                        "label":"历城区",
                        "full_label":"山东省济南市历城区"
                    },
                    {
                        "id":"370113000000",
                        "label":"长清区",
                        "full_label":"山东省济南市长清区"
                    },
                    {
                        "id":"370114000000",
                        "label":"章丘区",
                        "full_label":"山东省济南市章丘区"
                    },
                    {
                        "id":"370115000000",
                        "label":"济阳区",
                        "full_label":"山东省济南市济阳区"
                    },
                    {
                        "id":"370116000000",
                        "label":"莱芜区",
                        "full_label":"山东省济南市莱芜区"
                    },
                    {
                        "id":"370117000000",
                        "label":"钢城区",
                        "full_label":"山东省济南市钢城区"
                    },
                    {
                        "id":"370124000000",
                        "label":"平阴县",
                        "full_label":"山东省济南市平阴县"
                    },
                    {
                        "id":"370126000000",
                        "label":"商河县",
                        "full_label":"山东省济南市商河县"
                    }
                ],
                "full_label":"山东省济南市"
            },
            {
                "id":"370200000000",
                "label":"青岛市",
                "children":[
                    {
                        "id":"370202000000",
                        "label":"市南区",
                        "full_label":"山东省青岛市市南区"
                    },
                    {
                        "id":"370203000000",
                        "label":"市北区",
                        "full_label":"山东省青岛市市北区"
                    },
                    {
                        "id":"370211000000",
                        "label":"黄岛区",
                        "full_label":"山东省青岛市黄岛区"
                    },
                    {
                        "id":"370212000000",
                        "label":"崂山区",
                        "full_label":"山东省青岛市崂山区"
                    },
                    {
                        "id":"370213000000",
                        "label":"李沧区",
                        "full_label":"山东省青岛市李沧区"
                    },
                    {
                        "id":"370214000000",
                        "label":"城阳区",
                        "full_label":"山东省青岛市城阳区"
                    },
                    {
                        "id":"370215000000",
                        "label":"即墨区",
                        "full_label":"山东省青岛市即墨区"
                    },
                    {
                        "id":"370281000000",
                        "label":"胶州市",
                        "full_label":"山东省青岛市胶州市"
                    },
                    {
                        "id":"370283000000",
                        "label":"平度市",
                        "full_label":"山东省青岛市平度市"
                    },
                    {
                        "id":"370285000000",
                        "label":"莱西市",
                        "full_label":"山东省青岛市莱西市"
                    }
                ],
                "full_label":"山东省青岛市"
            },
            {
                "id":"370300000000",
                "label":"淄博市",
                "children":[
                    {
                        "id":"370302000000",
                        "label":"淄川区",
                        "full_label":"山东省淄博市淄川区"
                    },
                    {
                        "id":"370303000000",
                        "label":"张店区",
                        "full_label":"山东省淄博市张店区"
                    },
                    {
                        "id":"370304000000",
                        "label":"博山区",
                        "full_label":"山东省淄博市博山区"
                    },
                    {
                        "id":"370305000000",
                        "label":"临淄区",
                        "full_label":"山东省淄博市临淄区"
                    },
                    {
                        "id":"370306000000",
                        "label":"周村区",
                        "full_label":"山东省淄博市周村区"
                    },
                    {
                        "id":"370321000000",
                        "label":"桓台县",
                        "full_label":"山东省淄博市桓台县"
                    },
                    {
                        "id":"370322000000",
                        "label":"高青县",
                        "full_label":"山东省淄博市高青县"
                    },
                    {
                        "id":"370323000000",
                        "label":"沂源县",
                        "full_label":"山东省淄博市沂源县"
                    }
                ],
                "full_label":"山东省淄博市"
            },
            {
                "id":"370400000000",
                "label":"枣庄市",
                "children":[
                    {
                        "id":"370402000000",
                        "label":"市中区",
                        "full_label":"山东省枣庄市市中区"
                    },
                    {
                        "id":"370403000000",
                        "label":"薛城区",
                        "full_label":"山东省枣庄市薛城区"
                    },
                    {
                        "id":"370404000000",
                        "label":"峄城区",
                        "full_label":"山东省枣庄市峄城区"
                    },
                    {
                        "id":"370405000000",
                        "label":"台儿庄区",
                        "full_label":"山东省枣庄市台儿庄区"
                    },
                    {
                        "id":"370406000000",
                        "label":"山亭区",
                        "full_label":"山东省枣庄市山亭区"
                    },
                    {
                        "id":"370481000000",
                        "label":"滕州市",
                        "full_label":"山东省枣庄市滕州市"
                    }
                ],
                "full_label":"山东省枣庄市"
            },
            {
                "id":"370500000000",
                "label":"东营市",
                "children":[
                    {
                        "id":"370502000000",
                        "label":"东营区",
                        "full_label":"山东省东营市东营区"
                    },
                    {
                        "id":"370503000000",
                        "label":"河口区",
                        "full_label":"山东省东营市河口区"
                    },
                    {
                        "id":"370505000000",
                        "label":"垦利区",
                        "full_label":"山东省东营市垦利区"
                    },
                    {
                        "id":"370522000000",
                        "label":"利津县",
                        "full_label":"山东省东营市利津县"
                    },
                    {
                        "id":"370523000000",
                        "label":"广饶县",
                        "full_label":"山东省东营市广饶县"
                    }
                ],
                "full_label":"山东省东营市"
            },
            {
                "id":"370600000000",
                "label":"烟台市",
                "children":[
                    {
                        "id":"370602000000",
                        "label":"芝罘区",
                        "full_label":"山东省烟台市芝罘区"
                    },
                    {
                        "id":"370611000000",
                        "label":"福山区",
                        "full_label":"山东省烟台市福山区"
                    },
                    {
                        "id":"370612000000",
                        "label":"牟平区",
                        "full_label":"山东省烟台市牟平区"
                    },
                    {
                        "id":"370613000000",
                        "label":"莱山区",
                        "full_label":"山东省烟台市莱山区"
                    },
                    {
                        "id":"370614000000",
                        "label":"蓬莱区",
                        "full_label":"山东省烟台市蓬莱区"
                    },
                    {
                        "id":"370681000000",
                        "label":"龙口市",
                        "full_label":"山东省烟台市龙口市"
                    },
                    {
                        "id":"370682000000",
                        "label":"莱阳市",
                        "full_label":"山东省烟台市莱阳市"
                    },
                    {
                        "id":"370683000000",
                        "label":"莱州市",
                        "full_label":"山东省烟台市莱州市"
                    },
                    {
                        "id":"370685000000",
                        "label":"招远市",
                        "full_label":"山东省烟台市招远市"
                    },
                    {
                        "id":"370686000000",
                        "label":"栖霞市",
                        "full_label":"山东省烟台市栖霞市"
                    },
                    {
                        "id":"370687000000",
                        "label":"海阳市",
                        "full_label":"山东省烟台市海阳市"
                    }
                ],
                "full_label":"山东省烟台市"
            },
            {
                "id":"370700000000",
                "label":"潍坊市",
                "children":[
                    {
                        "id":"370702000000",
                        "label":"潍城区",
                        "full_label":"山东省潍坊市潍城区"
                    },
                    {
                        "id":"370703000000",
                        "label":"寒亭区",
                        "full_label":"山东省潍坊市寒亭区"
                    },
                    {
                        "id":"370704000000",
                        "label":"坊子区",
                        "full_label":"山东省潍坊市坊子区"
                    },
                    {
                        "id":"370705000000",
                        "label":"奎文区",
                        "full_label":"山东省潍坊市奎文区"
                    },
                    {
                        "id":"370724000000",
                        "label":"临朐县",
                        "full_label":"山东省潍坊市临朐县"
                    },
                    {
                        "id":"370725000000",
                        "label":"昌乐县",
                        "full_label":"山东省潍坊市昌乐县"
                    },
                    {
                        "id":"370781000000",
                        "label":"青州市",
                        "full_label":"山东省潍坊市青州市"
                    },
                    {
                        "id":"370782000000",
                        "label":"诸城市",
                        "full_label":"山东省潍坊市诸城市"
                    },
                    {
                        "id":"370783000000",
                        "label":"寿光市",
                        "full_label":"山东省潍坊市寿光市"
                    },
                    {
                        "id":"370784000000",
                        "label":"安丘市",
                        "full_label":"山东省潍坊市安丘市"
                    },
                    {
                        "id":"370785000000",
                        "label":"高密市",
                        "full_label":"山东省潍坊市高密市"
                    },
                    {
                        "id":"370786000000",
                        "label":"昌邑市",
                        "full_label":"山东省潍坊市昌邑市"
                    }
                ],
                "full_label":"山东省潍坊市"
            },
            {
                "id":"370800000000",
                "label":"济宁市",
                "children":[
                    {
                        "id":"370811000000",
                        "label":"任城区",
                        "full_label":"山东省济宁市任城区"
                    },
                    {
                        "id":"370812000000",
                        "label":"兖州区",
                        "full_label":"山东省济宁市兖州区"
                    },
                    {
                        "id":"370826000000",
                        "label":"微山县",
                        "full_label":"山东省济宁市微山县"
                    },
                    {
                        "id":"370827000000",
                        "label":"鱼台县",
                        "full_label":"山东省济宁市鱼台县"
                    },
                    {
                        "id":"370828000000",
                        "label":"金乡县",
                        "full_label":"山东省济宁市金乡县"
                    },
                    {
                        "id":"370829000000",
                        "label":"嘉祥县",
                        "full_label":"山东省济宁市嘉祥县"
                    },
                    {
                        "id":"370830000000",
                        "label":"汶上县",
                        "full_label":"山东省济宁市汶上县"
                    },
                    {
                        "id":"370831000000",
                        "label":"泗水县",
                        "full_label":"山东省济宁市泗水县"
                    },
                    {
                        "id":"370832000000",
                        "label":"梁山县",
                        "full_label":"山东省济宁市梁山县"
                    },
                    {
                        "id":"370881000000",
                        "label":"曲阜市",
                        "full_label":"山东省济宁市曲阜市"
                    },
                    {
                        "id":"370883000000",
                        "label":"邹城市",
                        "full_label":"山东省济宁市邹城市"
                    }
                ],
                "full_label":"山东省济宁市"
            },
            {
                "id":"370900000000",
                "label":"泰安市",
                "children":[
                    {
                        "id":"370902000000",
                        "label":"泰山区",
                        "full_label":"山东省泰安市泰山区"
                    },
                    {
                        "id":"370911000000",
                        "label":"岱岳区",
                        "full_label":"山东省泰安市岱岳区"
                    },
                    {
                        "id":"370921000000",
                        "label":"宁阳县",
                        "full_label":"山东省泰安市宁阳县"
                    },
                    {
                        "id":"370923000000",
                        "label":"东平县",
                        "full_label":"山东省泰安市东平县"
                    },
                    {
                        "id":"370982000000",
                        "label":"新泰市",
                        "full_label":"山东省泰安市新泰市"
                    },
                    {
                        "id":"370983000000",
                        "label":"肥城市",
                        "full_label":"山东省泰安市肥城市"
                    }
                ],
                "full_label":"山东省泰安市"
            },
            {
                "id":"371000000000",
                "label":"威海市",
                "children":[
                    {
                        "id":"371002000000",
                        "label":"环翠区",
                        "full_label":"山东省威海市环翠区"
                    },
                    {
                        "id":"371003000000",
                        "label":"文登区",
                        "full_label":"山东省威海市文登区"
                    },
                    {
                        "id":"371082000000",
                        "label":"荣成市",
                        "full_label":"山东省威海市荣成市"
                    },
                    {
                        "id":"371083000000",
                        "label":"乳山市",
                        "full_label":"山东省威海市乳山市"
                    }
                ],
                "full_label":"山东省威海市"
            },
            {
                "id":"371100000000",
                "label":"日照市",
                "children":[
                    {
                        "id":"371102000000",
                        "label":"东港区",
                        "full_label":"山东省日照市东港区"
                    },
                    {
                        "id":"371103000000",
                        "label":"岚山区",
                        "full_label":"山东省日照市岚山区"
                    },
                    {
                        "id":"371121000000",
                        "label":"五莲县",
                        "full_label":"山东省日照市五莲县"
                    },
                    {
                        "id":"371122000000",
                        "label":"莒县",
                        "full_label":"山东省日照市莒县"
                    }
                ],
                "full_label":"山东省日照市"
            },
            {
                "id":"371300000000",
                "label":"临沂市",
                "children":[
                    {
                        "id":"371302000000",
                        "label":"兰山区",
                        "full_label":"山东省临沂市兰山区"
                    },
                    {
                        "id":"371311000000",
                        "label":"罗庄区",
                        "full_label":"山东省临沂市罗庄区"
                    },
                    {
                        "id":"371312000000",
                        "label":"河东区",
                        "full_label":"山东省临沂市河东区"
                    },
                    {
                        "id":"371321000000",
                        "label":"沂南县",
                        "full_label":"山东省临沂市沂南县"
                    },
                    {
                        "id":"371322000000",
                        "label":"郯城县",
                        "full_label":"山东省临沂市郯城县"
                    },
                    {
                        "id":"371323000000",
                        "label":"沂水县",
                        "full_label":"山东省临沂市沂水县"
                    },
                    {
                        "id":"371324000000",
                        "label":"兰陵县",
                        "full_label":"山东省临沂市兰陵县"
                    },
                    {
                        "id":"371325000000",
                        "label":"费县",
                        "full_label":"山东省临沂市费县"
                    },
                    {
                        "id":"371326000000",
                        "label":"平邑县",
                        "full_label":"山东省临沂市平邑县"
                    },
                    {
                        "id":"371327000000",
                        "label":"莒南县",
                        "full_label":"山东省临沂市莒南县"
                    },
                    {
                        "id":"371328000000",
                        "label":"蒙阴县",
                        "full_label":"山东省临沂市蒙阴县"
                    },
                    {
                        "id":"371329000000",
                        "label":"临沭县",
                        "full_label":"山东省临沂市临沭县"
                    }
                ],
                "full_label":"山东省临沂市"
            },
            {
                "id":"371400000000",
                "label":"德州市",
                "children":[
                    {
                        "id":"371402000000",
                        "label":"德城区",
                        "full_label":"山东省德州市德城区"
                    },
                    {
                        "id":"371403000000",
                        "label":"陵城区",
                        "full_label":"山东省德州市陵城区"
                    },
                    {
                        "id":"371422000000",
                        "label":"宁津县",
                        "full_label":"山东省德州市宁津县"
                    },
                    {
                        "id":"371423000000",
                        "label":"庆云县",
                        "full_label":"山东省德州市庆云县"
                    },
                    {
                        "id":"371424000000",
                        "label":"临邑县",
                        "full_label":"山东省德州市临邑县"
                    },
                    {
                        "id":"371425000000",
                        "label":"齐河县",
                        "full_label":"山东省德州市齐河县"
                    },
                    {
                        "id":"371426000000",
                        "label":"平原县",
                        "full_label":"山东省德州市平原县"
                    },
                    {
                        "id":"371427000000",
                        "label":"夏津县",
                        "full_label":"山东省德州市夏津县"
                    },
                    {
                        "id":"371428000000",
                        "label":"武城县",
                        "full_label":"山东省德州市武城县"
                    },
                    {
                        "id":"371481000000",
                        "label":"乐陵市",
                        "full_label":"山东省德州市乐陵市"
                    },
                    {
                        "id":"371482000000",
                        "label":"禹城市",
                        "full_label":"山东省德州市禹城市"
                    }
                ],
                "full_label":"山东省德州市"
            },
            {
                "id":"371500000000",
                "label":"聊城市",
                "children":[
                    {
                        "id":"371502000000",
                        "label":"东昌府区",
                        "full_label":"山东省聊城市东昌府区"
                    },
                    {
                        "id":"371503000000",
                        "label":"茌平区",
                        "full_label":"山东省聊城市茌平区"
                    },
                    {
                        "id":"371521000000",
                        "label":"阳谷县",
                        "full_label":"山东省聊城市阳谷县"
                    },
                    {
                        "id":"371522000000",
                        "label":"莘县",
                        "full_label":"山东省聊城市莘县"
                    },
                    {
                        "id":"371524000000",
                        "label":"东阿县",
                        "full_label":"山东省聊城市东阿县"
                    },
                    {
                        "id":"371525000000",
                        "label":"冠县",
                        "full_label":"山东省聊城市冠县"
                    },
                    {
                        "id":"371526000000",
                        "label":"高唐县",
                        "full_label":"山东省聊城市高唐县"
                    },
                    {
                        "id":"371581000000",
                        "label":"临清市",
                        "full_label":"山东省聊城市临清市"
                    }
                ],
                "full_label":"山东省聊城市"
            },
            {
                "id":"371600000000",
                "label":"滨州市",
                "children":[
                    {
                        "id":"371602000000",
                        "label":"滨城区",
                        "full_label":"山东省滨州市滨城区"
                    },
                    {
                        "id":"371603000000",
                        "label":"沾化区",
                        "full_label":"山东省滨州市沾化区"
                    },
                    {
                        "id":"371621000000",
                        "label":"惠民县",
                        "full_label":"山东省滨州市惠民县"
                    },
                    {
                        "id":"371622000000",
                        "label":"阳信县",
                        "full_label":"山东省滨州市阳信县"
                    },
                    {
                        "id":"371623000000",
                        "label":"无棣县",
                        "full_label":"山东省滨州市无棣县"
                    },
                    {
                        "id":"371625000000",
                        "label":"博兴县",
                        "full_label":"山东省滨州市博兴县"
                    },
                    {
                        "id":"371681000000",
                        "label":"邹平市",
                        "full_label":"山东省滨州市邹平市"
                    }
                ],
                "full_label":"山东省滨州市"
            },
            {
                "id":"371700000000",
                "label":"菏泽市",
                "children":[
                    {
                        "id":"371702000000",
                        "label":"牡丹区",
                        "full_label":"山东省菏泽市牡丹区"
                    },
                    {
                        "id":"371703000000",
                        "label":"定陶区",
                        "full_label":"山东省菏泽市定陶区"
                    },
                    {
                        "id":"371721000000",
                        "label":"曹县",
                        "full_label":"山东省菏泽市曹县"
                    },
                    {
                        "id":"371722000000",
                        "label":"单县",
                        "full_label":"山东省菏泽市单县"
                    },
                    {
                        "id":"371723000000",
                        "label":"成武县",
                        "full_label":"山东省菏泽市成武县"
                    },
                    {
                        "id":"371724000000",
                        "label":"巨野县",
                        "full_label":"山东省菏泽市巨野县"
                    },
                    {
                        "id":"371725000000",
                        "label":"郓城县",
                        "full_label":"山东省菏泽市郓城县"
                    },
                    {
                        "id":"371726000000",
                        "label":"鄄城县",
                        "full_label":"山东省菏泽市鄄城县"
                    },
                    {
                        "id":"371728000000",
                        "label":"东明县",
                        "full_label":"山东省菏泽市东明县"
                    }
                ],
                "full_label":"山东省菏泽市"
            }
        ],
        "full_label":"山东省"
    },
    {
        "id":"410000000000",
        "label":"河南省",
        "children":[
            {
                "id":"410100000000",
                "label":"郑州市",
                "children":[
                    {
                        "id":"410102000000",
                        "label":"中原区",
                        "full_label":"河南省郑州市中原区"
                    },
                    {
                        "id":"410103000000",
                        "label":"二七区",
                        "full_label":"河南省郑州市二七区"
                    },
                    {
                        "id":"410104000000",
                        "label":"管城回族区",
                        "full_label":"河南省郑州市管城回族区"
                    },
                    {
                        "id":"410105000000",
                        "label":"金水区",
                        "full_label":"河南省郑州市金水区"
                    },
                    {
                        "id":"410106000000",
                        "label":"上街区",
                        "full_label":"河南省郑州市上街区"
                    },
                    {
                        "id":"410108000000",
                        "label":"惠济区",
                        "full_label":"河南省郑州市惠济区"
                    },
                    {
                        "id":"410122000000",
                        "label":"中牟县",
                        "full_label":"河南省郑州市中牟县"
                    },
                    {
                        "id":"410181000000",
                        "label":"巩义市",
                        "full_label":"河南省郑州市巩义市"
                    },
                    {
                        "id":"410182000000",
                        "label":"荥阳市",
                        "full_label":"河南省郑州市荥阳市"
                    },
                    {
                        "id":"410183000000",
                        "label":"新密市",
                        "full_label":"河南省郑州市新密市"
                    },
                    {
                        "id":"410184000000",
                        "label":"新郑市",
                        "full_label":"河南省郑州市新郑市"
                    },
                    {
                        "id":"410185000000",
                        "label":"登封市",
                        "full_label":"河南省郑州市登封市"
                    }
                ],
                "full_label":"河南省郑州市"
            },
            {
                "id":"410200000000",
                "label":"开封市",
                "children":[
                    {
                        "id":"410202000000",
                        "label":"龙亭区",
                        "full_label":"河南省开封市龙亭区"
                    },
                    {
                        "id":"410203000000",
                        "label":"顺河回族区",
                        "full_label":"河南省开封市顺河回族区"
                    },
                    {
                        "id":"410204000000",
                        "label":"鼓楼区",
                        "full_label":"河南省开封市鼓楼区"
                    },
                    {
                        "id":"410205000000",
                        "label":"禹王台区",
                        "full_label":"河南省开封市禹王台区"
                    },
                    {
                        "id":"410212000000",
                        "label":"祥符区",
                        "full_label":"河南省开封市祥符区"
                    },
                    {
                        "id":"410221000000",
                        "label":"杞县",
                        "full_label":"河南省开封市杞县"
                    },
                    {
                        "id":"410222000000",
                        "label":"通许县",
                        "full_label":"河南省开封市通许县"
                    },
                    {
                        "id":"410223000000",
                        "label":"尉氏县",
                        "full_label":"河南省开封市尉氏县"
                    },
                    {
                        "id":"410225000000",
                        "label":"兰考县",
                        "full_label":"河南省开封市兰考县"
                    }
                ],
                "full_label":"河南省开封市"
            },
            {
                "id":"410300000000",
                "label":"洛阳市",
                "children":[
                    {
                        "id":"410302000000",
                        "label":"老城区",
                        "full_label":"河南省洛阳市老城区"
                    },
                    {
                        "id":"410303000000",
                        "label":"西工区",
                        "full_label":"河南省洛阳市西工区"
                    },
                    {
                        "id":"410304000000",
                        "label":"瀍河回族区",
                        "full_label":"河南省洛阳市瀍河回族区"
                    },
                    {
                        "id":"410305000000",
                        "label":"涧西区",
                        "full_label":"河南省洛阳市涧西区"
                    },
                    {
                        "id":"410307000000",
                        "label":"偃师区",
                        "full_label":"河南省洛阳市偃师区"
                    },
                    {
                        "id":"410308000000",
                        "label":"孟津区",
                        "full_label":"河南省洛阳市孟津区"
                    },
                    {
                        "id":"410311000000",
                        "label":"洛龙区",
                        "full_label":"河南省洛阳市洛龙区"
                    },
                    {
                        "id":"410323000000",
                        "label":"新安县",
                        "full_label":"河南省洛阳市新安县"
                    },
                    {
                        "id":"410324000000",
                        "label":"栾川县",
                        "full_label":"河南省洛阳市栾川县"
                    },
                    {
                        "id":"410325000000",
                        "label":"嵩县",
                        "full_label":"河南省洛阳市嵩县"
                    },
                    {
                        "id":"410326000000",
                        "label":"汝阳县",
                        "full_label":"河南省洛阳市汝阳县"
                    },
                    {
                        "id":"410327000000",
                        "label":"宜阳县",
                        "full_label":"河南省洛阳市宜阳县"
                    },
                    {
                        "id":"410328000000",
                        "label":"洛宁县",
                        "full_label":"河南省洛阳市洛宁县"
                    },
                    {
                        "id":"410329000000",
                        "label":"伊川县",
                        "full_label":"河南省洛阳市伊川县"
                    }
                ],
                "full_label":"河南省洛阳市"
            },
            {
                "id":"410400000000",
                "label":"平顶山市",
                "children":[
                    {
                        "id":"410402000000",
                        "label":"新华区",
                        "full_label":"河南省平顶山市新华区"
                    },
                    {
                        "id":"410403000000",
                        "label":"卫东区",
                        "full_label":"河南省平顶山市卫东区"
                    },
                    {
                        "id":"410404000000",
                        "label":"石龙区",
                        "full_label":"河南省平顶山市石龙区"
                    },
                    {
                        "id":"410411000000",
                        "label":"湛河区",
                        "full_label":"河南省平顶山市湛河区"
                    },
                    {
                        "id":"410421000000",
                        "label":"宝丰县",
                        "full_label":"河南省平顶山市宝丰县"
                    },
                    {
                        "id":"410422000000",
                        "label":"叶县",
                        "full_label":"河南省平顶山市叶县"
                    },
                    {
                        "id":"410423000000",
                        "label":"鲁山县",
                        "full_label":"河南省平顶山市鲁山县"
                    },
                    {
                        "id":"410425000000",
                        "label":"郏县",
                        "full_label":"河南省平顶山市郏县"
                    },
                    {
                        "id":"410481000000",
                        "label":"舞钢市",
                        "full_label":"河南省平顶山市舞钢市"
                    },
                    {
                        "id":"410482000000",
                        "label":"汝州市",
                        "full_label":"河南省平顶山市汝州市"
                    }
                ],
                "full_label":"河南省平顶山市"
            },
            {
                "id":"410500000000",
                "label":"安阳市",
                "children":[
                    {
                        "id":"410502000000",
                        "label":"文峰区",
                        "full_label":"河南省安阳市文峰区"
                    },
                    {
                        "id":"410503000000",
                        "label":"北关区",
                        "full_label":"河南省安阳市北关区"
                    },
                    {
                        "id":"410505000000",
                        "label":"殷都区",
                        "full_label":"河南省安阳市殷都区"
                    },
                    {
                        "id":"410506000000",
                        "label":"龙安区",
                        "full_label":"河南省安阳市龙安区"
                    },
                    {
                        "id":"410522000000",
                        "label":"安阳县",
                        "full_label":"河南省安阳市安阳县"
                    },
                    {
                        "id":"410523000000",
                        "label":"汤阴县",
                        "full_label":"河南省安阳市汤阴县"
                    },
                    {
                        "id":"410526000000",
                        "label":"滑县",
                        "full_label":"河南省安阳市滑县"
                    },
                    {
                        "id":"410527000000",
                        "label":"内黄县",
                        "full_label":"河南省安阳市内黄县"
                    },
                    {
                        "id":"410581000000",
                        "label":"林州市",
                        "full_label":"河南省安阳市林州市"
                    }
                ],
                "full_label":"河南省安阳市"
            },
            {
                "id":"410600000000",
                "label":"鹤壁市",
                "children":[
                    {
                        "id":"410602000000",
                        "label":"鹤山区",
                        "full_label":"河南省鹤壁市鹤山区"
                    },
                    {
                        "id":"410603000000",
                        "label":"山城区",
                        "full_label":"河南省鹤壁市山城区"
                    },
                    {
                        "id":"410611000000",
                        "label":"淇滨区",
                        "full_label":"河南省鹤壁市淇滨区"
                    },
                    {
                        "id":"410621000000",
                        "label":"浚县",
                        "full_label":"河南省鹤壁市浚县"
                    },
                    {
                        "id":"410622000000",
                        "label":"淇县",
                        "full_label":"河南省鹤壁市淇县"
                    }
                ],
                "full_label":"河南省鹤壁市"
            },
            {
                "id":"410700000000",
                "label":"新乡市",
                "children":[
                    {
                        "id":"410702000000",
                        "label":"红旗区",
                        "full_label":"河南省新乡市红旗区"
                    },
                    {
                        "id":"410703000000",
                        "label":"卫滨区",
                        "full_label":"河南省新乡市卫滨区"
                    },
                    {
                        "id":"410704000000",
                        "label":"凤泉区",
                        "full_label":"河南省新乡市凤泉区"
                    },
                    {
                        "id":"410711000000",
                        "label":"牧野区",
                        "full_label":"河南省新乡市牧野区"
                    },
                    {
                        "id":"410721000000",
                        "label":"新乡县",
                        "full_label":"河南省新乡市新乡县"
                    },
                    {
                        "id":"410724000000",
                        "label":"获嘉县",
                        "full_label":"河南省新乡市获嘉县"
                    },
                    {
                        "id":"410725000000",
                        "label":"原阳县",
                        "full_label":"河南省新乡市原阳县"
                    },
                    {
                        "id":"410726000000",
                        "label":"延津县",
                        "full_label":"河南省新乡市延津县"
                    },
                    {
                        "id":"410727000000",
                        "label":"封丘县",
                        "full_label":"河南省新乡市封丘县"
                    },
                    {
                        "id":"410781000000",
                        "label":"卫辉市",
                        "full_label":"河南省新乡市卫辉市"
                    },
                    {
                        "id":"410782000000",
                        "label":"辉县市",
                        "full_label":"河南省新乡市辉县市"
                    },
                    {
                        "id":"410783000000",
                        "label":"长垣市",
                        "full_label":"河南省新乡市长垣市"
                    }
                ],
                "full_label":"河南省新乡市"
            },
            {
                "id":"410800000000",
                "label":"焦作市",
                "children":[
                    {
                        "id":"410802000000",
                        "label":"解放区",
                        "full_label":"河南省焦作市解放区"
                    },
                    {
                        "id":"410803000000",
                        "label":"中站区",
                        "full_label":"河南省焦作市中站区"
                    },
                    {
                        "id":"410804000000",
                        "label":"马村区",
                        "full_label":"河南省焦作市马村区"
                    },
                    {
                        "id":"410811000000",
                        "label":"山阳区",
                        "full_label":"河南省焦作市山阳区"
                    },
                    {
                        "id":"410821000000",
                        "label":"修武县",
                        "full_label":"河南省焦作市修武县"
                    },
                    {
                        "id":"410822000000",
                        "label":"博爱县",
                        "full_label":"河南省焦作市博爱县"
                    },
                    {
                        "id":"410823000000",
                        "label":"武陟县",
                        "full_label":"河南省焦作市武陟县"
                    },
                    {
                        "id":"410825000000",
                        "label":"温县",
                        "full_label":"河南省焦作市温县"
                    },
                    {
                        "id":"410882000000",
                        "label":"沁阳市",
                        "full_label":"河南省焦作市沁阳市"
                    },
                    {
                        "id":"410883000000",
                        "label":"孟州市",
                        "full_label":"河南省焦作市孟州市"
                    }
                ],
                "full_label":"河南省焦作市"
            },
            {
                "id":"410900000000",
                "label":"濮阳市",
                "children":[
                    {
                        "id":"410902000000",
                        "label":"华龙区",
                        "full_label":"河南省濮阳市华龙区"
                    },
                    {
                        "id":"410922000000",
                        "label":"清丰县",
                        "full_label":"河南省濮阳市清丰县"
                    },
                    {
                        "id":"410923000000",
                        "label":"南乐县",
                        "full_label":"河南省濮阳市南乐县"
                    },
                    {
                        "id":"410926000000",
                        "label":"范县",
                        "full_label":"河南省濮阳市范县"
                    },
                    {
                        "id":"410927000000",
                        "label":"台前县",
                        "full_label":"河南省濮阳市台前县"
                    },
                    {
                        "id":"410928000000",
                        "label":"濮阳县",
                        "full_label":"河南省濮阳市濮阳县"
                    }
                ],
                "full_label":"河南省濮阳市"
            },
            {
                "id":"411000000000",
                "label":"许昌市",
                "children":[
                    {
                        "id":"411002000000",
                        "label":"魏都区",
                        "full_label":"河南省许昌市魏都区"
                    },
                    {
                        "id":"411003000000",
                        "label":"建安区",
                        "full_label":"河南省许昌市建安区"
                    },
                    {
                        "id":"411024000000",
                        "label":"鄢陵县",
                        "full_label":"河南省许昌市鄢陵县"
                    },
                    {
                        "id":"411025000000",
                        "label":"襄城县",
                        "full_label":"河南省许昌市襄城县"
                    },
                    {
                        "id":"411081000000",
                        "label":"禹州市",
                        "full_label":"河南省许昌市禹州市"
                    },
                    {
                        "id":"411082000000",
                        "label":"长葛市",
                        "full_label":"河南省许昌市长葛市"
                    }
                ],
                "full_label":"河南省许昌市"
            },
            {
                "id":"411100000000",
                "label":"漯河市",
                "children":[
                    {
                        "id":"411102000000",
                        "label":"源汇区",
                        "full_label":"河南省漯河市源汇区"
                    },
                    {
                        "id":"411103000000",
                        "label":"郾城区",
                        "full_label":"河南省漯河市郾城区"
                    },
                    {
                        "id":"411104000000",
                        "label":"召陵区",
                        "full_label":"河南省漯河市召陵区"
                    },
                    {
                        "id":"411121000000",
                        "label":"舞阳县",
                        "full_label":"河南省漯河市舞阳县"
                    },
                    {
                        "id":"411122000000",
                        "label":"临颍县",
                        "full_label":"河南省漯河市临颍县"
                    }
                ],
                "full_label":"河南省漯河市"
            },
            {
                "id":"411200000000",
                "label":"三门峡市",
                "children":[
                    {
                        "id":"411202000000",
                        "label":"湖滨区",
                        "full_label":"河南省三门峡市湖滨区"
                    },
                    {
                        "id":"411203000000",
                        "label":"陕州区",
                        "full_label":"河南省三门峡市陕州区"
                    },
                    {
                        "id":"411221000000",
                        "label":"渑池县",
                        "full_label":"河南省三门峡市渑池县"
                    },
                    {
                        "id":"411224000000",
                        "label":"卢氏县",
                        "full_label":"河南省三门峡市卢氏县"
                    },
                    {
                        "id":"411281000000",
                        "label":"义马市",
                        "full_label":"河南省三门峡市义马市"
                    },
                    {
                        "id":"411282000000",
                        "label":"灵宝市",
                        "full_label":"河南省三门峡市灵宝市"
                    }
                ],
                "full_label":"河南省三门峡市"
            },
            {
                "id":"411300000000",
                "label":"南阳市",
                "children":[
                    {
                        "id":"411302000000",
                        "label":"宛城区",
                        "full_label":"河南省南阳市宛城区"
                    },
                    {
                        "id":"411303000000",
                        "label":"卧龙区",
                        "full_label":"河南省南阳市卧龙区"
                    },
                    {
                        "id":"411321000000",
                        "label":"南召县",
                        "full_label":"河南省南阳市南召县"
                    },
                    {
                        "id":"411322000000",
                        "label":"方城县",
                        "full_label":"河南省南阳市方城县"
                    },
                    {
                        "id":"411323000000",
                        "label":"西峡县",
                        "full_label":"河南省南阳市西峡县"
                    },
                    {
                        "id":"411324000000",
                        "label":"镇平县",
                        "full_label":"河南省南阳市镇平县"
                    },
                    {
                        "id":"411325000000",
                        "label":"内乡县",
                        "full_label":"河南省南阳市内乡县"
                    },
                    {
                        "id":"411326000000",
                        "label":"淅川县",
                        "full_label":"河南省南阳市淅川县"
                    },
                    {
                        "id":"411327000000",
                        "label":"社旗县",
                        "full_label":"河南省南阳市社旗县"
                    },
                    {
                        "id":"411328000000",
                        "label":"唐河县",
                        "full_label":"河南省南阳市唐河县"
                    },
                    {
                        "id":"411329000000",
                        "label":"新野县",
                        "full_label":"河南省南阳市新野县"
                    },
                    {
                        "id":"411330000000",
                        "label":"桐柏县",
                        "full_label":"河南省南阳市桐柏县"
                    },
                    {
                        "id":"411381000000",
                        "label":"邓州市",
                        "full_label":"河南省南阳市邓州市"
                    }
                ],
                "full_label":"河南省南阳市"
            },
            {
                "id":"411400000000",
                "label":"商丘市",
                "children":[
                    {
                        "id":"411402000000",
                        "label":"梁园区",
                        "full_label":"河南省商丘市梁园区"
                    },
                    {
                        "id":"411403000000",
                        "label":"睢阳区",
                        "full_label":"河南省商丘市睢阳区"
                    },
                    {
                        "id":"411421000000",
                        "label":"民权县",
                        "full_label":"河南省商丘市民权县"
                    },
                    {
                        "id":"411422000000",
                        "label":"睢县",
                        "full_label":"河南省商丘市睢县"
                    },
                    {
                        "id":"411423000000",
                        "label":"宁陵县",
                        "full_label":"河南省商丘市宁陵县"
                    },
                    {
                        "id":"411424000000",
                        "label":"柘城县",
                        "full_label":"河南省商丘市柘城县"
                    },
                    {
                        "id":"411425000000",
                        "label":"虞城县",
                        "full_label":"河南省商丘市虞城县"
                    },
                    {
                        "id":"411426000000",
                        "label":"夏邑县",
                        "full_label":"河南省商丘市夏邑县"
                    },
                    {
                        "id":"411481000000",
                        "label":"永城市",
                        "full_label":"河南省商丘市永城市"
                    }
                ],
                "full_label":"河南省商丘市"
            },
            {
                "id":"411500000000",
                "label":"信阳市",
                "children":[
                    {
                        "id":"411502000000",
                        "label":"浉河区",
                        "full_label":"河南省信阳市浉河区"
                    },
                    {
                        "id":"411503000000",
                        "label":"平桥区",
                        "full_label":"河南省信阳市平桥区"
                    },
                    {
                        "id":"411521000000",
                        "label":"罗山县",
                        "full_label":"河南省信阳市罗山县"
                    },
                    {
                        "id":"411522000000",
                        "label":"光山县",
                        "full_label":"河南省信阳市光山县"
                    },
                    {
                        "id":"411523000000",
                        "label":"新县",
                        "full_label":"河南省信阳市新县"
                    },
                    {
                        "id":"411524000000",
                        "label":"商城县",
                        "full_label":"河南省信阳市商城县"
                    },
                    {
                        "id":"411525000000",
                        "label":"固始县",
                        "full_label":"河南省信阳市固始县"
                    },
                    {
                        "id":"411526000000",
                        "label":"潢川县",
                        "full_label":"河南省信阳市潢川县"
                    },
                    {
                        "id":"411527000000",
                        "label":"淮滨县",
                        "full_label":"河南省信阳市淮滨县"
                    },
                    {
                        "id":"411528000000",
                        "label":"息县",
                        "full_label":"河南省信阳市息县"
                    }
                ],
                "full_label":"河南省信阳市"
            },
            {
                "id":"411600000000",
                "label":"周口市",
                "children":[
                    {
                        "id":"411602000000",
                        "label":"川汇区",
                        "full_label":"河南省周口市川汇区"
                    },
                    {
                        "id":"411603000000",
                        "label":"淮阳区",
                        "full_label":"河南省周口市淮阳区"
                    },
                    {
                        "id":"411621000000",
                        "label":"扶沟县",
                        "full_label":"河南省周口市扶沟县"
                    },
                    {
                        "id":"411622000000",
                        "label":"西华县",
                        "full_label":"河南省周口市西华县"
                    },
                    {
                        "id":"411623000000",
                        "label":"商水县",
                        "full_label":"河南省周口市商水县"
                    },
                    {
                        "id":"411624000000",
                        "label":"沈丘县",
                        "full_label":"河南省周口市沈丘县"
                    },
                    {
                        "id":"411625000000",
                        "label":"郸城县",
                        "full_label":"河南省周口市郸城县"
                    },
                    {
                        "id":"411627000000",
                        "label":"太康县",
                        "full_label":"河南省周口市太康县"
                    },
                    {
                        "id":"411628000000",
                        "label":"鹿邑县",
                        "full_label":"河南省周口市鹿邑县"
                    },
                    {
                        "id":"411681000000",
                        "label":"项城市",
                        "full_label":"河南省周口市项城市"
                    }
                ],
                "full_label":"河南省周口市"
            },
            {
                "id":"411700000000",
                "label":"驻马店市",
                "children":[
                    {
                        "id":"411702000000",
                        "label":"驿城区",
                        "full_label":"河南省驻马店市驿城区"
                    },
                    {
                        "id":"411721000000",
                        "label":"西平县",
                        "full_label":"河南省驻马店市西平县"
                    },
                    {
                        "id":"411722000000",
                        "label":"上蔡县",
                        "full_label":"河南省驻马店市上蔡县"
                    },
                    {
                        "id":"411723000000",
                        "label":"平舆县",
                        "full_label":"河南省驻马店市平舆县"
                    },
                    {
                        "id":"411724000000",
                        "label":"正阳县",
                        "full_label":"河南省驻马店市正阳县"
                    },
                    {
                        "id":"411725000000",
                        "label":"确山县",
                        "full_label":"河南省驻马店市确山县"
                    },
                    {
                        "id":"411726000000",
                        "label":"泌阳县",
                        "full_label":"河南省驻马店市泌阳县"
                    },
                    {
                        "id":"411727000000",
                        "label":"汝南县",
                        "full_label":"河南省驻马店市汝南县"
                    },
                    {
                        "id":"411728000000",
                        "label":"遂平县",
                        "full_label":"河南省驻马店市遂平县"
                    },
                    {
                        "id":"411729000000",
                        "label":"新蔡县",
                        "full_label":"河南省驻马店市新蔡县"
                    }
                ],
                "full_label":"河南省驻马店市"
            },
            {
                "id":"419001000000",
                "label":"济源市",
                "full_label":"河南省济源市"
            }
        ],
        "full_label":"河南省"
    },
    {
        "id":"420000000000",
        "label":"湖北省",
        "children":[
            {
                "id":"420100000000",
                "label":"武汉市",
                "children":[
                    {
                        "id":"420102000000",
                        "label":"江岸区",
                        "full_label":"湖北省武汉市江岸区"
                    },
                    {
                        "id":"420103000000",
                        "label":"江汉区",
                        "full_label":"湖北省武汉市江汉区"
                    },
                    {
                        "id":"420104000000",
                        "label":"硚口区",
                        "full_label":"湖北省武汉市硚口区"
                    },
                    {
                        "id":"420105000000",
                        "label":"汉阳区",
                        "full_label":"湖北省武汉市汉阳区"
                    },
                    {
                        "id":"420106000000",
                        "label":"武昌区",
                        "full_label":"湖北省武汉市武昌区"
                    },
                    {
                        "id":"420107000000",
                        "label":"青山区",
                        "full_label":"湖北省武汉市青山区"
                    },
                    {
                        "id":"420111000000",
                        "label":"洪山区",
                        "full_label":"湖北省武汉市洪山区"
                    },
                    {
                        "id":"420112000000",
                        "label":"东西湖区",
                        "full_label":"湖北省武汉市东西湖区"
                    },
                    {
                        "id":"420113000000",
                        "label":"汉南区",
                        "full_label":"湖北省武汉市汉南区"
                    },
                    {
                        "id":"420114000000",
                        "label":"蔡甸区",
                        "full_label":"湖北省武汉市蔡甸区"
                    },
                    {
                        "id":"420115000000",
                        "label":"江夏区",
                        "full_label":"湖北省武汉市江夏区"
                    },
                    {
                        "id":"420116000000",
                        "label":"黄陂区",
                        "full_label":"湖北省武汉市黄陂区"
                    },
                    {
                        "id":"420117000000",
                        "label":"新洲区",
                        "full_label":"湖北省武汉市新洲区"
                    }
                ],
                "full_label":"湖北省武汉市"
            },
            {
                "id":"420200000000",
                "label":"黄石市",
                "children":[
                    {
                        "id":"420202000000",
                        "label":"黄石港区",
                        "full_label":"湖北省黄石市黄石港区"
                    },
                    {
                        "id":"420203000000",
                        "label":"西塞山区",
                        "full_label":"湖北省黄石市西塞山区"
                    },
                    {
                        "id":"420204000000",
                        "label":"下陆区",
                        "full_label":"湖北省黄石市下陆区"
                    },
                    {
                        "id":"420205000000",
                        "label":"铁山区",
                        "full_label":"湖北省黄石市铁山区"
                    },
                    {
                        "id":"420222000000",
                        "label":"阳新县",
                        "full_label":"湖北省黄石市阳新县"
                    },
                    {
                        "id":"420281000000",
                        "label":"大冶市",
                        "full_label":"湖北省黄石市大冶市"
                    }
                ],
                "full_label":"湖北省黄石市"
            },
            {
                "id":"420300000000",
                "label":"十堰市",
                "children":[
                    {
                        "id":"420302000000",
                        "label":"茅箭区",
                        "full_label":"湖北省十堰市茅箭区"
                    },
                    {
                        "id":"420303000000",
                        "label":"张湾区",
                        "full_label":"湖北省十堰市张湾区"
                    },
                    {
                        "id":"420304000000",
                        "label":"郧阳区",
                        "full_label":"湖北省十堰市郧阳区"
                    },
                    {
                        "id":"420322000000",
                        "label":"郧西县",
                        "full_label":"湖北省十堰市郧西县"
                    },
                    {
                        "id":"420323000000",
                        "label":"竹山县",
                        "full_label":"湖北省十堰市竹山县"
                    },
                    {
                        "id":"420324000000",
                        "label":"竹溪县",
                        "full_label":"湖北省十堰市竹溪县"
                    },
                    {
                        "id":"420325000000",
                        "label":"房县",
                        "full_label":"湖北省十堰市房县"
                    },
                    {
                        "id":"420381000000",
                        "label":"丹江口市",
                        "full_label":"湖北省十堰市丹江口市"
                    }
                ],
                "full_label":"湖北省十堰市"
            },
            {
                "id":"420500000000",
                "label":"宜昌市",
                "children":[
                    {
                        "id":"420502000000",
                        "label":"西陵区",
                        "full_label":"湖北省宜昌市西陵区"
                    },
                    {
                        "id":"420503000000",
                        "label":"伍家岗区",
                        "full_label":"湖北省宜昌市伍家岗区"
                    },
                    {
                        "id":"420504000000",
                        "label":"点军区",
                        "full_label":"湖北省宜昌市点军区"
                    },
                    {
                        "id":"420505000000",
                        "label":"猇亭区",
                        "full_label":"湖北省宜昌市猇亭区"
                    },
                    {
                        "id":"420506000000",
                        "label":"夷陵区",
                        "full_label":"湖北省宜昌市夷陵区"
                    },
                    {
                        "id":"420525000000",
                        "label":"远安县",
                        "full_label":"湖北省宜昌市远安县"
                    },
                    {
                        "id":"420526000000",
                        "label":"兴山县",
                        "full_label":"湖北省宜昌市兴山县"
                    },
                    {
                        "id":"420527000000",
                        "label":"秭归县",
                        "full_label":"湖北省宜昌市秭归县"
                    },
                    {
                        "id":"420528000000",
                        "label":"长阳土家族自治县",
                        "full_label":"湖北省宜昌市长阳土家族自治县"
                    },
                    {
                        "id":"420529000000",
                        "label":"五峰土家族自治县",
                        "full_label":"湖北省宜昌市五峰土家族自治县"
                    },
                    {
                        "id":"420581000000",
                        "label":"宜都市",
                        "full_label":"湖北省宜昌市宜都市"
                    },
                    {
                        "id":"420582000000",
                        "label":"当阳市",
                        "full_label":"湖北省宜昌市当阳市"
                    },
                    {
                        "id":"420583000000",
                        "label":"枝江市",
                        "full_label":"湖北省宜昌市枝江市"
                    }
                ],
                "full_label":"湖北省宜昌市"
            },
            {
                "id":"420600000000",
                "label":"襄阳市",
                "children":[
                    {
                        "id":"420602000000",
                        "label":"襄城区",
                        "full_label":"湖北省襄阳市襄城区"
                    },
                    {
                        "id":"420606000000",
                        "label":"樊城区",
                        "full_label":"湖北省襄阳市樊城区"
                    },
                    {
                        "id":"420607000000",
                        "label":"襄州区",
                        "full_label":"湖北省襄阳市襄州区"
                    },
                    {
                        "id":"420624000000",
                        "label":"南漳县",
                        "full_label":"湖北省襄阳市南漳县"
                    },
                    {
                        "id":"420625000000",
                        "label":"谷城县",
                        "full_label":"湖北省襄阳市谷城县"
                    },
                    {
                        "id":"420626000000",
                        "label":"保康县",
                        "full_label":"湖北省襄阳市保康县"
                    },
                    {
                        "id":"420682000000",
                        "label":"老河口市",
                        "full_label":"湖北省襄阳市老河口市"
                    },
                    {
                        "id":"420683000000",
                        "label":"枣阳市",
                        "full_label":"湖北省襄阳市枣阳市"
                    },
                    {
                        "id":"420684000000",
                        "label":"宜城市",
                        "full_label":"湖北省襄阳市宜城市"
                    }
                ],
                "full_label":"湖北省襄阳市"
            },
            {
                "id":"420700000000",
                "label":"鄂州市",
                "children":[
                    {
                        "id":"420702000000",
                        "label":"梁子湖区",
                        "full_label":"湖北省鄂州市梁子湖区"
                    },
                    {
                        "id":"420703000000",
                        "label":"华容区",
                        "full_label":"湖北省鄂州市华容区"
                    },
                    {
                        "id":"420704000000",
                        "label":"鄂城区",
                        "full_label":"湖北省鄂州市鄂城区"
                    }
                ],
                "full_label":"湖北省鄂州市"
            },
            {
                "id":"420800000000",
                "label":"荆门市",
                "children":[
                    {
                        "id":"420802000000",
                        "label":"东宝区",
                        "full_label":"湖北省荆门市东宝区"
                    },
                    {
                        "id":"420804000000",
                        "label":"掇刀区",
                        "full_label":"湖北省荆门市掇刀区"
                    },
                    {
                        "id":"420822000000",
                        "label":"沙洋县",
                        "full_label":"湖北省荆门市沙洋县"
                    },
                    {
                        "id":"420881000000",
                        "label":"钟祥市",
                        "full_label":"湖北省荆门市钟祥市"
                    },
                    {
                        "id":"420882000000",
                        "label":"京山市",
                        "full_label":"湖北省荆门市京山市"
                    }
                ],
                "full_label":"湖北省荆门市"
            },
            {
                "id":"420900000000",
                "label":"孝感市",
                "children":[
                    {
                        "id":"420902000000",
                        "label":"孝南区",
                        "full_label":"湖北省孝感市孝南区"
                    },
                    {
                        "id":"420921000000",
                        "label":"孝昌县",
                        "full_label":"湖北省孝感市孝昌县"
                    },
                    {
                        "id":"420922000000",
                        "label":"大悟县",
                        "full_label":"湖北省孝感市大悟县"
                    },
                    {
                        "id":"420923000000",
                        "label":"云梦县",
                        "full_label":"湖北省孝感市云梦县"
                    },
                    {
                        "id":"420981000000",
                        "label":"应城市",
                        "full_label":"湖北省孝感市应城市"
                    },
                    {
                        "id":"420982000000",
                        "label":"安陆市",
                        "full_label":"湖北省孝感市安陆市"
                    },
                    {
                        "id":"420984000000",
                        "label":"汉川市",
                        "full_label":"湖北省孝感市汉川市"
                    }
                ],
                "full_label":"湖北省孝感市"
            },
            {
                "id":"421000000000",
                "label":"荆州市",
                "children":[
                    {
                        "id":"421002000000",
                        "label":"沙市区",
                        "full_label":"湖北省荆州市沙市区"
                    },
                    {
                        "id":"421003000000",
                        "label":"荆州区",
                        "full_label":"湖北省荆州市荆州区"
                    },
                    {
                        "id":"421022000000",
                        "label":"公安县",
                        "full_label":"湖北省荆州市公安县"
                    },
                    {
                        "id":"421024000000",
                        "label":"江陵县",
                        "full_label":"湖北省荆州市江陵县"
                    },
                    {
                        "id":"421081000000",
                        "label":"石首市",
                        "full_label":"湖北省荆州市石首市"
                    },
                    {
                        "id":"421083000000",
                        "label":"洪湖市",
                        "full_label":"湖北省荆州市洪湖市"
                    },
                    {
                        "id":"421087000000",
                        "label":"松滋市",
                        "full_label":"湖北省荆州市松滋市"
                    },
                    {
                        "id":"421088000000",
                        "label":"监利市",
                        "full_label":"湖北省荆州市监利市"
                    }
                ],
                "full_label":"湖北省荆州市"
            },
            {
                "id":"421100000000",
                "label":"黄冈市",
                "children":[
                    {
                        "id":"421102000000",
                        "label":"黄州区",
                        "full_label":"湖北省黄冈市黄州区"
                    },
                    {
                        "id":"421121000000",
                        "label":"团风县",
                        "full_label":"湖北省黄冈市团风县"
                    },
                    {
                        "id":"421122000000",
                        "label":"红安县",
                        "full_label":"湖北省黄冈市红安县"
                    },
                    {
                        "id":"421123000000",
                        "label":"罗田县",
                        "full_label":"湖北省黄冈市罗田县"
                    },
                    {
                        "id":"421124000000",
                        "label":"英山县",
                        "full_label":"湖北省黄冈市英山县"
                    },
                    {
                        "id":"421125000000",
                        "label":"浠水县",
                        "full_label":"湖北省黄冈市浠水县"
                    },
                    {
                        "id":"421126000000",
                        "label":"蕲春县",
                        "full_label":"湖北省黄冈市蕲春县"
                    },
                    {
                        "id":"421127000000",
                        "label":"黄梅县",
                        "full_label":"湖北省黄冈市黄梅县"
                    },
                    {
                        "id":"421181000000",
                        "label":"麻城市",
                        "full_label":"湖北省黄冈市麻城市"
                    },
                    {
                        "id":"421182000000",
                        "label":"武穴市",
                        "full_label":"湖北省黄冈市武穴市"
                    }
                ],
                "full_label":"湖北省黄冈市"
            },
            {
                "id":"421200000000",
                "label":"咸宁市",
                "children":[
                    {
                        "id":"421202000000",
                        "label":"咸安区",
                        "full_label":"湖北省咸宁市咸安区"
                    },
                    {
                        "id":"421221000000",
                        "label":"嘉鱼县",
                        "full_label":"湖北省咸宁市嘉鱼县"
                    },
                    {
                        "id":"421222000000",
                        "label":"通城县",
                        "full_label":"湖北省咸宁市通城县"
                    },
                    {
                        "id":"421223000000",
                        "label":"崇阳县",
                        "full_label":"湖北省咸宁市崇阳县"
                    },
                    {
                        "id":"421224000000",
                        "label":"通山县",
                        "full_label":"湖北省咸宁市通山县"
                    },
                    {
                        "id":"421281000000",
                        "label":"赤壁市",
                        "full_label":"湖北省咸宁市赤壁市"
                    }
                ],
                "full_label":"湖北省咸宁市"
            },
            {
                "id":"421300000000",
                "label":"随州市",
                "children":[
                    {
                        "id":"421303000000",
                        "label":"曾都区",
                        "full_label":"湖北省随州市曾都区"
                    },
                    {
                        "id":"421321000000",
                        "label":"随县",
                        "full_label":"湖北省随州市随县"
                    },
                    {
                        "id":"421381000000",
                        "label":"广水市",
                        "full_label":"湖北省随州市广水市"
                    }
                ],
                "full_label":"湖北省随州市"
            },
            {
                "id":"422800000000",
                "label":"恩施土家族苗族自治州",
                "children":[
                    {
                        "id":"422801000000",
                        "label":"恩施市",
                        "full_label":"湖北省恩施土家族苗族自治州恩施市"
                    },
                    {
                        "id":"422802000000",
                        "label":"利川市",
                        "full_label":"湖北省恩施土家族苗族自治州利川市"
                    },
                    {
                        "id":"422822000000",
                        "label":"建始县",
                        "full_label":"湖北省恩施土家族苗族自治州建始县"
                    },
                    {
                        "id":"422823000000",
                        "label":"巴东县",
                        "full_label":"湖北省恩施土家族苗族自治州巴东县"
                    },
                    {
                        "id":"422825000000",
                        "label":"宣恩县",
                        "full_label":"湖北省恩施土家族苗族自治州宣恩县"
                    },
                    {
                        "id":"422826000000",
                        "label":"咸丰县",
                        "full_label":"湖北省恩施土家族苗族自治州咸丰县"
                    },
                    {
                        "id":"422827000000",
                        "label":"来凤县",
                        "full_label":"湖北省恩施土家族苗族自治州来凤县"
                    },
                    {
                        "id":"422828000000",
                        "label":"鹤峰县",
                        "full_label":"湖北省恩施土家族苗族自治州鹤峰县"
                    }
                ],
                "full_label":"湖北省恩施土家族苗族自治州"
            },
            {
                "id":"429004000000",
                "label":"仙桃市",
                "full_label":"湖北省仙桃市"
            },
            {
                "id":"429005000000",
                "label":"潜江市",
                "full_label":"湖北省潜江市"
            },
            {
                "id":"429006000000",
                "label":"天门市",
                "full_label":"湖北省天门市"
            },
            {
                "id":"429021000000",
                "label":"神农架林区",
                "full_label":"湖北省神农架林区"
            }
        ],
        "full_label":"湖北省"
    },
    {
        "id":"430000000000",
        "label":"湖南省",
        "children":[
            {
                "id":"430100000000",
                "label":"长沙市",
                "children":[
                    {
                        "id":"430102000000",
                        "label":"芙蓉区",
                        "full_label":"湖南省长沙市芙蓉区"
                    },
                    {
                        "id":"430103000000",
                        "label":"天心区",
                        "full_label":"湖南省长沙市天心区"
                    },
                    {
                        "id":"430104000000",
                        "label":"岳麓区",
                        "full_label":"湖南省长沙市岳麓区"
                    },
                    {
                        "id":"430105000000",
                        "label":"开福区",
                        "full_label":"湖南省长沙市开福区"
                    },
                    {
                        "id":"430111000000",
                        "label":"雨花区",
                        "full_label":"湖南省长沙市雨花区"
                    },
                    {
                        "id":"430112000000",
                        "label":"望城区",
                        "full_label":"湖南省长沙市望城区"
                    },
                    {
                        "id":"430121000000",
                        "label":"长沙县",
                        "full_label":"湖南省长沙市长沙县"
                    },
                    {
                        "id":"430181000000",
                        "label":"浏阳市",
                        "full_label":"湖南省长沙市浏阳市"
                    },
                    {
                        "id":"430182000000",
                        "label":"宁乡市",
                        "full_label":"湖南省长沙市宁乡市"
                    }
                ],
                "full_label":"湖南省长沙市"
            },
            {
                "id":"430200000000",
                "label":"株洲市",
                "children":[
                    {
                        "id":"430202000000",
                        "label":"荷塘区",
                        "full_label":"湖南省株洲市荷塘区"
                    },
                    {
                        "id":"430203000000",
                        "label":"芦淞区",
                        "full_label":"湖南省株洲市芦淞区"
                    },
                    {
                        "id":"430204000000",
                        "label":"石峰区",
                        "full_label":"湖南省株洲市石峰区"
                    },
                    {
                        "id":"430211000000",
                        "label":"天元区",
                        "full_label":"湖南省株洲市天元区"
                    },
                    {
                        "id":"430212000000",
                        "label":"渌口区",
                        "full_label":"湖南省株洲市渌口区"
                    },
                    {
                        "id":"430223000000",
                        "label":"攸县",
                        "full_label":"湖南省株洲市攸县"
                    },
                    {
                        "id":"430224000000",
                        "label":"茶陵县",
                        "full_label":"湖南省株洲市茶陵县"
                    },
                    {
                        "id":"430225000000",
                        "label":"炎陵县",
                        "full_label":"湖南省株洲市炎陵县"
                    },
                    {
                        "id":"430281000000",
                        "label":"醴陵市",
                        "full_label":"湖南省株洲市醴陵市"
                    }
                ],
                "full_label":"湖南省株洲市"
            },
            {
                "id":"430300000000",
                "label":"湘潭市",
                "children":[
                    {
                        "id":"430302000000",
                        "label":"雨湖区",
                        "full_label":"湖南省湘潭市雨湖区"
                    },
                    {
                        "id":"430304000000",
                        "label":"岳塘区",
                        "full_label":"湖南省湘潭市岳塘区"
                    },
                    {
                        "id":"430321000000",
                        "label":"湘潭县",
                        "full_label":"湖南省湘潭市湘潭县"
                    },
                    {
                        "id":"430381000000",
                        "label":"湘乡市",
                        "full_label":"湖南省湘潭市湘乡市"
                    },
                    {
                        "id":"430382000000",
                        "label":"韶山市",
                        "full_label":"湖南省湘潭市韶山市"
                    }
                ],
                "full_label":"湖南省湘潭市"
            },
            {
                "id":"430400000000",
                "label":"衡阳市",
                "children":[
                    {
                        "id":"430405000000",
                        "label":"珠晖区",
                        "full_label":"湖南省衡阳市珠晖区"
                    },
                    {
                        "id":"430406000000",
                        "label":"雁峰区",
                        "full_label":"湖南省衡阳市雁峰区"
                    },
                    {
                        "id":"430407000000",
                        "label":"石鼓区",
                        "full_label":"湖南省衡阳市石鼓区"
                    },
                    {
                        "id":"430408000000",
                        "label":"蒸湘区",
                        "full_label":"湖南省衡阳市蒸湘区"
                    },
                    {
                        "id":"430412000000",
                        "label":"南岳区",
                        "full_label":"湖南省衡阳市南岳区"
                    },
                    {
                        "id":"430421000000",
                        "label":"衡阳县",
                        "full_label":"湖南省衡阳市衡阳县"
                    },
                    {
                        "id":"430422000000",
                        "label":"衡南县",
                        "full_label":"湖南省衡阳市衡南县"
                    },
                    {
                        "id":"430423000000",
                        "label":"衡山县",
                        "full_label":"湖南省衡阳市衡山县"
                    },
                    {
                        "id":"430424000000",
                        "label":"衡东县",
                        "full_label":"湖南省衡阳市衡东县"
                    },
                    {
                        "id":"430426000000",
                        "label":"祁东县",
                        "full_label":"湖南省衡阳市祁东县"
                    },
                    {
                        "id":"430481000000",
                        "label":"耒阳市",
                        "full_label":"湖南省衡阳市耒阳市"
                    },
                    {
                        "id":"430482000000",
                        "label":"常宁市",
                        "full_label":"湖南省衡阳市常宁市"
                    }
                ],
                "full_label":"湖南省衡阳市"
            },
            {
                "id":"430500000000",
                "label":"邵阳市",
                "children":[
                    {
                        "id":"430502000000",
                        "label":"双清区",
                        "full_label":"湖南省邵阳市双清区"
                    },
                    {
                        "id":"430503000000",
                        "label":"大祥区",
                        "full_label":"湖南省邵阳市大祥区"
                    },
                    {
                        "id":"430511000000",
                        "label":"北塔区",
                        "full_label":"湖南省邵阳市北塔区"
                    },
                    {
                        "id":"430522000000",
                        "label":"新邵县",
                        "full_label":"湖南省邵阳市新邵县"
                    },
                    {
                        "id":"430523000000",
                        "label":"邵阳县",
                        "full_label":"湖南省邵阳市邵阳县"
                    },
                    {
                        "id":"430524000000",
                        "label":"隆回县",
                        "full_label":"湖南省邵阳市隆回县"
                    },
                    {
                        "id":"430525000000",
                        "label":"洞口县",
                        "full_label":"湖南省邵阳市洞口县"
                    },
                    {
                        "id":"430527000000",
                        "label":"绥宁县",
                        "full_label":"湖南省邵阳市绥宁县"
                    },
                    {
                        "id":"430528000000",
                        "label":"新宁县",
                        "full_label":"湖南省邵阳市新宁县"
                    },
                    {
                        "id":"430529000000",
                        "label":"城步苗族自治县",
                        "full_label":"湖南省邵阳市城步苗族自治县"
                    },
                    {
                        "id":"430581000000",
                        "label":"武冈市",
                        "full_label":"湖南省邵阳市武冈市"
                    },
                    {
                        "id":"430582000000",
                        "label":"邵东市",
                        "full_label":"湖南省邵阳市邵东市"
                    }
                ],
                "full_label":"湖南省邵阳市"
            },
            {
                "id":"430600000000",
                "label":"岳阳市",
                "children":[
                    {
                        "id":"430602000000",
                        "label":"岳阳楼区",
                        "full_label":"湖南省岳阳市岳阳楼区"
                    },
                    {
                        "id":"430603000000",
                        "label":"云溪区",
                        "full_label":"湖南省岳阳市云溪区"
                    },
                    {
                        "id":"430611000000",
                        "label":"君山区",
                        "full_label":"湖南省岳阳市君山区"
                    },
                    {
                        "id":"430621000000",
                        "label":"岳阳县",
                        "full_label":"湖南省岳阳市岳阳县"
                    },
                    {
                        "id":"430623000000",
                        "label":"华容县",
                        "full_label":"湖南省岳阳市华容县"
                    },
                    {
                        "id":"430624000000",
                        "label":"湘阴县",
                        "full_label":"湖南省岳阳市湘阴县"
                    },
                    {
                        "id":"430626000000",
                        "label":"平江县",
                        "full_label":"湖南省岳阳市平江县"
                    },
                    {
                        "id":"430681000000",
                        "label":"汨罗市",
                        "full_label":"湖南省岳阳市汨罗市"
                    },
                    {
                        "id":"430682000000",
                        "label":"临湘市",
                        "full_label":"湖南省岳阳市临湘市"
                    }
                ],
                "full_label":"湖南省岳阳市"
            },
            {
                "id":"430700000000",
                "label":"常德市",
                "children":[
                    {
                        "id":"430702000000",
                        "label":"武陵区",
                        "full_label":"湖南省常德市武陵区"
                    },
                    {
                        "id":"430703000000",
                        "label":"鼎城区",
                        "full_label":"湖南省常德市鼎城区"
                    },
                    {
                        "id":"430721000000",
                        "label":"安乡县",
                        "full_label":"湖南省常德市安乡县"
                    },
                    {
                        "id":"430722000000",
                        "label":"汉寿县",
                        "full_label":"湖南省常德市汉寿县"
                    },
                    {
                        "id":"430723000000",
                        "label":"澧县",
                        "full_label":"湖南省常德市澧县"
                    },
                    {
                        "id":"430724000000",
                        "label":"临澧县",
                        "full_label":"湖南省常德市临澧县"
                    },
                    {
                        "id":"430725000000",
                        "label":"桃源县",
                        "full_label":"湖南省常德市桃源县"
                    },
                    {
                        "id":"430726000000",
                        "label":"石门县",
                        "full_label":"湖南省常德市石门县"
                    },
                    {
                        "id":"430781000000",
                        "label":"津市市",
                        "full_label":"湖南省常德市津市市"
                    }
                ],
                "full_label":"湖南省常德市"
            },
            {
                "id":"430800000000",
                "label":"张家界市",
                "children":[
                    {
                        "id":"430802000000",
                        "label":"永定区",
                        "full_label":"湖南省张家界市永定区"
                    },
                    {
                        "id":"430811000000",
                        "label":"武陵源区",
                        "full_label":"湖南省张家界市武陵源区"
                    },
                    {
                        "id":"430821000000",
                        "label":"慈利县",
                        "full_label":"湖南省张家界市慈利县"
                    },
                    {
                        "id":"430822000000",
                        "label":"桑植县",
                        "full_label":"湖南省张家界市桑植县"
                    }
                ],
                "full_label":"湖南省张家界市"
            },
            {
                "id":"430900000000",
                "label":"益阳市",
                "children":[
                    {
                        "id":"430902000000",
                        "label":"资阳区",
                        "full_label":"湖南省益阳市资阳区"
                    },
                    {
                        "id":"430903000000",
                        "label":"赫山区",
                        "full_label":"湖南省益阳市赫山区"
                    },
                    {
                        "id":"430921000000",
                        "label":"南县",
                        "full_label":"湖南省益阳市南县"
                    },
                    {
                        "id":"430922000000",
                        "label":"桃江县",
                        "full_label":"湖南省益阳市桃江县"
                    },
                    {
                        "id":"430923000000",
                        "label":"安化县",
                        "full_label":"湖南省益阳市安化县"
                    },
                    {
                        "id":"430981000000",
                        "label":"沅江市",
                        "full_label":"湖南省益阳市沅江市"
                    }
                ],
                "full_label":"湖南省益阳市"
            },
            {
                "id":"431000000000",
                "label":"郴州市",
                "children":[
                    {
                        "id":"431002000000",
                        "label":"北湖区",
                        "full_label":"湖南省郴州市北湖区"
                    },
                    {
                        "id":"431003000000",
                        "label":"苏仙区",
                        "full_label":"湖南省郴州市苏仙区"
                    },
                    {
                        "id":"431021000000",
                        "label":"桂阳县",
                        "full_label":"湖南省郴州市桂阳县"
                    },
                    {
                        "id":"431022000000",
                        "label":"宜章县",
                        "full_label":"湖南省郴州市宜章县"
                    },
                    {
                        "id":"431023000000",
                        "label":"永兴县",
                        "full_label":"湖南省郴州市永兴县"
                    },
                    {
                        "id":"431024000000",
                        "label":"嘉禾县",
                        "full_label":"湖南省郴州市嘉禾县"
                    },
                    {
                        "id":"431025000000",
                        "label":"临武县",
                        "full_label":"湖南省郴州市临武县"
                    },
                    {
                        "id":"431026000000",
                        "label":"汝城县",
                        "full_label":"湖南省郴州市汝城县"
                    },
                    {
                        "id":"431027000000",
                        "label":"桂东县",
                        "full_label":"湖南省郴州市桂东县"
                    },
                    {
                        "id":"431028000000",
                        "label":"安仁县",
                        "full_label":"湖南省郴州市安仁县"
                    },
                    {
                        "id":"431081000000",
                        "label":"资兴市",
                        "full_label":"湖南省郴州市资兴市"
                    }
                ],
                "full_label":"湖南省郴州市"
            },
            {
                "id":"431100000000",
                "label":"永州市",
                "children":[
                    {
                        "id":"431102000000",
                        "label":"零陵区",
                        "full_label":"湖南省永州市零陵区"
                    },
                    {
                        "id":"431103000000",
                        "label":"冷水滩区",
                        "full_label":"湖南省永州市冷水滩区"
                    },
                    {
                        "id":"431122000000",
                        "label":"东安县",
                        "full_label":"湖南省永州市东安县"
                    },
                    {
                        "id":"431123000000",
                        "label":"双牌县",
                        "full_label":"湖南省永州市双牌县"
                    },
                    {
                        "id":"431124000000",
                        "label":"道县",
                        "full_label":"湖南省永州市道县"
                    },
                    {
                        "id":"431125000000",
                        "label":"江永县",
                        "full_label":"湖南省永州市江永县"
                    },
                    {
                        "id":"431126000000",
                        "label":"宁远县",
                        "full_label":"湖南省永州市宁远县"
                    },
                    {
                        "id":"431127000000",
                        "label":"蓝山县",
                        "full_label":"湖南省永州市蓝山县"
                    },
                    {
                        "id":"431128000000",
                        "label":"新田县",
                        "full_label":"湖南省永州市新田县"
                    },
                    {
                        "id":"431129000000",
                        "label":"江华瑶族自治县",
                        "full_label":"湖南省永州市江华瑶族自治县"
                    },
                    {
                        "id":"431181000000",
                        "label":"祁阳市",
                        "full_label":"湖南省永州市祁阳市"
                    }
                ],
                "full_label":"湖南省永州市"
            },
            {
                "id":"431200000000",
                "label":"怀化市",
                "children":[
                    {
                        "id":"431202000000",
                        "label":"鹤城区",
                        "full_label":"湖南省怀化市鹤城区"
                    },
                    {
                        "id":"431221000000",
                        "label":"中方县",
                        "full_label":"湖南省怀化市中方县"
                    },
                    {
                        "id":"431222000000",
                        "label":"沅陵县",
                        "full_label":"湖南省怀化市沅陵县"
                    },
                    {
                        "id":"431223000000",
                        "label":"辰溪县",
                        "full_label":"湖南省怀化市辰溪县"
                    },
                    {
                        "id":"431224000000",
                        "label":"溆浦县",
                        "full_label":"湖南省怀化市溆浦县"
                    },
                    {
                        "id":"431225000000",
                        "label":"会同县",
                        "full_label":"湖南省怀化市会同县"
                    },
                    {
                        "id":"431226000000",
                        "label":"麻阳苗族自治县",
                        "full_label":"湖南省怀化市麻阳苗族自治县"
                    },
                    {
                        "id":"431227000000",
                        "label":"新晃侗族自治县",
                        "full_label":"湖南省怀化市新晃侗族自治县"
                    },
                    {
                        "id":"431228000000",
                        "label":"芷江侗族自治县",
                        "full_label":"湖南省怀化市芷江侗族自治县"
                    },
                    {
                        "id":"431229000000",
                        "label":"靖州苗族侗族自治县",
                        "full_label":"湖南省怀化市靖州苗族侗族自治县"
                    },
                    {
                        "id":"431230000000",
                        "label":"通道侗族自治县",
                        "full_label":"湖南省怀化市通道侗族自治县"
                    },
                    {
                        "id":"431281000000",
                        "label":"洪江市",
                        "full_label":"湖南省怀化市洪江市"
                    }
                ],
                "full_label":"湖南省怀化市"
            },
            {
                "id":"431300000000",
                "label":"娄底市",
                "children":[
                    {
                        "id":"431302000000",
                        "label":"娄星区",
                        "full_label":"湖南省娄底市娄星区"
                    },
                    {
                        "id":"431321000000",
                        "label":"双峰县",
                        "full_label":"湖南省娄底市双峰县"
                    },
                    {
                        "id":"431322000000",
                        "label":"新化县",
                        "full_label":"湖南省娄底市新化县"
                    },
                    {
                        "id":"431381000000",
                        "label":"冷水江市",
                        "full_label":"湖南省娄底市冷水江市"
                    },
                    {
                        "id":"431382000000",
                        "label":"涟源市",
                        "full_label":"湖南省娄底市涟源市"
                    }
                ],
                "full_label":"湖南省娄底市"
            },
            {
                "id":"433100000000",
                "label":"湘西土家族苗族自治州",
                "children":[
                    {
                        "id":"433101000000",
                        "label":"吉首市",
                        "full_label":"湖南省湘西土家族苗族自治州吉首市"
                    },
                    {
                        "id":"433122000000",
                        "label":"泸溪县",
                        "full_label":"湖南省湘西土家族苗族自治州泸溪县"
                    },
                    {
                        "id":"433123000000",
                        "label":"凤凰县",
                        "full_label":"湖南省湘西土家族苗族自治州凤凰县"
                    },
                    {
                        "id":"433124000000",
                        "label":"花垣县",
                        "full_label":"湖南省湘西土家族苗族自治州花垣县"
                    },
                    {
                        "id":"433125000000",
                        "label":"保靖县",
                        "full_label":"湖南省湘西土家族苗族自治州保靖县"
                    },
                    {
                        "id":"433126000000",
                        "label":"古丈县",
                        "full_label":"湖南省湘西土家族苗族自治州古丈县"
                    },
                    {
                        "id":"433127000000",
                        "label":"永顺县",
                        "full_label":"湖南省湘西土家族苗族自治州永顺县"
                    },
                    {
                        "id":"433130000000",
                        "label":"龙山县",
                        "full_label":"湖南省湘西土家族苗族自治州龙山县"
                    }
                ],
                "full_label":"湖南省湘西土家族苗族自治州"
            }
        ],
        "full_label":"湖南省"
    },
    {
        "id":"440000000000",
        "label":"广东省",
        "children":[
            {
                "id":"440100000000",
                "label":"广州市",
                "children":[
                    {
                        "id":"440103000000",
                        "label":"荔湾区",
                        "full_label":"广东省广州市荔湾区"
                    },
                    {
                        "id":"440104000000",
                        "label":"越秀区",
                        "full_label":"广东省广州市越秀区"
                    },
                    {
                        "id":"440105000000",
                        "label":"海珠区",
                        "full_label":"广东省广州市海珠区"
                    },
                    {
                        "id":"440106000000",
                        "label":"天河区",
                        "full_label":"广东省广州市天河区"
                    },
                    {
                        "id":"440111000000",
                        "label":"白云区",
                        "full_label":"广东省广州市白云区"
                    },
                    {
                        "id":"440112000000",
                        "label":"黄埔区",
                        "full_label":"广东省广州市黄埔区"
                    },
                    {
                        "id":"440113000000",
                        "label":"番禺区",
                        "full_label":"广东省广州市番禺区"
                    },
                    {
                        "id":"440114000000",
                        "label":"花都区",
                        "full_label":"广东省广州市花都区"
                    },
                    {
                        "id":"440115000000",
                        "label":"南沙区",
                        "full_label":"广东省广州市南沙区"
                    },
                    {
                        "id":"440117000000",
                        "label":"从化区",
                        "full_label":"广东省广州市从化区"
                    },
                    {
                        "id":"440118000000",
                        "label":"增城区",
                        "full_label":"广东省广州市增城区"
                    }
                ],
                "full_label":"广东省广州市"
            },
            {
                "id":"440200000000",
                "label":"韶关市",
                "children":[
                    {
                        "id":"440203000000",
                        "label":"武江区",
                        "full_label":"广东省韶关市武江区"
                    },
                    {
                        "id":"440204000000",
                        "label":"浈江区",
                        "full_label":"广东省韶关市浈江区"
                    },
                    {
                        "id":"440205000000",
                        "label":"曲江区",
                        "full_label":"广东省韶关市曲江区"
                    },
                    {
                        "id":"440222000000",
                        "label":"始兴县",
                        "full_label":"广东省韶关市始兴县"
                    },
                    {
                        "id":"440224000000",
                        "label":"仁化县",
                        "full_label":"广东省韶关市仁化县"
                    },
                    {
                        "id":"440229000000",
                        "label":"翁源县",
                        "full_label":"广东省韶关市翁源县"
                    },
                    {
                        "id":"440232000000",
                        "label":"乳源瑶族自治县",
                        "full_label":"广东省韶关市乳源瑶族自治县"
                    },
                    {
                        "id":"440233000000",
                        "label":"新丰县",
                        "full_label":"广东省韶关市新丰县"
                    },
                    {
                        "id":"440281000000",
                        "label":"乐昌市",
                        "full_label":"广东省韶关市乐昌市"
                    },
                    {
                        "id":"440282000000",
                        "label":"南雄市",
                        "full_label":"广东省韶关市南雄市"
                    }
                ],
                "full_label":"广东省韶关市"
            },
            {
                "id":"440300000000",
                "label":"深圳市",
                "children":[
                    {
                        "id":"440303000000",
                        "label":"罗湖区",
                        "full_label":"广东省深圳市罗湖区"
                    },
                    {
                        "id":"440304000000",
                        "label":"福田区",
                        "full_label":"广东省深圳市福田区"
                    },
                    {
                        "id":"440305000000",
                        "label":"南山区",
                        "full_label":"广东省深圳市南山区"
                    },
                    {
                        "id":"440306000000",
                        "label":"宝安区",
                        "full_label":"广东省深圳市宝安区"
                    },
                    {
                        "id":"440307000000",
                        "label":"龙岗区",
                        "full_label":"广东省深圳市龙岗区"
                    },
                    {
                        "id":"440308000000",
                        "label":"盐田区",
                        "full_label":"广东省深圳市盐田区"
                    },
                    {
                        "id":"440309000000",
                        "label":"龙华区",
                        "full_label":"广东省深圳市龙华区"
                    },
                    {
                        "id":"440310000000",
                        "label":"坪山区",
                        "full_label":"广东省深圳市坪山区"
                    },
                    {
                        "id":"440311000000",
                        "label":"光明区",
                        "full_label":"广东省深圳市光明区"
                    }
                ],
                "full_label":"广东省深圳市"
            },
            {
                "id":"440400000000",
                "label":"珠海市",
                "children":[
                    {
                        "id":"440402000000",
                        "label":"香洲区",
                        "full_label":"广东省珠海市香洲区"
                    },
                    {
                        "id":"440403000000",
                        "label":"斗门区",
                        "full_label":"广东省珠海市斗门区"
                    },
                    {
                        "id":"440404000000",
                        "label":"金湾区",
                        "full_label":"广东省珠海市金湾区"
                    }
                ],
                "full_label":"广东省珠海市"
            },
            {
                "id":"440500000000",
                "label":"汕头市",
                "children":[
                    {
                        "id":"440507000000",
                        "label":"龙湖区",
                        "full_label":"广东省汕头市龙湖区"
                    },
                    {
                        "id":"440511000000",
                        "label":"金平区",
                        "full_label":"广东省汕头市金平区"
                    },
                    {
                        "id":"440512000000",
                        "label":"濠江区",
                        "full_label":"广东省汕头市濠江区"
                    },
                    {
                        "id":"440513000000",
                        "label":"潮阳区",
                        "full_label":"广东省汕头市潮阳区"
                    },
                    {
                        "id":"440514000000",
                        "label":"潮南区",
                        "full_label":"广东省汕头市潮南区"
                    },
                    {
                        "id":"440515000000",
                        "label":"澄海区",
                        "full_label":"广东省汕头市澄海区"
                    },
                    {
                        "id":"440523000000",
                        "label":"南澳县",
                        "full_label":"广东省汕头市南澳县"
                    }
                ],
                "full_label":"广东省汕头市"
            },
            {
                "id":"440600000000",
                "label":"佛山市",
                "children":[
                    {
                        "id":"440604000000",
                        "label":"禅城区",
                        "full_label":"广东省佛山市禅城区"
                    },
                    {
                        "id":"440605000000",
                        "label":"南海区",
                        "full_label":"广东省佛山市南海区"
                    },
                    {
                        "id":"440606000000",
                        "label":"顺德区",
                        "full_label":"广东省佛山市顺德区"
                    },
                    {
                        "id":"440607000000",
                        "label":"三水区",
                        "full_label":"广东省佛山市三水区"
                    },
                    {
                        "id":"440608000000",
                        "label":"高明区",
                        "full_label":"广东省佛山市高明区"
                    }
                ],
                "full_label":"广东省佛山市"
            },
            {
                "id":"440700000000",
                "label":"江门市",
                "children":[
                    {
                        "id":"440703000000",
                        "label":"蓬江区",
                        "full_label":"广东省江门市蓬江区"
                    },
                    {
                        "id":"440704000000",
                        "label":"江海区",
                        "full_label":"广东省江门市江海区"
                    },
                    {
                        "id":"440705000000",
                        "label":"新会区",
                        "full_label":"广东省江门市新会区"
                    },
                    {
                        "id":"440781000000",
                        "label":"台山市",
                        "full_label":"广东省江门市台山市"
                    },
                    {
                        "id":"440783000000",
                        "label":"开平市",
                        "full_label":"广东省江门市开平市"
                    },
                    {
                        "id":"440784000000",
                        "label":"鹤山市",
                        "full_label":"广东省江门市鹤山市"
                    },
                    {
                        "id":"440785000000",
                        "label":"恩平市",
                        "full_label":"广东省江门市恩平市"
                    }
                ],
                "full_label":"广东省江门市"
            },
            {
                "id":"440800000000",
                "label":"湛江市",
                "children":[
                    {
                        "id":"440802000000",
                        "label":"赤坎区",
                        "full_label":"广东省湛江市赤坎区"
                    },
                    {
                        "id":"440803000000",
                        "label":"霞山区",
                        "full_label":"广东省湛江市霞山区"
                    },
                    {
                        "id":"440804000000",
                        "label":"坡头区",
                        "full_label":"广东省湛江市坡头区"
                    },
                    {
                        "id":"440811000000",
                        "label":"麻章区",
                        "full_label":"广东省湛江市麻章区"
                    },
                    {
                        "id":"440823000000",
                        "label":"遂溪县",
                        "full_label":"广东省湛江市遂溪县"
                    },
                    {
                        "id":"440825000000",
                        "label":"徐闻县",
                        "full_label":"广东省湛江市徐闻县"
                    },
                    {
                        "id":"440881000000",
                        "label":"廉江市",
                        "full_label":"广东省湛江市廉江市"
                    },
                    {
                        "id":"440882000000",
                        "label":"雷州市",
                        "full_label":"广东省湛江市雷州市"
                    },
                    {
                        "id":"440883000000",
                        "label":"吴川市",
                        "full_label":"广东省湛江市吴川市"
                    }
                ],
                "full_label":"广东省湛江市"
            },
            {
                "id":"440900000000",
                "label":"茂名市",
                "children":[
                    {
                        "id":"440902000000",
                        "label":"茂南区",
                        "full_label":"广东省茂名市茂南区"
                    },
                    {
                        "id":"440904000000",
                        "label":"电白区",
                        "full_label":"广东省茂名市电白区"
                    },
                    {
                        "id":"440981000000",
                        "label":"高州市",
                        "full_label":"广东省茂名市高州市"
                    },
                    {
                        "id":"440982000000",
                        "label":"化州市",
                        "full_label":"广东省茂名市化州市"
                    },
                    {
                        "id":"440983000000",
                        "label":"信宜市",
                        "full_label":"广东省茂名市信宜市"
                    }
                ],
                "full_label":"广东省茂名市"
            },
            {
                "id":"441200000000",
                "label":"肇庆市",
                "children":[
                    {
                        "id":"441202000000",
                        "label":"端州区",
                        "full_label":"广东省肇庆市端州区"
                    },
                    {
                        "id":"441203000000",
                        "label":"鼎湖区",
                        "full_label":"广东省肇庆市鼎湖区"
                    },
                    {
                        "id":"441204000000",
                        "label":"高要区",
                        "full_label":"广东省肇庆市高要区"
                    },
                    {
                        "id":"441223000000",
                        "label":"广宁县",
                        "full_label":"广东省肇庆市广宁县"
                    },
                    {
                        "id":"441224000000",
                        "label":"怀集县",
                        "full_label":"广东省肇庆市怀集县"
                    },
                    {
                        "id":"441225000000",
                        "label":"封开县",
                        "full_label":"广东省肇庆市封开县"
                    },
                    {
                        "id":"441226000000",
                        "label":"德庆县",
                        "full_label":"广东省肇庆市德庆县"
                    },
                    {
                        "id":"441284000000",
                        "label":"四会市",
                        "full_label":"广东省肇庆市四会市"
                    }
                ],
                "full_label":"广东省肇庆市"
            },
            {
                "id":"441300000000",
                "label":"惠州市",
                "children":[
                    {
                        "id":"441302000000",
                        "label":"惠城区",
                        "full_label":"广东省惠州市惠城区"
                    },
                    {
                        "id":"441303000000",
                        "label":"惠阳区",
                        "full_label":"广东省惠州市惠阳区"
                    },
                    {
                        "id":"441322000000",
                        "label":"博罗县",
                        "full_label":"广东省惠州市博罗县"
                    },
                    {
                        "id":"441323000000",
                        "label":"惠东县",
                        "full_label":"广东省惠州市惠东县"
                    },
                    {
                        "id":"441324000000",
                        "label":"龙门县",
                        "full_label":"广东省惠州市龙门县"
                    }
                ],
                "full_label":"广东省惠州市"
            },
            {
                "id":"441400000000",
                "label":"梅州市",
                "children":[
                    {
                        "id":"441402000000",
                        "label":"梅江区",
                        "full_label":"广东省梅州市梅江区"
                    },
                    {
                        "id":"441403000000",
                        "label":"梅县区",
                        "full_label":"广东省梅州市梅县区"
                    },
                    {
                        "id":"441422000000",
                        "label":"大埔县",
                        "full_label":"广东省梅州市大埔县"
                    },
                    {
                        "id":"441423000000",
                        "label":"丰顺县",
                        "full_label":"广东省梅州市丰顺县"
                    },
                    {
                        "id":"441424000000",
                        "label":"五华县",
                        "full_label":"广东省梅州市五华县"
                    },
                    {
                        "id":"441426000000",
                        "label":"平远县",
                        "full_label":"广东省梅州市平远县"
                    },
                    {
                        "id":"441427000000",
                        "label":"蕉岭县",
                        "full_label":"广东省梅州市蕉岭县"
                    },
                    {
                        "id":"441481000000",
                        "label":"兴宁市",
                        "full_label":"广东省梅州市兴宁市"
                    }
                ],
                "full_label":"广东省梅州市"
            },
            {
                "id":"441500000000",
                "label":"汕尾市",
                "children":[
                    {
                        "id":"441502000000",
                        "label":"城区",
                        "full_label":"广东省汕尾市城区"
                    },
                    {
                        "id":"441521000000",
                        "label":"海丰县",
                        "full_label":"广东省汕尾市海丰县"
                    },
                    {
                        "id":"441523000000",
                        "label":"陆河县",
                        "full_label":"广东省汕尾市陆河县"
                    },
                    {
                        "id":"441581000000",
                        "label":"陆丰市",
                        "full_label":"广东省汕尾市陆丰市"
                    }
                ],
                "full_label":"广东省汕尾市"
            },
            {
                "id":"441600000000",
                "label":"河源市",
                "children":[
                    {
                        "id":"441602000000",
                        "label":"源城区",
                        "full_label":"广东省河源市源城区"
                    },
                    {
                        "id":"441621000000",
                        "label":"紫金县",
                        "full_label":"广东省河源市紫金县"
                    },
                    {
                        "id":"441622000000",
                        "label":"龙川县",
                        "full_label":"广东省河源市龙川县"
                    },
                    {
                        "id":"441623000000",
                        "label":"连平县",
                        "full_label":"广东省河源市连平县"
                    },
                    {
                        "id":"441624000000",
                        "label":"和平县",
                        "full_label":"广东省河源市和平县"
                    },
                    {
                        "id":"441625000000",
                        "label":"东源县",
                        "full_label":"广东省河源市东源县"
                    }
                ],
                "full_label":"广东省河源市"
            },
            {
                "id":"441700000000",
                "label":"阳江市",
                "children":[
                    {
                        "id":"441702000000",
                        "label":"江城区",
                        "full_label":"广东省阳江市江城区"
                    },
                    {
                        "id":"441704000000",
                        "label":"阳东区",
                        "full_label":"广东省阳江市阳东区"
                    },
                    {
                        "id":"441721000000",
                        "label":"阳西县",
                        "full_label":"广东省阳江市阳西县"
                    },
                    {
                        "id":"441781000000",
                        "label":"阳春市",
                        "full_label":"广东省阳江市阳春市"
                    }
                ],
                "full_label":"广东省阳江市"
            },
            {
                "id":"441800000000",
                "label":"清远市",
                "children":[
                    {
                        "id":"441802000000",
                        "label":"清城区",
                        "full_label":"广东省清远市清城区"
                    },
                    {
                        "id":"441803000000",
                        "label":"清新区",
                        "full_label":"广东省清远市清新区"
                    },
                    {
                        "id":"441821000000",
                        "label":"佛冈县",
                        "full_label":"广东省清远市佛冈县"
                    },
                    {
                        "id":"441823000000",
                        "label":"阳山县",
                        "full_label":"广东省清远市阳山县"
                    },
                    {
                        "id":"441825000000",
                        "label":"连山壮族瑶族自治县",
                        "full_label":"广东省清远市连山壮族瑶族自治县"
                    },
                    {
                        "id":"441826000000",
                        "label":"连南瑶族自治县",
                        "full_label":"广东省清远市连南瑶族自治县"
                    },
                    {
                        "id":"441881000000",
                        "label":"英德市",
                        "full_label":"广东省清远市英德市"
                    },
                    {
                        "id":"441882000000",
                        "label":"连州市",
                        "full_label":"广东省清远市连州市"
                    }
                ],
                "full_label":"广东省清远市"
            },
            {
                "id":"441900000000",
                "label":"东莞市",
                "full_label":"广东省东莞市"
            },
            {
                "id":"442000000000",
                "label":"中山市",
                "full_label":"广东省中山市"
            },
            {
                "id":"445100000000",
                "label":"潮州市",
                "children":[
                    {
                        "id":"445102000000",
                        "label":"湘桥区",
                        "full_label":"广东省潮州市湘桥区"
                    },
                    {
                        "id":"445103000000",
                        "label":"潮安区",
                        "full_label":"广东省潮州市潮安区"
                    },
                    {
                        "id":"445122000000",
                        "label":"饶平县",
                        "full_label":"广东省潮州市饶平县"
                    }
                ],
                "full_label":"广东省潮州市"
            },
            {
                "id":"445200000000",
                "label":"揭阳市",
                "children":[
                    {
                        "id":"445202000000",
                        "label":"榕城区",
                        "full_label":"广东省揭阳市榕城区"
                    },
                    {
                        "id":"445203000000",
                        "label":"揭东区",
                        "full_label":"广东省揭阳市揭东区"
                    },
                    {
                        "id":"445222000000",
                        "label":"揭西县",
                        "full_label":"广东省揭阳市揭西县"
                    },
                    {
                        "id":"445224000000",
                        "label":"惠来县",
                        "full_label":"广东省揭阳市惠来县"
                    },
                    {
                        "id":"445281000000",
                        "label":"普宁市",
                        "full_label":"广东省揭阳市普宁市"
                    }
                ],
                "full_label":"广东省揭阳市"
            },
            {
                "id":"445300000000",
                "label":"云浮市",
                "children":[
                    {
                        "id":"445302000000",
                        "label":"云城区",
                        "full_label":"广东省云浮市云城区"
                    },
                    {
                        "id":"445303000000",
                        "label":"云安区",
                        "full_label":"广东省云浮市云安区"
                    },
                    {
                        "id":"445321000000",
                        "label":"新兴县",
                        "full_label":"广东省云浮市新兴县"
                    },
                    {
                        "id":"445322000000",
                        "label":"郁南县",
                        "full_label":"广东省云浮市郁南县"
                    },
                    {
                        "id":"445381000000",
                        "label":"罗定市",
                        "full_label":"广东省云浮市罗定市"
                    }
                ],
                "full_label":"广东省云浮市"
            }
        ],
        "full_label":"广东省"
    },
    {
        "id":"450000000000",
        "label":"广西壮族自治区",
        "children":[
            {
                "id":"450100000000",
                "label":"南宁市",
                "children":[
                    {
                        "id":"450102000000",
                        "label":"兴宁区",
                        "full_label":"广西壮族自治区南宁市兴宁区"
                    },
                    {
                        "id":"450103000000",
                        "label":"青秀区",
                        "full_label":"广西壮族自治区南宁市青秀区"
                    },
                    {
                        "id":"450105000000",
                        "label":"江南区",
                        "full_label":"广西壮族自治区南宁市江南区"
                    },
                    {
                        "id":"450107000000",
                        "label":"西乡塘区",
                        "full_label":"广西壮族自治区南宁市西乡塘区"
                    },
                    {
                        "id":"450108000000",
                        "label":"良庆区",
                        "full_label":"广西壮族自治区南宁市良庆区"
                    },
                    {
                        "id":"450109000000",
                        "label":"邕宁区",
                        "full_label":"广西壮族自治区南宁市邕宁区"
                    },
                    {
                        "id":"450110000000",
                        "label":"武鸣区",
                        "full_label":"广西壮族自治区南宁市武鸣区"
                    },
                    {
                        "id":"450123000000",
                        "label":"隆安县",
                        "full_label":"广西壮族自治区南宁市隆安县"
                    },
                    {
                        "id":"450124000000",
                        "label":"马山县",
                        "full_label":"广西壮族自治区南宁市马山县"
                    },
                    {
                        "id":"450125000000",
                        "label":"上林县",
                        "full_label":"广西壮族自治区南宁市上林县"
                    },
                    {
                        "id":"450126000000",
                        "label":"宾阳县",
                        "full_label":"广西壮族自治区南宁市宾阳县"
                    },
                    {
                        "id":"450181000000",
                        "label":"横州市",
                        "full_label":"广西壮族自治区南宁市横州市"
                    }
                ],
                "full_label":"广西壮族自治区南宁市"
            },
            {
                "id":"450200000000",
                "label":"柳州市",
                "children":[
                    {
                        "id":"450202000000",
                        "label":"城中区",
                        "full_label":"广西壮族自治区柳州市城中区"
                    },
                    {
                        "id":"450203000000",
                        "label":"鱼峰区",
                        "full_label":"广西壮族自治区柳州市鱼峰区"
                    },
                    {
                        "id":"450204000000",
                        "label":"柳南区",
                        "full_label":"广西壮族自治区柳州市柳南区"
                    },
                    {
                        "id":"450205000000",
                        "label":"柳北区",
                        "full_label":"广西壮族自治区柳州市柳北区"
                    },
                    {
                        "id":"450206000000",
                        "label":"柳江区",
                        "full_label":"广西壮族自治区柳州市柳江区"
                    },
                    {
                        "id":"450222000000",
                        "label":"柳城县",
                        "full_label":"广西壮族自治区柳州市柳城县"
                    },
                    {
                        "id":"450223000000",
                        "label":"鹿寨县",
                        "full_label":"广西壮族自治区柳州市鹿寨县"
                    },
                    {
                        "id":"450224000000",
                        "label":"融安县",
                        "full_label":"广西壮族自治区柳州市融安县"
                    },
                    {
                        "id":"450225000000",
                        "label":"融水苗族自治县",
                        "full_label":"广西壮族自治区柳州市融水苗族自治县"
                    },
                    {
                        "id":"450226000000",
                        "label":"三江侗族自治县",
                        "full_label":"广西壮族自治区柳州市三江侗族自治县"
                    }
                ],
                "full_label":"广西壮族自治区柳州市"
            },
            {
                "id":"450300000000",
                "label":"桂林市",
                "children":[
                    {
                        "id":"450302000000",
                        "label":"秀峰区",
                        "full_label":"广西壮族自治区桂林市秀峰区"
                    },
                    {
                        "id":"450303000000",
                        "label":"叠彩区",
                        "full_label":"广西壮族自治区桂林市叠彩区"
                    },
                    {
                        "id":"450304000000",
                        "label":"象山区",
                        "full_label":"广西壮族自治区桂林市象山区"
                    },
                    {
                        "id":"450305000000",
                        "label":"七星区",
                        "full_label":"广西壮族自治区桂林市七星区"
                    },
                    {
                        "id":"450311000000",
                        "label":"雁山区",
                        "full_label":"广西壮族自治区桂林市雁山区"
                    },
                    {
                        "id":"450312000000",
                        "label":"临桂区",
                        "full_label":"广西壮族自治区桂林市临桂区"
                    },
                    {
                        "id":"450321000000",
                        "label":"阳朔县",
                        "full_label":"广西壮族自治区桂林市阳朔县"
                    },
                    {
                        "id":"450323000000",
                        "label":"灵川县",
                        "full_label":"广西壮族自治区桂林市灵川县"
                    },
                    {
                        "id":"450324000000",
                        "label":"全州县",
                        "full_label":"广西壮族自治区桂林市全州县"
                    },
                    {
                        "id":"450325000000",
                        "label":"兴安县",
                        "full_label":"广西壮族自治区桂林市兴安县"
                    },
                    {
                        "id":"450326000000",
                        "label":"永福县",
                        "full_label":"广西壮族自治区桂林市永福县"
                    },
                    {
                        "id":"450327000000",
                        "label":"灌阳县",
                        "full_label":"广西壮族自治区桂林市灌阳县"
                    },
                    {
                        "id":"450328000000",
                        "label":"龙胜各族自治县",
                        "full_label":"广西壮族自治区桂林市龙胜各族自治县"
                    },
                    {
                        "id":"450329000000",
                        "label":"资源县",
                        "full_label":"广西壮族自治区桂林市资源县"
                    },
                    {
                        "id":"450330000000",
                        "label":"平乐县",
                        "full_label":"广西壮族自治区桂林市平乐县"
                    },
                    {
                        "id":"450332000000",
                        "label":"恭城瑶族自治县",
                        "full_label":"广西壮族自治区桂林市恭城瑶族自治县"
                    },
                    {
                        "id":"450381000000",
                        "label":"荔浦市",
                        "full_label":"广西壮族自治区桂林市荔浦市"
                    }
                ],
                "full_label":"广西壮族自治区桂林市"
            },
            {
                "id":"450400000000",
                "label":"梧州市",
                "children":[
                    {
                        "id":"450403000000",
                        "label":"万秀区",
                        "full_label":"广西壮族自治区梧州市万秀区"
                    },
                    {
                        "id":"450405000000",
                        "label":"长洲区",
                        "full_label":"广西壮族自治区梧州市长洲区"
                    },
                    {
                        "id":"450406000000",
                        "label":"龙圩区",
                        "full_label":"广西壮族自治区梧州市龙圩区"
                    },
                    {
                        "id":"450421000000",
                        "label":"苍梧县",
                        "full_label":"广西壮族自治区梧州市苍梧县"
                    },
                    {
                        "id":"450422000000",
                        "label":"藤县",
                        "full_label":"广西壮族自治区梧州市藤县"
                    },
                    {
                        "id":"450423000000",
                        "label":"蒙山县",
                        "full_label":"广西壮族自治区梧州市蒙山县"
                    },
                    {
                        "id":"450481000000",
                        "label":"岑溪市",
                        "full_label":"广西壮族自治区梧州市岑溪市"
                    }
                ],
                "full_label":"广西壮族自治区梧州市"
            },
            {
                "id":"450500000000",
                "label":"北海市",
                "children":[
                    {
                        "id":"450502000000",
                        "label":"海城区",
                        "full_label":"广西壮族自治区北海市海城区"
                    },
                    {
                        "id":"450503000000",
                        "label":"银海区",
                        "full_label":"广西壮族自治区北海市银海区"
                    },
                    {
                        "id":"450512000000",
                        "label":"铁山港区",
                        "full_label":"广西壮族自治区北海市铁山港区"
                    },
                    {
                        "id":"450521000000",
                        "label":"合浦县",
                        "full_label":"广西壮族自治区北海市合浦县"
                    }
                ],
                "full_label":"广西壮族自治区北海市"
            },
            {
                "id":"450600000000",
                "label":"防城港市",
                "children":[
                    {
                        "id":"450602000000",
                        "label":"港口区",
                        "full_label":"广西壮族自治区防城港市港口区"
                    },
                    {
                        "id":"450603000000",
                        "label":"防城区",
                        "full_label":"广西壮族自治区防城港市防城区"
                    },
                    {
                        "id":"450621000000",
                        "label":"上思县",
                        "full_label":"广西壮族自治区防城港市上思县"
                    },
                    {
                        "id":"450681000000",
                        "label":"东兴市",
                        "full_label":"广西壮族自治区防城港市东兴市"
                    }
                ],
                "full_label":"广西壮族自治区防城港市"
            },
            {
                "id":"450700000000",
                "label":"钦州市",
                "children":[
                    {
                        "id":"450702000000",
                        "label":"钦南区",
                        "full_label":"广西壮族自治区钦州市钦南区"
                    },
                    {
                        "id":"450703000000",
                        "label":"钦北区",
                        "full_label":"广西壮族自治区钦州市钦北区"
                    },
                    {
                        "id":"450721000000",
                        "label":"灵山县",
                        "full_label":"广西壮族自治区钦州市灵山县"
                    },
                    {
                        "id":"450722000000",
                        "label":"浦北县",
                        "full_label":"广西壮族自治区钦州市浦北县"
                    }
                ],
                "full_label":"广西壮族自治区钦州市"
            },
            {
                "id":"450800000000",
                "label":"贵港市",
                "children":[
                    {
                        "id":"450802000000",
                        "label":"港北区",
                        "full_label":"广西壮族自治区贵港市港北区"
                    },
                    {
                        "id":"450803000000",
                        "label":"港南区",
                        "full_label":"广西壮族自治区贵港市港南区"
                    },
                    {
                        "id":"450804000000",
                        "label":"覃塘区",
                        "full_label":"广西壮族自治区贵港市覃塘区"
                    },
                    {
                        "id":"450821000000",
                        "label":"平南县",
                        "full_label":"广西壮族自治区贵港市平南县"
                    },
                    {
                        "id":"450881000000",
                        "label":"桂平市",
                        "full_label":"广西壮族自治区贵港市桂平市"
                    }
                ],
                "full_label":"广西壮族自治区贵港市"
            },
            {
                "id":"450900000000",
                "label":"玉林市",
                "children":[
                    {
                        "id":"450902000000",
                        "label":"玉州区",
                        "full_label":"广西壮族自治区玉林市玉州区"
                    },
                    {
                        "id":"450903000000",
                        "label":"福绵区",
                        "full_label":"广西壮族自治区玉林市福绵区"
                    },
                    {
                        "id":"450921000000",
                        "label":"容县",
                        "full_label":"广西壮族自治区玉林市容县"
                    },
                    {
                        "id":"450922000000",
                        "label":"陆川县",
                        "full_label":"广西壮族自治区玉林市陆川县"
                    },
                    {
                        "id":"450923000000",
                        "label":"博白县",
                        "full_label":"广西壮族自治区玉林市博白县"
                    },
                    {
                        "id":"450924000000",
                        "label":"兴业县",
                        "full_label":"广西壮族自治区玉林市兴业县"
                    },
                    {
                        "id":"450981000000",
                        "label":"北流市",
                        "full_label":"广西壮族自治区玉林市北流市"
                    }
                ],
                "full_label":"广西壮族自治区玉林市"
            },
            {
                "id":"451000000000",
                "label":"百色市",
                "children":[
                    {
                        "id":"451002000000",
                        "label":"右江区",
                        "full_label":"广西壮族自治区百色市右江区"
                    },
                    {
                        "id":"451003000000",
                        "label":"田阳区",
                        "full_label":"广西壮族自治区百色市田阳区"
                    },
                    {
                        "id":"451022000000",
                        "label":"田东县",
                        "full_label":"广西壮族自治区百色市田东县"
                    },
                    {
                        "id":"451024000000",
                        "label":"德保县",
                        "full_label":"广西壮族自治区百色市德保县"
                    },
                    {
                        "id":"451026000000",
                        "label":"那坡县",
                        "full_label":"广西壮族自治区百色市那坡县"
                    },
                    {
                        "id":"451027000000",
                        "label":"凌云县",
                        "full_label":"广西壮族自治区百色市凌云县"
                    },
                    {
                        "id":"451028000000",
                        "label":"乐业县",
                        "full_label":"广西壮族自治区百色市乐业县"
                    },
                    {
                        "id":"451029000000",
                        "label":"田林县",
                        "full_label":"广西壮族自治区百色市田林县"
                    },
                    {
                        "id":"451030000000",
                        "label":"西林县",
                        "full_label":"广西壮族自治区百色市西林县"
                    },
                    {
                        "id":"451031000000",
                        "label":"隆林各族自治县",
                        "full_label":"广西壮族自治区百色市隆林各族自治县"
                    },
                    {
                        "id":"451081000000",
                        "label":"靖西市",
                        "full_label":"广西壮族自治区百色市靖西市"
                    },
                    {
                        "id":"451082000000",
                        "label":"平果市",
                        "full_label":"广西壮族自治区百色市平果市"
                    }
                ],
                "full_label":"广西壮族自治区百色市"
            },
            {
                "id":"451100000000",
                "label":"贺州市",
                "children":[
                    {
                        "id":"451102000000",
                        "label":"八步区",
                        "full_label":"广西壮族自治区贺州市八步区"
                    },
                    {
                        "id":"451103000000",
                        "label":"平桂区",
                        "full_label":"广西壮族自治区贺州市平桂区"
                    },
                    {
                        "id":"451121000000",
                        "label":"昭平县",
                        "full_label":"广西壮族自治区贺州市昭平县"
                    },
                    {
                        "id":"451122000000",
                        "label":"钟山县",
                        "full_label":"广西壮族自治区贺州市钟山县"
                    },
                    {
                        "id":"451123000000",
                        "label":"富川瑶族自治县",
                        "full_label":"广西壮族自治区贺州市富川瑶族自治县"
                    }
                ],
                "full_label":"广西壮族自治区贺州市"
            },
            {
                "id":"451200000000",
                "label":"河池市",
                "children":[
                    {
                        "id":"451202000000",
                        "label":"金城江区",
                        "full_label":"广西壮族自治区河池市金城江区"
                    },
                    {
                        "id":"451203000000",
                        "label":"宜州区",
                        "full_label":"广西壮族自治区河池市宜州区"
                    },
                    {
                        "id":"451221000000",
                        "label":"南丹县",
                        "full_label":"广西壮族自治区河池市南丹县"
                    },
                    {
                        "id":"451222000000",
                        "label":"天峨县",
                        "full_label":"广西壮族自治区河池市天峨县"
                    },
                    {
                        "id":"451223000000",
                        "label":"凤山县",
                        "full_label":"广西壮族自治区河池市凤山县"
                    },
                    {
                        "id":"451224000000",
                        "label":"东兰县",
                        "full_label":"广西壮族自治区河池市东兰县"
                    },
                    {
                        "id":"451225000000",
                        "label":"罗城仫佬族自治县",
                        "full_label":"广西壮族自治区河池市罗城仫佬族自治县"
                    },
                    {
                        "id":"451226000000",
                        "label":"环江毛南族自治县",
                        "full_label":"广西壮族自治区河池市环江毛南族自治县"
                    },
                    {
                        "id":"451227000000",
                        "label":"巴马瑶族自治县",
                        "full_label":"广西壮族自治区河池市巴马瑶族自治县"
                    },
                    {
                        "id":"451228000000",
                        "label":"都安瑶族自治县",
                        "full_label":"广西壮族自治区河池市都安瑶族自治县"
                    },
                    {
                        "id":"451229000000",
                        "label":"大化瑶族自治县",
                        "full_label":"广西壮族自治区河池市大化瑶族自治县"
                    }
                ],
                "full_label":"广西壮族自治区河池市"
            },
            {
                "id":"451300000000",
                "label":"来宾市",
                "children":[
                    {
                        "id":"451302000000",
                        "label":"兴宾区",
                        "full_label":"广西壮族自治区来宾市兴宾区"
                    },
                    {
                        "id":"451321000000",
                        "label":"忻城县",
                        "full_label":"广西壮族自治区来宾市忻城县"
                    },
                    {
                        "id":"451322000000",
                        "label":"象州县",
                        "full_label":"广西壮族自治区来宾市象州县"
                    },
                    {
                        "id":"451323000000",
                        "label":"武宣县",
                        "full_label":"广西壮族自治区来宾市武宣县"
                    },
                    {
                        "id":"451324000000",
                        "label":"金秀瑶族自治县",
                        "full_label":"广西壮族自治区来宾市金秀瑶族自治县"
                    },
                    {
                        "id":"451381000000",
                        "label":"合山市",
                        "full_label":"广西壮族自治区来宾市合山市"
                    }
                ],
                "full_label":"广西壮族自治区来宾市"
            },
            {
                "id":"451400000000",
                "label":"崇左市",
                "children":[
                    {
                        "id":"451402000000",
                        "label":"江州区",
                        "full_label":"广西壮族自治区崇左市江州区"
                    },
                    {
                        "id":"451421000000",
                        "label":"扶绥县",
                        "full_label":"广西壮族自治区崇左市扶绥县"
                    },
                    {
                        "id":"451422000000",
                        "label":"宁明县",
                        "full_label":"广西壮族自治区崇左市宁明县"
                    },
                    {
                        "id":"451423000000",
                        "label":"龙州县",
                        "full_label":"广西壮族自治区崇左市龙州县"
                    },
                    {
                        "id":"451424000000",
                        "label":"大新县",
                        "full_label":"广西壮族自治区崇左市大新县"
                    },
                    {
                        "id":"451425000000",
                        "label":"天等县",
                        "full_label":"广西壮族自治区崇左市天等县"
                    },
                    {
                        "id":"451481000000",
                        "label":"凭祥市",
                        "full_label":"广西壮族自治区崇左市凭祥市"
                    }
                ],
                "full_label":"广西壮族自治区崇左市"
            }
        ],
        "full_label":"广西壮族自治区"
    },
    {
        "id":"460000000000",
        "label":"海南省",
        "children":[
            {
                "id":"460100000000",
                "label":"海口市",
                "children":[
                    {
                        "id":"460105000000",
                        "label":"秀英区",
                        "full_label":"海南省海口市秀英区"
                    },
                    {
                        "id":"460106000000",
                        "label":"龙华区",
                        "full_label":"海南省海口市龙华区"
                    },
                    {
                        "id":"460107000000",
                        "label":"琼山区",
                        "full_label":"海南省海口市琼山区"
                    },
                    {
                        "id":"460108000000",
                        "label":"美兰区",
                        "full_label":"海南省海口市美兰区"
                    }
                ],
                "full_label":"海南省海口市"
            },
            {
                "id":"460200000000",
                "label":"三亚市",
                "children":[
                    {
                        "id":"460202000000",
                        "label":"海棠区",
                        "full_label":"海南省三亚市海棠区"
                    },
                    {
                        "id":"460203000000",
                        "label":"吉阳区",
                        "full_label":"海南省三亚市吉阳区"
                    },
                    {
                        "id":"460204000000",
                        "label":"天涯区",
                        "full_label":"海南省三亚市天涯区"
                    },
                    {
                        "id":"460205000000",
                        "label":"崖州区",
                        "full_label":"海南省三亚市崖州区"
                    }
                ],
                "full_label":"海南省三亚市"
            },
            {
                "id":"460300000000",
                "label":"三沙市",
                "children":[
                    {
                        "id":"460301000000",
                        "label":"西沙区",
                        "full_label":"海南省三沙市西沙区"
                    },
                    {
                        "id":"460302000000",
                        "label":"南沙区",
                        "full_label":"海南省三沙市南沙区"
                    }
                ],
                "full_label":"海南省三沙市"
            },
            {
                "id":"460400000000",
                "label":"儋州市",
                "full_label":"海南省儋州市"
            },
            {
                "id":"469001000000",
                "label":"五指山市",
                "full_label":"海南省五指山市"
            },
            {
                "id":"469002000000",
                "label":"琼海市",
                "full_label":"海南省琼海市"
            },
            {
                "id":"469005000000",
                "label":"文昌市",
                "full_label":"海南省文昌市"
            },
            {
                "id":"469006000000",
                "label":"万宁市",
                "full_label":"海南省万宁市"
            },
            {
                "id":"469007000000",
                "label":"东方市",
                "full_label":"海南省东方市"
            },
            {
                "id":"469021000000",
                "label":"定安县",
                "full_label":"海南省定安县"
            },
            {
                "id":"469022000000",
                "label":"屯昌县",
                "full_label":"海南省屯昌县"
            },
            {
                "id":"469023000000",
                "label":"澄迈县",
                "full_label":"海南省澄迈县"
            },
            {
                "id":"469024000000",
                "label":"临高县",
                "full_label":"海南省临高县"
            },
            {
                "id":"469025000000",
                "label":"白沙黎族自治县",
                "full_label":"海南省白沙黎族自治县"
            },
            {
                "id":"469026000000",
                "label":"昌江黎族自治县",
                "full_label":"海南省昌江黎族自治县"
            },
            {
                "id":"469027000000",
                "label":"乐东黎族自治县",
                "full_label":"海南省乐东黎族自治县"
            },
            {
                "id":"469028000000",
                "label":"陵水黎族自治县",
                "full_label":"海南省陵水黎族自治县"
            },
            {
                "id":"469029000000",
                "label":"保亭黎族苗族自治县",
                "full_label":"海南省保亭黎族苗族自治县"
            },
            {
                "id":"469030000000",
                "label":"琼中黎族苗族自治县",
                "full_label":"海南省琼中黎族苗族自治县"
            }
        ],
        "full_label":"海南省"
    },
    {
        "id":"500000000000",
        "label":"重庆市",
        "children":[
            {
                "id":"500100000000",
                "label":"重庆城区",
                "children":[
                    {
                        "id":"500101000000",
                        "label":"万州区",
                        "full_label":"重庆市重庆城区万州区"
                    },
                    {
                        "id":"500102000000",
                        "label":"涪陵区",
                        "full_label":"重庆市重庆城区涪陵区"
                    },
                    {
                        "id":"500103000000",
                        "label":"渝中区",
                        "full_label":"重庆市重庆城区渝中区"
                    },
                    {
                        "id":"500104000000",
                        "label":"大渡口区",
                        "full_label":"重庆市重庆城区大渡口区"
                    },
                    {
                        "id":"500105000000",
                        "label":"江北区",
                        "full_label":"重庆市重庆城区江北区"
                    },
                    {
                        "id":"500106000000",
                        "label":"沙坪坝区",
                        "full_label":"重庆市重庆城区沙坪坝区"
                    },
                    {
                        "id":"500107000000",
                        "label":"九龙坡区",
                        "full_label":"重庆市重庆城区九龙坡区"
                    },
                    {
                        "id":"500108000000",
                        "label":"南岸区",
                        "full_label":"重庆市重庆城区南岸区"
                    },
                    {
                        "id":"500109000000",
                        "label":"北碚区",
                        "full_label":"重庆市重庆城区北碚区"
                    },
                    {
                        "id":"500110000000",
                        "label":"綦江区",
                        "full_label":"重庆市重庆城区綦江区"
                    },
                    {
                        "id":"500111000000",
                        "label":"大足区",
                        "full_label":"重庆市重庆城区大足区"
                    },
                    {
                        "id":"500112000000",
                        "label":"渝北区",
                        "full_label":"重庆市重庆城区渝北区"
                    },
                    {
                        "id":"500113000000",
                        "label":"巴南区",
                        "full_label":"重庆市重庆城区巴南区"
                    },
                    {
                        "id":"500114000000",
                        "label":"黔江区",
                        "full_label":"重庆市重庆城区黔江区"
                    },
                    {
                        "id":"500115000000",
                        "label":"长寿区",
                        "full_label":"重庆市重庆城区长寿区"
                    },
                    {
                        "id":"500116000000",
                        "label":"江津区",
                        "full_label":"重庆市重庆城区江津区"
                    },
                    {
                        "id":"500117000000",
                        "label":"合川区",
                        "full_label":"重庆市重庆城区合川区"
                    },
                    {
                        "id":"500118000000",
                        "label":"永川区",
                        "full_label":"重庆市重庆城区永川区"
                    },
                    {
                        "id":"500119000000",
                        "label":"南川区",
                        "full_label":"重庆市重庆城区南川区"
                    },
                    {
                        "id":"500120000000",
                        "label":"璧山区",
                        "full_label":"重庆市重庆城区璧山区"
                    },
                    {
                        "id":"500151000000",
                        "label":"铜梁区",
                        "full_label":"重庆市重庆城区铜梁区"
                    },
                    {
                        "id":"500152000000",
                        "label":"潼南区",
                        "full_label":"重庆市重庆城区潼南区"
                    },
                    {
                        "id":"500153000000",
                        "label":"荣昌区",
                        "full_label":"重庆市重庆城区荣昌区"
                    },
                    {
                        "id":"500154000000",
                        "label":"开州区",
                        "full_label":"重庆市重庆城区开州区"
                    },
                    {
                        "id":"500155000000",
                        "label":"梁平区",
                        "full_label":"重庆市重庆城区梁平区"
                    },
                    {
                        "id":"500156000000",
                        "label":"武隆区",
                        "full_label":"重庆市重庆城区武隆区"
                    }
                ],
                "full_label":"重庆市重庆城区"
            },
            {
                "id":"500200000000",
                "label":"重庆郊县",
                "children":[
                    {
                        "id":"500229000000",
                        "label":"城口县",
                        "full_label":"重庆市重庆郊县城口县"
                    },
                    {
                        "id":"500230000000",
                        "label":"丰都县",
                        "full_label":"重庆市重庆郊县丰都县"
                    },
                    {
                        "id":"500231000000",
                        "label":"垫江县",
                        "full_label":"重庆市重庆郊县垫江县"
                    },
                    {
                        "id":"500233000000",
                        "label":"忠县",
                        "full_label":"重庆市重庆郊县忠县"
                    },
                    {
                        "id":"500235000000",
                        "label":"云阳县",
                        "full_label":"重庆市重庆郊县云阳县"
                    },
                    {
                        "id":"500236000000",
                        "label":"奉节县",
                        "full_label":"重庆市重庆郊县奉节县"
                    },
                    {
                        "id":"500237000000",
                        "label":"巫山县",
                        "full_label":"重庆市重庆郊县巫山县"
                    },
                    {
                        "id":"500238000000",
                        "label":"巫溪县",
                        "full_label":"重庆市重庆郊县巫溪县"
                    },
                    {
                        "id":"500240000000",
                        "label":"石柱土家族自治县",
                        "full_label":"重庆市重庆郊县石柱土家族自治县"
                    },
                    {
                        "id":"500241000000",
                        "label":"秀山土家族苗族自治县",
                        "full_label":"重庆市重庆郊县秀山土家族苗族自治县"
                    },
                    {
                        "id":"500242000000",
                        "label":"酉阳土家族苗族自治县",
                        "full_label":"重庆市重庆郊县酉阳土家族苗族自治县"
                    },
                    {
                        "id":"500243000000",
                        "label":"彭水苗族土家族自治县",
                        "full_label":"重庆市重庆郊县彭水苗族土家族自治县"
                    }
                ],
                "full_label":"重庆市重庆郊县"
            }
        ],
        "full_label":"重庆市"
    },
    {
        "id":"510000000000",
        "label":"四川省",
        "children":[
            {
                "id":"510100000000",
                "label":"成都市",
                "children":[
                    {
                        "id":"510104000000",
                        "label":"锦江区",
                        "full_label":"四川省成都市锦江区"
                    },
                    {
                        "id":"510105000000",
                        "label":"青羊区",
                        "full_label":"四川省成都市青羊区"
                    },
                    {
                        "id":"510106000000",
                        "label":"金牛区",
                        "full_label":"四川省成都市金牛区"
                    },
                    {
                        "id":"510107000000",
                        "label":"武侯区",
                        "full_label":"四川省成都市武侯区"
                    },
                    {
                        "id":"510108000000",
                        "label":"成华区",
                        "full_label":"四川省成都市成华区"
                    },
                    {
                        "id":"510112000000",
                        "label":"龙泉驿区",
                        "full_label":"四川省成都市龙泉驿区"
                    },
                    {
                        "id":"510113000000",
                        "label":"青白江区",
                        "full_label":"四川省成都市青白江区"
                    },
                    {
                        "id":"510114000000",
                        "label":"新都区",
                        "full_label":"四川省成都市新都区"
                    },
                    {
                        "id":"510115000000",
                        "label":"温江区",
                        "full_label":"四川省成都市温江区"
                    },
                    {
                        "id":"510116000000",
                        "label":"双流区",
                        "full_label":"四川省成都市双流区"
                    },
                    {
                        "id":"510117000000",
                        "label":"郫都区",
                        "full_label":"四川省成都市郫都区"
                    },
                    {
                        "id":"510118000000",
                        "label":"新津区",
                        "full_label":"四川省成都市新津区"
                    },
                    {
                        "id":"510121000000",
                        "label":"金堂县",
                        "full_label":"四川省成都市金堂县"
                    },
                    {
                        "id":"510129000000",
                        "label":"大邑县",
                        "full_label":"四川省成都市大邑县"
                    },
                    {
                        "id":"510131000000",
                        "label":"蒲江县",
                        "full_label":"四川省成都市蒲江县"
                    },
                    {
                        "id":"510181000000",
                        "label":"都江堰市",
                        "full_label":"四川省成都市都江堰市"
                    },
                    {
                        "id":"510182000000",
                        "label":"彭州市",
                        "full_label":"四川省成都市彭州市"
                    },
                    {
                        "id":"510183000000",
                        "label":"邛崃市",
                        "full_label":"四川省成都市邛崃市"
                    },
                    {
                        "id":"510184000000",
                        "label":"崇州市",
                        "full_label":"四川省成都市崇州市"
                    },
                    {
                        "id":"510185000000",
                        "label":"简阳市",
                        "full_label":"四川省成都市简阳市"
                    }
                ],
                "full_label":"四川省成都市"
            },
            {
                "id":"510300000000",
                "label":"自贡市",
                "children":[
                    {
                        "id":"510302000000",
                        "label":"自流井区",
                        "full_label":"四川省自贡市自流井区"
                    },
                    {
                        "id":"510303000000",
                        "label":"贡井区",
                        "full_label":"四川省自贡市贡井区"
                    },
                    {
                        "id":"510304000000",
                        "label":"大安区",
                        "full_label":"四川省自贡市大安区"
                    },
                    {
                        "id":"510311000000",
                        "label":"沿滩区",
                        "full_label":"四川省自贡市沿滩区"
                    },
                    {
                        "id":"510321000000",
                        "label":"荣县",
                        "full_label":"四川省自贡市荣县"
                    },
                    {
                        "id":"510322000000",
                        "label":"富顺县",
                        "full_label":"四川省自贡市富顺县"
                    }
                ],
                "full_label":"四川省自贡市"
            },
            {
                "id":"510400000000",
                "label":"攀枝花市",
                "children":[
                    {
                        "id":"510402000000",
                        "label":"东区",
                        "full_label":"四川省攀枝花市东区"
                    },
                    {
                        "id":"510403000000",
                        "label":"西区",
                        "full_label":"四川省攀枝花市西区"
                    },
                    {
                        "id":"510411000000",
                        "label":"仁和区",
                        "full_label":"四川省攀枝花市仁和区"
                    },
                    {
                        "id":"510421000000",
                        "label":"米易县",
                        "full_label":"四川省攀枝花市米易县"
                    },
                    {
                        "id":"510422000000",
                        "label":"盐边县",
                        "full_label":"四川省攀枝花市盐边县"
                    }
                ],
                "full_label":"四川省攀枝花市"
            },
            {
                "id":"510500000000",
                "label":"泸州市",
                "children":[
                    {
                        "id":"510502000000",
                        "label":"江阳区",
                        "full_label":"四川省泸州市江阳区"
                    },
                    {
                        "id":"510503000000",
                        "label":"纳溪区",
                        "full_label":"四川省泸州市纳溪区"
                    },
                    {
                        "id":"510504000000",
                        "label":"龙马潭区",
                        "full_label":"四川省泸州市龙马潭区"
                    },
                    {
                        "id":"510521000000",
                        "label":"泸县",
                        "full_label":"四川省泸州市泸县"
                    },
                    {
                        "id":"510522000000",
                        "label":"合江县",
                        "full_label":"四川省泸州市合江县"
                    },
                    {
                        "id":"510524000000",
                        "label":"叙永县",
                        "full_label":"四川省泸州市叙永县"
                    },
                    {
                        "id":"510525000000",
                        "label":"古蔺县",
                        "full_label":"四川省泸州市古蔺县"
                    }
                ],
                "full_label":"四川省泸州市"
            },
            {
                "id":"510600000000",
                "label":"德阳市",
                "children":[
                    {
                        "id":"510603000000",
                        "label":"旌阳区",
                        "full_label":"四川省德阳市旌阳区"
                    },
                    {
                        "id":"510604000000",
                        "label":"罗江区",
                        "full_label":"四川省德阳市罗江区"
                    },
                    {
                        "id":"510623000000",
                        "label":"中江县",
                        "full_label":"四川省德阳市中江县"
                    },
                    {
                        "id":"510681000000",
                        "label":"广汉市",
                        "full_label":"四川省德阳市广汉市"
                    },
                    {
                        "id":"510682000000",
                        "label":"什邡市",
                        "full_label":"四川省德阳市什邡市"
                    },
                    {
                        "id":"510683000000",
                        "label":"绵竹市",
                        "full_label":"四川省德阳市绵竹市"
                    }
                ],
                "full_label":"四川省德阳市"
            },
            {
                "id":"510700000000",
                "label":"绵阳市",
                "children":[
                    {
                        "id":"510703000000",
                        "label":"涪城区",
                        "full_label":"四川省绵阳市涪城区"
                    },
                    {
                        "id":"510704000000",
                        "label":"游仙区",
                        "full_label":"四川省绵阳市游仙区"
                    },
                    {
                        "id":"510705000000",
                        "label":"安州区",
                        "full_label":"四川省绵阳市安州区"
                    },
                    {
                        "id":"510722000000",
                        "label":"三台县",
                        "full_label":"四川省绵阳市三台县"
                    },
                    {
                        "id":"510723000000",
                        "label":"盐亭县",
                        "full_label":"四川省绵阳市盐亭县"
                    },
                    {
                        "id":"510725000000",
                        "label":"梓潼县",
                        "full_label":"四川省绵阳市梓潼县"
                    },
                    {
                        "id":"510726000000",
                        "label":"北川羌族自治县",
                        "full_label":"四川省绵阳市北川羌族自治县"
                    },
                    {
                        "id":"510727000000",
                        "label":"平武县",
                        "full_label":"四川省绵阳市平武县"
                    },
                    {
                        "id":"510781000000",
                        "label":"江油市",
                        "full_label":"四川省绵阳市江油市"
                    }
                ],
                "full_label":"四川省绵阳市"
            },
            {
                "id":"510800000000",
                "label":"广元市",
                "children":[
                    {
                        "id":"510802000000",
                        "label":"利州区",
                        "full_label":"四川省广元市利州区"
                    },
                    {
                        "id":"510811000000",
                        "label":"昭化区",
                        "full_label":"四川省广元市昭化区"
                    },
                    {
                        "id":"510812000000",
                        "label":"朝天区",
                        "full_label":"四川省广元市朝天区"
                    },
                    {
                        "id":"510821000000",
                        "label":"旺苍县",
                        "full_label":"四川省广元市旺苍县"
                    },
                    {
                        "id":"510822000000",
                        "label":"青川县",
                        "full_label":"四川省广元市青川县"
                    },
                    {
                        "id":"510823000000",
                        "label":"剑阁县",
                        "full_label":"四川省广元市剑阁县"
                    },
                    {
                        "id":"510824000000",
                        "label":"苍溪县",
                        "full_label":"四川省广元市苍溪县"
                    }
                ],
                "full_label":"四川省广元市"
            },
            {
                "id":"510900000000",
                "label":"遂宁市",
                "children":[
                    {
                        "id":"510903000000",
                        "label":"船山区",
                        "full_label":"四川省遂宁市船山区"
                    },
                    {
                        "id":"510904000000",
                        "label":"安居区",
                        "full_label":"四川省遂宁市安居区"
                    },
                    {
                        "id":"510921000000",
                        "label":"蓬溪县",
                        "full_label":"四川省遂宁市蓬溪县"
                    },
                    {
                        "id":"510923000000",
                        "label":"大英县",
                        "full_label":"四川省遂宁市大英县"
                    },
                    {
                        "id":"510981000000",
                        "label":"射洪市",
                        "full_label":"四川省遂宁市射洪市"
                    }
                ],
                "full_label":"四川省遂宁市"
            },
            {
                "id":"511000000000",
                "label":"内江市",
                "children":[
                    {
                        "id":"511002000000",
                        "label":"市中区",
                        "full_label":"四川省内江市市中区"
                    },
                    {
                        "id":"511011000000",
                        "label":"东兴区",
                        "full_label":"四川省内江市东兴区"
                    },
                    {
                        "id":"511024000000",
                        "label":"威远县",
                        "full_label":"四川省内江市威远县"
                    },
                    {
                        "id":"511025000000",
                        "label":"资中县",
                        "full_label":"四川省内江市资中县"
                    },
                    {
                        "id":"511083000000",
                        "label":"隆昌市",
                        "full_label":"四川省内江市隆昌市"
                    }
                ],
                "full_label":"四川省内江市"
            },
            {
                "id":"511100000000",
                "label":"乐山市",
                "children":[
                    {
                        "id":"511102000000",
                        "label":"市中区",
                        "full_label":"四川省乐山市市中区"
                    },
                    {
                        "id":"511111000000",
                        "label":"沙湾区",
                        "full_label":"四川省乐山市沙湾区"
                    },
                    {
                        "id":"511112000000",
                        "label":"五通桥区",
                        "full_label":"四川省乐山市五通桥区"
                    },
                    {
                        "id":"511113000000",
                        "label":"金口河区",
                        "full_label":"四川省乐山市金口河区"
                    },
                    {
                        "id":"511123000000",
                        "label":"犍为县",
                        "full_label":"四川省乐山市犍为县"
                    },
                    {
                        "id":"511124000000",
                        "label":"井研县",
                        "full_label":"四川省乐山市井研县"
                    },
                    {
                        "id":"511126000000",
                        "label":"夹江县",
                        "full_label":"四川省乐山市夹江县"
                    },
                    {
                        "id":"511129000000",
                        "label":"沐川县",
                        "full_label":"四川省乐山市沐川县"
                    },
                    {
                        "id":"511132000000",
                        "label":"峨边彝族自治县",
                        "full_label":"四川省乐山市峨边彝族自治县"
                    },
                    {
                        "id":"511133000000",
                        "label":"马边彝族自治县",
                        "full_label":"四川省乐山市马边彝族自治县"
                    },
                    {
                        "id":"511181000000",
                        "label":"峨眉山市",
                        "full_label":"四川省乐山市峨眉山市"
                    }
                ],
                "full_label":"四川省乐山市"
            },
            {
                "id":"511300000000",
                "label":"南充市",
                "children":[
                    {
                        "id":"511302000000",
                        "label":"顺庆区",
                        "full_label":"四川省南充市顺庆区"
                    },
                    {
                        "id":"511303000000",
                        "label":"高坪区",
                        "full_label":"四川省南充市高坪区"
                    },
                    {
                        "id":"511304000000",
                        "label":"嘉陵区",
                        "full_label":"四川省南充市嘉陵区"
                    },
                    {
                        "id":"511321000000",
                        "label":"南部县",
                        "full_label":"四川省南充市南部县"
                    },
                    {
                        "id":"511322000000",
                        "label":"营山县",
                        "full_label":"四川省南充市营山县"
                    },
                    {
                        "id":"511323000000",
                        "label":"蓬安县",
                        "full_label":"四川省南充市蓬安县"
                    },
                    {
                        "id":"511324000000",
                        "label":"仪陇县",
                        "full_label":"四川省南充市仪陇县"
                    },
                    {
                        "id":"511325000000",
                        "label":"西充县",
                        "full_label":"四川省南充市西充县"
                    },
                    {
                        "id":"511381000000",
                        "label":"阆中市",
                        "full_label":"四川省南充市阆中市"
                    }
                ],
                "full_label":"四川省南充市"
            },
            {
                "id":"511400000000",
                "label":"眉山市",
                "children":[
                    {
                        "id":"511402000000",
                        "label":"东坡区",
                        "full_label":"四川省眉山市东坡区"
                    },
                    {
                        "id":"511403000000",
                        "label":"彭山区",
                        "full_label":"四川省眉山市彭山区"
                    },
                    {
                        "id":"511421000000",
                        "label":"仁寿县",
                        "full_label":"四川省眉山市仁寿县"
                    },
                    {
                        "id":"511423000000",
                        "label":"洪雅县",
                        "full_label":"四川省眉山市洪雅县"
                    },
                    {
                        "id":"511424000000",
                        "label":"丹棱县",
                        "full_label":"四川省眉山市丹棱县"
                    },
                    {
                        "id":"511425000000",
                        "label":"青神县",
                        "full_label":"四川省眉山市青神县"
                    }
                ],
                "full_label":"四川省眉山市"
            },
            {
                "id":"511500000000",
                "label":"宜宾市",
                "children":[
                    {
                        "id":"511502000000",
                        "label":"翠屏区",
                        "full_label":"四川省宜宾市翠屏区"
                    },
                    {
                        "id":"511503000000",
                        "label":"南溪区",
                        "full_label":"四川省宜宾市南溪区"
                    },
                    {
                        "id":"511504000000",
                        "label":"叙州区",
                        "full_label":"四川省宜宾市叙州区"
                    },
                    {
                        "id":"511523000000",
                        "label":"江安县",
                        "full_label":"四川省宜宾市江安县"
                    },
                    {
                        "id":"511524000000",
                        "label":"长宁县",
                        "full_label":"四川省宜宾市长宁县"
                    },
                    {
                        "id":"511525000000",
                        "label":"高县",
                        "full_label":"四川省宜宾市高县"
                    },
                    {
                        "id":"511526000000",
                        "label":"珙县",
                        "full_label":"四川省宜宾市珙县"
                    },
                    {
                        "id":"511527000000",
                        "label":"筠连县",
                        "full_label":"四川省宜宾市筠连县"
                    },
                    {
                        "id":"511528000000",
                        "label":"兴文县",
                        "full_label":"四川省宜宾市兴文县"
                    },
                    {
                        "id":"511529000000",
                        "label":"屏山县",
                        "full_label":"四川省宜宾市屏山县"
                    }
                ],
                "full_label":"四川省宜宾市"
            },
            {
                "id":"511600000000",
                "label":"广安市",
                "children":[
                    {
                        "id":"511602000000",
                        "label":"广安区",
                        "full_label":"四川省广安市广安区"
                    },
                    {
                        "id":"511603000000",
                        "label":"前锋区",
                        "full_label":"四川省广安市前锋区"
                    },
                    {
                        "id":"511621000000",
                        "label":"岳池县",
                        "full_label":"四川省广安市岳池县"
                    },
                    {
                        "id":"511622000000",
                        "label":"武胜县",
                        "full_label":"四川省广安市武胜县"
                    },
                    {
                        "id":"511623000000",
                        "label":"邻水县",
                        "full_label":"四川省广安市邻水县"
                    },
                    {
                        "id":"511681000000",
                        "label":"华蓥市",
                        "full_label":"四川省广安市华蓥市"
                    }
                ],
                "full_label":"四川省广安市"
            },
            {
                "id":"511700000000",
                "label":"达州市",
                "children":[
                    {
                        "id":"511702000000",
                        "label":"通川区",
                        "full_label":"四川省达州市通川区"
                    },
                    {
                        "id":"511703000000",
                        "label":"达川区",
                        "full_label":"四川省达州市达川区"
                    },
                    {
                        "id":"511722000000",
                        "label":"宣汉县",
                        "full_label":"四川省达州市宣汉县"
                    },
                    {
                        "id":"511723000000",
                        "label":"开江县",
                        "full_label":"四川省达州市开江县"
                    },
                    {
                        "id":"511724000000",
                        "label":"大竹县",
                        "full_label":"四川省达州市大竹县"
                    },
                    {
                        "id":"511725000000",
                        "label":"渠县",
                        "full_label":"四川省达州市渠县"
                    },
                    {
                        "id":"511781000000",
                        "label":"万源市",
                        "full_label":"四川省达州市万源市"
                    }
                ],
                "full_label":"四川省达州市"
            },
            {
                "id":"511800000000",
                "label":"雅安市",
                "children":[
                    {
                        "id":"511802000000",
                        "label":"雨城区",
                        "full_label":"四川省雅安市雨城区"
                    },
                    {
                        "id":"511803000000",
                        "label":"名山区",
                        "full_label":"四川省雅安市名山区"
                    },
                    {
                        "id":"511822000000",
                        "label":"荥经县",
                        "full_label":"四川省雅安市荥经县"
                    },
                    {
                        "id":"511823000000",
                        "label":"汉源县",
                        "full_label":"四川省雅安市汉源县"
                    },
                    {
                        "id":"511824000000",
                        "label":"石棉县",
                        "full_label":"四川省雅安市石棉县"
                    },
                    {
                        "id":"511825000000",
                        "label":"天全县",
                        "full_label":"四川省雅安市天全县"
                    },
                    {
                        "id":"511826000000",
                        "label":"芦山县",
                        "full_label":"四川省雅安市芦山县"
                    },
                    {
                        "id":"511827000000",
                        "label":"宝兴县",
                        "full_label":"四川省雅安市宝兴县"
                    }
                ],
                "full_label":"四川省雅安市"
            },
            {
                "id":"511900000000",
                "label":"巴中市",
                "children":[
                    {
                        "id":"511902000000",
                        "label":"巴州区",
                        "full_label":"四川省巴中市巴州区"
                    },
                    {
                        "id":"511903000000",
                        "label":"恩阳区",
                        "full_label":"四川省巴中市恩阳区"
                    },
                    {
                        "id":"511921000000",
                        "label":"通江县",
                        "full_label":"四川省巴中市通江县"
                    },
                    {
                        "id":"511922000000",
                        "label":"南江县",
                        "full_label":"四川省巴中市南江县"
                    },
                    {
                        "id":"511923000000",
                        "label":"平昌县",
                        "full_label":"四川省巴中市平昌县"
                    }
                ],
                "full_label":"四川省巴中市"
            },
            {
                "id":"512000000000",
                "label":"资阳市",
                "children":[
                    {
                        "id":"512002000000",
                        "label":"雁江区",
                        "full_label":"四川省资阳市雁江区"
                    },
                    {
                        "id":"512021000000",
                        "label":"安岳县",
                        "full_label":"四川省资阳市安岳县"
                    },
                    {
                        "id":"512022000000",
                        "label":"乐至县",
                        "full_label":"四川省资阳市乐至县"
                    }
                ],
                "full_label":"四川省资阳市"
            },
            {
                "id":"513200000000",
                "label":"阿坝藏族羌族自治州",
                "children":[
                    {
                        "id":"513201000000",
                        "label":"马尔康市",
                        "full_label":"四川省阿坝藏族羌族自治州马尔康市"
                    },
                    {
                        "id":"513221000000",
                        "label":"汶川县",
                        "full_label":"四川省阿坝藏族羌族自治州汶川县"
                    },
                    {
                        "id":"513222000000",
                        "label":"理县",
                        "full_label":"四川省阿坝藏族羌族自治州理县"
                    },
                    {
                        "id":"513223000000",
                        "label":"茂县",
                        "full_label":"四川省阿坝藏族羌族自治州茂县"
                    },
                    {
                        "id":"513224000000",
                        "label":"松潘县",
                        "full_label":"四川省阿坝藏族羌族自治州松潘县"
                    },
                    {
                        "id":"513225000000",
                        "label":"九寨沟县",
                        "full_label":"四川省阿坝藏族羌族自治州九寨沟县"
                    },
                    {
                        "id":"513226000000",
                        "label":"金川县",
                        "full_label":"四川省阿坝藏族羌族自治州金川县"
                    },
                    {
                        "id":"513227000000",
                        "label":"小金县",
                        "full_label":"四川省阿坝藏族羌族自治州小金县"
                    },
                    {
                        "id":"513228000000",
                        "label":"黑水县",
                        "full_label":"四川省阿坝藏族羌族自治州黑水县"
                    },
                    {
                        "id":"513230000000",
                        "label":"壤塘县",
                        "full_label":"四川省阿坝藏族羌族自治州壤塘县"
                    },
                    {
                        "id":"513231000000",
                        "label":"阿坝县",
                        "full_label":"四川省阿坝藏族羌族自治州阿坝县"
                    },
                    {
                        "id":"513232000000",
                        "label":"若尔盖县",
                        "full_label":"四川省阿坝藏族羌族自治州若尔盖县"
                    },
                    {
                        "id":"513233000000",
                        "label":"红原县",
                        "full_label":"四川省阿坝藏族羌族自治州红原县"
                    }
                ],
                "full_label":"四川省阿坝藏族羌族自治州"
            },
            {
                "id":"513300000000",
                "label":"甘孜藏族自治州",
                "children":[
                    {
                        "id":"513301000000",
                        "label":"康定市",
                        "full_label":"四川省甘孜藏族自治州康定市"
                    },
                    {
                        "id":"513322000000",
                        "label":"泸定县",
                        "full_label":"四川省甘孜藏族自治州泸定县"
                    },
                    {
                        "id":"513323000000",
                        "label":"丹巴县",
                        "full_label":"四川省甘孜藏族自治州丹巴县"
                    },
                    {
                        "id":"513324000000",
                        "label":"九龙县",
                        "full_label":"四川省甘孜藏族自治州九龙县"
                    },
                    {
                        "id":"513325000000",
                        "label":"雅江县",
                        "full_label":"四川省甘孜藏族自治州雅江县"
                    },
                    {
                        "id":"513326000000",
                        "label":"道孚县",
                        "full_label":"四川省甘孜藏族自治州道孚县"
                    },
                    {
                        "id":"513327000000",
                        "label":"炉霍县",
                        "full_label":"四川省甘孜藏族自治州炉霍县"
                    },
                    {
                        "id":"513328000000",
                        "label":"甘孜县",
                        "full_label":"四川省甘孜藏族自治州甘孜县"
                    },
                    {
                        "id":"513329000000",
                        "label":"新龙县",
                        "full_label":"四川省甘孜藏族自治州新龙县"
                    },
                    {
                        "id":"513330000000",
                        "label":"德格县",
                        "full_label":"四川省甘孜藏族自治州德格县"
                    },
                    {
                        "id":"513331000000",
                        "label":"白玉县",
                        "full_label":"四川省甘孜藏族自治州白玉县"
                    },
                    {
                        "id":"513332000000",
                        "label":"石渠县",
                        "full_label":"四川省甘孜藏族自治州石渠县"
                    },
                    {
                        "id":"513333000000",
                        "label":"色达县",
                        "full_label":"四川省甘孜藏族自治州色达县"
                    },
                    {
                        "id":"513334000000",
                        "label":"理塘县",
                        "full_label":"四川省甘孜藏族自治州理塘县"
                    },
                    {
                        "id":"513335000000",
                        "label":"巴塘县",
                        "full_label":"四川省甘孜藏族自治州巴塘县"
                    },
                    {
                        "id":"513336000000",
                        "label":"乡城县",
                        "full_label":"四川省甘孜藏族自治州乡城县"
                    },
                    {
                        "id":"513337000000",
                        "label":"稻城县",
                        "full_label":"四川省甘孜藏族自治州稻城县"
                    },
                    {
                        "id":"513338000000",
                        "label":"得荣县",
                        "full_label":"四川省甘孜藏族自治州得荣县"
                    }
                ],
                "full_label":"四川省甘孜藏族自治州"
            },
            {
                "id":"513400000000",
                "label":"凉山彝族自治州",
                "children":[
                    {
                        "id":"513401000000",
                        "label":"西昌市",
                        "full_label":"四川省凉山彝族自治州西昌市"
                    },
                    {
                        "id":"513402000000",
                        "label":"会理市",
                        "full_label":"四川省凉山彝族自治州会理市"
                    },
                    {
                        "id":"513422000000",
                        "label":"木里藏族自治县",
                        "full_label":"四川省凉山彝族自治州木里藏族自治县"
                    },
                    {
                        "id":"513423000000",
                        "label":"盐源县",
                        "full_label":"四川省凉山彝族自治州盐源县"
                    },
                    {
                        "id":"513424000000",
                        "label":"德昌县",
                        "full_label":"四川省凉山彝族自治州德昌县"
                    },
                    {
                        "id":"513426000000",
                        "label":"会东县",
                        "full_label":"四川省凉山彝族自治州会东县"
                    },
                    {
                        "id":"513427000000",
                        "label":"宁南县",
                        "full_label":"四川省凉山彝族自治州宁南县"
                    },
                    {
                        "id":"513428000000",
                        "label":"普格县",
                        "full_label":"四川省凉山彝族自治州普格县"
                    },
                    {
                        "id":"513429000000",
                        "label":"布拖县",
                        "full_label":"四川省凉山彝族自治州布拖县"
                    },
                    {
                        "id":"513430000000",
                        "label":"金阳县",
                        "full_label":"四川省凉山彝族自治州金阳县"
                    },
                    {
                        "id":"513431000000",
                        "label":"昭觉县",
                        "full_label":"四川省凉山彝族自治州昭觉县"
                    },
                    {
                        "id":"513432000000",
                        "label":"喜德县",
                        "full_label":"四川省凉山彝族自治州喜德县"
                    },
                    {
                        "id":"513433000000",
                        "label":"冕宁县",
                        "full_label":"四川省凉山彝族自治州冕宁县"
                    },
                    {
                        "id":"513434000000",
                        "label":"越西县",
                        "full_label":"四川省凉山彝族自治州越西县"
                    },
                    {
                        "id":"513435000000",
                        "label":"甘洛县",
                        "full_label":"四川省凉山彝族自治州甘洛县"
                    },
                    {
                        "id":"513436000000",
                        "label":"美姑县",
                        "full_label":"四川省凉山彝族自治州美姑县"
                    },
                    {
                        "id":"513437000000",
                        "label":"雷波县",
                        "full_label":"四川省凉山彝族自治州雷波县"
                    }
                ],
                "full_label":"四川省凉山彝族自治州"
            }
        ],
        "full_label":"四川省"
    },
    {
        "id":"520000000000",
        "label":"贵州省",
        "children":[
            {
                "id":"520100000000",
                "label":"贵阳市",
                "children":[
                    {
                        "id":"520102000000",
                        "label":"南明区",
                        "full_label":"贵州省贵阳市南明区"
                    },
                    {
                        "id":"520103000000",
                        "label":"云岩区",
                        "full_label":"贵州省贵阳市云岩区"
                    },
                    {
                        "id":"520111000000",
                        "label":"花溪区",
                        "full_label":"贵州省贵阳市花溪区"
                    },
                    {
                        "id":"520112000000",
                        "label":"乌当区",
                        "full_label":"贵州省贵阳市乌当区"
                    },
                    {
                        "id":"520113000000",
                        "label":"白云区",
                        "full_label":"贵州省贵阳市白云区"
                    },
                    {
                        "id":"520115000000",
                        "label":"观山湖区",
                        "full_label":"贵州省贵阳市观山湖区"
                    },
                    {
                        "id":"520121000000",
                        "label":"开阳县",
                        "full_label":"贵州省贵阳市开阳县"
                    },
                    {
                        "id":"520122000000",
                        "label":"息烽县",
                        "full_label":"贵州省贵阳市息烽县"
                    },
                    {
                        "id":"520123000000",
                        "label":"修文县",
                        "full_label":"贵州省贵阳市修文县"
                    },
                    {
                        "id":"520181000000",
                        "label":"清镇市",
                        "full_label":"贵州省贵阳市清镇市"
                    }
                ],
                "full_label":"贵州省贵阳市"
            },
            {
                "id":"520200000000",
                "label":"六盘水市",
                "children":[
                    {
                        "id":"520201000000",
                        "label":"钟山区",
                        "full_label":"贵州省六盘水市钟山区"
                    },
                    {
                        "id":"520203000000",
                        "label":"六枝特区",
                        "full_label":"贵州省六盘水市六枝特区"
                    },
                    {
                        "id":"520204000000",
                        "label":"水城区",
                        "full_label":"贵州省六盘水市水城区"
                    },
                    {
                        "id":"520281000000",
                        "label":"盘州市",
                        "full_label":"贵州省六盘水市盘州市"
                    }
                ],
                "full_label":"贵州省六盘水市"
            },
            {
                "id":"520300000000",
                "label":"遵义市",
                "children":[
                    {
                        "id":"520302000000",
                        "label":"红花岗区",
                        "full_label":"贵州省遵义市红花岗区"
                    },
                    {
                        "id":"520303000000",
                        "label":"汇川区",
                        "full_label":"贵州省遵义市汇川区"
                    },
                    {
                        "id":"520304000000",
                        "label":"播州区",
                        "full_label":"贵州省遵义市播州区"
                    },
                    {
                        "id":"520322000000",
                        "label":"桐梓县",
                        "full_label":"贵州省遵义市桐梓县"
                    },
                    {
                        "id":"520323000000",
                        "label":"绥阳县",
                        "full_label":"贵州省遵义市绥阳县"
                    },
                    {
                        "id":"520324000000",
                        "label":"正安县",
                        "full_label":"贵州省遵义市正安县"
                    },
                    {
                        "id":"520325000000",
                        "label":"道真仡佬族苗族自治县",
                        "full_label":"贵州省遵义市道真仡佬族苗族自治县"
                    },
                    {
                        "id":"520326000000",
                        "label":"务川仡佬族苗族自治县",
                        "full_label":"贵州省遵义市务川仡佬族苗族自治县"
                    },
                    {
                        "id":"520327000000",
                        "label":"凤冈县",
                        "full_label":"贵州省遵义市凤冈县"
                    },
                    {
                        "id":"520328000000",
                        "label":"湄潭县",
                        "full_label":"贵州省遵义市湄潭县"
                    },
                    {
                        "id":"520329000000",
                        "label":"余庆县",
                        "full_label":"贵州省遵义市余庆县"
                    },
                    {
                        "id":"520330000000",
                        "label":"习水县",
                        "full_label":"贵州省遵义市习水县"
                    },
                    {
                        "id":"520381000000",
                        "label":"赤水市",
                        "full_label":"贵州省遵义市赤水市"
                    },
                    {
                        "id":"520382000000",
                        "label":"仁怀市",
                        "full_label":"贵州省遵义市仁怀市"
                    }
                ],
                "full_label":"贵州省遵义市"
            },
            {
                "id":"520400000000",
                "label":"安顺市",
                "children":[
                    {
                        "id":"520402000000",
                        "label":"西秀区",
                        "full_label":"贵州省安顺市西秀区"
                    },
                    {
                        "id":"520403000000",
                        "label":"平坝区",
                        "full_label":"贵州省安顺市平坝区"
                    },
                    {
                        "id":"520422000000",
                        "label":"普定县",
                        "full_label":"贵州省安顺市普定县"
                    },
                    {
                        "id":"520423000000",
                        "label":"镇宁布依族苗族自治县",
                        "full_label":"贵州省安顺市镇宁布依族苗族自治县"
                    },
                    {
                        "id":"520424000000",
                        "label":"关岭布依族苗族自治县",
                        "full_label":"贵州省安顺市关岭布依族苗族自治县"
                    },
                    {
                        "id":"520425000000",
                        "label":"紫云苗族布依族自治县",
                        "full_label":"贵州省安顺市紫云苗族布依族自治县"
                    }
                ],
                "full_label":"贵州省安顺市"
            },
            {
                "id":"520500000000",
                "label":"毕节市",
                "children":[
                    {
                        "id":"520502000000",
                        "label":"七星关区",
                        "full_label":"贵州省毕节市七星关区"
                    },
                    {
                        "id":"520521000000",
                        "label":"大方县",
                        "full_label":"贵州省毕节市大方县"
                    },
                    {
                        "id":"520523000000",
                        "label":"金沙县",
                        "full_label":"贵州省毕节市金沙县"
                    },
                    {
                        "id":"520524000000",
                        "label":"织金县",
                        "full_label":"贵州省毕节市织金县"
                    },
                    {
                        "id":"520525000000",
                        "label":"纳雍县",
                        "full_label":"贵州省毕节市纳雍县"
                    },
                    {
                        "id":"520526000000",
                        "label":"威宁彝族回族苗族自治县",
                        "full_label":"贵州省毕节市威宁彝族回族苗族自治县"
                    },
                    {
                        "id":"520527000000",
                        "label":"赫章县",
                        "full_label":"贵州省毕节市赫章县"
                    },
                    {
                        "id":"520581000000",
                        "label":"黔西市",
                        "full_label":"贵州省毕节市黔西市"
                    }
                ],
                "full_label":"贵州省毕节市"
            },
            {
                "id":"520600000000",
                "label":"铜仁市",
                "children":[
                    {
                        "id":"520602000000",
                        "label":"碧江区",
                        "full_label":"贵州省铜仁市碧江区"
                    },
                    {
                        "id":"520603000000",
                        "label":"万山区",
                        "full_label":"贵州省铜仁市万山区"
                    },
                    {
                        "id":"520621000000",
                        "label":"江口县",
                        "full_label":"贵州省铜仁市江口县"
                    },
                    {
                        "id":"520622000000",
                        "label":"玉屏侗族自治县",
                        "full_label":"贵州省铜仁市玉屏侗族自治县"
                    },
                    {
                        "id":"520623000000",
                        "label":"石阡县",
                        "full_label":"贵州省铜仁市石阡县"
                    },
                    {
                        "id":"520624000000",
                        "label":"思南县",
                        "full_label":"贵州省铜仁市思南县"
                    },
                    {
                        "id":"520625000000",
                        "label":"印江土家族苗族自治县",
                        "full_label":"贵州省铜仁市印江土家族苗族自治县"
                    },
                    {
                        "id":"520626000000",
                        "label":"德江县",
                        "full_label":"贵州省铜仁市德江县"
                    },
                    {
                        "id":"520627000000",
                        "label":"沿河土家族自治县",
                        "full_label":"贵州省铜仁市沿河土家族自治县"
                    },
                    {
                        "id":"520628000000",
                        "label":"松桃苗族自治县",
                        "full_label":"贵州省铜仁市松桃苗族自治县"
                    }
                ],
                "full_label":"贵州省铜仁市"
            },
            {
                "id":"522300000000",
                "label":"黔西南布依族苗族自治州",
                "children":[
                    {
                        "id":"522301000000",
                        "label":"兴义市",
                        "full_label":"贵州省黔西南布依族苗族自治州兴义市"
                    },
                    {
                        "id":"522302000000",
                        "label":"兴仁市",
                        "full_label":"贵州省黔西南布依族苗族自治州兴仁市"
                    },
                    {
                        "id":"522323000000",
                        "label":"普安县",
                        "full_label":"贵州省黔西南布依族苗族自治州普安县"
                    },
                    {
                        "id":"522324000000",
                        "label":"晴隆县",
                        "full_label":"贵州省黔西南布依族苗族自治州晴隆县"
                    },
                    {
                        "id":"522325000000",
                        "label":"贞丰县",
                        "full_label":"贵州省黔西南布依族苗族自治州贞丰县"
                    },
                    {
                        "id":"522326000000",
                        "label":"望谟县",
                        "full_label":"贵州省黔西南布依族苗族自治州望谟县"
                    },
                    {
                        "id":"522327000000",
                        "label":"册亨县",
                        "full_label":"贵州省黔西南布依族苗族自治州册亨县"
                    },
                    {
                        "id":"522328000000",
                        "label":"安龙县",
                        "full_label":"贵州省黔西南布依族苗族自治州安龙县"
                    }
                ],
                "full_label":"贵州省黔西南布依族苗族自治州"
            },
            {
                "id":"522600000000",
                "label":"黔东南苗族侗族自治州",
                "children":[
                    {
                        "id":"522601000000",
                        "label":"凯里市",
                        "full_label":"贵州省黔东南苗族侗族自治州凯里市"
                    },
                    {
                        "id":"522622000000",
                        "label":"黄平县",
                        "full_label":"贵州省黔东南苗族侗族自治州黄平县"
                    },
                    {
                        "id":"522623000000",
                        "label":"施秉县",
                        "full_label":"贵州省黔东南苗族侗族自治州施秉县"
                    },
                    {
                        "id":"522624000000",
                        "label":"三穗县",
                        "full_label":"贵州省黔东南苗族侗族自治州三穗县"
                    },
                    {
                        "id":"522625000000",
                        "label":"镇远县",
                        "full_label":"贵州省黔东南苗族侗族自治州镇远县"
                    },
                    {
                        "id":"522626000000",
                        "label":"岑巩县",
                        "full_label":"贵州省黔东南苗族侗族自治州岑巩县"
                    },
                    {
                        "id":"522627000000",
                        "label":"天柱县",
                        "full_label":"贵州省黔东南苗族侗族自治州天柱县"
                    },
                    {
                        "id":"522628000000",
                        "label":"锦屏县",
                        "full_label":"贵州省黔东南苗族侗族自治州锦屏县"
                    },
                    {
                        "id":"522629000000",
                        "label":"剑河县",
                        "full_label":"贵州省黔东南苗族侗族自治州剑河县"
                    },
                    {
                        "id":"522630000000",
                        "label":"台江县",
                        "full_label":"贵州省黔东南苗族侗族自治州台江县"
                    },
                    {
                        "id":"522631000000",
                        "label":"黎平县",
                        "full_label":"贵州省黔东南苗族侗族自治州黎平县"
                    },
                    {
                        "id":"522632000000",
                        "label":"榕江县",
                        "full_label":"贵州省黔东南苗族侗族自治州榕江县"
                    },
                    {
                        "id":"522633000000",
                        "label":"从江县",
                        "full_label":"贵州省黔东南苗族侗族自治州从江县"
                    },
                    {
                        "id":"522634000000",
                        "label":"雷山县",
                        "full_label":"贵州省黔东南苗族侗族自治州雷山县"
                    },
                    {
                        "id":"522635000000",
                        "label":"麻江县",
                        "full_label":"贵州省黔东南苗族侗族自治州麻江县"
                    },
                    {
                        "id":"522636000000",
                        "label":"丹寨县",
                        "full_label":"贵州省黔东南苗族侗族自治州丹寨县"
                    }
                ],
                "full_label":"贵州省黔东南苗族侗族自治州"
            },
            {
                "id":"522700000000",
                "label":"黔南布依族苗族自治州",
                "children":[
                    {
                        "id":"522701000000",
                        "label":"都匀市",
                        "full_label":"贵州省黔南布依族苗族自治州都匀市"
                    },
                    {
                        "id":"522702000000",
                        "label":"福泉市",
                        "full_label":"贵州省黔南布依族苗族自治州福泉市"
                    },
                    {
                        "id":"522722000000",
                        "label":"荔波县",
                        "full_label":"贵州省黔南布依族苗族自治州荔波县"
                    },
                    {
                        "id":"522723000000",
                        "label":"贵定县",
                        "full_label":"贵州省黔南布依族苗族自治州贵定县"
                    },
                    {
                        "id":"522725000000",
                        "label":"瓮安县",
                        "full_label":"贵州省黔南布依族苗族自治州瓮安县"
                    },
                    {
                        "id":"522726000000",
                        "label":"独山县",
                        "full_label":"贵州省黔南布依族苗族自治州独山县"
                    },
                    {
                        "id":"522727000000",
                        "label":"平塘县",
                        "full_label":"贵州省黔南布依族苗族自治州平塘县"
                    },
                    {
                        "id":"522728000000",
                        "label":"罗甸县",
                        "full_label":"贵州省黔南布依族苗族自治州罗甸县"
                    },
                    {
                        "id":"522729000000",
                        "label":"长顺县",
                        "full_label":"贵州省黔南布依族苗族自治州长顺县"
                    },
                    {
                        "id":"522730000000",
                        "label":"龙里县",
                        "full_label":"贵州省黔南布依族苗族自治州龙里县"
                    },
                    {
                        "id":"522731000000",
                        "label":"惠水县",
                        "full_label":"贵州省黔南布依族苗族自治州惠水县"
                    },
                    {
                        "id":"522732000000",
                        "label":"三都水族自治县",
                        "full_label":"贵州省黔南布依族苗族自治州三都水族自治县"
                    }
                ],
                "full_label":"贵州省黔南布依族苗族自治州"
            }
        ],
        "full_label":"贵州省"
    },
    {
        "id":"530000000000",
        "label":"云南省",
        "children":[
            {
                "id":"530100000000",
                "label":"昆明市",
                "children":[
                    {
                        "id":"530102000000",
                        "label":"五华区",
                        "full_label":"云南省昆明市五华区"
                    },
                    {
                        "id":"530103000000",
                        "label":"盘龙区",
                        "full_label":"云南省昆明市盘龙区"
                    },
                    {
                        "id":"530111000000",
                        "label":"官渡区",
                        "full_label":"云南省昆明市官渡区"
                    },
                    {
                        "id":"530112000000",
                        "label":"西山区",
                        "full_label":"云南省昆明市西山区"
                    },
                    {
                        "id":"530113000000",
                        "label":"东川区",
                        "full_label":"云南省昆明市东川区"
                    },
                    {
                        "id":"530114000000",
                        "label":"呈贡区",
                        "full_label":"云南省昆明市呈贡区"
                    },
                    {
                        "id":"530115000000",
                        "label":"晋宁区",
                        "full_label":"云南省昆明市晋宁区"
                    },
                    {
                        "id":"530124000000",
                        "label":"富民县",
                        "full_label":"云南省昆明市富民县"
                    },
                    {
                        "id":"530125000000",
                        "label":"宜良县",
                        "full_label":"云南省昆明市宜良县"
                    },
                    {
                        "id":"530126000000",
                        "label":"石林彝族自治县",
                        "full_label":"云南省昆明市石林彝族自治县"
                    },
                    {
                        "id":"530127000000",
                        "label":"嵩明县",
                        "full_label":"云南省昆明市嵩明县"
                    },
                    {
                        "id":"530128000000",
                        "label":"禄劝彝族苗族自治县",
                        "full_label":"云南省昆明市禄劝彝族苗族自治县"
                    },
                    {
                        "id":"530129000000",
                        "label":"寻甸回族彝族自治县",
                        "full_label":"云南省昆明市寻甸回族彝族自治县"
                    },
                    {
                        "id":"530181000000",
                        "label":"安宁市",
                        "full_label":"云南省昆明市安宁市"
                    }
                ],
                "full_label":"云南省昆明市"
            },
            {
                "id":"530300000000",
                "label":"曲靖市",
                "children":[
                    {
                        "id":"530302000000",
                        "label":"麒麟区",
                        "full_label":"云南省曲靖市麒麟区"
                    },
                    {
                        "id":"530303000000",
                        "label":"沾益区",
                        "full_label":"云南省曲靖市沾益区"
                    },
                    {
                        "id":"530304000000",
                        "label":"马龙区",
                        "full_label":"云南省曲靖市马龙区"
                    },
                    {
                        "id":"530322000000",
                        "label":"陆良县",
                        "full_label":"云南省曲靖市陆良县"
                    },
                    {
                        "id":"530323000000",
                        "label":"师宗县",
                        "full_label":"云南省曲靖市师宗县"
                    },
                    {
                        "id":"530324000000",
                        "label":"罗平县",
                        "full_label":"云南省曲靖市罗平县"
                    },
                    {
                        "id":"530325000000",
                        "label":"富源县",
                        "full_label":"云南省曲靖市富源县"
                    },
                    {
                        "id":"530326000000",
                        "label":"会泽县",
                        "full_label":"云南省曲靖市会泽县"
                    },
                    {
                        "id":"530381000000",
                        "label":"宣威市",
                        "full_label":"云南省曲靖市宣威市"
                    }
                ],
                "full_label":"云南省曲靖市"
            },
            {
                "id":"530400000000",
                "label":"玉溪市",
                "children":[
                    {
                        "id":"530402000000",
                        "label":"红塔区",
                        "full_label":"云南省玉溪市红塔区"
                    },
                    {
                        "id":"530403000000",
                        "label":"江川区",
                        "full_label":"云南省玉溪市江川区"
                    },
                    {
                        "id":"530423000000",
                        "label":"通海县",
                        "full_label":"云南省玉溪市通海县"
                    },
                    {
                        "id":"530424000000",
                        "label":"华宁县",
                        "full_label":"云南省玉溪市华宁县"
                    },
                    {
                        "id":"530425000000",
                        "label":"易门县",
                        "full_label":"云南省玉溪市易门县"
                    },
                    {
                        "id":"530426000000",
                        "label":"峨山彝族自治县",
                        "full_label":"云南省玉溪市峨山彝族自治县"
                    },
                    {
                        "id":"530427000000",
                        "label":"新平彝族傣族自治县",
                        "full_label":"云南省玉溪市新平彝族傣族自治县"
                    },
                    {
                        "id":"530428000000",
                        "label":"元江哈尼族彝族傣族自治县",
                        "full_label":"云南省玉溪市元江哈尼族彝族傣族自治县"
                    },
                    {
                        "id":"530481000000",
                        "label":"澄江市",
                        "full_label":"云南省玉溪市澄江市"
                    }
                ],
                "full_label":"云南省玉溪市"
            },
            {
                "id":"530500000000",
                "label":"保山市",
                "children":[
                    {
                        "id":"530502000000",
                        "label":"隆阳区",
                        "full_label":"云南省保山市隆阳区"
                    },
                    {
                        "id":"530521000000",
                        "label":"施甸县",
                        "full_label":"云南省保山市施甸县"
                    },
                    {
                        "id":"530523000000",
                        "label":"龙陵县",
                        "full_label":"云南省保山市龙陵县"
                    },
                    {
                        "id":"530524000000",
                        "label":"昌宁县",
                        "full_label":"云南省保山市昌宁县"
                    },
                    {
                        "id":"530581000000",
                        "label":"腾冲市",
                        "full_label":"云南省保山市腾冲市"
                    }
                ],
                "full_label":"云南省保山市"
            },
            {
                "id":"530600000000",
                "label":"昭通市",
                "children":[
                    {
                        "id":"530602000000",
                        "label":"昭阳区",
                        "full_label":"云南省昭通市昭阳区"
                    },
                    {
                        "id":"530621000000",
                        "label":"鲁甸县",
                        "full_label":"云南省昭通市鲁甸县"
                    },
                    {
                        "id":"530622000000",
                        "label":"巧家县",
                        "full_label":"云南省昭通市巧家县"
                    },
                    {
                        "id":"530623000000",
                        "label":"盐津县",
                        "full_label":"云南省昭通市盐津县"
                    },
                    {
                        "id":"530624000000",
                        "label":"大关县",
                        "full_label":"云南省昭通市大关县"
                    },
                    {
                        "id":"530625000000",
                        "label":"永善县",
                        "full_label":"云南省昭通市永善县"
                    },
                    {
                        "id":"530626000000",
                        "label":"绥江县",
                        "full_label":"云南省昭通市绥江县"
                    },
                    {
                        "id":"530627000000",
                        "label":"镇雄县",
                        "full_label":"云南省昭通市镇雄县"
                    },
                    {
                        "id":"530628000000",
                        "label":"彝良县",
                        "full_label":"云南省昭通市彝良县"
                    },
                    {
                        "id":"530629000000",
                        "label":"威信县",
                        "full_label":"云南省昭通市威信县"
                    },
                    {
                        "id":"530681000000",
                        "label":"水富市",
                        "full_label":"云南省昭通市水富市"
                    }
                ],
                "full_label":"云南省昭通市"
            },
            {
                "id":"530700000000",
                "label":"丽江市",
                "children":[
                    {
                        "id":"530702000000",
                        "label":"古城区",
                        "full_label":"云南省丽江市古城区"
                    },
                    {
                        "id":"530721000000",
                        "label":"玉龙纳西族自治县",
                        "full_label":"云南省丽江市玉龙纳西族自治县"
                    },
                    {
                        "id":"530722000000",
                        "label":"永胜县",
                        "full_label":"云南省丽江市永胜县"
                    },
                    {
                        "id":"530723000000",
                        "label":"华坪县",
                        "full_label":"云南省丽江市华坪县"
                    },
                    {
                        "id":"530724000000",
                        "label":"宁蒗彝族自治县",
                        "full_label":"云南省丽江市宁蒗彝族自治县"
                    }
                ],
                "full_label":"云南省丽江市"
            },
            {
                "id":"530800000000",
                "label":"普洱市",
                "children":[
                    {
                        "id":"530802000000",
                        "label":"思茅区",
                        "full_label":"云南省普洱市思茅区"
                    },
                    {
                        "id":"530821000000",
                        "label":"宁洱哈尼族彝族自治县",
                        "full_label":"云南省普洱市宁洱哈尼族彝族自治县"
                    },
                    {
                        "id":"530822000000",
                        "label":"墨江哈尼族自治县",
                        "full_label":"云南省普洱市墨江哈尼族自治县"
                    },
                    {
                        "id":"530823000000",
                        "label":"景东彝族自治县",
                        "full_label":"云南省普洱市景东彝族自治县"
                    },
                    {
                        "id":"530824000000",
                        "label":"景谷傣族彝族自治县",
                        "full_label":"云南省普洱市景谷傣族彝族自治县"
                    },
                    {
                        "id":"530825000000",
                        "label":"镇沅彝族哈尼族拉祜族自治县",
                        "full_label":"云南省普洱市镇沅彝族哈尼族拉祜族自治县"
                    },
                    {
                        "id":"530826000000",
                        "label":"江城哈尼族彝族自治县",
                        "full_label":"云南省普洱市江城哈尼族彝族自治县"
                    },
                    {
                        "id":"530827000000",
                        "label":"孟连傣族拉祜族佤族自治县",
                        "full_label":"云南省普洱市孟连傣族拉祜族佤族自治县"
                    },
                    {
                        "id":"530828000000",
                        "label":"澜沧拉祜族自治县",
                        "full_label":"云南省普洱市澜沧拉祜族自治县"
                    },
                    {
                        "id":"530829000000",
                        "label":"西盟佤族自治县",
                        "full_label":"云南省普洱市西盟佤族自治县"
                    }
                ],
                "full_label":"云南省普洱市"
            },
            {
                "id":"530900000000",
                "label":"临沧市",
                "children":[
                    {
                        "id":"530902000000",
                        "label":"临翔区",
                        "full_label":"云南省临沧市临翔区"
                    },
                    {
                        "id":"530921000000",
                        "label":"凤庆县",
                        "full_label":"云南省临沧市凤庆县"
                    },
                    {
                        "id":"530922000000",
                        "label":"云县",
                        "full_label":"云南省临沧市云县"
                    },
                    {
                        "id":"530923000000",
                        "label":"永德县",
                        "full_label":"云南省临沧市永德县"
                    },
                    {
                        "id":"530924000000",
                        "label":"镇康县",
                        "full_label":"云南省临沧市镇康县"
                    },
                    {
                        "id":"530925000000",
                        "label":"双江拉祜族佤族布朗族傣族自治县",
                        "full_label":"云南省临沧市双江拉祜族佤族布朗族傣族自治县"
                    },
                    {
                        "id":"530926000000",
                        "label":"耿马傣族佤族自治县",
                        "full_label":"云南省临沧市耿马傣族佤族自治县"
                    },
                    {
                        "id":"530927000000",
                        "label":"沧源佤族自治县",
                        "full_label":"云南省临沧市沧源佤族自治县"
                    }
                ],
                "full_label":"云南省临沧市"
            },
            {
                "id":"532300000000",
                "label":"楚雄彝族自治州",
                "children":[
                    {
                        "id":"532301000000",
                        "label":"楚雄市",
                        "full_label":"云南省楚雄彝族自治州楚雄市"
                    },
                    {
                        "id":"532302000000",
                        "label":"禄丰市",
                        "full_label":"云南省楚雄彝族自治州禄丰市"
                    },
                    {
                        "id":"532322000000",
                        "label":"双柏县",
                        "full_label":"云南省楚雄彝族自治州双柏县"
                    },
                    {
                        "id":"532323000000",
                        "label":"牟定县",
                        "full_label":"云南省楚雄彝族自治州牟定县"
                    },
                    {
                        "id":"532324000000",
                        "label":"南华县",
                        "full_label":"云南省楚雄彝族自治州南华县"
                    },
                    {
                        "id":"532325000000",
                        "label":"姚安县",
                        "full_label":"云南省楚雄彝族自治州姚安县"
                    },
                    {
                        "id":"532326000000",
                        "label":"大姚县",
                        "full_label":"云南省楚雄彝族自治州大姚县"
                    },
                    {
                        "id":"532327000000",
                        "label":"永仁县",
                        "full_label":"云南省楚雄彝族自治州永仁县"
                    },
                    {
                        "id":"532328000000",
                        "label":"元谋县",
                        "full_label":"云南省楚雄彝族自治州元谋县"
                    },
                    {
                        "id":"532329000000",
                        "label":"武定县",
                        "full_label":"云南省楚雄彝族自治州武定县"
                    }
                ],
                "full_label":"云南省楚雄彝族自治州"
            },
            {
                "id":"532500000000",
                "label":"红河哈尼族彝族自治州",
                "children":[
                    {
                        "id":"532501000000",
                        "label":"个旧市",
                        "full_label":"云南省红河哈尼族彝族自治州个旧市"
                    },
                    {
                        "id":"532502000000",
                        "label":"开远市",
                        "full_label":"云南省红河哈尼族彝族自治州开远市"
                    },
                    {
                        "id":"532503000000",
                        "label":"蒙自市",
                        "full_label":"云南省红河哈尼族彝族自治州蒙自市"
                    },
                    {
                        "id":"532504000000",
                        "label":"弥勒市",
                        "full_label":"云南省红河哈尼族彝族自治州弥勒市"
                    },
                    {
                        "id":"532523000000",
                        "label":"屏边苗族自治县",
                        "full_label":"云南省红河哈尼族彝族自治州屏边苗族自治县"
                    },
                    {
                        "id":"532524000000",
                        "label":"建水县",
                        "full_label":"云南省红河哈尼族彝族自治州建水县"
                    },
                    {
                        "id":"532525000000",
                        "label":"石屏县",
                        "full_label":"云南省红河哈尼族彝族自治州石屏县"
                    },
                    {
                        "id":"532527000000",
                        "label":"泸西县",
                        "full_label":"云南省红河哈尼族彝族自治州泸西县"
                    },
                    {
                        "id":"532528000000",
                        "label":"元阳县",
                        "full_label":"云南省红河哈尼族彝族自治州元阳县"
                    },
                    {
                        "id":"532529000000",
                        "label":"红河县",
                        "full_label":"云南省红河哈尼族彝族自治州红河县"
                    },
                    {
                        "id":"532530000000",
                        "label":"金平苗族瑶族傣族自治县",
                        "full_label":"云南省红河哈尼族彝族自治州金平苗族瑶族傣族自治县"
                    },
                    {
                        "id":"532531000000",
                        "label":"绿春县",
                        "full_label":"云南省红河哈尼族彝族自治州绿春县"
                    },
                    {
                        "id":"532532000000",
                        "label":"河口瑶族自治县",
                        "full_label":"云南省红河哈尼族彝族自治州河口瑶族自治县"
                    }
                ],
                "full_label":"云南省红河哈尼族彝族自治州"
            },
            {
                "id":"532600000000",
                "label":"文山壮族苗族自治州",
                "children":[
                    {
                        "id":"532601000000",
                        "label":"文山市",
                        "full_label":"云南省文山壮族苗族自治州文山市"
                    },
                    {
                        "id":"532622000000",
                        "label":"砚山县",
                        "full_label":"云南省文山壮族苗族自治州砚山县"
                    },
                    {
                        "id":"532623000000",
                        "label":"西畴县",
                        "full_label":"云南省文山壮族苗族自治州西畴县"
                    },
                    {
                        "id":"532624000000",
                        "label":"麻栗坡县",
                        "full_label":"云南省文山壮族苗族自治州麻栗坡县"
                    },
                    {
                        "id":"532625000000",
                        "label":"马关县",
                        "full_label":"云南省文山壮族苗族自治州马关县"
                    },
                    {
                        "id":"532626000000",
                        "label":"丘北县",
                        "full_label":"云南省文山壮族苗族自治州丘北县"
                    },
                    {
                        "id":"532627000000",
                        "label":"广南县",
                        "full_label":"云南省文山壮族苗族自治州广南县"
                    },
                    {
                        "id":"532628000000",
                        "label":"富宁县",
                        "full_label":"云南省文山壮族苗族自治州富宁县"
                    }
                ],
                "full_label":"云南省文山壮族苗族自治州"
            },
            {
                "id":"532800000000",
                "label":"西双版纳傣族自治州",
                "children":[
                    {
                        "id":"532801000000",
                        "label":"景洪市",
                        "full_label":"云南省西双版纳傣族自治州景洪市"
                    },
                    {
                        "id":"532822000000",
                        "label":"勐海县",
                        "full_label":"云南省西双版纳傣族自治州勐海县"
                    },
                    {
                        "id":"532823000000",
                        "label":"勐腊县",
                        "full_label":"云南省西双版纳傣族自治州勐腊县"
                    }
                ],
                "full_label":"云南省西双版纳傣族自治州"
            },
            {
                "id":"532900000000",
                "label":"大理白族自治州",
                "children":[
                    {
                        "id":"532901000000",
                        "label":"大理市",
                        "full_label":"云南省大理白族自治州大理市"
                    },
                    {
                        "id":"532922000000",
                        "label":"漾濞彝族自治县",
                        "full_label":"云南省大理白族自治州漾濞彝族自治县"
                    },
                    {
                        "id":"532923000000",
                        "label":"祥云县",
                        "full_label":"云南省大理白族自治州祥云县"
                    },
                    {
                        "id":"532924000000",
                        "label":"宾川县",
                        "full_label":"云南省大理白族自治州宾川县"
                    },
                    {
                        "id":"532925000000",
                        "label":"弥渡县",
                        "full_label":"云南省大理白族自治州弥渡县"
                    },
                    {
                        "id":"532926000000",
                        "label":"南涧彝族自治县",
                        "full_label":"云南省大理白族自治州南涧彝族自治县"
                    },
                    {
                        "id":"532927000000",
                        "label":"巍山彝族回族自治县",
                        "full_label":"云南省大理白族自治州巍山彝族回族自治县"
                    },
                    {
                        "id":"532928000000",
                        "label":"永平县",
                        "full_label":"云南省大理白族自治州永平县"
                    },
                    {
                        "id":"532929000000",
                        "label":"云龙县",
                        "full_label":"云南省大理白族自治州云龙县"
                    },
                    {
                        "id":"532930000000",
                        "label":"洱源县",
                        "full_label":"云南省大理白族自治州洱源县"
                    },
                    {
                        "id":"532931000000",
                        "label":"剑川县",
                        "full_label":"云南省大理白族自治州剑川县"
                    },
                    {
                        "id":"532932000000",
                        "label":"鹤庆县",
                        "full_label":"云南省大理白族自治州鹤庆县"
                    }
                ],
                "full_label":"云南省大理白族自治州"
            },
            {
                "id":"533100000000",
                "label":"德宏傣族景颇族自治州",
                "children":[
                    {
                        "id":"533102000000",
                        "label":"瑞丽市",
                        "full_label":"云南省德宏傣族景颇族自治州瑞丽市"
                    },
                    {
                        "id":"533103000000",
                        "label":"芒市",
                        "full_label":"云南省德宏傣族景颇族自治州芒市"
                    },
                    {
                        "id":"533122000000",
                        "label":"梁河县",
                        "full_label":"云南省德宏傣族景颇族自治州梁河县"
                    },
                    {
                        "id":"533123000000",
                        "label":"盈江县",
                        "full_label":"云南省德宏傣族景颇族自治州盈江县"
                    },
                    {
                        "id":"533124000000",
                        "label":"陇川县",
                        "full_label":"云南省德宏傣族景颇族自治州陇川县"
                    }
                ],
                "full_label":"云南省德宏傣族景颇族自治州"
            },
            {
                "id":"533300000000",
                "label":"怒江傈僳族自治州",
                "children":[
                    {
                        "id":"533301000000",
                        "label":"泸水市",
                        "full_label":"云南省怒江傈僳族自治州泸水市"
                    },
                    {
                        "id":"533323000000",
                        "label":"福贡县",
                        "full_label":"云南省怒江傈僳族自治州福贡县"
                    },
                    {
                        "id":"533324000000",
                        "label":"贡山独龙族怒族自治县",
                        "full_label":"云南省怒江傈僳族自治州贡山独龙族怒族自治县"
                    },
                    {
                        "id":"533325000000",
                        "label":"兰坪白族普米族自治县",
                        "full_label":"云南省怒江傈僳族自治州兰坪白族普米族自治县"
                    }
                ],
                "full_label":"云南省怒江傈僳族自治州"
            },
            {
                "id":"533400000000",
                "label":"迪庆藏族自治州",
                "children":[
                    {
                        "id":"533401000000",
                        "label":"香格里拉市",
                        "full_label":"云南省迪庆藏族自治州香格里拉市"
                    },
                    {
                        "id":"533422000000",
                        "label":"德钦县",
                        "full_label":"云南省迪庆藏族自治州德钦县"
                    },
                    {
                        "id":"533423000000",
                        "label":"维西傈僳族自治县",
                        "full_label":"云南省迪庆藏族自治州维西傈僳族自治县"
                    }
                ],
                "full_label":"云南省迪庆藏族自治州"
            }
        ],
        "full_label":"云南省"
    },
    {
        "id":"540000000000",
        "label":"西藏自治区",
        "children":[
            {
                "id":"540100000000",
                "label":"拉萨市",
                "children":[
                    {
                        "id":"540102000000",
                        "label":"城关区",
                        "full_label":"西藏自治区拉萨市城关区"
                    },
                    {
                        "id":"540103000000",
                        "label":"堆龙德庆区",
                        "full_label":"西藏自治区拉萨市堆龙德庆区"
                    },
                    {
                        "id":"540104000000",
                        "label":"达孜区",
                        "full_label":"西藏自治区拉萨市达孜区"
                    },
                    {
                        "id":"540121000000",
                        "label":"林周县",
                        "full_label":"西藏自治区拉萨市林周县"
                    },
                    {
                        "id":"540122000000",
                        "label":"当雄县",
                        "full_label":"西藏自治区拉萨市当雄县"
                    },
                    {
                        "id":"540123000000",
                        "label":"尼木县",
                        "full_label":"西藏自治区拉萨市尼木县"
                    },
                    {
                        "id":"540124000000",
                        "label":"曲水县",
                        "full_label":"西藏自治区拉萨市曲水县"
                    },
                    {
                        "id":"540127000000",
                        "label":"墨竹工卡县",
                        "full_label":"西藏自治区拉萨市墨竹工卡县"
                    }
                ],
                "full_label":"西藏自治区拉萨市"
            },
            {
                "id":"540200000000",
                "label":"日喀则市",
                "children":[
                    {
                        "id":"540202000000",
                        "label":"桑珠孜区",
                        "full_label":"西藏自治区日喀则市桑珠孜区"
                    },
                    {
                        "id":"540221000000",
                        "label":"南木林县",
                        "full_label":"西藏自治区日喀则市南木林县"
                    },
                    {
                        "id":"540222000000",
                        "label":"江孜县",
                        "full_label":"西藏自治区日喀则市江孜县"
                    },
                    {
                        "id":"540223000000",
                        "label":"定日县",
                        "full_label":"西藏自治区日喀则市定日县"
                    },
                    {
                        "id":"540224000000",
                        "label":"萨迦县",
                        "full_label":"西藏自治区日喀则市萨迦县"
                    },
                    {
                        "id":"540225000000",
                        "label":"拉孜县",
                        "full_label":"西藏自治区日喀则市拉孜县"
                    },
                    {
                        "id":"540226000000",
                        "label":"昂仁县",
                        "full_label":"西藏自治区日喀则市昂仁县"
                    },
                    {
                        "id":"540227000000",
                        "label":"谢通门县",
                        "full_label":"西藏自治区日喀则市谢通门县"
                    },
                    {
                        "id":"540228000000",
                        "label":"白朗县",
                        "full_label":"西藏自治区日喀则市白朗县"
                    },
                    {
                        "id":"540229000000",
                        "label":"仁布县",
                        "full_label":"西藏自治区日喀则市仁布县"
                    },
                    {
                        "id":"540230000000",
                        "label":"康马县",
                        "full_label":"西藏自治区日喀则市康马县"
                    },
                    {
                        "id":"540231000000",
                        "label":"定结县",
                        "full_label":"西藏自治区日喀则市定结县"
                    },
                    {
                        "id":"540232000000",
                        "label":"仲巴县",
                        "full_label":"西藏自治区日喀则市仲巴县"
                    },
                    {
                        "id":"540233000000",
                        "label":"亚东县",
                        "full_label":"西藏自治区日喀则市亚东县"
                    },
                    {
                        "id":"540234000000",
                        "label":"吉隆县",
                        "full_label":"西藏自治区日喀则市吉隆县"
                    },
                    {
                        "id":"540235000000",
                        "label":"聂拉木县",
                        "full_label":"西藏自治区日喀则市聂拉木县"
                    },
                    {
                        "id":"540236000000",
                        "label":"萨嘎县",
                        "full_label":"西藏自治区日喀则市萨嘎县"
                    },
                    {
                        "id":"540237000000",
                        "label":"岗巴县",
                        "full_label":"西藏自治区日喀则市岗巴县"
                    }
                ],
                "full_label":"西藏自治区日喀则市"
            },
            {
                "id":"540300000000",
                "label":"昌都市",
                "children":[
                    {
                        "id":"540302000000",
                        "label":"卡若区",
                        "full_label":"西藏自治区昌都市卡若区"
                    },
                    {
                        "id":"540321000000",
                        "label":"江达县",
                        "full_label":"西藏自治区昌都市江达县"
                    },
                    {
                        "id":"540322000000",
                        "label":"贡觉县",
                        "full_label":"西藏自治区昌都市贡觉县"
                    },
                    {
                        "id":"540323000000",
                        "label":"类乌齐县",
                        "full_label":"西藏自治区昌都市类乌齐县"
                    },
                    {
                        "id":"540324000000",
                        "label":"丁青县",
                        "full_label":"西藏自治区昌都市丁青县"
                    },
                    {
                        "id":"540325000000",
                        "label":"察雅县",
                        "full_label":"西藏自治区昌都市察雅县"
                    },
                    {
                        "id":"540326000000",
                        "label":"八宿县",
                        "full_label":"西藏自治区昌都市八宿县"
                    },
                    {
                        "id":"540327000000",
                        "label":"左贡县",
                        "full_label":"西藏自治区昌都市左贡县"
                    },
                    {
                        "id":"540328000000",
                        "label":"芒康县",
                        "full_label":"西藏自治区昌都市芒康县"
                    },
                    {
                        "id":"540329000000",
                        "label":"洛隆县",
                        "full_label":"西藏自治区昌都市洛隆县"
                    },
                    {
                        "id":"540330000000",
                        "label":"边坝县",
                        "full_label":"西藏自治区昌都市边坝县"
                    }
                ],
                "full_label":"西藏自治区昌都市"
            },
            {
                "id":"540400000000",
                "label":"林芝市",
                "children":[
                    {
                        "id":"540402000000",
                        "label":"巴宜区",
                        "full_label":"西藏自治区林芝市巴宜区"
                    },
                    {
                        "id":"540421000000",
                        "label":"工布江达县",
                        "full_label":"西藏自治区林芝市工布江达县"
                    },
                    {
                        "id":"540422000000",
                        "label":"米林县",
                        "full_label":"西藏自治区林芝市米林县"
                    },
                    {
                        "id":"540423000000",
                        "label":"墨脱县",
                        "full_label":"西藏自治区林芝市墨脱县"
                    },
                    {
                        "id":"540424000000",
                        "label":"波密县",
                        "full_label":"西藏自治区林芝市波密县"
                    },
                    {
                        "id":"540425000000",
                        "label":"察隅县",
                        "full_label":"西藏自治区林芝市察隅县"
                    },
                    {
                        "id":"540426000000",
                        "label":"朗县",
                        "full_label":"西藏自治区林芝市朗县"
                    }
                ],
                "full_label":"西藏自治区林芝市"
            },
            {
                "id":"540500000000",
                "label":"山南市",
                "children":[
                    {
                        "id":"540502000000",
                        "label":"乃东区",
                        "full_label":"西藏自治区山南市乃东区"
                    },
                    {
                        "id":"540521000000",
                        "label":"扎囊县",
                        "full_label":"西藏自治区山南市扎囊县"
                    },
                    {
                        "id":"540522000000",
                        "label":"贡嘎县",
                        "full_label":"西藏自治区山南市贡嘎县"
                    },
                    {
                        "id":"540523000000",
                        "label":"桑日县",
                        "full_label":"西藏自治区山南市桑日县"
                    },
                    {
                        "id":"540524000000",
                        "label":"琼结县",
                        "full_label":"西藏自治区山南市琼结县"
                    },
                    {
                        "id":"540525000000",
                        "label":"曲松县",
                        "full_label":"西藏自治区山南市曲松县"
                    },
                    {
                        "id":"540526000000",
                        "label":"措美县",
                        "full_label":"西藏自治区山南市措美县"
                    },
                    {
                        "id":"540527000000",
                        "label":"洛扎县",
                        "full_label":"西藏自治区山南市洛扎县"
                    },
                    {
                        "id":"540528000000",
                        "label":"加查县",
                        "full_label":"西藏自治区山南市加查县"
                    },
                    {
                        "id":"540529000000",
                        "label":"隆子县",
                        "full_label":"西藏自治区山南市隆子县"
                    },
                    {
                        "id":"540530000000",
                        "label":"错那县",
                        "full_label":"西藏自治区山南市错那县"
                    },
                    {
                        "id":"540531000000",
                        "label":"浪卡子县",
                        "full_label":"西藏自治区山南市浪卡子县"
                    }
                ],
                "full_label":"西藏自治区山南市"
            },
            {
                "id":"540600000000",
                "label":"那曲市",
                "children":[
                    {
                        "id":"540602000000",
                        "label":"色尼区",
                        "full_label":"西藏自治区那曲市色尼区"
                    },
                    {
                        "id":"540621000000",
                        "label":"嘉黎县",
                        "full_label":"西藏自治区那曲市嘉黎县"
                    },
                    {
                        "id":"540622000000",
                        "label":"比如县",
                        "full_label":"西藏自治区那曲市比如县"
                    },
                    {
                        "id":"540623000000",
                        "label":"聂荣县",
                        "full_label":"西藏自治区那曲市聂荣县"
                    },
                    {
                        "id":"540624000000",
                        "label":"安多县",
                        "full_label":"西藏自治区那曲市安多县"
                    },
                    {
                        "id":"540625000000",
                        "label":"申扎县",
                        "full_label":"西藏自治区那曲市申扎县"
                    },
                    {
                        "id":"540626000000",
                        "label":"索县",
                        "full_label":"西藏自治区那曲市索县"
                    },
                    {
                        "id":"540627000000",
                        "label":"班戈县",
                        "full_label":"西藏自治区那曲市班戈县"
                    },
                    {
                        "id":"540628000000",
                        "label":"巴青县",
                        "full_label":"西藏自治区那曲市巴青县"
                    },
                    {
                        "id":"540629000000",
                        "label":"尼玛县",
                        "full_label":"西藏自治区那曲市尼玛县"
                    },
                    {
                        "id":"540630000000",
                        "label":"双湖县",
                        "full_label":"西藏自治区那曲市双湖县"
                    }
                ],
                "full_label":"西藏自治区那曲市"
            },
            {
                "id":"542500000000",
                "label":"阿里地区",
                "children":[
                    {
                        "id":"542521000000",
                        "label":"普兰县",
                        "full_label":"西藏自治区阿里地区普兰县"
                    },
                    {
                        "id":"542522000000",
                        "label":"札达县",
                        "full_label":"西藏自治区阿里地区札达县"
                    },
                    {
                        "id":"542523000000",
                        "label":"噶尔县",
                        "full_label":"西藏自治区阿里地区噶尔县"
                    },
                    {
                        "id":"542524000000",
                        "label":"日土县",
                        "full_label":"西藏自治区阿里地区日土县"
                    },
                    {
                        "id":"542525000000",
                        "label":"革吉县",
                        "full_label":"西藏自治区阿里地区革吉县"
                    },
                    {
                        "id":"542526000000",
                        "label":"改则县",
                        "full_label":"西藏自治区阿里地区改则县"
                    },
                    {
                        "id":"542527000000",
                        "label":"措勤县",
                        "full_label":"西藏自治区阿里地区措勤县"
                    }
                ],
                "full_label":"西藏自治区阿里地区"
            }
        ],
        "full_label":"西藏自治区"
    },
    {
        "id":"610000000000",
        "label":"陕西省",
        "children":[
            {
                "id":"610100000000",
                "label":"西安市",
                "children":[
                    {
                        "id":"610102000000",
                        "label":"新城区",
                        "full_label":"陕西省西安市新城区"
                    },
                    {
                        "id":"610103000000",
                        "label":"碑林区",
                        "full_label":"陕西省西安市碑林区"
                    },
                    {
                        "id":"610104000000",
                        "label":"莲湖区",
                        "full_label":"陕西省西安市莲湖区"
                    },
                    {
                        "id":"610111000000",
                        "label":"灞桥区",
                        "full_label":"陕西省西安市灞桥区"
                    },
                    {
                        "id":"610112000000",
                        "label":"未央区",
                        "full_label":"陕西省西安市未央区"
                    },
                    {
                        "id":"610113000000",
                        "label":"雁塔区",
                        "full_label":"陕西省西安市雁塔区"
                    },
                    {
                        "id":"610114000000",
                        "label":"阎良区",
                        "full_label":"陕西省西安市阎良区"
                    },
                    {
                        "id":"610115000000",
                        "label":"临潼区",
                        "full_label":"陕西省西安市临潼区"
                    },
                    {
                        "id":"610116000000",
                        "label":"长安区",
                        "full_label":"陕西省西安市长安区"
                    },
                    {
                        "id":"610117000000",
                        "label":"高陵区",
                        "full_label":"陕西省西安市高陵区"
                    },
                    {
                        "id":"610118000000",
                        "label":"鄠邑区",
                        "full_label":"陕西省西安市鄠邑区"
                    },
                    {
                        "id":"610122000000",
                        "label":"蓝田县",
                        "full_label":"陕西省西安市蓝田县"
                    },
                    {
                        "id":"610124000000",
                        "label":"周至县",
                        "full_label":"陕西省西安市周至县"
                    }
                ],
                "full_label":"陕西省西安市"
            },
            {
                "id":"610200000000",
                "label":"铜川市",
                "children":[
                    {
                        "id":"610202000000",
                        "label":"王益区",
                        "full_label":"陕西省铜川市王益区"
                    },
                    {
                        "id":"610203000000",
                        "label":"印台区",
                        "full_label":"陕西省铜川市印台区"
                    },
                    {
                        "id":"610204000000",
                        "label":"耀州区",
                        "full_label":"陕西省铜川市耀州区"
                    },
                    {
                        "id":"610222000000",
                        "label":"宜君县",
                        "full_label":"陕西省铜川市宜君县"
                    }
                ],
                "full_label":"陕西省铜川市"
            },
            {
                "id":"610300000000",
                "label":"宝鸡市",
                "children":[
                    {
                        "id":"610302000000",
                        "label":"渭滨区",
                        "full_label":"陕西省宝鸡市渭滨区"
                    },
                    {
                        "id":"610303000000",
                        "label":"金台区",
                        "full_label":"陕西省宝鸡市金台区"
                    },
                    {
                        "id":"610304000000",
                        "label":"陈仓区",
                        "full_label":"陕西省宝鸡市陈仓区"
                    },
                    {
                        "id":"610305000000",
                        "label":"凤翔区",
                        "full_label":"陕西省宝鸡市凤翔区"
                    },
                    {
                        "id":"610323000000",
                        "label":"岐山县",
                        "full_label":"陕西省宝鸡市岐山县"
                    },
                    {
                        "id":"610324000000",
                        "label":"扶风县",
                        "full_label":"陕西省宝鸡市扶风县"
                    },
                    {
                        "id":"610326000000",
                        "label":"眉县",
                        "full_label":"陕西省宝鸡市眉县"
                    },
                    {
                        "id":"610327000000",
                        "label":"陇县",
                        "full_label":"陕西省宝鸡市陇县"
                    },
                    {
                        "id":"610328000000",
                        "label":"千阳县",
                        "full_label":"陕西省宝鸡市千阳县"
                    },
                    {
                        "id":"610329000000",
                        "label":"麟游县",
                        "full_label":"陕西省宝鸡市麟游县"
                    },
                    {
                        "id":"610330000000",
                        "label":"凤县",
                        "full_label":"陕西省宝鸡市凤县"
                    },
                    {
                        "id":"610331000000",
                        "label":"太白县",
                        "full_label":"陕西省宝鸡市太白县"
                    }
                ],
                "full_label":"陕西省宝鸡市"
            },
            {
                "id":"610400000000",
                "label":"咸阳市",
                "children":[
                    {
                        "id":"610402000000",
                        "label":"秦都区",
                        "full_label":"陕西省咸阳市秦都区"
                    },
                    {
                        "id":"610403000000",
                        "label":"杨陵区",
                        "full_label":"陕西省咸阳市杨陵区"
                    },
                    {
                        "id":"610404000000",
                        "label":"渭城区",
                        "full_label":"陕西省咸阳市渭城区"
                    },
                    {
                        "id":"610422000000",
                        "label":"三原县",
                        "full_label":"陕西省咸阳市三原县"
                    },
                    {
                        "id":"610423000000",
                        "label":"泾阳县",
                        "full_label":"陕西省咸阳市泾阳县"
                    },
                    {
                        "id":"610424000000",
                        "label":"乾县",
                        "full_label":"陕西省咸阳市乾县"
                    },
                    {
                        "id":"610425000000",
                        "label":"礼泉县",
                        "full_label":"陕西省咸阳市礼泉县"
                    },
                    {
                        "id":"610426000000",
                        "label":"永寿县",
                        "full_label":"陕西省咸阳市永寿县"
                    },
                    {
                        "id":"610428000000",
                        "label":"长武县",
                        "full_label":"陕西省咸阳市长武县"
                    },
                    {
                        "id":"610429000000",
                        "label":"旬邑县",
                        "full_label":"陕西省咸阳市旬邑县"
                    },
                    {
                        "id":"610430000000",
                        "label":"淳化县",
                        "full_label":"陕西省咸阳市淳化县"
                    },
                    {
                        "id":"610431000000",
                        "label":"武功县",
                        "full_label":"陕西省咸阳市武功县"
                    },
                    {
                        "id":"610481000000",
                        "label":"兴平市",
                        "full_label":"陕西省咸阳市兴平市"
                    },
                    {
                        "id":"610482000000",
                        "label":"彬州市",
                        "full_label":"陕西省咸阳市彬州市"
                    }
                ],
                "full_label":"陕西省咸阳市"
            },
            {
                "id":"610500000000",
                "label":"渭南市",
                "children":[
                    {
                        "id":"610502000000",
                        "label":"临渭区",
                        "full_label":"陕西省渭南市临渭区"
                    },
                    {
                        "id":"610503000000",
                        "label":"华州区",
                        "full_label":"陕西省渭南市华州区"
                    },
                    {
                        "id":"610522000000",
                        "label":"潼关县",
                        "full_label":"陕西省渭南市潼关县"
                    },
                    {
                        "id":"610523000000",
                        "label":"大荔县",
                        "full_label":"陕西省渭南市大荔县"
                    },
                    {
                        "id":"610524000000",
                        "label":"合阳县",
                        "full_label":"陕西省渭南市合阳县"
                    },
                    {
                        "id":"610525000000",
                        "label":"澄城县",
                        "full_label":"陕西省渭南市澄城县"
                    },
                    {
                        "id":"610526000000",
                        "label":"蒲城县",
                        "full_label":"陕西省渭南市蒲城县"
                    },
                    {
                        "id":"610527000000",
                        "label":"白水县",
                        "full_label":"陕西省渭南市白水县"
                    },
                    {
                        "id":"610528000000",
                        "label":"富平县",
                        "full_label":"陕西省渭南市富平县"
                    },
                    {
                        "id":"610581000000",
                        "label":"韩城市",
                        "full_label":"陕西省渭南市韩城市"
                    },
                    {
                        "id":"610582000000",
                        "label":"华阴市",
                        "full_label":"陕西省渭南市华阴市"
                    }
                ],
                "full_label":"陕西省渭南市"
            },
            {
                "id":"610600000000",
                "label":"延安市",
                "children":[
                    {
                        "id":"610602000000",
                        "label":"宝塔区",
                        "full_label":"陕西省延安市宝塔区"
                    },
                    {
                        "id":"610603000000",
                        "label":"安塞区",
                        "full_label":"陕西省延安市安塞区"
                    },
                    {
                        "id":"610621000000",
                        "label":"延长县",
                        "full_label":"陕西省延安市延长县"
                    },
                    {
                        "id":"610622000000",
                        "label":"延川县",
                        "full_label":"陕西省延安市延川县"
                    },
                    {
                        "id":"610625000000",
                        "label":"志丹县",
                        "full_label":"陕西省延安市志丹县"
                    },
                    {
                        "id":"610626000000",
                        "label":"吴起县",
                        "full_label":"陕西省延安市吴起县"
                    },
                    {
                        "id":"610627000000",
                        "label":"甘泉县",
                        "full_label":"陕西省延安市甘泉县"
                    },
                    {
                        "id":"610628000000",
                        "label":"富县",
                        "full_label":"陕西省延安市富县"
                    },
                    {
                        "id":"610629000000",
                        "label":"洛川县",
                        "full_label":"陕西省延安市洛川县"
                    },
                    {
                        "id":"610630000000",
                        "label":"宜川县",
                        "full_label":"陕西省延安市宜川县"
                    },
                    {
                        "id":"610631000000",
                        "label":"黄龙县",
                        "full_label":"陕西省延安市黄龙县"
                    },
                    {
                        "id":"610632000000",
                        "label":"黄陵县",
                        "full_label":"陕西省延安市黄陵县"
                    },
                    {
                        "id":"610681000000",
                        "label":"子长市",
                        "full_label":"陕西省延安市子长市"
                    }
                ],
                "full_label":"陕西省延安市"
            },
            {
                "id":"610700000000",
                "label":"汉中市",
                "children":[
                    {
                        "id":"610702000000",
                        "label":"汉台区",
                        "full_label":"陕西省汉中市汉台区"
                    },
                    {
                        "id":"610703000000",
                        "label":"南郑区",
                        "full_label":"陕西省汉中市南郑区"
                    },
                    {
                        "id":"610722000000",
                        "label":"城固县",
                        "full_label":"陕西省汉中市城固县"
                    },
                    {
                        "id":"610723000000",
                        "label":"洋县",
                        "full_label":"陕西省汉中市洋县"
                    },
                    {
                        "id":"610724000000",
                        "label":"西乡县",
                        "full_label":"陕西省汉中市西乡县"
                    },
                    {
                        "id":"610725000000",
                        "label":"勉县",
                        "full_label":"陕西省汉中市勉县"
                    },
                    {
                        "id":"610726000000",
                        "label":"宁强县",
                        "full_label":"陕西省汉中市宁强县"
                    },
                    {
                        "id":"610727000000",
                        "label":"略阳县",
                        "full_label":"陕西省汉中市略阳县"
                    },
                    {
                        "id":"610728000000",
                        "label":"镇巴县",
                        "full_label":"陕西省汉中市镇巴县"
                    },
                    {
                        "id":"610729000000",
                        "label":"留坝县",
                        "full_label":"陕西省汉中市留坝县"
                    },
                    {
                        "id":"610730000000",
                        "label":"佛坪县",
                        "full_label":"陕西省汉中市佛坪县"
                    }
                ],
                "full_label":"陕西省汉中市"
            },
            {
                "id":"610800000000",
                "label":"榆林市",
                "children":[
                    {
                        "id":"610802000000",
                        "label":"榆阳区",
                        "full_label":"陕西省榆林市榆阳区"
                    },
                    {
                        "id":"610803000000",
                        "label":"横山区",
                        "full_label":"陕西省榆林市横山区"
                    },
                    {
                        "id":"610822000000",
                        "label":"府谷县",
                        "full_label":"陕西省榆林市府谷县"
                    },
                    {
                        "id":"610824000000",
                        "label":"靖边县",
                        "full_label":"陕西省榆林市靖边县"
                    },
                    {
                        "id":"610825000000",
                        "label":"定边县",
                        "full_label":"陕西省榆林市定边县"
                    },
                    {
                        "id":"610826000000",
                        "label":"绥德县",
                        "full_label":"陕西省榆林市绥德县"
                    },
                    {
                        "id":"610827000000",
                        "label":"米脂县",
                        "full_label":"陕西省榆林市米脂县"
                    },
                    {
                        "id":"610828000000",
                        "label":"佳县",
                        "full_label":"陕西省榆林市佳县"
                    },
                    {
                        "id":"610829000000",
                        "label":"吴堡县",
                        "full_label":"陕西省榆林市吴堡县"
                    },
                    {
                        "id":"610830000000",
                        "label":"清涧县",
                        "full_label":"陕西省榆林市清涧县"
                    },
                    {
                        "id":"610831000000",
                        "label":"子洲县",
                        "full_label":"陕西省榆林市子洲县"
                    },
                    {
                        "id":"610881000000",
                        "label":"神木市",
                        "full_label":"陕西省榆林市神木市"
                    }
                ],
                "full_label":"陕西省榆林市"
            },
            {
                "id":"610900000000",
                "label":"安康市",
                "children":[
                    {
                        "id":"610902000000",
                        "label":"汉滨区",
                        "full_label":"陕西省安康市汉滨区"
                    },
                    {
                        "id":"610921000000",
                        "label":"汉阴县",
                        "full_label":"陕西省安康市汉阴县"
                    },
                    {
                        "id":"610922000000",
                        "label":"石泉县",
                        "full_label":"陕西省安康市石泉县"
                    },
                    {
                        "id":"610923000000",
                        "label":"宁陕县",
                        "full_label":"陕西省安康市宁陕县"
                    },
                    {
                        "id":"610924000000",
                        "label":"紫阳县",
                        "full_label":"陕西省安康市紫阳县"
                    },
                    {
                        "id":"610925000000",
                        "label":"岚皋县",
                        "full_label":"陕西省安康市岚皋县"
                    },
                    {
                        "id":"610926000000",
                        "label":"平利县",
                        "full_label":"陕西省安康市平利县"
                    },
                    {
                        "id":"610927000000",
                        "label":"镇坪县",
                        "full_label":"陕西省安康市镇坪县"
                    },
                    {
                        "id":"610929000000",
                        "label":"白河县",
                        "full_label":"陕西省安康市白河县"
                    },
                    {
                        "id":"610981000000",
                        "label":"旬阳市",
                        "full_label":"陕西省安康市旬阳市"
                    }
                ],
                "full_label":"陕西省安康市"
            },
            {
                "id":"611000000000",
                "label":"商洛市",
                "children":[
                    {
                        "id":"611002000000",
                        "label":"商州区",
                        "full_label":"陕西省商洛市商州区"
                    },
                    {
                        "id":"611021000000",
                        "label":"洛南县",
                        "full_label":"陕西省商洛市洛南县"
                    },
                    {
                        "id":"611022000000",
                        "label":"丹凤县",
                        "full_label":"陕西省商洛市丹凤县"
                    },
                    {
                        "id":"611023000000",
                        "label":"商南县",
                        "full_label":"陕西省商洛市商南县"
                    },
                    {
                        "id":"611024000000",
                        "label":"山阳县",
                        "full_label":"陕西省商洛市山阳县"
                    },
                    {
                        "id":"611025000000",
                        "label":"镇安县",
                        "full_label":"陕西省商洛市镇安县"
                    },
                    {
                        "id":"611026000000",
                        "label":"柞水县",
                        "full_label":"陕西省商洛市柞水县"
                    }
                ],
                "full_label":"陕西省商洛市"
            }
        ],
        "full_label":"陕西省"
    },
    {
        "id":"620000000000",
        "label":"甘肃省",
        "children":[
            {
                "id":"620100000000",
                "label":"兰州市",
                "children":[
                    {
                        "id":"620102000000",
                        "label":"城关区",
                        "full_label":"甘肃省兰州市城关区"
                    },
                    {
                        "id":"620103000000",
                        "label":"七里河区",
                        "full_label":"甘肃省兰州市七里河区"
                    },
                    {
                        "id":"620104000000",
                        "label":"西固区",
                        "full_label":"甘肃省兰州市西固区"
                    },
                    {
                        "id":"620105000000",
                        "label":"安宁区",
                        "full_label":"甘肃省兰州市安宁区"
                    },
                    {
                        "id":"620111000000",
                        "label":"红古区",
                        "full_label":"甘肃省兰州市红古区"
                    },
                    {
                        "id":"620121000000",
                        "label":"永登县",
                        "full_label":"甘肃省兰州市永登县"
                    },
                    {
                        "id":"620122000000",
                        "label":"皋兰县",
                        "full_label":"甘肃省兰州市皋兰县"
                    },
                    {
                        "id":"620123000000",
                        "label":"榆中县",
                        "full_label":"甘肃省兰州市榆中县"
                    }
                ],
                "full_label":"甘肃省兰州市"
            },
            {
                "id":"620200000000",
                "label":"嘉峪关市",
                "children":[
                    {
                        "id":"620201000000",
                        "label":"市辖区",
                        "full_label":"甘肃省嘉峪关市市辖区"
                    }
                ],
                "full_label":"甘肃省嘉峪关市"
            },
            {
                "id":"620300000000",
                "label":"金昌市",
                "children":[
                    {
                        "id":"620302000000",
                        "label":"金川区",
                        "full_label":"甘肃省金昌市金川区"
                    },
                    {
                        "id":"620321000000",
                        "label":"永昌县",
                        "full_label":"甘肃省金昌市永昌县"
                    }
                ],
                "full_label":"甘肃省金昌市"
            },
            {
                "id":"620400000000",
                "label":"白银市",
                "children":[
                    {
                        "id":"620402000000",
                        "label":"白银区",
                        "full_label":"甘肃省白银市白银区"
                    },
                    {
                        "id":"620403000000",
                        "label":"平川区",
                        "full_label":"甘肃省白银市平川区"
                    },
                    {
                        "id":"620421000000",
                        "label":"靖远县",
                        "full_label":"甘肃省白银市靖远县"
                    },
                    {
                        "id":"620422000000",
                        "label":"会宁县",
                        "full_label":"甘肃省白银市会宁县"
                    },
                    {
                        "id":"620423000000",
                        "label":"景泰县",
                        "full_label":"甘肃省白银市景泰县"
                    }
                ],
                "full_label":"甘肃省白银市"
            },
            {
                "id":"620500000000",
                "label":"天水市",
                "children":[
                    {
                        "id":"620502000000",
                        "label":"秦州区",
                        "full_label":"甘肃省天水市秦州区"
                    },
                    {
                        "id":"620503000000",
                        "label":"麦积区",
                        "full_label":"甘肃省天水市麦积区"
                    },
                    {
                        "id":"620521000000",
                        "label":"清水县",
                        "full_label":"甘肃省天水市清水县"
                    },
                    {
                        "id":"620522000000",
                        "label":"秦安县",
                        "full_label":"甘肃省天水市秦安县"
                    },
                    {
                        "id":"620523000000",
                        "label":"甘谷县",
                        "full_label":"甘肃省天水市甘谷县"
                    },
                    {
                        "id":"620524000000",
                        "label":"武山县",
                        "full_label":"甘肃省天水市武山县"
                    },
                    {
                        "id":"620525000000",
                        "label":"张家川回族自治县",
                        "full_label":"甘肃省天水市张家川回族自治县"
                    }
                ],
                "full_label":"甘肃省天水市"
            },
            {
                "id":"620600000000",
                "label":"武威市",
                "children":[
                    {
                        "id":"620602000000",
                        "label":"凉州区",
                        "full_label":"甘肃省武威市凉州区"
                    },
                    {
                        "id":"620621000000",
                        "label":"民勤县",
                        "full_label":"甘肃省武威市民勤县"
                    },
                    {
                        "id":"620622000000",
                        "label":"古浪县",
                        "full_label":"甘肃省武威市古浪县"
                    },
                    {
                        "id":"620623000000",
                        "label":"天祝藏族自治县",
                        "full_label":"甘肃省武威市天祝藏族自治县"
                    }
                ],
                "full_label":"甘肃省武威市"
            },
            {
                "id":"620700000000",
                "label":"张掖市",
                "children":[
                    {
                        "id":"620702000000",
                        "label":"甘州区",
                        "full_label":"甘肃省张掖市甘州区"
                    },
                    {
                        "id":"620721000000",
                        "label":"肃南裕固族自治县",
                        "full_label":"甘肃省张掖市肃南裕固族自治县"
                    },
                    {
                        "id":"620722000000",
                        "label":"民乐县",
                        "full_label":"甘肃省张掖市民乐县"
                    },
                    {
                        "id":"620723000000",
                        "label":"临泽县",
                        "full_label":"甘肃省张掖市临泽县"
                    },
                    {
                        "id":"620724000000",
                        "label":"高台县",
                        "full_label":"甘肃省张掖市高台县"
                    },
                    {
                        "id":"620725000000",
                        "label":"山丹县",
                        "full_label":"甘肃省张掖市山丹县"
                    }
                ],
                "full_label":"甘肃省张掖市"
            },
            {
                "id":"620800000000",
                "label":"平凉市",
                "children":[
                    {
                        "id":"620802000000",
                        "label":"崆峒区",
                        "full_label":"甘肃省平凉市崆峒区"
                    },
                    {
                        "id":"620821000000",
                        "label":"泾川县",
                        "full_label":"甘肃省平凉市泾川县"
                    },
                    {
                        "id":"620822000000",
                        "label":"灵台县",
                        "full_label":"甘肃省平凉市灵台县"
                    },
                    {
                        "id":"620823000000",
                        "label":"崇信县",
                        "full_label":"甘肃省平凉市崇信县"
                    },
                    {
                        "id":"620825000000",
                        "label":"庄浪县",
                        "full_label":"甘肃省平凉市庄浪县"
                    },
                    {
                        "id":"620826000000",
                        "label":"静宁县",
                        "full_label":"甘肃省平凉市静宁县"
                    },
                    {
                        "id":"620881000000",
                        "label":"华亭市",
                        "full_label":"甘肃省平凉市华亭市"
                    }
                ],
                "full_label":"甘肃省平凉市"
            },
            {
                "id":"620900000000",
                "label":"酒泉市",
                "children":[
                    {
                        "id":"620902000000",
                        "label":"肃州区",
                        "full_label":"甘肃省酒泉市肃州区"
                    },
                    {
                        "id":"620921000000",
                        "label":"金塔县",
                        "full_label":"甘肃省酒泉市金塔县"
                    },
                    {
                        "id":"620922000000",
                        "label":"瓜州县",
                        "full_label":"甘肃省酒泉市瓜州县"
                    },
                    {
                        "id":"620923000000",
                        "label":"肃北蒙古族自治县",
                        "full_label":"甘肃省酒泉市肃北蒙古族自治县"
                    },
                    {
                        "id":"620924000000",
                        "label":"阿克塞哈萨克族自治县",
                        "full_label":"甘肃省酒泉市阿克塞哈萨克族自治县"
                    },
                    {
                        "id":"620981000000",
                        "label":"玉门市",
                        "full_label":"甘肃省酒泉市玉门市"
                    },
                    {
                        "id":"620982000000",
                        "label":"敦煌市",
                        "full_label":"甘肃省酒泉市敦煌市"
                    }
                ],
                "full_label":"甘肃省酒泉市"
            },
            {
                "id":"621000000000",
                "label":"庆阳市",
                "children":[
                    {
                        "id":"621002000000",
                        "label":"西峰区",
                        "full_label":"甘肃省庆阳市西峰区"
                    },
                    {
                        "id":"621021000000",
                        "label":"庆城县",
                        "full_label":"甘肃省庆阳市庆城县"
                    },
                    {
                        "id":"621022000000",
                        "label":"环县",
                        "full_label":"甘肃省庆阳市环县"
                    },
                    {
                        "id":"621023000000",
                        "label":"华池县",
                        "full_label":"甘肃省庆阳市华池县"
                    },
                    {
                        "id":"621024000000",
                        "label":"合水县",
                        "full_label":"甘肃省庆阳市合水县"
                    },
                    {
                        "id":"621025000000",
                        "label":"正宁县",
                        "full_label":"甘肃省庆阳市正宁县"
                    },
                    {
                        "id":"621026000000",
                        "label":"宁县",
                        "full_label":"甘肃省庆阳市宁县"
                    },
                    {
                        "id":"621027000000",
                        "label":"镇原县",
                        "full_label":"甘肃省庆阳市镇原县"
                    }
                ],
                "full_label":"甘肃省庆阳市"
            },
            {
                "id":"621100000000",
                "label":"定西市",
                "children":[
                    {
                        "id":"621102000000",
                        "label":"安定区",
                        "full_label":"甘肃省定西市安定区"
                    },
                    {
                        "id":"621121000000",
                        "label":"通渭县",
                        "full_label":"甘肃省定西市通渭县"
                    },
                    {
                        "id":"621122000000",
                        "label":"陇西县",
                        "full_label":"甘肃省定西市陇西县"
                    },
                    {
                        "id":"621123000000",
                        "label":"渭源县",
                        "full_label":"甘肃省定西市渭源县"
                    },
                    {
                        "id":"621124000000",
                        "label":"临洮县",
                        "full_label":"甘肃省定西市临洮县"
                    },
                    {
                        "id":"621125000000",
                        "label":"漳县",
                        "full_label":"甘肃省定西市漳县"
                    },
                    {
                        "id":"621126000000",
                        "label":"岷县",
                        "full_label":"甘肃省定西市岷县"
                    }
                ],
                "full_label":"甘肃省定西市"
            },
            {
                "id":"621200000000",
                "label":"陇南市",
                "children":[
                    {
                        "id":"621202000000",
                        "label":"武都区",
                        "full_label":"甘肃省陇南市武都区"
                    },
                    {
                        "id":"621221000000",
                        "label":"成县",
                        "full_label":"甘肃省陇南市成县"
                    },
                    {
                        "id":"621222000000",
                        "label":"文县",
                        "full_label":"甘肃省陇南市文县"
                    },
                    {
                        "id":"621223000000",
                        "label":"宕昌县",
                        "full_label":"甘肃省陇南市宕昌县"
                    },
                    {
                        "id":"621224000000",
                        "label":"康县",
                        "full_label":"甘肃省陇南市康县"
                    },
                    {
                        "id":"621225000000",
                        "label":"西和县",
                        "full_label":"甘肃省陇南市西和县"
                    },
                    {
                        "id":"621226000000",
                        "label":"礼县",
                        "full_label":"甘肃省陇南市礼县"
                    },
                    {
                        "id":"621227000000",
                        "label":"徽县",
                        "full_label":"甘肃省陇南市徽县"
                    },
                    {
                        "id":"621228000000",
                        "label":"两当县",
                        "full_label":"甘肃省陇南市两当县"
                    }
                ],
                "full_label":"甘肃省陇南市"
            },
            {
                "id":"622900000000",
                "label":"临夏回族自治州",
                "children":[
                    {
                        "id":"622901000000",
                        "label":"临夏市",
                        "full_label":"甘肃省临夏回族自治州临夏市"
                    },
                    {
                        "id":"622921000000",
                        "label":"临夏县",
                        "full_label":"甘肃省临夏回族自治州临夏县"
                    },
                    {
                        "id":"622922000000",
                        "label":"康乐县",
                        "full_label":"甘肃省临夏回族自治州康乐县"
                    },
                    {
                        "id":"622923000000",
                        "label":"永靖县",
                        "full_label":"甘肃省临夏回族自治州永靖县"
                    },
                    {
                        "id":"622924000000",
                        "label":"广河县",
                        "full_label":"甘肃省临夏回族自治州广河县"
                    },
                    {
                        "id":"622925000000",
                        "label":"和政县",
                        "full_label":"甘肃省临夏回族自治州和政县"
                    },
                    {
                        "id":"622926000000",
                        "label":"东乡族自治县",
                        "full_label":"甘肃省临夏回族自治州东乡族自治县"
                    },
                    {
                        "id":"622927000000",
                        "label":"积石山保安族东乡族撒拉族自治县",
                        "full_label":"甘肃省临夏回族自治州积石山保安族东乡族撒拉族自治县"
                    }
                ],
                "full_label":"甘肃省临夏回族自治州"
            },
            {
                "id":"623000000000",
                "label":"甘南藏族自治州",
                "children":[
                    {
                        "id":"623001000000",
                        "label":"合作市",
                        "full_label":"甘肃省甘南藏族自治州合作市"
                    },
                    {
                        "id":"623021000000",
                        "label":"临潭县",
                        "full_label":"甘肃省甘南藏族自治州临潭县"
                    },
                    {
                        "id":"623022000000",
                        "label":"卓尼县",
                        "full_label":"甘肃省甘南藏族自治州卓尼县"
                    },
                    {
                        "id":"623023000000",
                        "label":"舟曲县",
                        "full_label":"甘肃省甘南藏族自治州舟曲县"
                    },
                    {
                        "id":"623024000000",
                        "label":"迭部县",
                        "full_label":"甘肃省甘南藏族自治州迭部县"
                    },
                    {
                        "id":"623025000000",
                        "label":"玛曲县",
                        "full_label":"甘肃省甘南藏族自治州玛曲县"
                    },
                    {
                        "id":"623026000000",
                        "label":"碌曲县",
                        "full_label":"甘肃省甘南藏族自治州碌曲县"
                    },
                    {
                        "id":"623027000000",
                        "label":"夏河县",
                        "full_label":"甘肃省甘南藏族自治州夏河县"
                    }
                ],
                "full_label":"甘肃省甘南藏族自治州"
            }
        ],
        "full_label":"甘肃省"
    },
    {
        "id":"630000000000",
        "label":"青海省",
        "children":[
            {
                "id":"630100000000",
                "label":"西宁市",
                "children":[
                    {
                        "id":"630102000000",
                        "label":"城东区",
                        "full_label":"青海省西宁市城东区"
                    },
                    {
                        "id":"630103000000",
                        "label":"城中区",
                        "full_label":"青海省西宁市城中区"
                    },
                    {
                        "id":"630104000000",
                        "label":"城西区",
                        "full_label":"青海省西宁市城西区"
                    },
                    {
                        "id":"630105000000",
                        "label":"城北区",
                        "full_label":"青海省西宁市城北区"
                    },
                    {
                        "id":"630106000000",
                        "label":"湟中区",
                        "full_label":"青海省西宁市湟中区"
                    },
                    {
                        "id":"630121000000",
                        "label":"大通回族土族自治县",
                        "full_label":"青海省西宁市大通回族土族自治县"
                    },
                    {
                        "id":"630123000000",
                        "label":"湟源县",
                        "full_label":"青海省西宁市湟源县"
                    }
                ],
                "full_label":"青海省西宁市"
            },
            {
                "id":"630200000000",
                "label":"海东市",
                "children":[
                    {
                        "id":"630202000000",
                        "label":"乐都区",
                        "full_label":"青海省海东市乐都区"
                    },
                    {
                        "id":"630203000000",
                        "label":"平安区",
                        "full_label":"青海省海东市平安区"
                    },
                    {
                        "id":"630222000000",
                        "label":"民和回族土族自治县",
                        "full_label":"青海省海东市民和回族土族自治县"
                    },
                    {
                        "id":"630223000000",
                        "label":"互助土族自治县",
                        "full_label":"青海省海东市互助土族自治县"
                    },
                    {
                        "id":"630224000000",
                        "label":"化隆回族自治县",
                        "full_label":"青海省海东市化隆回族自治县"
                    },
                    {
                        "id":"630225000000",
                        "label":"循化撒拉族自治县",
                        "full_label":"青海省海东市循化撒拉族自治县"
                    }
                ],
                "full_label":"青海省海东市"
            },
            {
                "id":"632200000000",
                "label":"海北藏族自治州",
                "children":[
                    {
                        "id":"632221000000",
                        "label":"门源回族自治县",
                        "full_label":"青海省海北藏族自治州门源回族自治县"
                    },
                    {
                        "id":"632222000000",
                        "label":"祁连县",
                        "full_label":"青海省海北藏族自治州祁连县"
                    },
                    {
                        "id":"632223000000",
                        "label":"海晏县",
                        "full_label":"青海省海北藏族自治州海晏县"
                    },
                    {
                        "id":"632224000000",
                        "label":"刚察县",
                        "full_label":"青海省海北藏族自治州刚察县"
                    }
                ],
                "full_label":"青海省海北藏族自治州"
            },
            {
                "id":"632300000000",
                "label":"黄南藏族自治州",
                "children":[
                    {
                        "id":"632301000000",
                        "label":"同仁市",
                        "full_label":"青海省黄南藏族自治州同仁市"
                    },
                    {
                        "id":"632322000000",
                        "label":"尖扎县",
                        "full_label":"青海省黄南藏族自治州尖扎县"
                    },
                    {
                        "id":"632323000000",
                        "label":"泽库县",
                        "full_label":"青海省黄南藏族自治州泽库县"
                    },
                    {
                        "id":"632324000000",
                        "label":"河南蒙古族自治县",
                        "full_label":"青海省黄南藏族自治州河南蒙古族自治县"
                    }
                ],
                "full_label":"青海省黄南藏族自治州"
            },
            {
                "id":"632500000000",
                "label":"海南藏族自治州",
                "children":[
                    {
                        "id":"632521000000",
                        "label":"共和县",
                        "full_label":"青海省海南藏族自治州共和县"
                    },
                    {
                        "id":"632522000000",
                        "label":"同德县",
                        "full_label":"青海省海南藏族自治州同德县"
                    },
                    {
                        "id":"632523000000",
                        "label":"贵德县",
                        "full_label":"青海省海南藏族自治州贵德县"
                    },
                    {
                        "id":"632524000000",
                        "label":"兴海县",
                        "full_label":"青海省海南藏族自治州兴海县"
                    },
                    {
                        "id":"632525000000",
                        "label":"贵南县",
                        "full_label":"青海省海南藏族自治州贵南县"
                    }
                ],
                "full_label":"青海省海南藏族自治州"
            },
            {
                "id":"632600000000",
                "label":"果洛藏族自治州",
                "children":[
                    {
                        "id":"632621000000",
                        "label":"玛沁县",
                        "full_label":"青海省果洛藏族自治州玛沁县"
                    },
                    {
                        "id":"632622000000",
                        "label":"班玛县",
                        "full_label":"青海省果洛藏族自治州班玛县"
                    },
                    {
                        "id":"632623000000",
                        "label":"甘德县",
                        "full_label":"青海省果洛藏族自治州甘德县"
                    },
                    {
                        "id":"632624000000",
                        "label":"达日县",
                        "full_label":"青海省果洛藏族自治州达日县"
                    },
                    {
                        "id":"632625000000",
                        "label":"久治县",
                        "full_label":"青海省果洛藏族自治州久治县"
                    },
                    {
                        "id":"632626000000",
                        "label":"玛多县",
                        "full_label":"青海省果洛藏族自治州玛多县"
                    }
                ],
                "full_label":"青海省果洛藏族自治州"
            },
            {
                "id":"632700000000",
                "label":"玉树藏族自治州",
                "children":[
                    {
                        "id":"632701000000",
                        "label":"玉树市",
                        "full_label":"青海省玉树藏族自治州玉树市"
                    },
                    {
                        "id":"632722000000",
                        "label":"杂多县",
                        "full_label":"青海省玉树藏族自治州杂多县"
                    },
                    {
                        "id":"632723000000",
                        "label":"称多县",
                        "full_label":"青海省玉树藏族自治州称多县"
                    },
                    {
                        "id":"632724000000",
                        "label":"治多县",
                        "full_label":"青海省玉树藏族自治州治多县"
                    },
                    {
                        "id":"632725000000",
                        "label":"囊谦县",
                        "full_label":"青海省玉树藏族自治州囊谦县"
                    },
                    {
                        "id":"632726000000",
                        "label":"曲麻莱县",
                        "full_label":"青海省玉树藏族自治州曲麻莱县"
                    }
                ],
                "full_label":"青海省玉树藏族自治州"
            },
            {
                "id":"632800000000",
                "label":"海西蒙古族藏族自治州",
                "children":[
                    {
                        "id":"632801000000",
                        "label":"格尔木市",
                        "full_label":"青海省海西蒙古族藏族自治州格尔木市"
                    },
                    {
                        "id":"632802000000",
                        "label":"德令哈市",
                        "full_label":"青海省海西蒙古族藏族自治州德令哈市"
                    },
                    {
                        "id":"632803000000",
                        "label":"茫崖市",
                        "full_label":"青海省海西蒙古族藏族自治州茫崖市"
                    },
                    {
                        "id":"632821000000",
                        "label":"乌兰县",
                        "full_label":"青海省海西蒙古族藏族自治州乌兰县"
                    },
                    {
                        "id":"632822000000",
                        "label":"都兰县",
                        "full_label":"青海省海西蒙古族藏族自治州都兰县"
                    },
                    {
                        "id":"632823000000",
                        "label":"天峻县",
                        "full_label":"青海省海西蒙古族藏族自治州天峻县"
                    },
                    {
                        "id":"632825000000",
                        "label":"大柴旦行政委员会",
                        "full_label":"青海省海西蒙古族藏族自治州大柴旦行政委员会"
                    }
                ],
                "full_label":"青海省海西蒙古族藏族自治州"
            }
        ],
        "full_label":"青海省"
    },
    {
        "id":"640000000000",
        "label":"宁夏回族自治区",
        "children":[
            {
                "id":"640100000000",
                "label":"银川市",
                "children":[
                    {
                        "id":"640104000000",
                        "label":"兴庆区",
                        "full_label":"宁夏回族自治区银川市兴庆区"
                    },
                    {
                        "id":"640105000000",
                        "label":"西夏区",
                        "full_label":"宁夏回族自治区银川市西夏区"
                    },
                    {
                        "id":"640106000000",
                        "label":"金凤区",
                        "full_label":"宁夏回族自治区银川市金凤区"
                    },
                    {
                        "id":"640121000000",
                        "label":"永宁县",
                        "full_label":"宁夏回族自治区银川市永宁县"
                    },
                    {
                        "id":"640122000000",
                        "label":"贺兰县",
                        "full_label":"宁夏回族自治区银川市贺兰县"
                    },
                    {
                        "id":"640181000000",
                        "label":"灵武市",
                        "full_label":"宁夏回族自治区银川市灵武市"
                    }
                ],
                "full_label":"宁夏回族自治区银川市"
            },
            {
                "id":"640200000000",
                "label":"石嘴山市",
                "children":[
                    {
                        "id":"640202000000",
                        "label":"大武口区",
                        "full_label":"宁夏回族自治区石嘴山市大武口区"
                    },
                    {
                        "id":"640205000000",
                        "label":"惠农区",
                        "full_label":"宁夏回族自治区石嘴山市惠农区"
                    },
                    {
                        "id":"640221000000",
                        "label":"平罗县",
                        "full_label":"宁夏回族自治区石嘴山市平罗县"
                    }
                ],
                "full_label":"宁夏回族自治区石嘴山市"
            },
            {
                "id":"640300000000",
                "label":"吴忠市",
                "children":[
                    {
                        "id":"640302000000",
                        "label":"利通区",
                        "full_label":"宁夏回族自治区吴忠市利通区"
                    },
                    {
                        "id":"640303000000",
                        "label":"红寺堡区",
                        "full_label":"宁夏回族自治区吴忠市红寺堡区"
                    },
                    {
                        "id":"640323000000",
                        "label":"盐池县",
                        "full_label":"宁夏回族自治区吴忠市盐池县"
                    },
                    {
                        "id":"640324000000",
                        "label":"同心县",
                        "full_label":"宁夏回族自治区吴忠市同心县"
                    },
                    {
                        "id":"640381000000",
                        "label":"青铜峡市",
                        "full_label":"宁夏回族自治区吴忠市青铜峡市"
                    }
                ],
                "full_label":"宁夏回族自治区吴忠市"
            },
            {
                "id":"640400000000",
                "label":"固原市",
                "children":[
                    {
                        "id":"640402000000",
                        "label":"原州区",
                        "full_label":"宁夏回族自治区固原市原州区"
                    },
                    {
                        "id":"640422000000",
                        "label":"西吉县",
                        "full_label":"宁夏回族自治区固原市西吉县"
                    },
                    {
                        "id":"640423000000",
                        "label":"隆德县",
                        "full_label":"宁夏回族自治区固原市隆德县"
                    },
                    {
                        "id":"640424000000",
                        "label":"泾源县",
                        "full_label":"宁夏回族自治区固原市泾源县"
                    },
                    {
                        "id":"640425000000",
                        "label":"彭阳县",
                        "full_label":"宁夏回族自治区固原市彭阳县"
                    }
                ],
                "full_label":"宁夏回族自治区固原市"
            },
            {
                "id":"640500000000",
                "label":"中卫市",
                "children":[
                    {
                        "id":"640502000000",
                        "label":"沙坡头区",
                        "full_label":"宁夏回族自治区中卫市沙坡头区"
                    },
                    {
                        "id":"640521000000",
                        "label":"中宁县",
                        "full_label":"宁夏回族自治区中卫市中宁县"
                    },
                    {
                        "id":"640522000000",
                        "label":"海原县",
                        "full_label":"宁夏回族自治区中卫市海原县"
                    }
                ],
                "full_label":"宁夏回族自治区中卫市"
            }
        ],
        "full_label":"宁夏回族自治区"
    },
    {
        "id":"650000000000",
        "label":"新疆维吾尔自治区",
        "children":[
            {
                "id":"650100000000",
                "label":"乌鲁木齐市",
                "children":[
                    {
                        "id":"650102000000",
                        "label":"天山区",
                        "full_label":"新疆维吾尔自治区乌鲁木齐市天山区"
                    },
                    {
                        "id":"650103000000",
                        "label":"沙依巴克区",
                        "full_label":"新疆维吾尔自治区乌鲁木齐市沙依巴克区"
                    },
                    {
                        "id":"650104000000",
                        "label":"新市区",
                        "full_label":"新疆维吾尔自治区乌鲁木齐市新市区"
                    },
                    {
                        "id":"650105000000",
                        "label":"水磨沟区",
                        "full_label":"新疆维吾尔自治区乌鲁木齐市水磨沟区"
                    },
                    {
                        "id":"650106000000",
                        "label":"头屯河区",
                        "full_label":"新疆维吾尔自治区乌鲁木齐市头屯河区"
                    },
                    {
                        "id":"650107000000",
                        "label":"达坂城区",
                        "full_label":"新疆维吾尔自治区乌鲁木齐市达坂城区"
                    },
                    {
                        "id":"650109000000",
                        "label":"米东区",
                        "full_label":"新疆维吾尔自治区乌鲁木齐市米东区"
                    },
                    {
                        "id":"650121000000",
                        "label":"乌鲁木齐县",
                        "full_label":"新疆维吾尔自治区乌鲁木齐市乌鲁木齐县"
                    }
                ],
                "full_label":"新疆维吾尔自治区乌鲁木齐市"
            },
            {
                "id":"650200000000",
                "label":"克拉玛依市",
                "children":[
                    {
                        "id":"650202000000",
                        "label":"独山子区",
                        "full_label":"新疆维吾尔自治区克拉玛依市独山子区"
                    },
                    {
                        "id":"650203000000",
                        "label":"克拉玛依区",
                        "full_label":"新疆维吾尔自治区克拉玛依市克拉玛依区"
                    },
                    {
                        "id":"650204000000",
                        "label":"白碱滩区",
                        "full_label":"新疆维吾尔自治区克拉玛依市白碱滩区"
                    },
                    {
                        "id":"650205000000",
                        "label":"乌尔禾区",
                        "full_label":"新疆维吾尔自治区克拉玛依市乌尔禾区"
                    }
                ],
                "full_label":"新疆维吾尔自治区克拉玛依市"
            },
            {
                "id":"650400000000",
                "label":"吐鲁番市",
                "children":[
                    {
                        "id":"650402000000",
                        "label":"高昌区",
                        "full_label":"新疆维吾尔自治区吐鲁番市高昌区"
                    },
                    {
                        "id":"650421000000",
                        "label":"鄯善县",
                        "full_label":"新疆维吾尔自治区吐鲁番市鄯善县"
                    },
                    {
                        "id":"650422000000",
                        "label":"托克逊县",
                        "full_label":"新疆维吾尔自治区吐鲁番市托克逊县"
                    }
                ],
                "full_label":"新疆维吾尔自治区吐鲁番市"
            },
            {
                "id":"650500000000",
                "label":"哈密市",
                "children":[
                    {
                        "id":"650502000000",
                        "label":"伊州区",
                        "full_label":"新疆维吾尔自治区哈密市伊州区"
                    },
                    {
                        "id":"650521000000",
                        "label":"巴里坤哈萨克自治县",
                        "full_label":"新疆维吾尔自治区哈密市巴里坤哈萨克自治县"
                    },
                    {
                        "id":"650522000000",
                        "label":"伊吾县",
                        "full_label":"新疆维吾尔自治区哈密市伊吾县"
                    }
                ],
                "full_label":"新疆维吾尔自治区哈密市"
            },
            {
                "id":"652300000000",
                "label":"昌吉回族自治州",
                "children":[
                    {
                        "id":"652301000000",
                        "label":"昌吉市",
                        "full_label":"新疆维吾尔自治区昌吉回族自治州昌吉市"
                    },
                    {
                        "id":"652302000000",
                        "label":"阜康市",
                        "full_label":"新疆维吾尔自治区昌吉回族自治州阜康市"
                    },
                    {
                        "id":"652323000000",
                        "label":"呼图壁县",
                        "full_label":"新疆维吾尔自治区昌吉回族自治州呼图壁县"
                    },
                    {
                        "id":"652324000000",
                        "label":"玛纳斯县",
                        "full_label":"新疆维吾尔自治区昌吉回族自治州玛纳斯县"
                    },
                    {
                        "id":"652325000000",
                        "label":"奇台县",
                        "full_label":"新疆维吾尔自治区昌吉回族自治州奇台县"
                    },
                    {
                        "id":"652327000000",
                        "label":"吉木萨尔县",
                        "full_label":"新疆维吾尔自治区昌吉回族自治州吉木萨尔县"
                    },
                    {
                        "id":"652328000000",
                        "label":"木垒哈萨克自治县",
                        "full_label":"新疆维吾尔自治区昌吉回族自治州木垒哈萨克自治县"
                    }
                ],
                "full_label":"新疆维吾尔自治区昌吉回族自治州"
            },
            {
                "id":"652700000000",
                "label":"博尔塔拉蒙古自治州",
                "children":[
                    {
                        "id":"652701000000",
                        "label":"博乐市",
                        "full_label":"新疆维吾尔自治区博尔塔拉蒙古自治州博乐市"
                    },
                    {
                        "id":"652702000000",
                        "label":"阿拉山口市",
                        "full_label":"新疆维吾尔自治区博尔塔拉蒙古自治州阿拉山口市"
                    },
                    {
                        "id":"652722000000",
                        "label":"精河县",
                        "full_label":"新疆维吾尔自治区博尔塔拉蒙古自治州精河县"
                    },
                    {
                        "id":"652723000000",
                        "label":"温泉县",
                        "full_label":"新疆维吾尔自治区博尔塔拉蒙古自治州温泉县"
                    }
                ],
                "full_label":"新疆维吾尔自治区博尔塔拉蒙古自治州"
            },
            {
                "id":"652800000000",
                "label":"巴音郭楞蒙古自治州",
                "children":[
                    {
                        "id":"652801000000",
                        "label":"库尔勒市",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市"
                    },
                    {
                        "id":"652822000000",
                        "label":"轮台县",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州轮台县"
                    },
                    {
                        "id":"652823000000",
                        "label":"尉犁县",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州尉犁县"
                    },
                    {
                        "id":"652824000000",
                        "label":"若羌县",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州若羌县"
                    },
                    {
                        "id":"652825000000",
                        "label":"且末县",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州且末县"
                    },
                    {
                        "id":"652826000000",
                        "label":"焉耆回族自治县",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州焉耆回族自治县"
                    },
                    {
                        "id":"652827000000",
                        "label":"和静县",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州和静县"
                    },
                    {
                        "id":"652828000000",
                        "label":"和硕县",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州和硕县"
                    },
                    {
                        "id":"652829000000",
                        "label":"博湖县",
                        "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州博湖县"
                    }
                ],
                "full_label":"新疆维吾尔自治区巴音郭楞蒙古自治州"
            },
            {
                "id":"652900000000",
                "label":"阿克苏地区",
                "children":[
                    {
                        "id":"652901000000",
                        "label":"阿克苏市",
                        "full_label":"新疆维吾尔自治区阿克苏地区阿克苏市"
                    },
                    {
                        "id":"652902000000",
                        "label":"库车市",
                        "full_label":"新疆维吾尔自治区阿克苏地区库车市"
                    },
                    {
                        "id":"652922000000",
                        "label":"温宿县",
                        "full_label":"新疆维吾尔自治区阿克苏地区温宿县"
                    },
                    {
                        "id":"652924000000",
                        "label":"沙雅县",
                        "full_label":"新疆维吾尔自治区阿克苏地区沙雅县"
                    },
                    {
                        "id":"652925000000",
                        "label":"新和县",
                        "full_label":"新疆维吾尔自治区阿克苏地区新和县"
                    },
                    {
                        "id":"652926000000",
                        "label":"拜城县",
                        "full_label":"新疆维吾尔自治区阿克苏地区拜城县"
                    },
                    {
                        "id":"652927000000",
                        "label":"乌什县",
                        "full_label":"新疆维吾尔自治区阿克苏地区乌什县"
                    },
                    {
                        "id":"652928000000",
                        "label":"阿瓦提县",
                        "full_label":"新疆维吾尔自治区阿克苏地区阿瓦提县"
                    },
                    {
                        "id":"652929000000",
                        "label":"柯坪县",
                        "full_label":"新疆维吾尔自治区阿克苏地区柯坪县"
                    }
                ],
                "full_label":"新疆维吾尔自治区阿克苏地区"
            },
            {
                "id":"653000000000",
                "label":"克孜勒苏柯尔克孜自治州",
                "children":[
                    {
                        "id":"653001000000",
                        "label":"阿图什市",
                        "full_label":"新疆维吾尔自治区克孜勒苏柯尔克孜自治州阿图什市"
                    },
                    {
                        "id":"653022000000",
                        "label":"阿克陶县",
                        "full_label":"新疆维吾尔自治区克孜勒苏柯尔克孜自治州阿克陶县"
                    },
                    {
                        "id":"653023000000",
                        "label":"阿合奇县",
                        "full_label":"新疆维吾尔自治区克孜勒苏柯尔克孜自治州阿合奇县"
                    },
                    {
                        "id":"653024000000",
                        "label":"乌恰县",
                        "full_label":"新疆维吾尔自治区克孜勒苏柯尔克孜自治州乌恰县"
                    }
                ],
                "full_label":"新疆维吾尔自治区克孜勒苏柯尔克孜自治州"
            },
            {
                "id":"653100000000",
                "label":"喀什地区",
                "children":[
                    {
                        "id":"653101000000",
                        "label":"喀什市",
                        "full_label":"新疆维吾尔自治区喀什地区喀什市"
                    },
                    {
                        "id":"653121000000",
                        "label":"疏附县",
                        "full_label":"新疆维吾尔自治区喀什地区疏附县"
                    },
                    {
                        "id":"653122000000",
                        "label":"疏勒县",
                        "full_label":"新疆维吾尔自治区喀什地区疏勒县"
                    },
                    {
                        "id":"653123000000",
                        "label":"英吉沙县",
                        "full_label":"新疆维吾尔自治区喀什地区英吉沙县"
                    },
                    {
                        "id":"653124000000",
                        "label":"泽普县",
                        "full_label":"新疆维吾尔自治区喀什地区泽普县"
                    },
                    {
                        "id":"653125000000",
                        "label":"莎车县",
                        "full_label":"新疆维吾尔自治区喀什地区莎车县"
                    },
                    {
                        "id":"653126000000",
                        "label":"叶城县",
                        "full_label":"新疆维吾尔自治区喀什地区叶城县"
                    },
                    {
                        "id":"653127000000",
                        "label":"麦盖提县",
                        "full_label":"新疆维吾尔自治区喀什地区麦盖提县"
                    },
                    {
                        "id":"653128000000",
                        "label":"岳普湖县",
                        "full_label":"新疆维吾尔自治区喀什地区岳普湖县"
                    },
                    {
                        "id":"653129000000",
                        "label":"伽师县",
                        "full_label":"新疆维吾尔自治区喀什地区伽师县"
                    },
                    {
                        "id":"653130000000",
                        "label":"巴楚县",
                        "full_label":"新疆维吾尔自治区喀什地区巴楚县"
                    },
                    {
                        "id":"653131000000",
                        "label":"塔什库尔干塔吉克自治县",
                        "full_label":"新疆维吾尔自治区喀什地区塔什库尔干塔吉克自治县"
                    }
                ],
                "full_label":"新疆维吾尔自治区喀什地区"
            },
            {
                "id":"653200000000",
                "label":"和田地区",
                "children":[
                    {
                        "id":"653201000000",
                        "label":"和田市",
                        "full_label":"新疆维吾尔自治区和田地区和田市"
                    },
                    {
                        "id":"653221000000",
                        "label":"和田县",
                        "full_label":"新疆维吾尔自治区和田地区和田县"
                    },
                    {
                        "id":"653222000000",
                        "label":"墨玉县",
                        "full_label":"新疆维吾尔自治区和田地区墨玉县"
                    },
                    {
                        "id":"653223000000",
                        "label":"皮山县",
                        "full_label":"新疆维吾尔自治区和田地区皮山县"
                    },
                    {
                        "id":"653224000000",
                        "label":"洛浦县",
                        "full_label":"新疆维吾尔自治区和田地区洛浦县"
                    },
                    {
                        "id":"653225000000",
                        "label":"策勒县",
                        "full_label":"新疆维吾尔自治区和田地区策勒县"
                    },
                    {
                        "id":"653226000000",
                        "label":"于田县",
                        "full_label":"新疆维吾尔自治区和田地区于田县"
                    },
                    {
                        "id":"653227000000",
                        "label":"民丰县",
                        "full_label":"新疆维吾尔自治区和田地区民丰县"
                    }
                ],
                "full_label":"新疆维吾尔自治区和田地区"
            },
            {
                "id":"654000000000",
                "label":"伊犁哈萨克自治州",
                "children":[
                    {
                        "id":"654002000000",
                        "label":"伊宁市",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州伊宁市"
                    },
                    {
                        "id":"654003000000",
                        "label":"奎屯市",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州奎屯市"
                    },
                    {
                        "id":"654004000000",
                        "label":"霍尔果斯市",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州霍尔果斯市"
                    },
                    {
                        "id":"654021000000",
                        "label":"伊宁县",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州伊宁县"
                    },
                    {
                        "id":"654022000000",
                        "label":"察布查尔锡伯自治县",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州察布查尔锡伯自治县"
                    },
                    {
                        "id":"654023000000",
                        "label":"霍城县",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州霍城县"
                    },
                    {
                        "id":"654024000000",
                        "label":"巩留县",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州巩留县"
                    },
                    {
                        "id":"654025000000",
                        "label":"新源县",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州新源县"
                    },
                    {
                        "id":"654026000000",
                        "label":"昭苏县",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州昭苏县"
                    },
                    {
                        "id":"654027000000",
                        "label":"特克斯县",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州特克斯县"
                    },
                    {
                        "id":"654028000000",
                        "label":"尼勒克县",
                        "full_label":"新疆维吾尔自治区伊犁哈萨克自治州尼勒克县"
                    }
                ],
                "full_label":"新疆维吾尔自治区伊犁哈萨克自治州"
            },
            {
                "id":"654200000000",
                "label":"塔城地区",
                "children":[
                    {
                        "id":"654201000000",
                        "label":"塔城市",
                        "full_label":"新疆维吾尔自治区塔城地区塔城市"
                    },
                    {
                        "id":"654202000000",
                        "label":"乌苏市",
                        "full_label":"新疆维吾尔自治区塔城地区乌苏市"
                    },
                    {
                        "id":"654203000000",
                        "label":"沙湾市",
                        "full_label":"新疆维吾尔自治区塔城地区沙湾市"
                    },
                    {
                        "id":"654221000000",
                        "label":"额敏县",
                        "full_label":"新疆维吾尔自治区塔城地区额敏县"
                    },
                    {
                        "id":"654224000000",
                        "label":"托里县",
                        "full_label":"新疆维吾尔自治区塔城地区托里县"
                    },
                    {
                        "id":"654225000000",
                        "label":"裕民县",
                        "full_label":"新疆维吾尔自治区塔城地区裕民县"
                    },
                    {
                        "id":"654226000000",
                        "label":"和布克赛尔蒙古自治县",
                        "full_label":"新疆维吾尔自治区塔城地区和布克赛尔蒙古自治县"
                    }
                ],
                "full_label":"新疆维吾尔自治区塔城地区"
            },
            {
                "id":"654300000000",
                "label":"阿勒泰地区",
                "children":[
                    {
                        "id":"654301000000",
                        "label":"阿勒泰市",
                        "full_label":"新疆维吾尔自治区阿勒泰地区阿勒泰市"
                    },
                    {
                        "id":"654321000000",
                        "label":"布尔津县",
                        "full_label":"新疆维吾尔自治区阿勒泰地区布尔津县"
                    },
                    {
                        "id":"654322000000",
                        "label":"富蕴县",
                        "full_label":"新疆维吾尔自治区阿勒泰地区富蕴县"
                    },
                    {
                        "id":"654323000000",
                        "label":"福海县",
                        "full_label":"新疆维吾尔自治区阿勒泰地区福海县"
                    },
                    {
                        "id":"654324000000",
                        "label":"哈巴河县",
                        "full_label":"新疆维吾尔自治区阿勒泰地区哈巴河县"
                    },
                    {
                        "id":"654325000000",
                        "label":"青河县",
                        "full_label":"新疆维吾尔自治区阿勒泰地区青河县"
                    },
                    {
                        "id":"654326000000",
                        "label":"吉木乃县",
                        "full_label":"新疆维吾尔自治区阿勒泰地区吉木乃县"
                    }
                ],
                "full_label":"新疆维吾尔自治区阿勒泰地区"
            },
            {
                "id":"659001000000",
                "label":"石河子市",
                "full_label":"新疆维吾尔自治区石河子市"
            },
            {
                "id":"659002000000",
                "label":"阿拉尔市",
                "full_label":"新疆维吾尔自治区阿拉尔市"
            },
            {
                "id":"659003000000",
                "label":"图木舒克市",
                "full_label":"新疆维吾尔自治区图木舒克市"
            },
            {
                "id":"659004000000",
                "label":"五家渠市",
                "full_label":"新疆维吾尔自治区五家渠市"
            },
            {
                "id":"659005000000",
                "label":"北屯市",
                "full_label":"新疆维吾尔自治区北屯市"
            },
            {
                "id":"659006000000",
                "label":"铁门关市",
                "full_label":"新疆维吾尔自治区铁门关市"
            },
            {
                "id":"659007000000",
                "label":"双河市",
                "full_label":"新疆维吾尔自治区双河市"
            },
            {
                "id":"659008000000",
                "label":"可克达拉市",
                "full_label":"新疆维吾尔自治区可克达拉市"
            },
            {
                "id":"659009000000",
                "label":"昆玉市",
                "full_label":"新疆维吾尔自治区昆玉市"
            },
            {
                "id":"659010000000",
                "label":"胡杨河市",
                "full_label":"新疆维吾尔自治区胡杨河市"
            },
            {
                "id":"659011000000",
                "label":"新星市",
                "full_label":"新疆维吾尔自治区新星市"
            }
        ],
        "full_label":"新疆维吾尔自治区"
    },
    {
        "id":"710000000000",
        "label":"台湾省",
        "children":[
            {
                "id":"710100000000",
                "label":"台北市",
                "children":[
                    {
                        "id":"710101000000",
                        "label":"中正区",
                        "full_label":"台湾省台北市中正区"
                    },
                    {
                        "id":"710102000000",
                        "label":"大同区",
                        "full_label":"台湾省台北市大同区"
                    },
                    {
                        "id":"710103000000",
                        "label":"中山区",
                        "full_label":"台湾省台北市中山区"
                    },
                    {
                        "id":"710104000000",
                        "label":"松山区",
                        "full_label":"台湾省台北市松山区"
                    },
                    {
                        "id":"710105000000",
                        "label":"大安区",
                        "full_label":"台湾省台北市大安区"
                    },
                    {
                        "id":"710106000000",
                        "label":"万华区",
                        "full_label":"台湾省台北市万华区"
                    },
                    {
                        "id":"710107000000",
                        "label":"信义区",
                        "full_label":"台湾省台北市信义区"
                    },
                    {
                        "id":"710108000000",
                        "label":"士林区",
                        "full_label":"台湾省台北市士林区"
                    },
                    {
                        "id":"710109000000",
                        "label":"北投区",
                        "full_label":"台湾省台北市北投区"
                    },
                    {
                        "id":"710110000000",
                        "label":"内湖区",
                        "full_label":"台湾省台北市内湖区"
                    },
                    {
                        "id":"710111000000",
                        "label":"南港区",
                        "full_label":"台湾省台北市南港区"
                    },
                    {
                        "id":"710112000000",
                        "label":"文山区",
                        "full_label":"台湾省台北市文山区"
                    }
                ],
                "full_label":"台湾省台北市"
            },
            {
                "id":"710200000000",
                "label":"高雄市",
                "children":[
                    {
                        "id":"710201000000",
                        "label":"新兴区",
                        "full_label":"台湾省高雄市新兴区"
                    },
                    {
                        "id":"710202000000",
                        "label":"前金区",
                        "full_label":"台湾省高雄市前金区"
                    },
                    {
                        "id":"710203000000",
                        "label":"苓雅区",
                        "full_label":"台湾省高雄市苓雅区"
                    },
                    {
                        "id":"710204000000",
                        "label":"盐埕区",
                        "full_label":"台湾省高雄市盐埕区"
                    },
                    {
                        "id":"710205000000",
                        "label":"鼓山区",
                        "full_label":"台湾省高雄市鼓山区"
                    },
                    {
                        "id":"710206000000",
                        "label":"旗津区",
                        "full_label":"台湾省高雄市旗津区"
                    },
                    {
                        "id":"710207000000",
                        "label":"前镇区",
                        "full_label":"台湾省高雄市前镇区"
                    },
                    {
                        "id":"710208000000",
                        "label":"三民区",
                        "full_label":"台湾省高雄市三民区"
                    },
                    {
                        "id":"710209000000",
                        "label":"左营区",
                        "full_label":"台湾省高雄市左营区"
                    },
                    {
                        "id":"710210000000",
                        "label":"楠梓区",
                        "full_label":"台湾省高雄市楠梓区"
                    },
                    {
                        "id":"710211000000",
                        "label":"小港区",
                        "full_label":"台湾省高雄市小港区"
                    },
                    {
                        "id":"710242000000",
                        "label":"仁武区",
                        "full_label":"台湾省高雄市仁武区"
                    },
                    {
                        "id":"710243000000",
                        "label":"大社区",
                        "full_label":"台湾省高雄市大社区"
                    },
                    {
                        "id":"710244000000",
                        "label":"冈山区",
                        "full_label":"台湾省高雄市冈山区"
                    },
                    {
                        "id":"710245000000",
                        "label":"路竹区",
                        "full_label":"台湾省高雄市路竹区"
                    },
                    {
                        "id":"710246000000",
                        "label":"阿莲区",
                        "full_label":"台湾省高雄市阿莲区"
                    },
                    {
                        "id":"710247000000",
                        "label":"田寮区",
                        "full_label":"台湾省高雄市田寮区"
                    },
                    {
                        "id":"710248000000",
                        "label":"燕巢区",
                        "full_label":"台湾省高雄市燕巢区"
                    },
                    {
                        "id":"710249000000",
                        "label":"桥头区",
                        "full_label":"台湾省高雄市桥头区"
                    },
                    {
                        "id":"710250000000",
                        "label":"梓官区",
                        "full_label":"台湾省高雄市梓官区"
                    },
                    {
                        "id":"710251000000",
                        "label":"弥陀区",
                        "full_label":"台湾省高雄市弥陀区"
                    },
                    {
                        "id":"710252000000",
                        "label":"永安区",
                        "full_label":"台湾省高雄市永安区"
                    },
                    {
                        "id":"710253000000",
                        "label":"湖内区",
                        "full_label":"台湾省高雄市湖内区"
                    },
                    {
                        "id":"710254000000",
                        "label":"凤山区",
                        "full_label":"台湾省高雄市凤山区"
                    },
                    {
                        "id":"710255000000",
                        "label":"大寮区",
                        "full_label":"台湾省高雄市大寮区"
                    },
                    {
                        "id":"710256000000",
                        "label":"林园区",
                        "full_label":"台湾省高雄市林园区"
                    },
                    {
                        "id":"710257000000",
                        "label":"鸟松区",
                        "full_label":"台湾省高雄市鸟松区"
                    },
                    {
                        "id":"710258000000",
                        "label":"大树区",
                        "full_label":"台湾省高雄市大树区"
                    },
                    {
                        "id":"710259000000",
                        "label":"旗山区",
                        "full_label":"台湾省高雄市旗山区"
                    },
                    {
                        "id":"710260000000",
                        "label":"美浓区",
                        "full_label":"台湾省高雄市美浓区"
                    },
                    {
                        "id":"710261000000",
                        "label":"六龟区",
                        "full_label":"台湾省高雄市六龟区"
                    },
                    {
                        "id":"710262000000",
                        "label":"内门区",
                        "full_label":"台湾省高雄市内门区"
                    },
                    {
                        "id":"710263000000",
                        "label":"杉林区",
                        "full_label":"台湾省高雄市杉林区"
                    },
                    {
                        "id":"710264000000",
                        "label":"甲仙区",
                        "full_label":"台湾省高雄市甲仙区"
                    },
                    {
                        "id":"710265000000",
                        "label":"桃源区",
                        "full_label":"台湾省高雄市桃源区"
                    },
                    {
                        "id":"710266000000",
                        "label":"那玛夏区",
                        "full_label":"台湾省高雄市那玛夏区"
                    },
                    {
                        "id":"710267000000",
                        "label":"茂林区",
                        "full_label":"台湾省高雄市茂林区"
                    },
                    {
                        "id":"710268000000",
                        "label":"茄萣区",
                        "full_label":"台湾省高雄市茄萣区"
                    }
                ],
                "full_label":"台湾省高雄市"
            },
            {
                "id":"710300000000",
                "label":"台南市",
                "children":[
                    {
                        "id":"710301000000",
                        "label":"中西区",
                        "full_label":"台湾省台南市中西区"
                    },
                    {
                        "id":"710302000000",
                        "label":"东区",
                        "full_label":"台湾省台南市东区"
                    },
                    {
                        "id":"710303000000",
                        "label":"南区",
                        "full_label":"台湾省台南市南区"
                    },
                    {
                        "id":"710304000000",
                        "label":"北区",
                        "full_label":"台湾省台南市北区"
                    },
                    {
                        "id":"710305000000",
                        "label":"安平区",
                        "full_label":"台湾省台南市安平区"
                    },
                    {
                        "id":"710306000000",
                        "label":"安南区",
                        "full_label":"台湾省台南市安南区"
                    },
                    {
                        "id":"710339000000",
                        "label":"永康区",
                        "full_label":"台湾省台南市永康区"
                    },
                    {
                        "id":"710340000000",
                        "label":"归仁区",
                        "full_label":"台湾省台南市归仁区"
                    },
                    {
                        "id":"710341000000",
                        "label":"新化区",
                        "full_label":"台湾省台南市新化区"
                    },
                    {
                        "id":"710342000000",
                        "label":"左镇区",
                        "full_label":"台湾省台南市左镇区"
                    },
                    {
                        "id":"710343000000",
                        "label":"玉井区",
                        "full_label":"台湾省台南市玉井区"
                    },
                    {
                        "id":"710344000000",
                        "label":"楠西区",
                        "full_label":"台湾省台南市楠西区"
                    },
                    {
                        "id":"710345000000",
                        "label":"南化区",
                        "full_label":"台湾省台南市南化区"
                    },
                    {
                        "id":"710346000000",
                        "label":"仁德区",
                        "full_label":"台湾省台南市仁德区"
                    },
                    {
                        "id":"710347000000",
                        "label":"关庙区",
                        "full_label":"台湾省台南市关庙区"
                    },
                    {
                        "id":"710348000000",
                        "label":"龙崎区",
                        "full_label":"台湾省台南市龙崎区"
                    },
                    {
                        "id":"710349000000",
                        "label":"官田区",
                        "full_label":"台湾省台南市官田区"
                    },
                    {
                        "id":"710350000000",
                        "label":"麻豆区",
                        "full_label":"台湾省台南市麻豆区"
                    },
                    {
                        "id":"710351000000",
                        "label":"佳里区",
                        "full_label":"台湾省台南市佳里区"
                    },
                    {
                        "id":"710352000000",
                        "label":"西港区",
                        "full_label":"台湾省台南市西港区"
                    },
                    {
                        "id":"710353000000",
                        "label":"七股区",
                        "full_label":"台湾省台南市七股区"
                    },
                    {
                        "id":"710354000000",
                        "label":"将军区",
                        "full_label":"台湾省台南市将军区"
                    },
                    {
                        "id":"710355000000",
                        "label":"学甲区",
                        "full_label":"台湾省台南市学甲区"
                    },
                    {
                        "id":"710356000000",
                        "label":"北门区",
                        "full_label":"台湾省台南市北门区"
                    },
                    {
                        "id":"710357000000",
                        "label":"新营区",
                        "full_label":"台湾省台南市新营区"
                    },
                    {
                        "id":"710358000000",
                        "label":"后壁区",
                        "full_label":"台湾省台南市后壁区"
                    },
                    {
                        "id":"710359000000",
                        "label":"白河区",
                        "full_label":"台湾省台南市白河区"
                    },
                    {
                        "id":"710360000000",
                        "label":"东山区",
                        "full_label":"台湾省台南市东山区"
                    },
                    {
                        "id":"710361000000",
                        "label":"六甲区",
                        "full_label":"台湾省台南市六甲区"
                    },
                    {
                        "id":"710362000000",
                        "label":"下营区",
                        "full_label":"台湾省台南市下营区"
                    },
                    {
                        "id":"710363000000",
                        "label":"柳营区",
                        "full_label":"台湾省台南市柳营区"
                    },
                    {
                        "id":"710364000000",
                        "label":"盐水区",
                        "full_label":"台湾省台南市盐水区"
                    },
                    {
                        "id":"710365000000",
                        "label":"善化区",
                        "full_label":"台湾省台南市善化区"
                    },
                    {
                        "id":"710366000000",
                        "label":"大内区",
                        "full_label":"台湾省台南市大内区"
                    },
                    {
                        "id":"710367000000",
                        "label":"山上区",
                        "full_label":"台湾省台南市山上区"
                    },
                    {
                        "id":"710368000000",
                        "label":"新市区",
                        "full_label":"台湾省台南市新市区"
                    },
                    {
                        "id":"710369000000",
                        "label":"安定区",
                        "full_label":"台湾省台南市安定区"
                    }
                ],
                "full_label":"台湾省台南市"
            },
            {
                "id":"710400000000",
                "label":"台中市",
                "children":[
                    {
                        "id":"710401000000",
                        "label":"中区",
                        "full_label":"台湾省台中市中区"
                    },
                    {
                        "id":"710402000000",
                        "label":"东区",
                        "full_label":"台湾省台中市东区"
                    },
                    {
                        "id":"710403000000",
                        "label":"南区",
                        "full_label":"台湾省台中市南区"
                    },
                    {
                        "id":"710404000000",
                        "label":"西区",
                        "full_label":"台湾省台中市西区"
                    },
                    {
                        "id":"710405000000",
                        "label":"北区",
                        "full_label":"台湾省台中市北区"
                    },
                    {
                        "id":"710406000000",
                        "label":"北屯区",
                        "full_label":"台湾省台中市北屯区"
                    },
                    {
                        "id":"710407000000",
                        "label":"西屯区",
                        "full_label":"台湾省台中市西屯区"
                    },
                    {
                        "id":"710408000000",
                        "label":"南屯区",
                        "full_label":"台湾省台中市南屯区"
                    },
                    {
                        "id":"710431000000",
                        "label":"太平区",
                        "full_label":"台湾省台中市太平区"
                    },
                    {
                        "id":"710432000000",
                        "label":"大里区",
                        "full_label":"台湾省台中市大里区"
                    },
                    {
                        "id":"710433000000",
                        "label":"雾峰区",
                        "full_label":"台湾省台中市雾峰区"
                    },
                    {
                        "id":"710434000000",
                        "label":"乌日区",
                        "full_label":"台湾省台中市乌日区"
                    },
                    {
                        "id":"710435000000",
                        "label":"丰原区",
                        "full_label":"台湾省台中市丰原区"
                    },
                    {
                        "id":"710436000000",
                        "label":"后里区",
                        "full_label":"台湾省台中市后里区"
                    },
                    {
                        "id":"710437000000",
                        "label":"石冈区",
                        "full_label":"台湾省台中市石冈区"
                    },
                    {
                        "id":"710438000000",
                        "label":"东势区",
                        "full_label":"台湾省台中市东势区"
                    },
                    {
                        "id":"710439000000",
                        "label":"和平区",
                        "full_label":"台湾省台中市和平区"
                    },
                    {
                        "id":"710440000000",
                        "label":"新社区",
                        "full_label":"台湾省台中市新社区"
                    },
                    {
                        "id":"710441000000",
                        "label":"潭子区",
                        "full_label":"台湾省台中市潭子区"
                    },
                    {
                        "id":"710442000000",
                        "label":"大雅区",
                        "full_label":"台湾省台中市大雅区"
                    },
                    {
                        "id":"710443000000",
                        "label":"神冈区",
                        "full_label":"台湾省台中市神冈区"
                    },
                    {
                        "id":"710444000000",
                        "label":"大肚区",
                        "full_label":"台湾省台中市大肚区"
                    },
                    {
                        "id":"710445000000",
                        "label":"沙鹿区",
                        "full_label":"台湾省台中市沙鹿区"
                    },
                    {
                        "id":"710446000000",
                        "label":"龙井区",
                        "full_label":"台湾省台中市龙井区"
                    },
                    {
                        "id":"710447000000",
                        "label":"梧栖区",
                        "full_label":"台湾省台中市梧栖区"
                    },
                    {
                        "id":"710448000000",
                        "label":"清水区",
                        "full_label":"台湾省台中市清水区"
                    },
                    {
                        "id":"710449000000",
                        "label":"大甲区",
                        "full_label":"台湾省台中市大甲区"
                    },
                    {
                        "id":"710450000000",
                        "label":"外埔区",
                        "full_label":"台湾省台中市外埔区"
                    },
                    {
                        "id":"710451000000",
                        "label":"大安区",
                        "full_label":"台湾省台中市大安区"
                    }
                ],
                "full_label":"台湾省台中市"
            },
            {
                "id":"710600000000",
                "label":"南投县",
                "children":[
                    {
                        "id":"710614000000",
                        "label":"南投市",
                        "full_label":"台湾省南投县南投市"
                    },
                    {
                        "id":"710615000000",
                        "label":"中寮乡",
                        "full_label":"台湾省南投县中寮乡"
                    },
                    {
                        "id":"710616000000",
                        "label":"草屯镇",
                        "full_label":"台湾省南投县草屯镇"
                    },
                    {
                        "id":"710617000000",
                        "label":"国姓乡",
                        "full_label":"台湾省南投县国姓乡"
                    },
                    {
                        "id":"710618000000",
                        "label":"埔里镇",
                        "full_label":"台湾省南投县埔里镇"
                    },
                    {
                        "id":"710619000000",
                        "label":"仁爱乡",
                        "full_label":"台湾省南投县仁爱乡"
                    },
                    {
                        "id":"710620000000",
                        "label":"名间乡",
                        "full_label":"台湾省南投县名间乡"
                    },
                    {
                        "id":"710621000000",
                        "label":"集集镇",
                        "full_label":"台湾省南投县集集镇"
                    },
                    {
                        "id":"710622000000",
                        "label":"水里乡",
                        "full_label":"台湾省南投县水里乡"
                    },
                    {
                        "id":"710623000000",
                        "label":"鱼池乡",
                        "full_label":"台湾省南投县鱼池乡"
                    },
                    {
                        "id":"710624000000",
                        "label":"信义乡",
                        "full_label":"台湾省南投县信义乡"
                    },
                    {
                        "id":"710625000000",
                        "label":"竹山镇",
                        "full_label":"台湾省南投县竹山镇"
                    },
                    {
                        "id":"710626000000",
                        "label":"鹿谷乡",
                        "full_label":"台湾省南投县鹿谷乡"
                    }
                ],
                "full_label":"台湾省南投县"
            },
            {
                "id":"710700000000",
                "label":"基隆市",
                "children":[
                    {
                        "id":"710701000000",
                        "label":"仁爱区",
                        "full_label":"台湾省基隆市仁爱区"
                    },
                    {
                        "id":"710702000000",
                        "label":"信义区",
                        "full_label":"台湾省基隆市信义区"
                    },
                    {
                        "id":"710703000000",
                        "label":"中正区",
                        "full_label":"台湾省基隆市中正区"
                    },
                    {
                        "id":"710704000000",
                        "label":"中山区",
                        "full_label":"台湾省基隆市中山区"
                    },
                    {
                        "id":"710705000000",
                        "label":"安乐区",
                        "full_label":"台湾省基隆市安乐区"
                    },
                    {
                        "id":"710706000000",
                        "label":"暖暖区",
                        "full_label":"台湾省基隆市暖暖区"
                    },
                    {
                        "id":"710707000000",
                        "label":"七堵区",
                        "full_label":"台湾省基隆市七堵区"
                    }
                ],
                "full_label":"台湾省基隆市"
            },
            {
                "id":"710800000000",
                "label":"新竹市",
                "children":[
                    {
                        "id":"710801000000",
                        "label":"东区",
                        "full_label":"台湾省新竹市东区"
                    },
                    {
                        "id":"710802000000",
                        "label":"北区",
                        "full_label":"台湾省新竹市北区"
                    },
                    {
                        "id":"710803000000",
                        "label":"香山区",
                        "full_label":"台湾省新竹市香山区"
                    }
                ],
                "full_label":"台湾省新竹市"
            },
            {
                "id":"710900000000",
                "label":"嘉义市",
                "children":[
                    {
                        "id":"710901000000",
                        "label":"东区",
                        "full_label":"台湾省嘉义市东区"
                    },
                    {
                        "id":"710902000000",
                        "label":"西区",
                        "full_label":"台湾省嘉义市西区"
                    }
                ],
                "full_label":"台湾省嘉义市"
            },
            {
                "id":"711100000000",
                "label":"新北市",
                "children":[
                    {
                        "id":"711130000000",
                        "label":"万里区",
                        "full_label":"台湾省新北市万里区"
                    },
                    {
                        "id":"711131000000",
                        "label":"金山区",
                        "full_label":"台湾省新北市金山区"
                    },
                    {
                        "id":"711132000000",
                        "label":"板桥区",
                        "full_label":"台湾省新北市板桥区"
                    },
                    {
                        "id":"711133000000",
                        "label":"汐止区",
                        "full_label":"台湾省新北市汐止区"
                    },
                    {
                        "id":"711134000000",
                        "label":"深坑区",
                        "full_label":"台湾省新北市深坑区"
                    },
                    {
                        "id":"711135000000",
                        "label":"石碇区",
                        "full_label":"台湾省新北市石碇区"
                    },
                    {
                        "id":"711136000000",
                        "label":"瑞芳区",
                        "full_label":"台湾省新北市瑞芳区"
                    },
                    {
                        "id":"711137000000",
                        "label":"平溪区",
                        "full_label":"台湾省新北市平溪区"
                    },
                    {
                        "id":"711138000000",
                        "label":"双溪区",
                        "full_label":"台湾省新北市双溪区"
                    },
                    {
                        "id":"711139000000",
                        "label":"贡寮区",
                        "full_label":"台湾省新北市贡寮区"
                    },
                    {
                        "id":"711140000000",
                        "label":"新店区",
                        "full_label":"台湾省新北市新店区"
                    },
                    {
                        "id":"711141000000",
                        "label":"坪林区",
                        "full_label":"台湾省新北市坪林区"
                    },
                    {
                        "id":"711142000000",
                        "label":"乌来区",
                        "full_label":"台湾省新北市乌来区"
                    },
                    {
                        "id":"711143000000",
                        "label":"永和区",
                        "full_label":"台湾省新北市永和区"
                    },
                    {
                        "id":"711144000000",
                        "label":"中和区",
                        "full_label":"台湾省新北市中和区"
                    },
                    {
                        "id":"711145000000",
                        "label":"土城区",
                        "full_label":"台湾省新北市土城区"
                    },
                    {
                        "id":"711146000000",
                        "label":"三峡区",
                        "full_label":"台湾省新北市三峡区"
                    },
                    {
                        "id":"711147000000",
                        "label":"树林区",
                        "full_label":"台湾省新北市树林区"
                    },
                    {
                        "id":"711148000000",
                        "label":"莺歌区",
                        "full_label":"台湾省新北市莺歌区"
                    },
                    {
                        "id":"711149000000",
                        "label":"三重区",
                        "full_label":"台湾省新北市三重区"
                    },
                    {
                        "id":"711150000000",
                        "label":"新庄区",
                        "full_label":"台湾省新北市新庄区"
                    },
                    {
                        "id":"711151000000",
                        "label":"泰山区",
                        "full_label":"台湾省新北市泰山区"
                    },
                    {
                        "id":"711152000000",
                        "label":"林口区",
                        "full_label":"台湾省新北市林口区"
                    },
                    {
                        "id":"711153000000",
                        "label":"芦洲区",
                        "full_label":"台湾省新北市芦洲区"
                    },
                    {
                        "id":"711154000000",
                        "label":"五股区",
                        "full_label":"台湾省新北市五股区"
                    },
                    {
                        "id":"711155000000",
                        "label":"八里区",
                        "full_label":"台湾省新北市八里区"
                    },
                    {
                        "id":"711156000000",
                        "label":"淡水区",
                        "full_label":"台湾省新北市淡水区"
                    },
                    {
                        "id":"711157000000",
                        "label":"三芝区",
                        "full_label":"台湾省新北市三芝区"
                    },
                    {
                        "id":"711158000000",
                        "label":"石门区",
                        "full_label":"台湾省新北市石门区"
                    }
                ],
                "full_label":"台湾省新北市"
            },
            {
                "id":"711200000000",
                "label":"宜兰县",
                "children":[
                    {
                        "id":"711214000000",
                        "label":"宜兰市",
                        "full_label":"台湾省宜兰县宜兰市"
                    },
                    {
                        "id":"711215000000",
                        "label":"头城镇",
                        "full_label":"台湾省宜兰县头城镇"
                    },
                    {
                        "id":"711216000000",
                        "label":"礁溪乡",
                        "full_label":"台湾省宜兰县礁溪乡"
                    },
                    {
                        "id":"711217000000",
                        "label":"壮围乡",
                        "full_label":"台湾省宜兰县壮围乡"
                    },
                    {
                        "id":"711218000000",
                        "label":"员山乡",
                        "full_label":"台湾省宜兰县员山乡"
                    },
                    {
                        "id":"711219000000",
                        "label":"罗东镇",
                        "full_label":"台湾省宜兰县罗东镇"
                    },
                    {
                        "id":"711220000000",
                        "label":"三星乡",
                        "full_label":"台湾省宜兰县三星乡"
                    },
                    {
                        "id":"711221000000",
                        "label":"大同乡",
                        "full_label":"台湾省宜兰县大同乡"
                    },
                    {
                        "id":"711222000000",
                        "label":"五结乡",
                        "full_label":"台湾省宜兰县五结乡"
                    },
                    {
                        "id":"711223000000",
                        "label":"冬山乡",
                        "full_label":"台湾省宜兰县冬山乡"
                    },
                    {
                        "id":"711224000000",
                        "label":"苏澳镇",
                        "full_label":"台湾省宜兰县苏澳镇"
                    },
                    {
                        "id":"711225000000",
                        "label":"南澳乡",
                        "full_label":"台湾省宜兰县南澳乡"
                    }
                ],
                "full_label":"台湾省宜兰县"
            },
            {
                "id":"711300000000",
                "label":"新竹县",
                "children":[
                    {
                        "id":"711314000000",
                        "label":"竹北市",
                        "full_label":"台湾省新竹县竹北市"
                    },
                    {
                        "id":"711315000000",
                        "label":"湖口乡",
                        "full_label":"台湾省新竹县湖口乡"
                    },
                    {
                        "id":"711316000000",
                        "label":"新丰乡",
                        "full_label":"台湾省新竹县新丰乡"
                    },
                    {
                        "id":"711317000000",
                        "label":"新埔镇",
                        "full_label":"台湾省新竹县新埔镇"
                    },
                    {
                        "id":"711318000000",
                        "label":"关西镇",
                        "full_label":"台湾省新竹县关西镇"
                    },
                    {
                        "id":"711319000000",
                        "label":"芎林乡",
                        "full_label":"台湾省新竹县芎林乡"
                    },
                    {
                        "id":"711320000000",
                        "label":"宝山乡",
                        "full_label":"台湾省新竹县宝山乡"
                    },
                    {
                        "id":"711321000000",
                        "label":"竹东镇",
                        "full_label":"台湾省新竹县竹东镇"
                    },
                    {
                        "id":"711322000000",
                        "label":"五峰乡",
                        "full_label":"台湾省新竹县五峰乡"
                    },
                    {
                        "id":"711323000000",
                        "label":"横山乡",
                        "full_label":"台湾省新竹县横山乡"
                    },
                    {
                        "id":"711324000000",
                        "label":"尖石乡",
                        "full_label":"台湾省新竹县尖石乡"
                    },
                    {
                        "id":"711325000000",
                        "label":"北埔乡",
                        "full_label":"台湾省新竹县北埔乡"
                    },
                    {
                        "id":"711326000000",
                        "label":"峨眉乡",
                        "full_label":"台湾省新竹县峨眉乡"
                    }
                ],
                "full_label":"台湾省新竹县"
            },
            {
                "id":"711400000000",
                "label":"桃园市",
                "children":[
                    {
                        "id":"711414000000",
                        "label":"中坜区",
                        "full_label":"台湾省桃园市中坜区"
                    },
                    {
                        "id":"711415000000",
                        "label":"平镇区",
                        "full_label":"台湾省桃园市平镇区"
                    },
                    {
                        "id":"711416000000",
                        "label":"龙潭区",
                        "full_label":"台湾省桃园市龙潭区"
                    },
                    {
                        "id":"711417000000",
                        "label":"杨梅区",
                        "full_label":"台湾省桃园市杨梅区"
                    },
                    {
                        "id":"711418000000",
                        "label":"新屋区",
                        "full_label":"台湾省桃园市新屋区"
                    },
                    {
                        "id":"711419000000",
                        "label":"观音区",
                        "full_label":"台湾省桃园市观音区"
                    },
                    {
                        "id":"711420000000",
                        "label":"桃园区",
                        "full_label":"台湾省桃园市桃园区"
                    },
                    {
                        "id":"711421000000",
                        "label":"龟山区",
                        "full_label":"台湾省桃园市龟山区"
                    },
                    {
                        "id":"711422000000",
                        "label":"八德区",
                        "full_label":"台湾省桃园市八德区"
                    },
                    {
                        "id":"711423000000",
                        "label":"大溪区",
                        "full_label":"台湾省桃园市大溪区"
                    },
                    {
                        "id":"711424000000",
                        "label":"复兴区",
                        "full_label":"台湾省桃园市复兴区"
                    },
                    {
                        "id":"711425000000",
                        "label":"大园区",
                        "full_label":"台湾省桃园市大园区"
                    },
                    {
                        "id":"711426000000",
                        "label":"芦竹区",
                        "full_label":"台湾省桃园市芦竹区"
                    }
                ],
                "full_label":"台湾省桃园市"
            },
            {
                "id":"711500000000",
                "label":"苗栗县",
                "children":[
                    {
                        "id":"711519000000",
                        "label":"竹南镇",
                        "full_label":"台湾省苗栗县竹南镇"
                    },
                    {
                        "id":"711520000000",
                        "label":"头份市",
                        "full_label":"台湾省苗栗县头份市"
                    },
                    {
                        "id":"711521000000",
                        "label":"三湾乡",
                        "full_label":"台湾省苗栗县三湾乡"
                    },
                    {
                        "id":"711522000000",
                        "label":"南庄乡",
                        "full_label":"台湾省苗栗县南庄乡"
                    },
                    {
                        "id":"711523000000",
                        "label":"狮潭乡",
                        "full_label":"台湾省苗栗县狮潭乡"
                    },
                    {
                        "id":"711524000000",
                        "label":"后龙镇",
                        "full_label":"台湾省苗栗县后龙镇"
                    },
                    {
                        "id":"711525000000",
                        "label":"通霄镇",
                        "full_label":"台湾省苗栗县通霄镇"
                    },
                    {
                        "id":"711526000000",
                        "label":"苑里镇",
                        "full_label":"台湾省苗栗县苑里镇"
                    },
                    {
                        "id":"711527000000",
                        "label":"苗栗市",
                        "full_label":"台湾省苗栗县苗栗市"
                    },
                    {
                        "id":"711528000000",
                        "label":"造桥乡",
                        "full_label":"台湾省苗栗县造桥乡"
                    },
                    {
                        "id":"711529000000",
                        "label":"头屋乡",
                        "full_label":"台湾省苗栗县头屋乡"
                    },
                    {
                        "id":"711530000000",
                        "label":"公馆乡",
                        "full_label":"台湾省苗栗县公馆乡"
                    },
                    {
                        "id":"711531000000",
                        "label":"大湖乡",
                        "full_label":"台湾省苗栗县大湖乡"
                    },
                    {
                        "id":"711532000000",
                        "label":"泰安乡",
                        "full_label":"台湾省苗栗县泰安乡"
                    },
                    {
                        "id":"711533000000",
                        "label":"铜锣乡",
                        "full_label":"台湾省苗栗县铜锣乡"
                    },
                    {
                        "id":"711534000000",
                        "label":"三义乡",
                        "full_label":"台湾省苗栗县三义乡"
                    },
                    {
                        "id":"711535000000",
                        "label":"西湖乡",
                        "full_label":"台湾省苗栗县西湖乡"
                    },
                    {
                        "id":"711536000000",
                        "label":"卓兰镇",
                        "full_label":"台湾省苗栗县卓兰镇"
                    }
                ],
                "full_label":"台湾省苗栗县"
            },
            {
                "id":"711700000000",
                "label":"彰化县",
                "children":[
                    {
                        "id":"711727000000",
                        "label":"彰化市",
                        "full_label":"台湾省彰化县彰化市"
                    },
                    {
                        "id":"711728000000",
                        "label":"芬园乡",
                        "full_label":"台湾省彰化县芬园乡"
                    },
                    {
                        "id":"711729000000",
                        "label":"花坛乡",
                        "full_label":"台湾省彰化县花坛乡"
                    },
                    {
                        "id":"711730000000",
                        "label":"秀水乡",
                        "full_label":"台湾省彰化县秀水乡"
                    },
                    {
                        "id":"711731000000",
                        "label":"鹿港镇",
                        "full_label":"台湾省彰化县鹿港镇"
                    },
                    {
                        "id":"711732000000",
                        "label":"福兴乡",
                        "full_label":"台湾省彰化县福兴乡"
                    },
                    {
                        "id":"711733000000",
                        "label":"线西乡",
                        "full_label":"台湾省彰化县线西乡"
                    },
                    {
                        "id":"711734000000",
                        "label":"和美镇",
                        "full_label":"台湾省彰化县和美镇"
                    },
                    {
                        "id":"711735000000",
                        "label":"伸港乡",
                        "full_label":"台湾省彰化县伸港乡"
                    },
                    {
                        "id":"711736000000",
                        "label":"员林市",
                        "full_label":"台湾省彰化县员林市"
                    },
                    {
                        "id":"711737000000",
                        "label":"社头乡",
                        "full_label":"台湾省彰化县社头乡"
                    },
                    {
                        "id":"711738000000",
                        "label":"永靖乡",
                        "full_label":"台湾省彰化县永靖乡"
                    },
                    {
                        "id":"711739000000",
                        "label":"埔心乡",
                        "full_label":"台湾省彰化县埔心乡"
                    },
                    {
                        "id":"711740000000",
                        "label":"溪湖镇",
                        "full_label":"台湾省彰化县溪湖镇"
                    },
                    {
                        "id":"711741000000",
                        "label":"大村乡",
                        "full_label":"台湾省彰化县大村乡"
                    },
                    {
                        "id":"711742000000",
                        "label":"埔盐乡",
                        "full_label":"台湾省彰化县埔盐乡"
                    },
                    {
                        "id":"711743000000",
                        "label":"田中镇",
                        "full_label":"台湾省彰化县田中镇"
                    },
                    {
                        "id":"711744000000",
                        "label":"北斗镇",
                        "full_label":"台湾省彰化县北斗镇"
                    },
                    {
                        "id":"711745000000",
                        "label":"田尾乡",
                        "full_label":"台湾省彰化县田尾乡"
                    },
                    {
                        "id":"711746000000",
                        "label":"埤头乡",
                        "full_label":"台湾省彰化县埤头乡"
                    },
                    {
                        "id":"711747000000",
                        "label":"溪州乡",
                        "full_label":"台湾省彰化县溪州乡"
                    },
                    {
                        "id":"711748000000",
                        "label":"竹塘乡",
                        "full_label":"台湾省彰化县竹塘乡"
                    },
                    {
                        "id":"711749000000",
                        "label":"二林镇",
                        "full_label":"台湾省彰化县二林镇"
                    },
                    {
                        "id":"711750000000",
                        "label":"大城乡",
                        "full_label":"台湾省彰化县大城乡"
                    },
                    {
                        "id":"711751000000",
                        "label":"芳苑乡",
                        "full_label":"台湾省彰化县芳苑乡"
                    },
                    {
                        "id":"711752000000",
                        "label":"二水乡",
                        "full_label":"台湾省彰化县二水乡"
                    }
                ],
                "full_label":"台湾省彰化县"
            },
            {
                "id":"711900000000",
                "label":"嘉义县",
                "children":[
                    {
                        "id":"711919000000",
                        "label":"番路乡",
                        "full_label":"台湾省嘉义县番路乡"
                    },
                    {
                        "id":"711920000000",
                        "label":"梅山乡",
                        "full_label":"台湾省嘉义县梅山乡"
                    },
                    {
                        "id":"711921000000",
                        "label":"竹崎乡",
                        "full_label":"台湾省嘉义县竹崎乡"
                    },
                    {
                        "id":"711922000000",
                        "label":"阿里山乡",
                        "full_label":"台湾省嘉义县阿里山乡"
                    },
                    {
                        "id":"711923000000",
                        "label":"中埔乡",
                        "full_label":"台湾省嘉义县中埔乡"
                    },
                    {
                        "id":"711924000000",
                        "label":"大埔乡",
                        "full_label":"台湾省嘉义县大埔乡"
                    },
                    {
                        "id":"711925000000",
                        "label":"水上乡",
                        "full_label":"台湾省嘉义县水上乡"
                    },
                    {
                        "id":"711926000000",
                        "label":"鹿草乡",
                        "full_label":"台湾省嘉义县鹿草乡"
                    },
                    {
                        "id":"711927000000",
                        "label":"太保市",
                        "full_label":"台湾省嘉义县太保市"
                    },
                    {
                        "id":"711928000000",
                        "label":"朴子市",
                        "full_label":"台湾省嘉义县朴子市"
                    },
                    {
                        "id":"711929000000",
                        "label":"东石乡",
                        "full_label":"台湾省嘉义县东石乡"
                    },
                    {
                        "id":"711930000000",
                        "label":"六脚乡",
                        "full_label":"台湾省嘉义县六脚乡"
                    },
                    {
                        "id":"711931000000",
                        "label":"新港乡",
                        "full_label":"台湾省嘉义县新港乡"
                    },
                    {
                        "id":"711932000000",
                        "label":"民雄乡",
                        "full_label":"台湾省嘉义县民雄乡"
                    },
                    {
                        "id":"711933000000",
                        "label":"大林镇",
                        "full_label":"台湾省嘉义县大林镇"
                    },
                    {
                        "id":"711934000000",
                        "label":"溪口乡",
                        "full_label":"台湾省嘉义县溪口乡"
                    },
                    {
                        "id":"711935000000",
                        "label":"义竹乡",
                        "full_label":"台湾省嘉义县义竹乡"
                    },
                    {
                        "id":"711936000000",
                        "label":"布袋镇",
                        "full_label":"台湾省嘉义县布袋镇"
                    }
                ],
                "full_label":"台湾省嘉义县"
            },
            {
                "id":"712100000000",
                "label":"云林县",
                "children":[
                    {
                        "id":"712121000000",
                        "label":"斗南镇",
                        "full_label":"台湾省云林县斗南镇"
                    },
                    {
                        "id":"712122000000",
                        "label":"大埤乡",
                        "full_label":"台湾省云林县大埤乡"
                    },
                    {
                        "id":"712123000000",
                        "label":"虎尾镇",
                        "full_label":"台湾省云林县虎尾镇"
                    },
                    {
                        "id":"712124000000",
                        "label":"土库镇",
                        "full_label":"台湾省云林县土库镇"
                    },
                    {
                        "id":"712125000000",
                        "label":"褒忠乡",
                        "full_label":"台湾省云林县褒忠乡"
                    },
                    {
                        "id":"712126000000",
                        "label":"东势乡",
                        "full_label":"台湾省云林县东势乡"
                    },
                    {
                        "id":"712127000000",
                        "label":"台西乡",
                        "full_label":"台湾省云林县台西乡"
                    },
                    {
                        "id":"712128000000",
                        "label":"仑背乡",
                        "full_label":"台湾省云林县仑背乡"
                    },
                    {
                        "id":"712129000000",
                        "label":"麦寮乡",
                        "full_label":"台湾省云林县麦寮乡"
                    },
                    {
                        "id":"712130000000",
                        "label":"斗六市",
                        "full_label":"台湾省云林县斗六市"
                    },
                    {
                        "id":"712131000000",
                        "label":"林内乡",
                        "full_label":"台湾省云林县林内乡"
                    },
                    {
                        "id":"712132000000",
                        "label":"古坑乡",
                        "full_label":"台湾省云林县古坑乡"
                    },
                    {
                        "id":"712133000000",
                        "label":"莿桐乡",
                        "full_label":"台湾省云林县莿桐乡"
                    },
                    {
                        "id":"712134000000",
                        "label":"西螺镇",
                        "full_label":"台湾省云林县西螺镇"
                    },
                    {
                        "id":"712135000000",
                        "label":"二仑乡",
                        "full_label":"台湾省云林县二仑乡"
                    },
                    {
                        "id":"712136000000",
                        "label":"北港镇",
                        "full_label":"台湾省云林县北港镇"
                    },
                    {
                        "id":"712137000000",
                        "label":"水林乡",
                        "full_label":"台湾省云林县水林乡"
                    },
                    {
                        "id":"712138000000",
                        "label":"口湖乡",
                        "full_label":"台湾省云林县口湖乡"
                    },
                    {
                        "id":"712139000000",
                        "label":"四湖乡",
                        "full_label":"台湾省云林县四湖乡"
                    },
                    {
                        "id":"712140000000",
                        "label":"元长乡",
                        "full_label":"台湾省云林县元长乡"
                    }
                ],
                "full_label":"台湾省云林县"
            },
            {
                "id":"712400000000",
                "label":"屏东县",
                "children":[
                    {
                        "id":"712434000000",
                        "label":"屏东市",
                        "full_label":"台湾省屏东县屏东市"
                    },
                    {
                        "id":"712435000000",
                        "label":"三地门乡",
                        "full_label":"台湾省屏东县三地门乡"
                    },
                    {
                        "id":"712436000000",
                        "label":"雾台乡",
                        "full_label":"台湾省屏东县雾台乡"
                    },
                    {
                        "id":"712437000000",
                        "label":"玛家乡",
                        "full_label":"台湾省屏东县玛家乡"
                    },
                    {
                        "id":"712438000000",
                        "label":"九如乡",
                        "full_label":"台湾省屏东县九如乡"
                    },
                    {
                        "id":"712439000000",
                        "label":"里港乡",
                        "full_label":"台湾省屏东县里港乡"
                    },
                    {
                        "id":"712440000000",
                        "label":"高树乡",
                        "full_label":"台湾省屏东县高树乡"
                    },
                    {
                        "id":"712441000000",
                        "label":"盐埔乡",
                        "full_label":"台湾省屏东县盐埔乡"
                    },
                    {
                        "id":"712442000000",
                        "label":"长治乡",
                        "full_label":"台湾省屏东县长治乡"
                    },
                    {
                        "id":"712443000000",
                        "label":"麟洛乡",
                        "full_label":"台湾省屏东县麟洛乡"
                    },
                    {
                        "id":"712444000000",
                        "label":"竹田乡",
                        "full_label":"台湾省屏东县竹田乡"
                    },
                    {
                        "id":"712445000000",
                        "label":"内埔乡",
                        "full_label":"台湾省屏东县内埔乡"
                    },
                    {
                        "id":"712446000000",
                        "label":"万丹乡",
                        "full_label":"台湾省屏东县万丹乡"
                    },
                    {
                        "id":"712447000000",
                        "label":"潮州镇",
                        "full_label":"台湾省屏东县潮州镇"
                    },
                    {
                        "id":"712448000000",
                        "label":"泰武乡",
                        "full_label":"台湾省屏东县泰武乡"
                    },
                    {
                        "id":"712449000000",
                        "label":"来义乡",
                        "full_label":"台湾省屏东县来义乡"
                    },
                    {
                        "id":"712450000000",
                        "label":"万峦乡",
                        "full_label":"台湾省屏东县万峦乡"
                    },
                    {
                        "id":"712451000000",
                        "label":"崁顶乡",
                        "full_label":"台湾省屏东县崁顶乡"
                    },
                    {
                        "id":"712452000000",
                        "label":"新埤乡",
                        "full_label":"台湾省屏东县新埤乡"
                    },
                    {
                        "id":"712453000000",
                        "label":"南州乡",
                        "full_label":"台湾省屏东县南州乡"
                    },
                    {
                        "id":"712454000000",
                        "label":"林边乡",
                        "full_label":"台湾省屏东县林边乡"
                    },
                    {
                        "id":"712455000000",
                        "label":"东港镇",
                        "full_label":"台湾省屏东县东港镇"
                    },
                    {
                        "id":"712456000000",
                        "label":"琉球乡",
                        "full_label":"台湾省屏东县琉球乡"
                    },
                    {
                        "id":"712457000000",
                        "label":"佳冬乡",
                        "full_label":"台湾省屏东县佳冬乡"
                    },
                    {
                        "id":"712458000000",
                        "label":"新园乡",
                        "full_label":"台湾省屏东县新园乡"
                    },
                    {
                        "id":"712459000000",
                        "label":"枋寮乡",
                        "full_label":"台湾省屏东县枋寮乡"
                    },
                    {
                        "id":"712460000000",
                        "label":"枋山乡",
                        "full_label":"台湾省屏东县枋山乡"
                    },
                    {
                        "id":"712461000000",
                        "label":"春日乡",
                        "full_label":"台湾省屏东县春日乡"
                    },
                    {
                        "id":"712462000000",
                        "label":"狮子乡",
                        "full_label":"台湾省屏东县狮子乡"
                    },
                    {
                        "id":"712463000000",
                        "label":"车城乡",
                        "full_label":"台湾省屏东县车城乡"
                    },
                    {
                        "id":"712464000000",
                        "label":"牡丹乡",
                        "full_label":"台湾省屏东县牡丹乡"
                    },
                    {
                        "id":"712465000000",
                        "label":"恒春镇",
                        "full_label":"台湾省屏东县恒春镇"
                    },
                    {
                        "id":"712466000000",
                        "label":"满州乡",
                        "full_label":"台湾省屏东县满州乡"
                    }
                ],
                "full_label":"台湾省屏东县"
            },
            {
                "id":"712500000000",
                "label":"台东县",
                "children":[
                    {
                        "id":"712517000000",
                        "label":"台东市",
                        "full_label":"台湾省台东县台东市"
                    },
                    {
                        "id":"712518000000",
                        "label":"绿岛乡",
                        "full_label":"台湾省台东县绿岛乡"
                    },
                    {
                        "id":"712519000000",
                        "label":"兰屿乡",
                        "full_label":"台湾省台东县兰屿乡"
                    },
                    {
                        "id":"712520000000",
                        "label":"延平乡",
                        "full_label":"台湾省台东县延平乡"
                    },
                    {
                        "id":"712521000000",
                        "label":"卑南乡",
                        "full_label":"台湾省台东县卑南乡"
                    },
                    {
                        "id":"712522000000",
                        "label":"鹿野乡",
                        "full_label":"台湾省台东县鹿野乡"
                    },
                    {
                        "id":"712523000000",
                        "label":"关山镇",
                        "full_label":"台湾省台东县关山镇"
                    },
                    {
                        "id":"712524000000",
                        "label":"海端乡",
                        "full_label":"台湾省台东县海端乡"
                    },
                    {
                        "id":"712525000000",
                        "label":"池上乡",
                        "full_label":"台湾省台东县池上乡"
                    },
                    {
                        "id":"712526000000",
                        "label":"东河乡",
                        "full_label":"台湾省台东县东河乡"
                    },
                    {
                        "id":"712527000000",
                        "label":"成功镇",
                        "full_label":"台湾省台东县成功镇"
                    },
                    {
                        "id":"712528000000",
                        "label":"长滨乡",
                        "full_label":"台湾省台东县长滨乡"
                    },
                    {
                        "id":"712529000000",
                        "label":"金峰乡",
                        "full_label":"台湾省台东县金峰乡"
                    },
                    {
                        "id":"712530000000",
                        "label":"大武乡",
                        "full_label":"台湾省台东县大武乡"
                    },
                    {
                        "id":"712531000000",
                        "label":"达仁乡",
                        "full_label":"台湾省台东县达仁乡"
                    },
                    {
                        "id":"712532000000",
                        "label":"太麻里乡",
                        "full_label":"台湾省台东县太麻里乡"
                    }
                ],
                "full_label":"台湾省台东县"
            },
            {
                "id":"712600000000",
                "label":"花莲县",
                "children":[
                    {
                        "id":"712615000000",
                        "label":"花莲市",
                        "full_label":"台湾省花莲县花莲市"
                    },
                    {
                        "id":"712616000000",
                        "label":"新城乡",
                        "full_label":"台湾省花莲县新城乡"
                    },
                    {
                        "id":"712618000000",
                        "label":"秀林乡",
                        "full_label":"台湾省花莲县秀林乡"
                    },
                    {
                        "id":"712619000000",
                        "label":"吉安乡",
                        "full_label":"台湾省花莲县吉安乡"
                    },
                    {
                        "id":"712620000000",
                        "label":"寿丰乡",
                        "full_label":"台湾省花莲县寿丰乡"
                    },
                    {
                        "id":"712621000000",
                        "label":"凤林镇",
                        "full_label":"台湾省花莲县凤林镇"
                    },
                    {
                        "id":"712622000000",
                        "label":"光复乡",
                        "full_label":"台湾省花莲县光复乡"
                    },
                    {
                        "id":"712623000000",
                        "label":"丰滨乡",
                        "full_label":"台湾省花莲县丰滨乡"
                    },
                    {
                        "id":"712624000000",
                        "label":"瑞穗乡",
                        "full_label":"台湾省花莲县瑞穗乡"
                    },
                    {
                        "id":"712625000000",
                        "label":"万荣乡",
                        "full_label":"台湾省花莲县万荣乡"
                    },
                    {
                        "id":"712626000000",
                        "label":"玉里镇",
                        "full_label":"台湾省花莲县玉里镇"
                    },
                    {
                        "id":"712627000000",
                        "label":"卓溪乡",
                        "full_label":"台湾省花莲县卓溪乡"
                    },
                    {
                        "id":"712628000000",
                        "label":"富里乡",
                        "full_label":"台湾省花莲县富里乡"
                    }
                ],
                "full_label":"台湾省花莲县"
            },
            {
                "id":"712700000000",
                "label":"澎湖县",
                "children":[
                    {
                        "id":"712707000000",
                        "label":"马公市",
                        "full_label":"台湾省澎湖县马公市"
                    },
                    {
                        "id":"712708000000",
                        "label":"西屿乡",
                        "full_label":"台湾省澎湖县西屿乡"
                    },
                    {
                        "id":"712709000000",
                        "label":"望安乡",
                        "full_label":"台湾省澎湖县望安乡"
                    },
                    {
                        "id":"712710000000",
                        "label":"七美乡",
                        "full_label":"台湾省澎湖县七美乡"
                    },
                    {
                        "id":"712711000000",
                        "label":"白沙乡",
                        "full_label":"台湾省澎湖县白沙乡"
                    },
                    {
                        "id":"712712000000",
                        "label":"湖西乡",
                        "full_label":"台湾省澎湖县湖西乡"
                    }
                ],
                "full_label":"台湾省澎湖县"
            }
        ],
        "full_label":"台湾省"
    },
    {
        "id":"810000000000",
        "label":"香港特别行政区",
        "full_label":"香港特别行政区"
    },
    {
        "id":"820000000000",
        "label":"澳门特别行政区",
        "full_label":"澳门特别行政区"
    },
    {
        "id":"0",
        "label":"其它",
        "full_label":"其它"
    }
]

export default county
